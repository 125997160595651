import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
class LeadEventsEdit extends Component {
  state = {
    event_type: "",
    event_id: 0,
    title: "",
    description: "",
    scheduled_at: "",
    added_on: "",
  };
  sch_at = "";
  componentDidMount() {
    window.resizeHandle();
    //
    window.$(".date-time-picker").datetimepicker({
      isRTL: window.App.isRTL(),
      format: "mm-dd-yyyy HH:ii P",
      showMeridian: true,
      autoclose: true,
      pickerPosition: window.App.isRTL() ? "bottom-right" : "bottom-left",
      todayBtn: true,
      startDate: new Date(),
    });
    /*.on("change", function (e) {
        window.setNativeValue(
          document.getElementById("ev_schAt"),
          e.currentTarget.value
        );
        console.log("on select event", e);
      })*/
    //
    // alert(this.props.match.params.event_id);
    this.setState({ event_id: this.props.match.params.event_id }, () => {
      this.initEvent();
    });
  }
  initEvent = () => {
    fetch(
      this.props.baseUrl + "/index.php/LeadEvents/Edit/" + this.state.event_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status && data.msg) {
          this.setState(
            {
              event_type: data.msg.type,
              event_id: data.msg.id,
              title: data.msg.title,
              description: data.msg.apnt_note,
              scheduled_at: data.msg.sch_time,
              added_on: data.msg.happened_on,
            },
            () => {
              this.sch_at = data.msg.sch_time;
              document.getElementById("ev_schAt").value = data.msg.sch_time;
            }
          );
        }
        console.log("We retreived this", data);
      })
      .catch((er) => {
        console.log("We retreived this", er);
      });
  };
  submitForm = (ev) => {
    ev.preventDefault();

    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/LeadEvents/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".LeadEventEditForm").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success", 0);
          this.initEvent();
        } else {
          window.displayAlert("#alert_container", data.msg, "danger", 0);
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <div className="page-container">
        <div className="page-content-wrapper">
          <div className="page-head">
            <div className="container">
              <div className="page-title">
                <h1>
                  {this.state.event_type == "callback"
                    ? "Edit Call Back"
                    : "Event"}
                  <small>{this.state.event_id}</small>
                </h1>
              </div>
            </div>
          </div>

          <div className="page-content">
            <div className="container">
              <div className="page-content-inner">
                <div className="row">
                  <div className="col-md-12">
                    <span id="alert_container"></span>
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">
                          <i className="fa fa-gift"></i>Edit{" "}
                          {this.state.event_type == "callback"
                            ? "Call Back"
                            : "Event"}
                        </div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body" id="port_adEv">
                        <div className="row">
                          <div className="col-md-12">
                            <form
                              className="LeadEventEditForm"
                              onSubmit={this.submitForm}
                            >
                              <input
                                type="hidden"
                                name="action"
                                value="edit_event"
                              />
                              <input
                                type="hidden"
                                name="event_id"
                                value={this.state.event_id}
                              />
                              <input
                                type="hidden"
                                name="event_type"
                                value={this.state.event_type}
                              />
                              {this.state.event_type === "callback" ? (
                                <React.Fragment>
                                  <input type="hidden" name="title" value="" />
                                  <input
                                    type="hidden"
                                    name="description"
                                    value=""
                                  />
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                              <table className="table table-striped table-bordered table-advance table-hover">
                                <tbody>
                                  {this.state.event_type !== "callback" ? (
                                    <React.Fragment>
                                      <tr>
                                        <td>Title</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            onChange={(e) =>
                                              this.setState({
                                                title: e.target.value,
                                              })
                                            }
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Description</td>
                                        <td>
                                          <textarea
                                            name="description"
                                            className="form-control"
                                            value={this.state.description}
                                            onChange={(e) =>
                                              this.setState({
                                                description: e.target.value,
                                              })
                                            }
                                          >
                                            {this.state.description}
                                          </textarea>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  <tr>
                                    <td>Scheduled On</td>
                                    <td>
                                      <input
                                        type="text"
                                        name="ev_schAt"
                                        className="date-time-picker form-control"
                                        id="ev_schAt"
                                        placeholder="Schedule"
                                        readOnly
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Event Type</td>
                                    <td>{this.state.event_type}</td>
                                  </tr>

                                  <tr>
                                    <td className="text-center" colSpan="2">
                                      <button
                                        type="submit"
                                        className="btn green-jungle"
                                      >
                                        Submit
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadEventsEdit;
