import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//check url and redirect in case of http
try {
  if (!window.location.href.includes("localhost"))
    if (window.location.protocol !== "https:") {
      //alert("ooo");
      window.location.replace(window.location.href.replace("http:", "https:"));
    }
} catch (e) {}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
