import React, { Component } from "react";
import HeadTitle from "../HeadTitle";
class ReportCDR extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_cdr",
      this.props.baseUrl + "/index.php/Report/Ajax_CDR",
      "",
      "",
      [[2, "desc"]]
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="CDR Report" subtitle="" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_cdr"
                          >
                            <thead>
                              <tr>
                                <th>Lead ID</th>
                                <th>Agent</th>
                                <th>Time</th>
                                <th>Customer Name</th>
                                <th>Phone Number</th>
                                <th>Dispo</th>
                                <th>Recording</th>
                                <th>Talk Time</th>
                                <th>Call Direction</th>
                                <th>Caller ID</th>
                                {/*<th>Dial Mode</th>*/}
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportCDR;
