import React, { Component } from 'react'
import $ from 'jquery'
import IframeComm from '../../react-iframe-comm'
import HeadTitle from '../../HeadTitle'

class UsersEmailConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: {},
      email_address: '',
      agent_email_override: 'Y',
      smtp_host: '',
      smtp_user: '',
      smtp_port: '',
      smtp_password: '',
      smtp_encryption: '',
      signin_option: 'other',
      showSmtpSettings: true,
      showSignInLink: true,
      showGmailDiv: false,
      auth_code: '',
      iframeAttributes: {},
    }
  }

  radioChange = (event) => {
    // console.log(event);
    if (event.target.checked) {
      this.setState({ signin_option: event.target.value })
      if (event.target.value == 'gmail') {
        this.setState({
          showGmailDiv: true,
          showSmtpSettings: false,
          smtp_host: 'smtp.gmail.com',
          smtp_port: 465,
        })
      } else {
        this.setState({ showGmailDiv: false, showSmtpSettings: true })
      }
    }
  }

  componentDidMount() {
    window.resizeHandle()
    window.App.initUniform()

    this.setState({
      iframeAttributes: {
        // src: "http://localhost:3001",
        //src: "https://dolphindev.asitss.com/googlesignin",
        src: 'https://dolphin.evs7.com/googlesignin',
        height: 110,
        width: 1000,
        scrolling: 'no',
        frameBorder: 0,
        name: 'gmailSignIn',
      },
    })
    fetch(this.props.baseUrl + '/index.php/Email/showUserEmailConfig', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp)
        this.setState(
          {
            /*email_address: resp.msg.gen_from_address,
            agent_email_override:
              resp.msg.agentemailoverride == false
                ? "N"
                : resp.msg.agentemailoverride,*/
            smtp_host: resp.msg.smtp_host,
            smtp_user: resp.msg.smtp_user,
            smtp_port: resp.msg.smtp_port,
            smtp_password: resp.msg.smtp_pass,
            smtp_encryption: resp.msg.smtp_encryption,
            signin_option: resp.msg.signin_option,
            showSignInLink: resp.msg.access_token == null,
          },
          function () {
            if (this.state.signin_option === 'gmail') {
              this.setState({ showGmailDiv: true, showSmtpSettings: false })
            } else if (this.state.signin_option === 'other') {
              this.setState({ showGmailDiv: false, showSmtpSettings: true })
            }
          }
        )
        window.App.initUniform()
      })
    //load its specific js file ;)
    window.Aurl = this.props.baseUrl
    const script = document.createElement('script')
    script.src = 'jsdolphin/emailtemplatesettings.js'
    script.async = true
    document.body.appendChild(script)
    ////////////////////////////////////
  }

  /*handleSignInOption = () => {
    //form.preventDefault();
    window.App.blockUI();
    let data = new URLSearchParams($("#form_SignInOption").serialize());

    fetch(this.props.baseUrl + "/index.php/Email/configUserEmail", {
      credentials: "include",
      method: "post",
      body: data,
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          this.setState(
            {
              email_address: data.msg.gen_from_address,
              agent_email_override:
                data.msg.agentemailoverride == false
                  ? "N"
                  : data.msg.agentemailoverride,
              smtp_host: data.msg.settings.smtp_host,
              smtp_user: data.msg.settings.smtp_user,
              smtp_port: data.msg.settings.smtp_port,
              smtp_password: data.msg.settings.smtp_pass,
              smtp_encryption: data.msg.settings.smtp_encryption,
              signin_option: data.msg.settings.signin_option,
              showSignInLink: data.msg.settings.access_token == null,
            },
            function () {
              if (this.state.signin_option === "gmail") {
                this.setState({ showGmailDiv: true, showSmtpSettings: false });
              } else if (this.state.signin_option === "other") {
                this.setState({ showGmailDiv: false, showSmtpSettings: true });
              }
            }
          );
          // window.bootstrapalert("Sign In option saved successfully");
         
        } else {
          // window.bootstrapalert("Error in saving Sign In option");
          window.displayAlert(
            "#alert_container",
            "Error in saving Sign In option",
            "danger"
          );
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        console.log(e);
        window.App.unblockUI();
      });
  };*/

  onReceiveMessage = (message) => {
    var data = message.data
    if (data.sender == 'gmailsignin') {
      console.log(message.data)
      console.log('onReceiveMessage')
      this.gmailSignInLinkClicked(data.response)
    } else if (data.sender == 'gmailsignout') {
      console.log('onReceiveMessage - gmailsignout')
      this.gmailSignOutLinkClicked()
    }
  }

  onReady = (content) => {
    console.log('on ready')
    //let iframe = document.getElementsByName("gmailSignIn")[0];
    //console.log(iframe.contentWindow.document.body.scrollWidth)
  }

  gmailSignInLinkClicked = (response) => {
    if (response.code != null) {
      this.setState({ smtp_host: 'smtp.gmail.com', smtp_port: 465 })
      let data = new URLSearchParams({
        code: response.code,
        signin_option: 'gmail',
      })
      window.App.blockUI()
      fetch(this.props.baseUrl + '/index.php/Email/configUserEmail', {
        credentials: 'include',
        method: 'post',
        body: data,
      })
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            this.setState({
              showSmtpSettings: false,
              showSignInLink: false,
              email_address: data.data.from_address,
            })
            // window.bootstrapalert("Gmail Sign In successfully");
            window.displayAlert(
              '#alert_container',
              'Gmail Sign In successfully',
              'success'
            )
          } else {
            // window.bootstrapalert("Error in Gmail Sign In");
            window.displayAlert(
              '#alert_container',
              'Error in Gmail Sign In',
              'danger'
            )
          }
          window.App.unblockUI()
        })
        .catch((e) => {
          console.log(e)
          window.App.unblockUI()
        })
    } else {
      console.log(response)
      console.log('error in Gmail Sign in')
    }
  }

  gmailSignOutLinkClicked = (response) => {
    // console.log("gmailSignOutLinkClicked");
    var that = this
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Email/userGmailSignOut', {
      credentials: 'include',
      method: 'post',
      // body: data
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        // console.log(data);
        if (data.status) {
          that.setState({ showSmtpSettings: false, showSignInLink: true })
          // window.displayAlert("#alert_container", data.msg, "success");
        } else {
          // window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        console.log(e)
        window.App.unblockUI()
      })
  }
  toggleSmtpPass = (e) => {
    if ($('#password').attr('type') == 'password') {
      $('#password').attr('type', 'text')
      $('#genpassword').html('<i class="fa fa-eye-slash fa-fw"></i>')
    } else {
      $('#genpassword').html('<i class="fa fa-eye fa-fw"></i>')
      $('#password').attr('type', 'password')
    }
  }

  handleSMTP = () => {
    //form.preventDefault();
    window.App.blockUI()

    let data = new URLSearchParams($('#form_addSMTP').serialize())

    fetch(this.props.baseUrl + '/index.php/Email/configUserEmail', {
      credentials: 'include',
      method: 'post',
      body: data,
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.setState(
            {
              /*email_address: data.msg.gen_from_address,
              agent_email_override:
                data.msg.agentemailoverride == false
                  ? "N"
                  : data.msg.agentemailoverride,*/
              smtp_host: data.data.smtp_host,
              smtp_user: data.data.smtp_user,
              smtp_port: data.data.smtp_port,
              smtp_password: data.data.smtp_pass,
              smtp_encryption: data.data.smtp_encryption,
              signin_option: data.data.signin_option,
              showSignInLink: data.data.access_token == null,
            },
            function () {
              if (this.state.signin_option === 'gmail') {
                this.setState({ showGmailDiv: true, showSmtpSettings: false })
              } else if (this.state.signin_option === 'other') {
                this.setState({ showGmailDiv: false, showSmtpSettings: true })
              }
            }
          )
          // window.bootstrapalert("SMTP Settings saved successfully");
          window.displayAlert(
            '#alert_container',
            'SMTP Settings saved successfully',
            'success'
          )
        } else {
          // window.bootstrapalert("Error in saving SMTP Settings");
          window.displayAlert(
            '#alert_container',
            'Error in saving SMTP Settings',
            'danger'
          )
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        console.log(e)
        window.App.unblockUI()
      })
  }
  saveAll = (e) => {
    //this.handleSignInOption();
    this.handleSMTP()
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Email Settings
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Edit</small>
                  </h1>
                </div>

                <div className="tabbable-line pull-right hidden">
                  <ul className="nav nav-tabs ">
                    <li className="active">
                      <a href="#tab_15_1" data-toggle="tab">
                        {' '}
                        List Template{' '}
                      </a>
                    </li>
                    <li>
                      <a href="#tab_15_2" data-toggle="tab">
                        {' '}
                        Settings{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <span id="alert_container"></span>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="icon-bubble font-dark"></i>
                            <span className="caption-subject font-dark sbold">
                              {' '}
                              Sign-In Options{' '}
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <form
                            id="form_SignInOption"
                            className="form-horizontal"
                          >
                            <input
                              type="hidden"
                              name="action"
                              value="update_signin_options"
                            />
                            <table className="table table-striped table-bordered table-advance">
                              <tbody>
                                <tr>
                                  <td>Sign-In Options</td>
                                  <td colSpan="3">
                                    <div
                                      className="radio-list"
                                      id="signinOptionList"
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          value="gmail"
                                          name="signin_option"
                                          id="signin_option_gmail"
                                          checked={
                                            this.state.signin_option === 'gmail'
                                          }
                                          onClick={this.radioChange}
                                        />{' '}
                                        Gmail
                                      </label>
                                      <label>
                                        <input
                                          type="radio"
                                          value="other"
                                          name="signin_option"
                                          id="signin_option_other"
                                          checked={
                                            this.state.signin_option === 'other'
                                          }
                                          onClick={this.radioChange}
                                        />{' '}
                                        Other SMTP{' '}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                {this.state.showGmailDiv === true ? (
                                  <tr
                                    id="gmailDivLink"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <td
                                      colspan="4"
                                      style={{ paddingBottom: '5px' }}
                                    >
                                      {this.state.showSignInLink === true ? (
                                        <IframeComm
                                          attributes={
                                            this.state.iframeAttributes
                                          }
                                          postMessageData='{"src":"emailTemplateSetting", "value":"Sign-In Using Gmail", "isSignedIn": false}'
                                          handleReady={this.onReady}
                                          handleReceiveMessage={
                                            this.onReceiveMessage
                                          }
                                        />
                                      ) : (
                                        // <div>
                                        //   Signed-in as Gmail user. Click here to
                                        <IframeComm
                                          attributes={
                                            this.state.iframeAttributes
                                          }
                                          postMessageData='{"src":"emailTemplateSetting", "value":"Sign-in as different user", "isSignedIn": true}'
                                          handleReady={this.onReady}
                                          handleReceiveMessage={
                                            this.onReceiveMessage
                                          }
                                        />
                                        //   or{" "}
                                        //   <a
                                        //     href="#/admin/emailtemplates/settings"
                                        //     onClick={
                                        //       this.gmailSignOutLinkClicked
                                        //     }
                                        //   >
                                        //     {" "}
                                        //     Signout
                                        //   </a>
                                        // </div>
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  <div></div>
                                )}
                                {this.state.showSmtpSettings === true ? (
                                  this.state.smtp_host != '' &&
                                  this.state.smtp_password != '' &&
                                  this.state.smtp_user != '' ? (
                                    <tr
                                      id="gmailDivLink"
                                      style={{ textAlign: 'center' }}
                                    >
                                      <td
                                        colspan="4"
                                        style={{
                                          paddingBottom: '5px',
                                          height: '110px',
                                        }}
                                      >
                                        <h1
                                          style={{
                                            fontSize: '30px',
                                            marginTop: '28px',
                                          }}
                                        >
                                          <b>
                                            Signed in with{' '}
                                            <em>{this.state.smtp_host}</em>
                                          </b>
                                        </h1>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr
                                      id="gmailDivLink"
                                      style={{ textAlign: 'center' }}
                                    >
                                      <td
                                        colspan="4"
                                        style={{
                                          paddingBottom: '5px',
                                          height: '110px',
                                        }}
                                      >
                                        <h1
                                          style={{
                                            fontSize: '30px',
                                            marginTop: '28px',
                                          }}
                                        >
                                          <b>Incomplete Smtp Detail.</b>
                                        </h1>
                                      </td>
                                    </tr>
                                  )
                                ) : (
                                  ''
                                )}
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.showSmtpSettings == true ? (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="portlet light ">
                            <div className="portlet-title">
                              <div className="caption">
                                <i className="icon-bubble font-dark"></i>
                                <span className="caption-subject font-dark sbold">
                                  {' '}
                                  SMTP Settings{' '}
                                </span>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <form
                                id="form_addSMTP"
                                className="form-horizontal"
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_email_settings"
                                />
                                <input
                                  type="hidden"
                                  name="signin_option"
                                  value="other"
                                />
                                <table className="table table-striped table-bordered table-advance">
                                  <tbody>
                                    <tr>
                                      <td>SMTP Host</td>
                                      <td>
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          className="form-control"
                                          name="smtp_host"
                                          id="smtp_host"
                                          value={this.state.smtp_host}
                                          // disabled={this.state.showSmtpSettings}
                                          onChange={(e) => {
                                            this.setState({
                                              smtp_host: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>

                                      <td
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '3px',
                                        }}
                                      >
                                        SMTP Port
                                      </td>
                                      <td>
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          className="form-control"
                                          name="smtp_port"
                                          id="smtp_port"
                                          value={this.state.smtp_port}
                                          // disabled={this.state.showSmtpSettings}
                                          onChange={(e) => {
                                            this.setState({
                                              smtp_port: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>

                                      <td
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '3px',
                                        }}
                                      >
                                        Encryption SSL/TLS
                                      </td>
                                      <td>
                                        <select
                                          name="smtp_enc"
                                          className="form-control"
                                          value={this.state.smtp_encryption}
                                          onChange={(e) => {
                                            this.setState({
                                              smtp_encryption: e.target.value,
                                            })
                                          }}
                                        >
                                          <option value="">NONE</option>
                                          <option>SSL</option>
                                          <option>TLS</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>SMTP User</td>
                                      <td>
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          className="form-control"
                                          name="smtp_user"
                                          id="smtp_user"
                                          value={this.state.smtp_user}
                                          // disabled={this.state.showSmtpSettings}
                                          onChange={(e) => {
                                            this.setState({
                                              smtp_user: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                      <td colspan="2"></td>
                                      <td
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '3px',
                                        }}
                                      >
                                        SMTP Password
                                      </td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <input
                                              autoComplete="new-password"
                                              type="password"
                                              id="password"
                                              className="form-control"
                                              name="smtp_password"
                                              style={{ paddingLeft: '10px' }}
                                              value={this.state.smtp_password}
                                              // disabled={
                                              //   this.state.showSmtpSettings
                                              // }
                                              onChange={(e) => {
                                                this.setState({
                                                  smtp_password: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="genpassword"
                                              className="btn green-jungle"
                                              type="button"
                                              onClick={this.toggleSmtpPass}
                                            >
                                              <i className="fa fa-eye fa-fw"></i>
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                    <td className="text-center" colspan="4">
                                      <button
                                        type="submit"
                                        id="save_smtp_settings"
                                        className="btn green-jungle"
                                      >
                                        Save
                                      </button>
                                    </td>
                                  </tr>*/}
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="portlet ">
                            <div className="portlet-body text-center">
                              <button
                                type="submit"
                                id="save_smtp_settings"
                                className="btn green-jungle btn-lg"
                                onClick={this.saveAll}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UsersEmailConfig
