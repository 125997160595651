import React, { Component } from 'react'
import UserGroupsdd from '../../Bricks/UserGroupsdd'
import $ from 'jquery'
import HeadTitle from '../../HeadTitle'
import { generatePassword } from '../../../helpers/minorhelp'
class UserAdd extends Component {
  state = {
    active_users: [],
    deact_user: '',
    call_after_deactivation: false,
    user_group: '',
  }
  componentDidMount() {
    window.resizeHandle()
    //load its specific js file ;)
    const script = document.createElement('script')
    script.src = 'jsdolphin/user_add.js'
    script.async = true
    document.body.appendChild(script)
    ////////////////////////////////////
  }
  handleSubmit = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#formUserAdd').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          //here we will check if number of seats have been exceeded by number of users. In case that's tru we have to ask user which user to deactivate
          try {
            window.global_alert_msg = 'User successfully Added'
            if (data.seats_left <= 0) {
              //show modal to disable a user :)
              this.listActiveUsers()
              return
            }
          } catch (e) {
            //alert(e);
          }
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          window.global_alert_msg = 'User successfully Added'
          this.props.history.push('/admin/users/view/1')
          //window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleGenPasswordClick = (e) => {
    // var generator = require("generate-password");

    // var password = generator.generate({
    //   length: 10,
    //   numbers: true,
    //   uppercase: true,
    //   lowercase: true,
    //   symbols: false,
    // });
    const password = generatePassword(10, false, true, true, true)
    $('#password').val(password)
    window.pass_show = false
    $('#genpassword').click()
  }
  listActiveUsers = () => {
    //fetch lists
    fetch(this.props.baseUrl + '/index.php/User/Ajax', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams('action=list_active_users'),
    })
      .then((data) => {
        //console.log(data);
        return data.json()
      })
      .then((data) => {
        this.setState({ active_users: data })
        window.$('#mdl_deact_user').modal('show')
      })
      .catch((err) => {})
  }
  deActivateUser = (e) => {
    //fetch lists
    fetch(this.props.baseUrl + '/index.php/User/Ajax', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams(
        'action=deactivate_user&user_id=' + this.state.deact_user
      ),
    })
      .then((data) => {
        //console.log(data);
        return data.json()
      })
      .then((data) => {
        if (data.status) {
          //call support if user has successfully deactivated a user :)
          if (this.state.call_after_deactivation) {
            document.getElementById('keypad_sel').value = '9727136622'
            window.li_call.click()
            this.setState({ call_after_deactivation: false })
          }
          //
          window.displayAlert('#alert_container', data.msg, 'success')
          //this.props.history.push("/admin/users/view/1");
        } else {
          window.$('#mdl_deact_user').modal('show')
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((err) => {
        this.props.blockUI(false)
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Users" subtitle="add new" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="formUserAdd"
                                onSubmit={this.handleSubmit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_user"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td width="40%">
                                        User ID{' '}
                                        <small>
                                          (4 or 5 digit extension number)
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          minLength="4"
                                          maxLength="5"
                                          type="text"
                                          className="form-control"
                                          name="user_id"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="full_name"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Email</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Password</td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <input
                                              type="password"
                                              id="password"
                                              className="form-control"
                                              name="password"
                                              style={{ paddingLeft: '10px' }}
                                            />
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="genpassword"
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-eye fa-fw"></i>
                                            </button>
                                            <button
                                              onClick={
                                                this.handleGenPasswordClick
                                              }
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{' '}
                                              Generate
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>User Group </td>
                                      <td>
                                        <input
                                          type="hidden"
                                          value={this.state.user_group}
                                          name="user_group"
                                        />
                                        <UserGroupsdd
                                          baseUrl={this.props.baseUrl}
                                          name="user_group"
                                          value={this.state.user_group}
                                          onChange={(selected) => {
                                            this.setState({
                                              user_group: selected,
                                            })
                                          }}
                                        ></UserGroupsdd>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>
                                        CID{" "}
                                        <small>(leave blank for default)</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="caller_id"
                                          maxLength="10"
                                        />
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td>
                                        SMS Caller ID{' '}
                                        <small>(leave blank for default)</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="sms_caller_id"
                                          maxLength="10"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Role</td>
                                      <td>
                                        <select
                                          name="role"
                                          className="bs-select form-control"
                                        >
                                          <option value="agent">Agent</option>

                                          <option value="manager">
                                            Manager
                                          </option>

                                          <option value="owner">Owner</option>

                                          {this.props.role == 'superadmin' ? (
                                            <option value="superadmin">
                                              Super Admin
                                            </option>
                                          ) : (
                                            ''
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dialing</td>
                                      <td>
                                        <select
                                          name="status"
                                          className="bs-select form-control"
                                        >
                                          <option value="Active">Yes</option>

                                          <option value="Inactive">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************************Static modal for deactivating an extra user */}
        <div
          id="mdl_deact_user"
          className="modal fade"
          tabindex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/*<button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>*/}
                <h4 className="modal-title">De-Activate A User</h4>
              </div>
              <div className="modal-body">
                <div className="alert alert-warning">
                  You are out of dialing seats. To activate dialing for this
                  user, please disable dialing for another user. Or you may call
                  to purchase an additional seat at{' '}
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      this.setState({
                        call_after_deactivation:
                          !this.state.call_after_deactivation,
                      })
                    }}
                  >
                    972-713-6622
                  </a>
                  .
                </div>
                {this.state.call_after_deactivation == true ? (
                  <div className="alert alert-info">
                    972-713-6622 will be called after a user is deactivated.
                  </div>
                ) : (
                  ''
                )}
                <select
                  name="deact_user"
                  className="form-control"
                  value={this.state.deact_user}
                  onChange={(e) => {
                    this.setState({ deact_user: e.target.value })
                  }}
                >
                  {this.state.active_users.map((user) => (
                    <option value={user.user_id}>
                      {user.full_name + '(' + user.user_id + ')'}
                    </option>
                  ))}
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn dark btn-outline"
                  onClick={(e) => {
                    this.props.blockUI(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn green"
                  onClick={(e) => this.deActivateUser(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/**************************************************************************************************** */}
      </React.Fragment>
    )
  }
}

export default UserAdd
