import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import HeadTitle from "../HeadTitle";
class Calendar extends Component {
  state = {};
  componentDidMount() {
    //window.datetimepickerinit();
    window.$(".date-time-picker").datetimepicker({
      isRTL: window.App.isRTL(),
      format: "mm-dd-yyyy HH:ii P",
      showMeridian: true,
      autoclose: true,
      pickerPosition: window.App.isRTL() ? "bottom-right" : "bottom-left",
      todayBtn: true,
      startDate: new Date(),
    });
    this.renderCal1();
    window.resizeHandle();
    //window.renderCalendar1("calendar1", this.props.baseUrl);
  }
  renderCal1 = () => {
    if (document.getElementById("calendar1").hasChildNodes()) {
      this.refreshCalendars();
    } else {
      var uurl = this.props.baseUrl;
      window.setTimeout(function () {
        //console.log(uurl);
        window.renderCalendar1("calendar1", uurl);
      }, 1000);
    }
  };
  renderCal2 = () => {
    if (document.getElementById("calendar2").hasChildNodes()) {
      this.refreshCalendars();
    } else {
      var uurl = this.props.baseUrl;
      window.setTimeout(function () {
        //console.log(uurl);
        window.renderCalendar2("calendar2", uurl);
      }, 1000);
    }
  };
  SubmitFormAddEvent = (form) => {
    form.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Calender/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".AddEventForm").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
        this.refreshCalendars();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  refreshCalendars = () => {
    //rerender calendars
    if (document.getElementById("calendar1").hasChildNodes()) {
      window.$("#calendar1").fullCalendar("refetchEvents");
    }
    if (document.getElementById("calendar2").hasChildNodes()) {
      window.$("#calendar2").fullCalendar("refetchEvents");
    }

    //////////////////////////
  };
  render() {
    return (
      <div className="page-container">
        <div className="page-content-wrapper">
          <div className="page-head">
            <div className="container">
              <div className="page-title">
                <h1>
                  Calendar
                  <small></small>
                </h1>
              </div>
              <div className="tabbable-line pull-right">
                <ul className="nav nav-tabs ">
                  <li className="active">
                    <NavLink
                      to="/#tab_15_1"
                      onClick={this.renderCal1}
                      data-toggle="tab"
                    >
                      My Calendar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/#tab_15_2"
                      onClick={this.renderCal2}
                      data-toggle="tab"
                    >
                      Shared Calendar
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="page-content">
            <div className="container">
              <div className="page-content-inner">
                <div className="row">
                  <div className="col-md-12">
                    <span id="alert_container"></span>
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">
                          <i className="fa fa-gift"></i>Add an Event
                        </div>
                        <div className="tools">
                          <NavLink to="/" className="collapse"></NavLink>
                        </div>
                      </div>
                      <div className="portlet-body" id="port_adEv">
                        <div className="row">
                          <div className="col-md-12">
                            <form
                              className="AddEventForm form-inline"
                              onSubmit={this.SubmitFormAddEvent}
                            >
                              <input
                                type="hidden"
                                className="form-control"
                                name="action"
                                value="addEvent"
                              />
                              <div className="form-group">
                                <label className="sr-only">Title</label>
                                <input
                                  type="text"
                                  name="ev_title"
                                  className="form-control"
                                  id="ev_title"
                                  placeholder="Title"
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Description</label>
                                <input
                                  type="text"
                                  name="ev_Description"
                                  className="form-control"
                                  id="ev_Description"
                                  placeholder="Description"
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Scheduled At</label>
                                <input
                                  type="text"
                                  name="ev_schAt"
                                  className="date-time-picker form-control"
                                  id="ev_schAt"
                                  placeholder="Schedule"
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Type</label>
                                <select
                                  name="ev_type"
                                  className="form-control bs-select"
                                  id="ev_type"
                                >
                                  <option value="general">General</option>

                                  <option value="appointment">
                                    Appointment
                                  </option>
                                </select>
                              </div>
                              <button type="submit" className="btn btn-default">
                                Add
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_15_1">
                        <div className="portlet light portlet-fit  calendar">
                          <div className="portlet-title">
                            <div className="col-md-8 col-md-offset-2 text-center">
                              <label
                                className="label"
                                style={{ backgroundColor: "red" }}
                              >
                                Call Back
                              </label>
                              <label
                                className="label"
                                style={{ backgroundColor: "blue" }}
                              >
                                Appointment
                              </label>
                              <label
                                className="label"
                                style={{ backgroundColor: "green" }}
                              >
                                General
                              </label>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div id="calendar1" className="has-toolbar"></div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_15_2">
                        <div className="portlet light portlet-fit  calendar">
                          <div className="portlet-title">
                            <div className="col-md-8 col-md-offset-2 text-center">
                              <label
                                className="label"
                                style={{ backgroundColor: "red" }}
                              >
                                Call Back
                              </label>
                              <label
                                className="label"
                                style={{ backgroundColor: "blue" }}
                              >
                                Appointment
                              </label>
                              <label
                                className="label"
                                style={{ backgroundColor: "green" }}
                              >
                                General
                              </label>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div id="calendar2" className="has-toolbar"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;
