import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import CampaignsDropDown from "../Bricks/CampaignsDropDown";
import HeadTitle from "../HeadTitle";
const refRate = [
  { key: 4000, value: "4 seconds" },
  { key: 10000, value: "10 seconds" },
  { key: 30000, value: "30 seconds" },
  { key: 60000, value: "1 minute" },
  { key: 120000, value: "2 minutes" },
  { key: 300000, value: "5 minutes" },
  { key: 600000, value: "10 minutes" },
  { key: 1200000, value: "20 minutes" },
  { key: 14400000, value: "40 minutes" },
  { key: 21600000, value: "60 minutes" },
  { key: 7200000, value: "2 hours" },
  { key: 63072000000, value: "2 years" },
];
class ReportRealTime extends Component {
  state = {
    user_id: "",
    campaign: "",
    campaigns: [],
    showcarrier_stats: false,
    showpresets_stats: false,
    show_agent_time_stats: false,
    refresh_rate: refRate[3].key,
    live_calls: [],
    live_agents: [],
    dialable_leads: 0,
    calls_today: 0,
    calls_answered: 0,
  };
  intervalObject = null;
  componentDidMount() {
    this.setState({ user_id: this.props.user_id });
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/realtimereport.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    this.intervalObject = setInterval(this.getStats, this.state.refresh_rate);

    //campaigns load and show with All Campaigns//
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Campaigns/Ajax", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=list_campaigns"),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        var campaigns = [{ id: "0", name: "All Campaigns" }];
        for (var i = 0; i < data.length; i++) {
          var camp_ob = {
            id: data[i].campaign_id,
            name: data[i].campaign_name,
          };
          campaigns.push(camp_ob);
        }
        //console.log(lists);
        this.setState({ campaigns });
        //window.load_select2("#qm_camps");

        this.getStats();
      })
      .catch((err) => {});
    /////////////////////////////////////////////////////
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalObject);
    //check if in session, then hangup
    if (window.webphone) {
      if (window.webphone.in_eavesdrop)
        window.webphone.wp_EavesDropHangup(window.webphone.eavesdrop_ext);

      if (window.webphone.in_whisper) {
        window.webphone.wp_WhisperHangup(window.webphone.whisper_ext);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user_id !== this.state.user_id) {
      this.setState({ user_id: nextProps.user_id });
    }
  }
  handleSubmitoptionsAll = (e, quick) => {
    e.preventDefault();

    const refrate = $("#ms_refRate").val();
    this.setState({ refresh_rate: refrate });
    window.clearInterval(this.intervalObject);
    this.intervalObject = setInterval(this.getStats, refrate);
    /*alert(refrate);*/
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Report/real_time_report", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("qm_camps=" + this.state.campaign),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          console.log(data.msg);
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  handleQuickRefreshRateUpdate = (e) => {
    let refRate = e.target.value;
    this.setState({ refresh_rate: refRate });
    clearInterval(this.intervalObject);
    this.intervalObject = setInterval(this.getStats, refRate);
  };
  /* calloncomponentMount() {
    //set onchange of first campaign
    window.$("#qm_camps").val(null).trigger("change");
    window.$("#qm_camps").on("change", function (e) {
      const selCamp = e.target.value;
      if (
        selCamp == null ||
        selCamp == "null" ||
        selCamp == undefined ||
        selCamp == ""
      )
        return;
    });
    /////////////////////////////////////
  }*/
  getStats = () => {
    //run interval to get stats dynamically
    var cmp = $("#qm_camps").val();
    var cdata = "ms_campaigns[]=" + $("#qm_camps").val();
    if (cmp == "0") {
      cdata = "";
    }
    //lets shift live agent report to react fully
    fetch(this.props.baseUrl + "/index.php/Ajax/realtime_report_stats", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams(cdata),
      cache: "no-store",
    })
      .then((fdata) => fdata.json())
      .then((fdata) => {
        console.error(fdata);
        if (fdata.live_calls == undefined) {
          fdata.live_calls = [];
        }
        if (fdata.ActiveUsers == undefined) {
          fdata.ActiveUsers = [];
        }
        this.setState({
          dialable_leads: fdata.dialable_leads,
          calls_today: fdata.total_calls,
          calls_answered: fdata.answered_calls,
          live_calls: fdata.live_calls,
          live_agents: fdata.ActiveUsers,
        });
      })
      .catch((e) => {
        console.error(e);
      });
    ////////////////////////////////////////////////////
    /*var Aurl = this.props.baseUrl;
    $.ajax({
      type: "POST",
      url: window.AJAX_PROCESS_URLRJS + "/index.php/Ajax/realtime_report_stats",
      data: cdata,
      xhrFields: {
        withCredentials: true,
      },
      success: function (data) {
        //$('#Time').html(data.server_time);
        //$("#numDialable").html(data.dialable_leads);
        //$('#statuses').html(data.statuses.count);
        //$('#statuses').attr('title',data.statuses.statuses);

        //$("#calls_today").html(data.total_calls);
        //$("#calls_answered").html(data.answered_calls);
        //$('#calls_dropped').html(data.dropped_calls);
        //$('#calls_drop_percent').html(data.dropped_call_percent);

        var live_calls_html = "";
        var live_agents_html = "";
        var live_calls = data.live_calls;

        for (var i = 0; i < live_calls.length; i++) {
          ///capitalize first letter of call status
          var cst = live_calls[i].call_status;
          live_calls[i].call_status =
            cst.charAt(0).toUpperCase() + cst.slice(1);
          ///////
          live_calls_html +=
            "<tr><td>" +
            live_calls[i].agent +
            "</td><td>" +
            live_calls[i].start_time +
            "</td><td>" +
            live_calls[i].call_status +
            "</td><td>" +
            live_calls[i].duration +
            "</td>";
        }

        var live_agents = data.ActiveUsers;
        for (var j = 0; j < live_agents.length; j++) {
          //lets see if its in whisper barge or eaves drop and do we need to give him ability to drop out ;)
          

          var status = "";
          switch (live_agents[j].status) {
            case "active":
              status =
                "<span class='label label-sm label-info'> Active </span>";
              break;
            case "incall":
              status =
                "<span class='label label-sm label-success'> In Call </span>";
              break;
            case "indispo":
              status =
                "<span class='label label-sm label-primary'> In Dispo </span>";
              break;
            default:
              status =
                "<span class='label label-sm label-danger'> In Active </span>";
              break;
          }
          if (window.webphone.in_whisper) {
            live_agents_html +=
              '<tr><td><div id="user_name"></div>' +
              live_agents[j].user_id +
              " - " +
              live_agents[j].full_name +
              '</td><td><div id="user_status">' +
              status +
              "</div></td><td>" +
              live_agents[j].time +
              '</td><td><button id="boot_user" title="Force Logout" href="<?= base_url()?>index.php/Report/boot_user/' +
              live_agents[j].user_id +
              '" class="btn btn-danger boot_user"><i class="icon-user-unfollow"></i></button>' +
              '<a title="EavesDrop" href="javascript:;" class="btn btn-info" onclick="webphone.wp_EavesDrop(\'' +
              live_agents[j].user_id +
              '\')"><i class="fa fa-eye"></i></a>' +
              '<a class="btn yellow-gold" id="whisper_' +
              live_agents[j].user_id +
              '"  title="Whisper" href="javascript:webphone.wp_WhisperHangup(\'' +
              live_agents[j].user_id +
              '\');"><i class="fa fa-bullhorn"></i></a>' +              
              "</td></tr>";
          } else {
            live_agents_html +=
              '<tr><td><div id="user_name"></div>' +
              live_agents[j].user_id +
              " - " +
              live_agents[j].full_name +
              '</td><td><div id="user_status">' +
              status +
              "</div></td><td>" +
              live_agents[j].time +
              '</td><td><button  id="boot_user" title="Force Logout" boot_id="' +
              live_agents[j].user_id +
              '" class="btn btn-danger boot_user"><i class="icon-user-unfollow"></i></button>' +
              '<a title="EavesDrop" href="javascript:;" class="btn btn-info" onclick="webphone.wp_EavesDrop(\'' +
              live_agents[j].user_id +
              '\')"><i class="fa fa-eye"></i></a>' +
              '<a class="btn yellow-gold" id="whisper_' +
              live_agents[j].user_id +
              '"  title="Whisper" href="javascript:webphone.wp_Whisper(\'' +
              live_agents[j].user_id +
              '\');"><i class="fa fa-bullhorn"></i></a>' +              
              "</td></tr>";
          }
        }

        //$("#live_calls").html(live_calls_html);
        //$("#live_agents_html").html(live_agents_html);

        //apply the event on boot user
      },
      dataType: "json",
    });*/
  };
  EavesDrop = (user_id) => {
    if (
      window.webphone.in_eavesdrop ||
      window.webphone.in_whisper ||
      window.webphone.in_barge
    ) {
      if (
        window.webphone.eavesdrop_ext != user_id &&
        window.webphone.whisper_ext != user_id &&
        window.webphone.barge_ext != user_id
      ) {
        window.bootbox.alert({
          message:
            "A session is already in progress. Plesae end that before starting another.",
        });
        return false;
      }
    }
    if (!window.webphone.in_eavesdrop) window.webphone.wp_EavesDrop(user_id);
    else window.webphone.wp_EavesDropHangup(user_id);
  };
  Barge = (user_id) => {
    if (
      window.webphone.in_eavesdrop ||
      window.webphone.in_whisper ||
      window.webphone.in_barge
    ) {
      if (
        window.webphone.eavesdrop_ext != user_id &&
        window.webphone.whisper_ext != user_id &&
        window.webphone.barge_ext != user_id
      ) {
        window.bootbox.alert({
          message:
            "A session is already in progress. Plesae end that before starting another.",
        });
        return false;
      }
    }
    if (!window.webphone.in_barge) window.webphone.wp_Barge(user_id);
    else window.webphone.wp_BargeHangup(user_id);
  };
  Whisper = (user_id) => {
    if (
      window.webphone.in_eavesdrop ||
      window.webphone.in_whisper ||
      window.webphone.in_barge
    ) {
      if (
        window.webphone.eavesdrop_ext != user_id &&
        window.webphone.whisper_ext != user_id &&
        window.webphone.barge_ext != user_id
      ) {
        window.bootbox.alert({
          message:
            "A session is already in progress. Plesae end that before starting another.",
        });
        return false;
      }
    }

    if (!window.webphone.in_whisper) {
      window.webphone.wp_Whisper(user_id);
    } else {
      window.webphone.wp_WhisperHangup(user_id);
    }
  };
  bootUser = (user_id) => {
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Report/boot_user/" + user_id, {
      method: "GET",
      credentials: "include",
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  customformatDuration = (dur) => {
    var durr = dur.split(":");
    var dst_ans = "";
    if (durr[0] !== "00") dst_ans = durr[0] + ":" + durr[1] + ":" + durr[2];
    else if (durr[1] !== "00") dst_ans = durr[1] + ":" + durr[2];
    else dst_ans = ":" + durr[2];

    return dst_ans;
  };
  render() {
    let css_here =
      ".spinner-eff {position: relative;width: 35px;height: 35px;}";
    return (
      <React.Fragment>
        <style>{css_here}</style>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Live Agent Report
                    <small></small>
                  </h1>
                </div>

                <div className="page-toolbar hidden">
                  <div className="btn-group btn-theme-panel">
                    <NavLink
                      to="/"
                      className="btn btn-tp dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="icon-settings"></i>
                    </NavLink>
                    <div className="dropdown-menu theme-panel pull-right dropdown-custom hold-on-click">
                      <div className="row">
                        <div className="col-md-12">
                          <form
                            id="form_optionsall"
                            onSubmit={this.handleSubmitoptionsAll}
                          >
                            <table className="table table-striped table-bordered table-advance table-hover">
                              <thead></thead>
                              <tbody>
                                <tr>
                                  <td>Campaigns</td>
                                  <td>
                                    <a id="ms_cmp_sel_all">Select All</a>
                                    &nbsp;
                                    <a id="ms_cmp_desel_all">Deselect All</a>
                                    {/*<select
                                    multiple="multiple"
                                    className="multi-select"
                                    id="ms_campaigns"
                                    name="ms_campaigns[]"
                                  >
                                   
                                  </select>*/}
                                    <CampaignsDropDown
                                      baseUrl={this.props.baseUrl}
                                      class="multi-select"
                                      ismulti={true}
                                      id="ms_campaigns"
                                      name="ms_campaigns[]"
                                    ></CampaignsDropDown>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Show Carrier Stats</td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-5">
                                        <select
                                          name="show_carrier_stats"
                                          className="form-control select2 input-sm"
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Show Preset Stats</td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-5">
                                        <select
                                          name="show_preset_stats"
                                          className="form-control select2 input-sm"
                                          data-style="blue"
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Agent Time Stats</td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-5">
                                        <select
                                          name="show_agenttime_stats"
                                          className="form-control select2 input-sm"
                                          data-style="blue"
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Refresh Rate</td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-5">
                                        <select
                                          className="form-control select2 input-sm"
                                          name="ms_refRate"
                                          id="ms_refRate"
                                          data-style="blue"
                                          value={this.state.refresh_rate}
                                          onChange={(e) => {
                                            this.setState({
                                              refresh_rate: e.target.value,
                                            });
                                          }}
                                        >
                                          {refRate.map((rr) => (
                                            <option value={rr.key}>
                                              {rr.value}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colspan="2">
                                    <button
                                      id="btn_submit_allopts"
                                      type="submit"
                                      className="btn green-jungle"
                                    >
                                      Submit
                                    </button>
                                    <button
                                      type="button"
                                      className="btn default hidden"
                                    >
                                      Cancel
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row widget-row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <table className="table table-striped table-bordered table-advance table-hover">
                        <tbody>
                          <tr>
                            <td
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Campaign
                            </td>
                            <td>
                              <select
                                className="bs-select form-control "
                                name="qm_camps"
                                id="qm_camps"
                                data-style="blue"
                                onChange={(e) => {
                                  this.getStats();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {this.state.campaigns.map(function (item, i) {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>

                              {/*<CampaignsDropDown
                                baseUrl={this.props.baseUrl}
                                name="qm_camps"
                                id="qm_camps"
                                MountCall={this.calloncomponentMount}
                              ></CampaignsDropDown>*/}
                            </td>

                            <td
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Refresh Rate
                            </td>
                            <td>
                              <select
                                className="bs-select form-control "
                                name="qm_refreshRate"
                                id="qm_refreshRate"
                                data-style="blue"
                                value={this.state.refresh_rate}
                                onChange={this.handleQuickRefreshRateUpdate}
                                style={{ cursor: "pointer" }}
                              >
                                {refRate.map((rr) => (
                                  <option value={rr.key}>{rr.value}</option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row widget-row">
                    <div className="col-md-4">
                      <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                        <h4 className="widget-thumb-heading">Dialable Leads</h4>
                        <div className="widget-thumb-wrap">
                          <i className="widget-thumb-icon bg-grey-salt bg-font-grey-salt fa fa-road"></i>
                          <div className="widget-thumb-body">
                            <span className="widget-thumb-subtitle"></span>
                            <span
                              className="widget-thumb-body-stat"
                              id="numDialable"
                            >
                              {this.state.dialable_leads}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                        <h4 className="widget-thumb-heading">Calls Today</h4>
                        <div className="widget-thumb-wrap">
                          <i className="widget-thumb-icon bg-yellow-soft bg-font-yellow-soft fa fa-phone"></i>
                          <div className="widget-thumb-body">
                            <span className="widget-thumb-subtitle"></span>
                            <span
                              className="widget-thumb-body-stat"
                              id="calls_today"
                            >
                              {this.state.calls_today}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                        <h4 className="widget-thumb-heading">Calls Answered</h4>
                        <div className="widget-thumb-wrap">
                          <i className="widget-thumb-icon bg-yellow-gold bg-font-yellow-gold fa fa-mail-reply"></i>
                          <div className="widget-thumb-body">
                            <span className="widget-thumb-subtitle"></span>
                            <span
                              className="widget-thumb-body-stat"
                              id="calls_answered"
                            >
                              {this.state.calls_answered}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet box green">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="fa fa-comments"></i>LIVE Calls
                          </div>
                          <div className="tools">
                            <NavLink to="/" className="collapse"></NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="table-scrollable">
                            <table className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th>Agent</th>
                                  <th>Start Time</th>
                                  <th>Status</th>
                                  <th>Duration</th>
                                  <th>Direction</th>
                                </tr>
                              </thead>
                              <tbody id="live_calls">
                                {this.state.live_calls.map((lc) =>
                                  lc.agent !== this.state.user_id ? (
                                    <tr>
                                      <td>
                                        <img
                                          className="img-circle"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          src={
                                            this.props.baseUrl +
                                            "/index.php/User/get_users_avatar/" +
                                            lc.agent
                                          }
                                        />{" "}
                                        &nbsp;&nbsp;
                                        {lc.full_name + " (" + lc.agent + ")"}
                                      </td>
                                      <td>{lc.start_time}</td>
                                      <td>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {lc.call_status == "incall"
                                            ? "In Call"
                                            : lc.call_status}
                                        </span>
                                      </td>
                                      <td>
                                        {this.customformatDuration(lc.duration)}
                                      </td>
                                      <td>{lc.call_direction}</td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet box green">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="fa fa-comments"></i>Active Agents
                          </div>
                          <div className="tools">
                            <NavLink to="/" className="collapse"></NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="table-scrollable">
                            <table className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Status</th>
                                  <th>Time Logged In</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody id="live_agents_html">
                                {this.state.live_agents.map((ac) =>
                                  ac.user_id !== this.state.user_id ? (
                                    <tr
                                      id={"la_trow_" + ac.user_id}
                                      style={
                                        window.webphone
                                          ? (window.webphone.in_eavesdrop ||
                                              window.webphone.in_whisper) &&
                                            (window.webphone.whisper_ext ==
                                              ac.user_id ||
                                              window.webphone.eavesdrop_ext ==
                                                ac.user_id)
                                            ? { border: "2px solid #C3F6C3" }
                                            : {}
                                          : {}
                                      }
                                    >
                                      <td>
                                        <img
                                          className="img-circle"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          src={
                                            this.props.baseUrl +
                                            "/index.php/User/get_users_avatar/" +
                                            ac.user_id
                                          }
                                        />{" "}
                                        &nbsp;&nbsp;
                                        {ac.full_name + " (" + ac.user_id + ")"}
                                      </td>
                                      <td>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {ac.status == "incall"
                                            ? "In Call"
                                            : ac.status == "indispo"
                                            ? "In Dispo"
                                            : ac.status}
                                        </span>
                                      </td>
                                      <td>
                                        {this.customformatDuration(ac.time)}
                                      </td>
                                      <td>
                                        <button
                                          onClick={(e) => {
                                            this.bootUser(ac.user_id);
                                          }}
                                          className="btn btn-danger boot_user"
                                          title="Force Logout"
                                        >
                                          <i class="icon-user-unfollow"></i>
                                        </button>
                                        {window.webphone ? (
                                          <React.Fragment>
                                            <button
                                              onClick={(e) => {
                                                this.EavesDrop(ac.user_id);
                                              }}
                                              className="btn btn-info"
                                              title="EavesDrop"
                                              id={"btnEavesdrop_" + ac.user_id}
                                            >
                                              {window.webphone.in_eavesdrop ==
                                                true &&
                                              window.webphone.eavesdrop_ext ==
                                                ac.user_id ? (
                                                <i class="fa fa-stop"></i>
                                              ) : (
                                                <i class="fa fa-eye"></i>
                                              )}
                                            </button>
                                            {/***Barge button */}
                                            <button
                                              onClick={(e) => {
                                                this.Barge(ac.user_id);
                                              }}
                                              className="btn btn-info"
                                              title="Barge"
                                              id={"btnBarge_" + ac.user_id}
                                            >
                                              {window.webphone.in_barge ==
                                                true &&
                                              window.webphone.barge_ext ==
                                                ac.user_id ? (
                                                <i class="fa fa-stop"></i>
                                              ) : (
                                                <i class="fa fa-headphones"></i>
                                              )}
                                            </button>
                                            {/************************ */}
                                            <button
                                              onClick={(e) => {
                                                this.Whisper(ac.user_id);
                                              }}
                                              className="btn yellow-gold"
                                              title="Whisper"
                                              id={"btnWhisper_" + ac.user_id}
                                            >
                                              {window.webphone.in_whisper ==
                                                true &&
                                              window.webphone.whisper_ext ==
                                                ac.user_id ? (
                                                <i class="fa fa-stop"></i>
                                              ) : (
                                                <i class="fa fa-bullhorn"></i>
                                              )}
                                            </button>
                                            <span
                                              id={"we_" + ac.user_id}
                                              class={
                                                window.webphone.in_whisper ==
                                                  true ||
                                                window.webphone.in_eavesdrop ==
                                                  true
                                                  ? ac.user_id ==
                                                      window.webphone
                                                        .whisper_ext ||
                                                    ac.user_id ==
                                                      window.webphone
                                                        .eavesdrop_ext
                                                    ? ""
                                                    : "hidden"
                                                  : "hidden"
                                              }
                                            >
                                              <div
                                                id="phone_incall_anim"
                                                className="info pull-right"
                                              >
                                                <div className="live_call_anim">
                                                  <div className="spinner-eff spinner-eff-3">
                                                    <div className="circle circle-1"></div>
                                                    <div className="circle circle-2"></div>
                                                    <div className="circle circle-3"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportRealTime;
