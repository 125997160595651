import React, { Component } from "react";
import $ from "jquery";
class ServerEdit extends Component {
  state = {
    server_id: "",
    name: "",
    ip: "",
    url: "",
    tenant_url_convention: "",
    pbx_user: "",
    pbx_pass: "",
    api_key: "",
    ftp_user: "",
    ftp_pass: "",
    ftp_port: "",
    active: "",
  };
  status_opts = [
    { key: 0, text: "Inactive" },
    { key: 1, text: "Active" },
  ];
  componentDidMount() {
    window.resizeHandle();
  }
  handleEditServer = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Servers/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_editserver").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  componentDidMount() {
    const server_id = this.props.match.params.server_id;
    //fetch and fill with info
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Servers/Edit/" + server_id, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          server_id: resp.id,
          name: resp.name,
          ip: resp.ip,
          url: resp.url,
          tenant_url_convention: resp.tenant_url_convention,
          pbx_user: resp.pbx_user,
          pbx_pass: resp.pbx_pass,
          api_key: resp.api_key,
          ftp_user: resp.ftp_user,
          ftp_pass: resp.ftp_pass,
          ftp_port: resp.ftp_port,
          active: resp.active,
        });
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    ///////////////////////////////
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Servers
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <form
                              id="form_editserver"
                              onSubmit={this.handleEditServer}
                            >
                              <input
                                type="hidden"
                                value="edit"
                                name="action"
                                className="form-control"
                              />
                              <input
                                type="hidden"
                                value={this.state.server_id}
                                name="id"
                                className="form-control"
                              />
                              <div className="col-md-8 col-md-offset-2">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="name"
                                          className="form-control"
                                          value={this.state.name}
                                          onChange={(e) => {
                                            this.setState({
                                              name: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>IP</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ip"
                                          className="form-control"
                                          value={this.state.ip}
                                          onChange={(e) => {
                                            this.setState({
                                              ip: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Main Url</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="url"
                                          className="form-control"
                                          value={this.state.url}
                                          onChange={(e) => {
                                            this.setState({
                                              url: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tenant Url Convention</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="tenant_url_convention"
                                          className="form-control"
                                          value={
                                            this.state.tenant_url_convention
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              tenant_url_convention:
                                                e.target.value,
                                            });
                                          }}
                                        />
                                        <div className="alert alert-info">
                                          <small>
                                            * (asterisk) - random numerics
                                            <br />
                                            ! (excalamation mark) for random
                                            alphabets
                                            <br />
                                            Example
                                            pbx9*&ampersand;!.zoomcalls.com will
                                            produce something like
                                            pbx90ga.zoomcalls.com or
                                            pbx901a.zoomcalls.com
                                          </small>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Fusionpbx Login User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="pbx_user"
                                          className="form-control"
                                          value={this.state.pbx_user}
                                          onChange={(e) => {
                                            this.setState({
                                              pbx_user: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Login Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="pbx_pass"
                                          className="form-control"
                                          value={this.state.pbx_pass}
                                          onChange={(e) => {
                                            this.setState({
                                              pbx_pass: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Api Key</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="api_key"
                                          className="form-control"
                                          value={this.state.api_key}
                                          onChange={(e) => {
                                            this.setState({
                                              api_key: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_user"
                                          className="form-control"
                                          value={this.state.ftp_user}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_user: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="ftp_pass"
                                          className="form-control"
                                          value={this.state.ftp_pass}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_pass: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Port</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_port"
                                          className="form-control"
                                          value={this.state.ftp_port}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_port: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Status</td>
                                      <td>
                                        <select
                                          className="form-control"
                                          name="active"
                                          value={this.state.active}
                                          onChange={(e) =>
                                            this.setState({
                                              active: e.target.value,
                                            })
                                          }
                                        >
                                          {this.status_opts.map((opt) => (
                                            <option
                                              key={opt.key}
                                              value={opt.key}
                                            >
                                              {opt.text}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn green-jungle"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServerEdit;
