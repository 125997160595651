import React, { Component } from 'react'
import LeadStatusDropDown from '../Bricks/LeadStatusDropDown'
import UsersDropDown from '../Bricks/UsersDropDown'
import EmailTemplatesDropDown from '../Bricks/EmailTemplatesDropDown'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { Select, Button } from 'antd'
const { Option } = Select
class PanelLeadInfo extends Component {
  state = {
    lead_email: '',
    email_from: '',
    email_templates: [],
    state: '',
    city: '',
    city: '',
    zip_code: '',
    isContact: false,
    owner: '',
    text_templates: [],
  }
  customfields_interval = null
  componentDidMount() {
    window
      .$('#qlv_message')
      .summernote({ height: 200 })
      .on('summernote.change', function (we, contents, $editable) {
        $('#email_body').html(contents)
      })

    let burl = this.props.baseUrl
    $('#qlv_templates_dropdown').change(function () {
      var template_id = $('#qlv_templates_dropdown').val()
      window.$.ajax({
        type: 'POST',
        url: burl + '/index.php/Ajax/Ajax_template',
        xhrFields: {
          withCredentials: true,
        },
        data: { id: template_id, lead_id: window.lead_dialed },
        success: function (data) {
          data = JSON.parse(data)
          // console.log(data['email_subject']);
          //window.$("#qlv_message").val(data["email_body"]);
          //window.$("#qlv_message").html(data["email_body"]);
          window
            .$('#qlv_message')
            .summernote('code', $('<div />').html(data['email_body']).text())
          window.$('#qlv_subject').html(data['email_subject'])
          window.$('#qlv_subject').val(data['email_subject'])
          window.$('#qlv_email_body').html(data['email_body'])
          window.$('#qlv_email_body').val(data['email_body'])
        },
      })
    })
    ////////////////////////////////////////////////////
    //this.props.blockUI(true);
    fetch(this.props.baseUrl + '/index.php/Email/email_template_list', {
      credentials: 'include',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          //let data=data.msg;
          //console.log(lead);
          //console.log("HIIII");
          this.setState({
            email_from: data.msg.gen_from_address,
            email_templates: data.msg.templates,
          })
          console.error('asdsa')
          console.error(data)
          window.$('.bs-select').trigger('change')
        }
        //this.props.blockUI(false);
      })
      .catch((e) => {
        //this.props.blockUI(false);
      })
    ///////////////////////////////////
    //get custom fields periodically and attach here I guess every 5 minutes
    //calling it first time
    this.pick_and_list_custom_fields()
    this.customfields_interval = setInterval(
      this.pick_and_list_custom_fields,
      300000
    )
    ///////////////////////

    //attaching paging button events
    window.$('#qlv_btn_act_next').on('click', function () {
      var page = window.$('#qlv_btn_act_next').attr('page')
      window.refresh_callNotes(false, window.lead_dialed, page)
    })
    window.$('#qlv_btn_act_prev').on('click', function () {
      var page = window.$('#qlv_btn_act_prev').attr('page')
      window.refresh_callNotes(false, window.lead_dialed, page)
    })
  }
  componentWillUnmount() {
    clearInterval(this.customfields_interval)
  }
  pick_and_list_custom_fields = () => {
    fetch(this.props.baseUrl + '/index.php/Manager/List_custom_sw_fields', {
      credentials: 'include',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          if (window.$('#webphones_bar').hasClass('hidden'))
            $('#qlv_disp_custom_sw_fields').html(data.msg)
        }
      })
      .catch((e) => {})
  }
  handleSubmitNote = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    var call_note = $('#note').val()
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams(
        'action=add_call_note&lead_id=' +
          window.lead_dialed +
          '&vicidial_id=' +
          window.call_uuid +
          '&call_notes=' +
          call_note +
          '&agent_id=' +
          window.extension
      ),
      /*body: new URLSearchParams($("#form_postnote").serialize())*/
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#qlv_alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#qlv_alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleSubmitEmail = (e) => {
    window.$('#se_lead_id').val(window.lead_dialed) //making sure lead id is filled when sending email
    e.preventDefault()
    window.App.blockUI()
    //this.props.blockUI(true);
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new FormData(e.target), //new URLSearchParams($("#qlv_form_postemail").serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#qlv_alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#qlv_alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleSubmitSMS = (e) => {
    e.preventDefault()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_postsms').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#qlv_alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#qlv_alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
        //refresh texting stats
        window.manage_texting_ability()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }

  MakeContact = (e) => {
    const lead_id = window.lead_dialed
    if (lead_id == '' || lead_id == undefined || lead_id == null) return false
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Leads/Makecontact/' + lead_id, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ isContact: true })
          window.displayAlert('#qlv_alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#qlv_alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  MakeLead = (e) => {
    const lead_id = window.lead_dialed
    if (lead_id == '' || lead_id == undefined || lead_id == null) return false
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Leads/Makelead/' + lead_id, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ isContact: false })
          window.displayAlert('#qlv_alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#qlv_alert_container', data.msg, 'danger')
        }
        window.$('#static').modal('hide')
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /***********Text Template Events */
  fillTemplates = (search) => {
    if (search == undefined) search = ''
    fetch(this.props.baseUrl + '/index.php/Texting/list_select/' + search, {
      credentials: 'include',
      method: 'GET',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ text_templates: data.msg })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  handleTemplateChange = (value) => {
    console.log(`selected ${value}`)
    fetch(
      this.props.baseUrl +
        '/index.php/Texting/view_parsed/' +
        value +
        '/' +
        window.lead_dialed,
      {
        credentials: 'include',
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ text_msg: data.msg })
        }
      })
      .catch((err) => {})
  }
  handleTemplateSearch = (search) => {
    this.fillTemplates(search)
  }
  handleTemplateClear = () => {
    this.fillTemplates()
  }
  handleTemplateFocus = () => {
    this.fillTemplates()
  }
  /************************************ */
  /****Print Lead */
  printlead = () => {
    var system_fields = [
      'qlv_info_lead_id',
      'qlv_lead_id',
      'qlv_title',
      'qlv_mi',
      'qlv_phone_code',
      'qlv_address2',
      'qlv_province',
      'qlv_country_code',
      'qlv_dob',
      'qlv_email2',
      'inform_qlv_comments',
      'qlv_fname',
      'qlv_lname',
      'qlv_email',
      'qlv_gender',
      'qlv_phone',
      'qlv_phone2',
      'qlv_phone3',
      'qlv_address1',
      'qlv_city',
      'qlv_state',
      'qlv_postal_code',
      'qlv_company_i',
      'qlv_source',
      'qlv_lead_status',
      'qlv_assigned_to',
    ]
    var mywindow = window.open('', 'PRINT', 'height=400,width=600')

    mywindow.document.write(
      '<html><head><title> Dolphin Cloud Lead ID ' +
        window.$('#qlv_info_lead_id').val() +
        '</title><style>.head{text-align:center} th{text-align:left;padding-left:5px}</style>'
    )
    mywindow.document.write('</head><body >')

    mywindow.document.write(
      "<h4 class='head'> Dolphin Cloud Dialer</h1><h4 class='head'> Lead ID: " +
        window.$('#qlv_info_lead_id').val() +
        '</h4>'
    )

    mywindow.document.write('<table>')
    mywindow.document.write(
      '<tr><th>First Name</th><td>' +
        window.$('#qlv_fname').val() +
        '</td><th>Last Name</th><td>' +
        window.$('#qlv_lname').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>Email</th><td>' +
        window.$('#qlv_email').val() +
        '</td><th>Phone</th><td>' +
        window.$('#qlv_phone').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>Phone2</th><td>' +
        window.$('#qlv_phone2').val() +
        '</td><th>Phone3</th><td>' +
        window.$('#qlv_phone3').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>Address1</th><td>' +
        window.$('#qlv_address1').val() +
        '</td><th>Address2</th><td>' +
        window.$('#qlv_address2').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>City</th><td>' +
        window.$('#qlv_city').val() +
        '</td><th>State</th><td>' +
        window.$('#qlv_state').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>Zip Code</th><td>' +
        window.$('#qlv_postal_code').val() +
        '</td><th>Company</th><td>' +
        window.$('#qlv_company').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      '<tr><th>Campaign ID</th><td>' +
        window.$('#ss_campaign_id').val() +
        '</td><th>List ID</th><td>' +
        window.$('#ss_list_id').val() +
        '</td></tr>'
    )
    mywindow.document.write(
      "<tr><th>Notes</th><td colspan='3'>" +
        window.$('#inform_qlv_comments').val() +
        '</td></tr>'
    )
    let cnt = 1
    window
      .$('#form_lead_info input, #form_lead_info select')
      .each(function (index) {
        var input = $(this)
        console.log(input.attr('id') + '=' + input.val())
        if (system_fields.findIndex((e) => e == input.attr('id')) === -1) {
          if (
            $('#lbl_' + input.attr('id')).html() !== undefined &&
            $('#lbl_' + input.attr('id')).html() !== null
          ) {
            console.log('Does not exists')
            if (cnt % 2 == 1) {
              mywindow.document.write('<tr>')
            }
            mywindow.document.write(
              '<th>' +
                $('#lbl_' + input.attr('id')).html() +
                '</th><td>' +
                input.val() +
                '</td>'
            )
            cnt++
            if (cnt % 2 == 1) {
              mywindow.document.write('</tr>')
            }
          }
        }
      })
    if (cnt % 2 === 0) {
      mywindow.document.write('</tr>')
    }
    mywindow.document.write('</table>')
    mywindow.document.write('</body></html>')
    mywindow.print()
    //mywindow.onbeforeprint = function(event) { alert('hi');mywindow.close(); };
    setTimeout(function () {
      mywindow.close()
    }, 3000)
    //alert('Type: ' + input.attr('type') + 'Name: ' + input.attr('name') + 'Value: ' + input.val());
  }
  /****************** */
  render() {
    return (
      <div className="container">
        <div className="page-content-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-sidebar">
                <div className="portlet light">
                  <div className="profile-userpic">
                    <img
                      id="profile_pic"
                      src="https://www.gravatar.com/avatar/"
                      className="img-responsive"
                      style={{ width: '35%' }}
                      alt=""
                    />{' '}
                  </div>

                  <div
                    className="profile-usertitle"
                    style={{ marginTop: '10px' }}
                  >
                    <div className="profile-usertitle-name" id="qlv_username">
                      {' '}
                    </div>

                    <div className="profile-usertitle-name" id="qlv_fullName">
                      {' '}
                    </div>
                    <div className="profile-usertitle-job" id="qlv_company">
                      {' '}
                    </div>
                  </div>

                  <hr style={{ marginTop: '15px', marginBottom: '15px' }} />
                  <div>
                    <h4 className="profile-desc-title">
                      Contact Information{' '}
                      <small>
                        <Button
                          onClick={(e) => {
                            this.printlead()
                          }}
                        >
                          <i class="fa fa-print"></i>
                        </Button>
                      </small>
                    </h4>
                    <div className="margin-top-20 profile-desc-link">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="profile-sideabar-info-h">Type:</h4>
                          <span>
                            {this.state.isContact ? (
                              <React.Fragment>
                                <a
                                  className="dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Contact
                                </a>
                                <ul
                                  className="dropdown-menu"
                                  style={{ minWidth: '100px' }}
                                  role="menu"
                                >
                                  <li role="presentation">
                                    <button
                                      onClick={this.MakeLead}
                                      className="btn btn-sm btn-info"
                                    >
                                      Convert to Lead
                                    </button>
                                  </li>
                                </ul>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <a
                                  className="dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Lead
                                </a>
                                <ul
                                  className="dropdown-menu"
                                  style={{ minWidth: '100px' }}
                                  role="menu"
                                >
                                  <li role="presentation">
                                    <button
                                      onClick={this.MakeContact}
                                      className="btn btn-sm btn-info"
                                    >
                                      Convert to Contact
                                    </button>
                                  </li>
                                </ul>
                              </React.Fragment>
                            )}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <h4 className="profile-sideabar-info-h">Owner:</h4>
                          <span id="qlv_sb_owner">{this.state.owner}</span>
                        </div>
                      </div>
                    </div>
                    <div className="margin-top-10 profile-desc-link">
                      <h4 className="profile-sideabar-info-h">Email:</h4>
                      <span id="qlv_sb_email">{this.state.lead_email}</span>
                    </div>
                    <div className="margin-top-10 profile-desc-link">
                      <h4 className="profile-sideabar-info-h">Phone:</h4>
                      <span id="qlv_sb_phone"></span>
                    </div>
                    <div className="margin-top-10 profile-desc-link">
                      <h4 className="profile-sideabar-info-h">Address:</h4>
                      <span id="qlv_sb_address"></span>
                    </div>
                    <div className="margin-top-10 profile-desc-link">
                      <div className="row">
                        <div className="col-md-5">
                          <h4 className="profile-sideabar-info-h">City:</h4>
                          <span id="qlv_sb_city">{this.state.city}</span>
                        </div>
                        <div className="col-md-3">
                          <h4 className="profile-sideabar-info-h">State:</h4>
                          <span id="qlv_sb_state">{this.state.state}</span>
                        </div>
                        <div className="col-md-4">
                          <h4 className="profile-sideabar-info-h">Zip:</h4>
                          <span id="qlv_sb_state">{this.state.zip_code}</span>
                        </div>
                      </div>
                    </div>
                    {/*<div className="margin-top-10 profile-desc-link hidden">
                      <h4 className="profile-sideabar-info-h">Lead ID:</h4>
                      <span id="qlv_sb_lead_id"></span>
                    </div>
                    <div className="margin-top-10 profile-desc-link hidden">
                      <h4 className="profile-sideabar-info-h">List ID:</h4>
                      <span id="qlv_sb_list_id"></span>
                    </div>
                    <div className="margin-top-10 profile-desc-link hidden">
                      <h4 className="profile-sideabar-info-h">Campaign ID:</h4>
                      <span id="qlv_sb_campaign_id"></span>
                    </div>*/}
                  </div>
                </div>
                {/*Below is account notes field being shifted*/}
                <div className="portlet light ">
                  <div className="portlet-title">
                    <div className="caption caption-md">
                      <i className="icon-globe theme-font hide"></i>
                      <span className="caption-subject font-blue-madison bold uppercase">
                        Account Notes
                      </span>
                    </div>
                  </div>
                  <div className="portlet-body">
                    <div
                      className="row"
                      style={{ paddingRight: '10px', paddingLeft: '10px' }}
                    >
                      <textarea
                        name="comments"
                        disabled
                        className="form-control qlv_field"
                        onChange={(e) => {
                          window.$('#inform_qlv_comments').val(e.target.value)
                        }}
                        id="qlv_comments"
                      ></textarea>
                    </div>
                  </div>
                </div>
                {/*Above is account notes field being shifted*/}
              </div>

              <div className="profile-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet light ">
                      <div className="portlet-title tabbable-line">
                        <div className="caption caption-md">
                          <i className="icon-globe theme-font hide"></i>
                          <span className="caption-subject font-blue-madison bold uppercase">
                            Profile Account
                          </span>
                        </div>
                        <ul className="nav nav-tabs">
                          <li className="active">
                            <a href="#tab_qlv_activities" data-toggle="tab">
                              Activities
                            </a>
                          </li>
                          <li>
                            <a href="#tab_qlv_info" data-toggle="tab">
                              Lead Info
                            </a>
                          </li>
                          <li>
                            <a href="#tab_qlv_script" data-toggle="tab">
                              Script
                            </a>
                          </li>

                          <li>
                            <a href="#qlv_tab_email" data-toggle="tab">
                              Email
                            </a>
                          </li>

                          <li>
                            <a href="#qlv_tab_text" data-toggle="tab">
                              Text
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="portlet-body">
                        <span id="qlv_alert_container"></span>
                        <div className="tab-content">
                          <div className="tab-pane" id="tab_qlv_info">
                            <form
                              role="form"
                              onSubmit={(f) => {
                                f.preventDefault()
                                window.save_leadInfo()
                              }}
                              action={
                                this.props.baseUrl +
                                '/index.php/Ajax/updateLead'
                              }
                              id="form_lead_info"
                            >
                              <input
                                type="hidden"
                                id="qlv_info_lead_id"
                                name="lead_id"
                              />
                              <input
                                type="hidden"
                                name="title"
                                className="form-control"
                                id="qlv_title"
                              />
                              <input
                                type="hidden"
                                name="middle_initial"
                                className="form-control"
                                id="qlv_mi"
                              />
                              <input
                                type="hidden"
                                name="phone_code"
                                className="form-control"
                                id="qlv_phone_code"
                              />
                              <input
                                type="hidden"
                                name="address2"
                                className="form-control"
                                id="qlv_address2"
                              />
                              <input
                                type="hidden"
                                name="province"
                                className="form-control"
                                id="qlv_province"
                              />
                              <input
                                type="hidden"
                                name="country_code"
                                className="form-control"
                                id="qlv_country_code"
                              />
                              <input
                                type="hidden"
                                name="date_of_birth"
                                className="form-control"
                                id="qlv_dob"
                              />
                              <input
                                type="hidden"
                                name="email2"
                                id="qlv_email2"
                                className="form-control"
                              />
                              {/* adding qlv_comments here so it stays a part of this form */}
                              <input
                                type="hidden"
                                name="comments"
                                id="inform_qlv_comments"
                              />
                              {/************************** */}
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="first_name"
                                      id="qlv_fname"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      id="qlv_lname"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      name="email"
                                      id="qlv_email"
                                      className="form-control qlv_field"
                                      value={this.state.lead_email}
                                      onChange={(e) => {
                                        this.setState({
                                          lead_email: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-2 hidden">
                                    <label className="control-label">
                                      Gender
                                    </label>
                                    <select
                                      name="gender"
                                      id="qlv_gender"
                                      className="sel2_in_modal form-control qlv_field"
                                    >
                                      <option defaultValue="Male">Male</option>
                                      <option defaultValue="Female">
                                        Female
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Phone{' '}
                                    </label>
                                    <input
                                      type="text"
                                      name="phone"
                                      id="qlv_phone"
                                      minLength="10"
                                      maxLength="10"
                                      required
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Phone 2
                                    </label>
                                    <input
                                      type="text"
                                      name="phone2"
                                      id="qlv_phone2"
                                      minLength="10"
                                      maxLength="10"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Phone 3
                                    </label>
                                    <input
                                      type="text"
                                      name="phone3"
                                      id="qlv_phone3"
                                      minLength="10"
                                      maxLength="10"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      name="address1"
                                      id="qlv_address1"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      id="qlv_city"
                                      className="form-control qlv_field"
                                      value={this.state.city}
                                      onChange={(e) => {
                                        this.setState({ city: e.target.value })
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label className="control-label">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      name="state"
                                      id="qlv_state"
                                      className="form-control qlv_field"
                                      value={this.state.state}
                                      onChange={(e) => {
                                        this.setState({
                                          state: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label className="control-label">
                                      Zip Code
                                    </label>
                                    <input
                                      type="text"
                                      name="postal_code"
                                      id="qlv_postal_code"
                                      className="form-control qlv_field"
                                      value={this.state.zip_code}
                                      onChange={(e) => {
                                        this.setState({
                                          zip_code: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="control-label">
                                      Company
                                    </label>
                                    <input
                                      type="text"
                                      name="company"
                                      id="qlv_company_i"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="control-label">
                                      Source
                                    </label>
                                    <input
                                      type="text"
                                      name="source"
                                      id="qlv_source"
                                      className="form-control qlv_field"
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label className="control-label">
                                      Lead Status
                                    </label>

                                    <LeadStatusDropDown
                                      inmodal={true}
                                      baseUrl={this.props.baseUrl}
                                      data-container="body"
                                      className="form-control qlv_field"
                                      name="lead_status"
                                      id="qlv_lead_status"
                                    ></LeadStatusDropDown>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="control-label">
                                      Assigned To
                                    </label>

                                    <UsersDropDown
                                      inmodal={true}
                                      baseUrl={this.props.baseUrl}
                                      data-container="body"
                                      className="form-control qlv_field"
                                      name="assigned_to"
                                      id="qlv_assigned_to"
                                    ></UsersDropDown>
                                  </div>
                                </div>
                              </div>
                              {/*<div className="form-group">
                                <div className="row">
                                  <div className="col-md-12">
                                    <label className="control-label">
                                      Notes
                                    </label>
                                    <textarea
                                      name="comments"
                                      disabled
                                      className="form-control qlv_field"
                                      id="qlv_comments"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>*/}
                              <span id="qlv_disp_custom_sw_fields"></span>
                              <div className="row margiv-top-10">
                                <div className="col-md-4">
                                  <button type="submit" className="btn green">
                                    {' '}
                                    Save Changes{' '}
                                  </button>
                                </div>
                                <div className="col-md-8 text-right">
                                  <span className="margin-right-10">
                                    Call Count:&nbsp;
                                    <b id="qlv_sb_called_count"></b>
                                  </span>
                                  <span className="margin-right-10">
                                    <a
                                      data-target="#AssignLeadToList"
                                      data-toggle="modal"
                                    >
                                      List
                                    </a>
                                    : <b id="qlv_sb_list_id"></b>
                                  </span>
                                  <span className="margin-right-10">
                                    Lead ID: <b id="qlv_sb_lead_id"></b>
                                  </span>
                                  <span>
                                    Last Call Status:{' '}
                                    <b id="qlv_sb_last_call_status"></b>
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div
                            className="tab-pane active"
                            id="tab_qlv_activities"
                          >
                            <div className="portlet light portlet-fit ">
                              <div className="portlet-body">
                                <div className="timeline">
                                  <div className="timeline-item">
                                    <div className="timeline-badge">
                                      <div className="timeline-icon">
                                        <i className="icon-user font-green-jungle"></i>
                                      </div>
                                    </div>
                                    <div className="timeline-body">
                                      <div className="timeline-body-arrow">
                                        {' '}
                                      </div>
                                      <div className="timeline-body-head">
                                        <form
                                          className="form-horizontal"
                                          id="qlv_form_postnote"
                                          onSubmit={this.handleSubmitNote}
                                        >
                                          <input
                                            type="hidden"
                                            name="action"
                                            value="add_call_note"
                                          />
                                          <input
                                            type="hidden"
                                            name="lead_id"
                                            id="sn_lead_id"
                                          />
                                          <input
                                            type="hidden"
                                            name="campaign_id"
                                            id="sn_campaign_id"
                                          />
                                          <input
                                            type="hidden"
                                            name="agent_id"
                                            id="sn_agent_id"
                                          />
                                          <input
                                            type="hidden"
                                            name="unique_id"
                                            id="sn_unique_id"
                                          />
                                          <label>Call Notes</label>
                                          <textarea
                                            className="qlv_field"
                                            name="note"
                                            id="note"
                                            rows="4"
                                            style={{ width: '100%' }}
                                          ></textarea>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                  <span id="qlv_leadNotes"></span>
                                </div>
                                <div className="clearfix">
                                  <div
                                    id="record_count"
                                    className="pull-left"
                                  ></div>
                                  <div className="btn-toolbar pull-right">
                                    <div className="btn-group">
                                      <button
                                        type="button"
                                        className="btn btn-default"
                                        id="qlv_btn_act_prev"
                                      >
                                        Prev
                                      </button>
                                    </div>
                                    <div className="btn-group">
                                      <button
                                        type="button"
                                        className="btn btn-default"
                                        id="qlv_btn_act_next"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tab-pane" id="tab_qlv_script">
                            <div className="row">
                              <div
                                name="script"
                                id="summernote_text"
                                className="col-md-12"
                              >
                                {' '}
                                <div
                                  className="col-md-10 col-md-offset-1"
                                  style={{
                                    height: '600px',
                                    backgroundColor: '#f3f3f3',
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div className="tab-pane" id="qlv_tab_email">
                            <form
                              className="form-horizontal"
                              id="qlv_form_postemail"
                              onSubmit={this.handleSubmitEmail}
                            >
                              <input
                                type="hidden"
                                name="action"
                                defaultValue="send_email"
                              />
                              <input
                                type="hidden"
                                name="lead_id"
                                id="se_lead_id"
                              />
                              {/*<div className="inbox-compose-btn">
                                <button className="btn green">
                                  <i className="fa fa-check"></i>Send
                                </button>
                                <div className="pull-right">
                                  <select
                                    id="templates_dropdown"
                                    className="form-control"
                                  >
                                    {this.state.email_templates.map(et => (
                                      <option value={et.key} key={et.key}>
                                        {et.value}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>*/}
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="inbox-form-group mail-to">
                                    <label className="control-label">To:</label>
                                    <div className="controls controls-to">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="SendTo"
                                        id="se_to"
                                        readOnly
                                        value={this.state.lead_email}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="inbox-form-group mail-to">
                                    <label className="control-label">
                                      From:
                                    </label>
                                    <div className="controls controls-to">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="SendFrom"
                                        id="se_from"
                                        value={this.state.email_from}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="inbox-form-group mail-to">
                                    <label className="control-label">CC:</label>
                                    <div className="controls controls-to">
                                      <input
                                        type="text"
                                        className="form-control qlv_field"
                                        name="SendCC"
                                        id="snd_email_cc"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-md-8">
                                  <div className="inbox-form-group">
                                    <label className="control-label">
                                      Subject:
                                    </label>
                                    <div className="controls">
                                      <input
                                        type="text"
                                        className="form-control qlv_field"
                                        name="EmailSubject"
                                        id="qlv_subject"
                                      />{' '}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="inbox-form-group">
                                    <label className="control-label">
                                      Template:
                                    </label>
                                    <div className="controls">
                                      <select
                                        id="qlv_templates_dropdown"
                                        className="form-control qlv_field"
                                        name="template_id"
                                      >
                                        <option></option>
                                        {this.state.email_templates.map(
                                          (et) => (
                                            <option value={et.key} key={et.key}>
                                              {et.value}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-md-12">
                                  <div className="inbox-form-group">
                                    <label className="control-label pull-left">
                                      Attachments:
                                    </label>
                                    <div
                                      className="controls pull-left"
                                      style={{
                                        paddingTop: '5px',
                                        marginLeft: '10px',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        class="form-control-file"
                                        id="attachments"
                                        name="attachments[]"
                                        multiple
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="inbox-form-group">
                                    <input
                                      type="hidden"
                                      name=""
                                      id="qlv_email_body"
                                    />
                                    <textarea
                                      name="EmailMessage"
                                      id="qlv_message"
                                      tabIndex="1"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    type="submit"
                                    className="btn green qlv_field"
                                  >
                                    <i className="fa fa-check"></i>Send
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="tab-pane" id="qlv_tab_text">
                            <form
                              className="form-horizontal"
                              id="form_postsms"
                              onSubmit={this.handleSubmitSMS}
                            >
                              <input
                                type="hidden"
                                name="action"
                                defaultValue="send_sms"
                              />
                              <input
                                type="hidden"
                                name="lead_id"
                                id="ss_lead_id"
                              />
                              <input
                                type="hidden"
                                name="campaign_id"
                                id="ss_campaign_id"
                              />
                              <input
                                type="hidden"
                                name="list_id"
                                id="ss_list_id"
                              />
                              <div className="alert alert-info">
                                <p>
                                  In order for the lead to receive the text
                                  message, they must have an SMS enabled phone.
                                  Please ensure you are following any local or
                                  national laws related to SMS messaging.{' '}
                                  <a
                                    href="https://support.evs7.com/knowledge-base/restrictions-on-sms-mms-content/"
                                    target="_blank"
                                  >
                                    View SMS restrictions.
                                  </a>
                                </p>
                                <b>
                                  <small>
                                    SMS Remaining:{' '}
                                    <span id="qlv_avaialable_credits"></span>
                                  </small>
                                </b>
                              </div>
                              <div className="form-group">
                                <div className="col-md-12">
                                  <div className="input-icon">
                                    <i className="icon-screen-smartphone"></i>
                                    <textarea
                                      rows="3"
                                      id="qlv_text_msg"
                                      name="msg"
                                      className="form-control qlv_field"
                                      value={this.state.text_msg}
                                      onChange={(e) => {
                                        this.setState({
                                          text_msg: e.target.value,
                                        })
                                      }}
                                    >
                                      {this.state.text_msg}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-md-4 margin-top-10">
                                  <Select
                                    showSearch
                                    style={{ width: 200, zIndex: 99999 }}
                                    placeholder="Select a Template"
                                    optionFilterProp="children"
                                    onChange={this.handleTemplateChange}
                                    onFocus={this.handleTemplateFocus}
                                    onSearch={this.handleTemplateSearch}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    allowClear={true}
                                    onClear={this.handleTemplateClear}
                                    getPopupContainer={(node) =>
                                      node.parentNode
                                    }
                                  >
                                    {this.state.text_templates.map(
                                      (template) => {
                                        console.log(template)
                                        return (
                                          <Option value={template.id}>
                                            {template.name}
                                          </Option>
                                        )
                                      }
                                    )}
                                  </Select>
                                </div>
                                <div className="col-md-4 pull-right margin-top-10">
                                  <button
                                    type="submit"
                                    className="btn green-jungle pull-right qlv_field"
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PanelLeadInfo
