import React, { Component } from "react";
import { Typography, Table, Empty, Popconfirm } from "antd";
import "../../../css/table_antd.css";
import HeadTitle from "../../HeadTitle";
const { Text } = Typography;
class UserGroups extends Component {
  state = {
    data: [],
    loading: false,
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSize: 10,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    sorting: {},
    global_search: "",
  };
  componentDidMount() {
    window.resizeHandle();
    this.fillInTheTable({});
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    if (show_alert !== undefined) {
      window.displayAlert(
        "#alert_container",
        window.global_alert_msg,
        "success"
      );
      window.global_alert_msg = "";
    }
    //
  }
  //Our method to bring in leads list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true });
    fetch(this.props.baseUrl + "/index.php/UserGroups/listUserGroups", {
      method: "post",
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        this.setState({
          loading: false,
          data: data.results,
          pagination: {
            ...params.pagination,
            total: data.pagination.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          },
          sortField: data.sortField,
          sortOrder: data.sortOrder,
        });
      })
      .catch((er) => {
        console.log(er);
      });
  };
  DeleteUserGroup = (name) => {
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/UserGroups/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=remove_user_group&name=" + name),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          this.fillInTheTable({ global_search: this.state.global_search });
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination: pagination, sorting: sorter });
    this.fillInTheTable({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
    });
  };
  /*********************************** */
  controlElipses = (text) => {
    if (text !== null && text !== undefined)
      text =
        text.length > 17 ? (
          <Text title={text}>{text.substring(0, 17) + "..."}</Text>
        ) : (
          text
        );
    console.warn(text);
    return text;
  };
  /**************** */
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="User Groups" subtitle="view" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <span id="alert_container"></span>
                    <div className="col-md-12">
                      <div id="bootstrap_alerts_demo"></div>

                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              User Groups
                            </span>
                            &nbsp;
                            <button
                              onClick={(e) => {
                                this.props.history.push(
                                  "/admin/usergroups/add"
                                );
                              }}
                              class="btn btn-default btn-circle btn-sm"
                            >
                              Add New
                            </button>
                          </div>
                          <div className="actions">
                            <input
                              placeholder="Search"
                              value={this.state.global_search}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    global_search: e.target.value,
                                  },
                                  function () {
                                    this.fillInTheTable({
                                      global_search: this.state.global_search,
                                    });
                                  }
                                );
                              }}
                              className="form-control input-sm"
                            />
                          </div>
                        </div>
                        <div className="portlet-body">
                          <Table
                            className="table-striped-rows"
                            size={"small"}
                            tableLayout={"auto"}
                            columns={[
                              {
                                title: "Name",
                                dataIndex: "name",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Description",
                                dataIndex: "description",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Actions",
                                dataIndex: "action",
                                render: (text, record, index) => {
                                  return (
                                    <React.Fragment>
                                      <button
                                        className="btn btn-warning"
                                        onClick={(e) => {
                                          this.props.history.push(
                                            "/admin/usergroups/edit/" +
                                              record.key
                                          );
                                        }}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      &nbsp;
                                      <Popconfirm
                                        title=" Are you sure?"
                                        onConfirm={(e) => {
                                          this.DeleteUserGroup(record.key);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <button
                                          className="btn btn-danger"
                                          onClick={(e) => {}}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </Popconfirm>
                                    </React.Fragment>
                                  );
                                },
                              },
                            ]}
                            dataSource={this.state.data}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                            scroll={{ x: true }}
                            locale={{
                              emptyText: (
                                <Empty description="No User Groups Created"></Empty>
                              ),
                            }}
                          ></Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserGroups;
