import React, { Component } from "react";
import $ from "jquery";
import { CSVLink, CSVDownload } from "react-csv";
import { dateFormat } from "highcharts";
import HeadTitle from "../HeadTitle";
class ReportExtensionSummary extends Component {
  state = {
    data: "",
    csvData: "",
    download_allowed: false,
    settings: null,
    user_role: "agent",
  };
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/reportextsummary.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    //we get settings here
    //if (this.props.user_role == "agent") this.periodicSettingsFetch();
    //else this.setState({ download_allowed: true });
    ////
    this.setState(
      {
        settings: this.props.settings,
        user_role: this.props.user_role,
      },
      function () {
        this.manage_download_ability();
      }
    );
  }
  componentDidUpdate() {
    if (
      this.state.settings == null &&
      this.props.settings !== null &&
      this.props.settings !== undefined
    ) {
      this.setState({ settings: this.props.settings });
    } else {
      if (this.props.settings !== null && this.props.settings !== undefined) {
        let st_change_needed = false;
        for (var i in this.state.settings) {
          if (this.state.settings[i] !== this.props.settings[i]) {
            st_change_needed = true;
            break;
          }
        }
        if (st_change_needed) {
          this.setState({ settings: this.props.settings }, function () {
            this.manage_download_ability();
          });
        }
      }
    }
    if (this.state.user_role !== this.props.user_role) {
      this.setState({ user_role: this.props.user_role }, function () {
        this.manage_download_ability();
      });
    }
  }
  manage_download_ability = () => {
    if (this.state.user_role == "agent") return "";
    if (this.state.user_role == "manager") {
      if (this.state.settings !== null) {
        if (this.state.settings.mgr_can_exports_leads_reports == "yes") {
          this.setState({ download_allowed: true });
        }
      }
    }
    this.setState({ download_allowed: false });
  };
  componentWillUnmount() {
    window.removeJS("jsdolphin/reportextsummary.js");
  }
  /*periodicSettingsFetch = () => {
    fetch(this.props.baseUrl + "/index.php/Ajax/GetSettings", {
      credentials: "include",
    })
      .then((blob) => blob.json())
      .then((data) => {
        if (data.status == true) {
          this.setState({ download_allowed: false });
          if (data.msg.allow_lead_export.toLowerCase() == "yes") {
            this.setState({ download_allowed: true });
          }
        }
        setTimeout(this.periodicSettingsFetch, 60000);
      })
      .catch((e) => {
        setTimeout(this.periodicSettingsFetch, 60000);
      });
  };*/
  handleFormSubmission = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Report/process_post", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_submit").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          $("#report_table").html(data.msg);
          window.HideIrrelevantData(); //hiding irrelevant columns
          this.setState({ data: data.msg, csvData: window.downloadAsCSV() });
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        {/*<!-- BEGIN CONTAINER -->*/}
        <div class="page-container">
          {/*<!-- BEGIN CONTENT -->*/}
          <div class="page-content-wrapper">
            {/*<!-- BEGIN CONTENT BODY -->
                <!-- BEGIN PAGE HEAD-->*/}
            <div class="page-head">
              <div class="container">
                {/*<!-- BEGIN PAGE TITLE -->*/}
                <div class="page-title">
                  <h1>
                    Extension Summary Report
                    <small></small>
                  </h1>
                </div>
                {/*<!-- END PAGE TITLE -->                       */}
              </div>
            </div>
            {/*<!-- END PAGE HEAD-->
                <!-- BEGIN PAGE CONTENT BODY -->*/}
            <div class="page-content">
              <div class="container">
                {/*<!-- BEGIN PAGE CONTENT INNER -->*/}
                <div class="page-content-inner">
                  <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                      <form
                        id="form_submit"
                        onSubmit={this.handleFormSubmission}
                      >
                        {/*<?php echo form_open(base_url('index.php/Report/ExtSummary'),'class="form-horizontal"','role="form"')?>*/}
                        <input
                          type="hidden"
                          name="action"
                          value="ext_summary"
                        />
                        <input type="hidden" id="esr_ds" name="date_start" />
                        <input type="hidden" id="esr_de" name="date_end" />
                        <div class="portlet light">
                          <div class="portlet-title">
                            <div class="caption">
                              <i class=" icon-layers font-green"></i>
                              <span class="caption-subject font-green bold uppercase">
                                Date Range <small id="cur_process"></small>
                              </span>
                            </div>
                            <div class="tools">
                              <a
                                href=""
                                className={
                                  this.state.data !== "" ? "expand" : "collapse"
                                }
                              >
                                {" "}
                              </a>
                            </div>
                          </div>
                          <div
                            class="portlet-body"
                            style={{
                              display:
                                this.state.data !== "" ? "none" : "block",
                            }}
                          >
                            {" "}
                            {/*<?=isset($data)?'style="display:none;"':'style="display:block;"'?>*/}
                            <div class="row">
                              <div class="col-md-12">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td>Date Range</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="hidden"
                                          id="date_start"
                                          value=""
                                        />
                                        <input
                                          type="hidden"
                                          id="date_end"
                                          value=""
                                        />
                                        <div
                                          id="date_range"
                                          class="btn default"
                                        >
                                          <i class="fa fa-calendar"></i> &nbsp;
                                          <span> </span>
                                          <b class="fa fa-angle-down"></b>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          class="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          class="btn default hidden"
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/*<?php echo form_close();?>*/}
                  </div>
                  <div class="row">
                    <div class="portlet light">
                      <div class="portlet-title">
                        <div class="caption">
                          <i class=" icon-layers font-green"></i>
                          <span class="caption-subject font-green bold uppercase">
                            Extension Summary Report{" "}
                            <small id="cur_process"></small>
                          </span>
                        </div>
                        <div class="tools">
                          &nbsp;
                          <a href="" class="collapse">
                            {" "}
                          </a>
                        </div>
                        <div class="actions">
                          {this.state.download_allowed ? (
                            <CSVLink
                              data={this.state.csvData}
                              filename={
                                "Dolphin_ESR_" +
                                window.moment().format("YYMD_Hms") +
                                ".csv"
                              }
                              class="btn btn-circle btn-icon-only btn-default"
                            >
                              <i class="fa fa-download"></i>
                            </CSVLink>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </div>
                      </div>
                      <div class="portlet-body">
                        {/*<?=isset($data)?$data:''?>*/}
                        <span id="report_table"></span>
                      </div>
                    </div>
                  </div>
                  <div>{/*<!-- END PAGE CONTENT INNER -->*/}</div>
                </div>
                {/*<!-- END PAGE CONTENT BODY -->*/}
                {/*<!-- END CONTENT BODY -->*/}
              </div>
              {/*<!-- END CONTENT -->            */}
            </div>
            {/*<!-- END CONTAINER -->*/}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportExtensionSummary;
