import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class WorkflowList extends Component {
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_workflows",
      this.props.baseUrl + "/index.php/Workflow/dtAjax",
      ""
    );

    ///////////////////////////////
    /****Below is delete control */
    var blockUI = this.props.blockUI;
    var Aurl = this.props.baseUrl;
    $("#dt_workflows").on("click", ".btn-danger", function () {
      var childKey = $(this).attr("delete_id");
      blockUI(true);
      fetch(Aurl + "/index.php/Workflow/Delete/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            $(this).closest("tr").remove();
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUI(false);
        })
        .catch((e) => {
          blockUI(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    if (show_alert !== undefined) {
      window.displayAlert(
        "#alert_container",
        window.global_alert_msg,
        "success"
      );
      window.global_alert_msg = "";
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Disposition Workflows" subtitle="view" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>

                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Disposition Workflows
                            </span>
                          </div>
                          <div className="actions">
                            <a
                              href="#/admin/dispoworkflows/add"
                              className="btn btn-default btn-circle btn-sm"
                            >
                              Add New Workflow
                            </a>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_workflows"
                          >
                            <thead>
                              <tr>
                                <th>Standard Dispos</th>
                                <th>Lead Status</th>
                                <th>Scope</th>
                                <th>Campaign</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WorkflowList;
