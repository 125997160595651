import React, { Component } from "react";
class LeadStatusDropDown extends Component {
  state = {
    lead_statuses: [],
    container: "body",
    class: "form-control",
    id: "",
    name: "lead_status",
    inmodal: false
  };
  componentDidMount() {
    if (this.props.id !== undefined) {
      this.state.id = this.props.id;
    }
    if (this.props.container !== undefined) {
      this.state.container = this.props.container;
    }
    if (this.props.class !== undefined) {
      this.state.class = this.props.class;
    }
    if (this.props.name !== undefined) {
      this.state.name = this.props.name;
    }
    if (this.props.inmodal !== undefined) {
      this.state.inmodal = this.props.inmodal;
    }
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Ajax/getLeadStatuses", {
      credentials: "include",
      method: "get"
    })
      .then(data => {
        //console.log(data);
        return data.json();
      })
      .then(data => {
        //console.log(data);
        var lead_statuses = [];
        for (var i = 0; i < data.length; i++) {
          lead_statuses.push(data[i]);
        }
        console.log(lead_statuses);
        console.log("iii");
        this.setState({ lead_statuses });
        if (this.state.inmodal == true) {
          window.load_select2_in_modal("#" + this.state.id);
        } else {
          window.load_select2("#" + this.state.id);
        }
      })
      .catch(err => {});
  }
  render() {
    let dd_lead_status = this.state.lead_statuses.map(function(item, i) {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
    return (
      <select
        data-container={this.state.container}
        name={this.state.name}
        id={this.state.id}
        className={this.state.class}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {dd_lead_status}
      </select>
    );
  }
}

export default LeadStatusDropDown;
