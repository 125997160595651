import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import CampaignsDropDown from "../Bricks/CampaignsDropDown";
import UsersDropDown from "../Bricks/UsersDropDown";
import HeadTitle from "../HeadTitle";
class MyPerfReport extends Component {
  state = {
    table: [],
    headers: [],
    download_allowed: false,
    settings: null,
    user_role: "agent",
  };
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/MyPerfReport.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    this.loadiframe();

    //we get settings here
    //if (this.props.user_role == "agent") this.periodicSettingsFetch();
    //else this.setState({ download_allowed: true });
    ////
    this.setState(
      {
        settings: this.props.settings,
        user_role: this.props.user_role,
      },
      function () {
        this.manage_download_ability();
      }
    );
  }
  componentDidUpdate() {
    if (
      this.state.settings == null &&
      this.props.settings !== null &&
      this.props.settings !== undefined
    ) {
      this.setState({ settings: this.props.settings });
    } else {
      if (this.props.settings !== null && this.props.settings !== undefined) {
        let st_change_needed = false;
        for (var i in this.state.settings) {
          if (this.state.settings[i] !== this.props.settings[i]) {
            st_change_needed = true;
            break;
          }
        }
        if (st_change_needed) {
          this.setState({ settings: this.props.settings }, function () {
            this.manage_download_ability();
          });
        }
      }
    }
    if (this.state.user_role !== this.props.user_role) {
      this.setState({ user_role: this.props.user_role }, function () {
        this.manage_download_ability();
      });
    }
  }
  manage_download_ability = () => {
    if (this.state.user_role == "agent") return "";
    if (this.state.user_role == "manager") {
      if (this.state.settings !== null) {
        if (this.state.settings.mgr_can_exports_leads_reports == "yes") {
          this.setState({ download_allowed: true });
        }
      }
    }
    this.setState({ download_allowed: false });
  };
  /*periodicSettingsFetch = () => {
    fetch(this.props.baseUrl + "/index.php/Ajax/GetSettings", {
      credentials: "include",
    })
      .then((blob) => blob.json())
      .then((data) => {
        if (data.status == true) {
          this.setState({ download_allowed: false });
          if (data.msg.allow_lead_export.toLowerCase() == "yes") {
            this.setState({ download_allowed: true });
          }
        }
        setTimeout(this.periodicSettingsFetch, 60000);
      })
      .catch((e) => {
        setTimeout(this.periodicSettingsFetch, 60000);
      });
  };*/
  handleAgentPerformance = (e) => {
    e.preventDefault();

    //we have to load current form iframe so user can download it as well ;)
    $("#download_iframe")
      .contents()
      .find("body")
      .html(
        '<form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Report/process_post?mystats=yes" id="form_agentperformance_d" ></form>'
      );
    $("#download_iframe")
      .contents()
      .find("#form_agentperformance_d")
      .html($("#form_agentperformance").html());

    //making multi select options to submit to iframe
    let campaign_selected = $("#ms_campaigns").val();
    let camp_options = "";
    for (var camp in campaign_selected) {
      camp_options +=
        "<option selected>" + campaign_selected[camp] + "</options>";
    }

    let users_selected = $("#ms_userss").val();
    let users_options = "";
    for (var users in users_selected) {
      users_options +=
        "<option selected>" + users_selected[users] + "</options>";
    }

    $("#download_iframe")
      .contents()
      .find("#form_agentperformance_d")
      .find("#ms_campaigns")
      .html(camp_options);
    $("#download_iframe")
      .contents()
      .find("#form_agentperformance_d")
      .find("#ms_users")
      .html(users_options);
    $("#download_iframe")
      .contents()
      .find("#form_agentperformance_d")
      .find("#download_field")
      .val("download");
    /////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Report/process_post", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_agentperformance").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        //console.log(data);
        if (data.status) {
          console.log(data.msg);
          let dtTable = data.msg.data;

          if (dtTable.length > 0) {
            let bheaders = [];
            for (var key in dtTable[0]) {
              bheaders.push(key);
            }
            this.setState({
              table: dtTable,
              headers: bheaders,
            });
          }
        } else {
          this.setState({
            table: [],
            headers: [],
          });
        }
        //collapse form
        /////////////////////////
        this.props.blockUI(false);
        //collapse form now
        if (window.$("#collapseBtn").hasClass("collapse")) {
          window.$("#collapseBtn").click();
        }

        /////////////////////////
      })
      .catch((e) => {
        this.setState({
          table: [],
          headers: [],
        });
        this.props.blockUI(false);
      });
  };
  handleReportDownload = (e) => {
    $("#download_iframe").contents().find("#form_agentperformance_d").submit();
  };
  loadiframe = () => {
    $("#download_iframe")
      .contents()
      .find("html")
      .html(
        '<html><head></head><body><form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Report/process_post" id="form_agentperformance_d" ></form> </body></html>'
      );
  };
  render() {
    return (
      <React.Fragment>
        <iframe id="download_iframe" style={{ display: "none" }}></iframe>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Agent Achievement Report
                    <small></small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <form
                        id="form_agentperformance"
                        onSubmit={this.handleAgentPerformance}
                      >
                        <input
                          type="hidden"
                          name="action"
                          value="agent_performance"
                        />
                        <input
                          type="hidden"
                          id="download_field"
                          name="download"
                          value=""
                        />
                        <input type="hidden" id="apr_ds" name="date_start" />
                        <input type="hidden" id="apr_de" name="date_end" />
                        <div className="portlet light">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className=" icon-layers font-green"></i>
                              <span className="caption-subject font-green bold uppercase">
                                Date Range <small id="cur_process"></small>
                              </span>
                            </div>
                            <div className="tools">
                              <NavLink
                                to="/"
                                id="collapseBtn"
                                className="collapse"
                              >
                                {" "}
                              </NavLink>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td>Date Range</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          id="date_start"
                                          value=""
                                        />
                                        <input
                                          type="hidden"
                                          id="date_end"
                                          value=""
                                        />
                                        <div
                                          id="date_range"
                                          className="btn default"
                                        >
                                          <i className="fa fa-calendar"></i>{" "}
                                          &nbsp;
                                          <span> </span>
                                          <b className="fa fa-angle-down"></b>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaigns</td>
                                      <td>
                                        <a id="ms_cmp_sel_all">Select All</a>
                                        &nbsp;
                                        <a to="/" id="ms_cmp_desel_all">
                                          Deselect All
                                        </a>
                                        {/*<select
                                        multiple="multiple"
                                        className="multi-select"
                                        id="ms_campaigns"
                                        name="ms_campaigns[]"
                                      ></select>*/}
                                        <CampaignsDropDown
                                          baseUrl={this.props.baseUrl}
                                          class="multi-select"
                                          ismulti={true}
                                          id="ms_campaigns"
                                          name="ms_campaigns[]"
                                        ></CampaignsDropDown>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Users</td>
                                      <td>
                                        <a to="/" id="ms_usr_sel_all">
                                          Select All
                                        </a>
                                        &nbsp;
                                        <a to="/" id="ms_usr_desel_all">
                                          Deselect All
                                        </a>
                                        {/*<select
                                        multiple="multiple"
                                        className="multi-select"
                                        id="ms_users"
                                        name="ms_users[]"
                                      ></select>*/}
                                    {/*<UsersDropDown
                                          baseUrl={this.props.baseUrl}
                                          class="multi-select"
                                          ismulti={true}
                                          id="ms_users"
                                          name="ms_users[]"
                                        ></UsersDropDown>*/}
                                    {/*</td>
                                    </tr>*/}
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          className="btn default"
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Agent Achievement Report{" "}
                              <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            {this.state.download_allowed == true ? (
                              <button
                                type="button"
                                className="btn btn-default"
                                data-original-title=""
                                title=""
                                onClick={this.handleReportDownload}
                              >
                                <i className="fa fa-download"></i>{" "}
                              </button>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}

                            <NavLink
                              to="/"
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-striped">
                                <thead>
                                  {this.state.headers.length > 0 ? (
                                    <tr>
                                      {this.state.headers.map((th) => (
                                        <th>{th}</th>
                                      ))}
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </thead>
                                <tbody>
                                  {this.state.table.map((row) => (
                                    <tr>
                                      {Object.keys(row).map((td) => (
                                        <td>{row[td]}</td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyPerfReport;
