import React, { useState } from 'react'
export default function PasswordInputFieldWithEye(props) {
  const [visibility, set_visibility] = useState(false)
  return (
    <div className="input-group">
      <div className="input-icon">
        <input
          name={props?.name}
          type={visibility ? 'text' : 'password'}
          className="form-control"
          style={{
            paddingLeft: '10px',
          }}
          value={props?.value}
          onChange={props?.onChange}
        />
      </div>
      <span className="input-group-btn">
        <button
          onClick={(e) => {
            set_visibility(!visibility)
          }}
          className="btn green-jungle"
          type="button"
        >
          <i
            className={`fa ${visibility ? 'fa-eye-slash' : 'fa-eye'} fa-fw`}
          ></i>
        </button>
      </span>
    </div>
  )
}
