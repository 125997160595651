import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import DropzoneComponent from "react-dropzone-component";

function DolDropzone(props) {
  var DropZone_ob = null;
  var djsConfig = {
    addRemoveLinks: true,
    withCredentials: true,
    maxFile: 1,
    timeout: 620000,
    params: {
      action: "add_file",
    },
  };
  var eventHandlers = {
    init: (dropzone) => {
      DropZone_ob = dropzone;
    },
    addedfile: (file, xhr, formdata) => {
      if (
        file.name.indexOf(".csv") == -1 &&
        file.name.indexOf(".xls") == -1 &&
        file.name.indexOf(".xlsx") == -1
      ) {
        window.bootbox.alert("You can only upload csv or excel spread sheets.");
        DropZone_ob.removeFile(file);
        return false;
      }
      let filesize = file.size / (1024 * 1024);
      if (filesize > 12) {
        window.bootbox.alert(
          "File size is greater than 12MB.  Please break down your list into smaller CSV files.  You may upload multiple files into a single list ID, one at a time."
        );
        DropZone_ob.removeFile(file);
        return false;
      }
    },
    removedfile: (file) => {
      props.fileAdded(false);
    },
    success: (file, xhr, formdata) => {
      var parsedJson = $.parseJSON(xhr);

      ///our array of searchable fields//
      //phone, first name, last name, company, email, address, city, state, zip
      var phone = [
        "phone number",
        "landline number",
        "phone",
        "cell number",
        "cell",
        "landline",
      ];
      var firstname = ["first name", "first_name", "first-name"];
      var lastname = ["last name", "last_name", "last-name"];
      var company = ["company", "business", "business", "business"];
      var email = ["email"];
      var address = ["address", "address1"];
      var city = ["city"];
      var state = ["state", "province"];
      var zip = ["zip", "zip code", "postal", "postal code"];
      ///////////////////////////////////////

      //for(var j=0; j<fields.length; j++){
      for (var ii = 0; ii < parsedJson.length; ii++) {
        $(".bs-map_field").append(
          '<option value="' +
            parsedJson[ii] +
            '">' +
            parsedJson[ii] +
            "</option>"
        );
        console.log("appended" + ii);
        //auto map few fields
        try {
          const option = parsedJson[ii].toLowerCase();

          for (var i = 0; i < phone.length; i++) {
            if (phone[i].indexOf(option) > -1) {
              $("#phone").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < firstname.length; i++) {
            if (firstname[i].indexOf(option) > -1) {
              $("#first_name").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < lastname.length; i++) {
            if (lastname[i].indexOf(option) > -1) {
              $("#last_name").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < company.length; i++) {
            if (company[i].indexOf(option) > -1) {
              $("#company").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < email.length; i++) {
            if (email[i].indexOf(option) > -1) {
              $("#email").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < address.length; i++) {
            if (address[i].indexOf(option) > -1) {
              $("#address").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < city.length; i++) {
            if (city[i].indexOf(option) > -1) {
              $("#city").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < state.length; i++) {
            if (state[i].indexOf(option) > -1) {
              $("#state").val(parsedJson[ii]);
              break;
            }
          }
          for (var i = 0; i < zip.length; i++) {
            if (zip[i].indexOf(option) > -1) {
              $("#zip_code").val(parsedJson[ii]);
              break;
            }
          }
        } catch (e) {}
        //
      }
      props.fileAdded(true);
      //DropZone_ob.removeFile(file);
    },
    error: (file, xhr, formdata) => {
      /*window.bootbox.alert(
        "An Error Occurred. Please try again, or contact Administrator."
      );*/
      DropZone_ob.removeFile(file);
    },
  };
  var componentConfig = {
    iconFiletypes: [".csv", ".xls"],
    showFiletypeIcon: true,
    postUrl: props.baseUrl + "/index.php/Leads/Ajax",
  };

  return (
    <DropzoneComponent
      config={componentConfig}
      eventHandlers={eventHandlers}
      djsConfig={djsConfig}
    ></DropzoneComponent>
  );
}
export default DolDropzone;
