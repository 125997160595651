import React, { Component } from "react";
import $ from "jquery";
import { Checkbox, Collapse, Select } from "antd";

import ListDropDown from "../Bricks/ListDropDown";
import UsersDropDown from "../Bricks/UsersDropDown";
import LeadStatusDropDown from "../Bricks/LeadStatusDropDown";
import DispoStatusesAll from "../Bricks/DispoStatusesAll";
import HeadTitle from "../HeadTitle";
const { Panel } = Collapse;
const { Option } = Select;
class LeadAdd extends Component {
  state = {
    phone_num: null,
    manual_dial_add: false,
    lead_id: 0,

    list_id: null,
    campaign_id: null,
    called_count: null,
    fname: null,
    lname: null,
    email: null,
    from_address: null,
    phone1: null,
    phone2: null,
    phone3: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip_code: null,
    company: null,
    source: null,
    lead_status: null,
    owner: null,
    last_call_dispo: null,
    notes: null,
    custom_fields: [], //lead.custom_fields,
    script: null,
    ignore_duplicate: "0",
    is_submitted: false,
    is_discarded: false,
    list_time_zone_setting: "MANUAL",
    lists: [],
  };
  componentDidMount() {
    window.load_select2(".bs-select");
    window.resizeHandle();
    this.fillLists();
    try {
      const pnum = this.props.match.params.phone_number;
      const leadId = this.props.match.params.lead_id;
      this.setState({ phone_num: pnum, lead_id: leadId });

      if (pnum > 0)
        this.setState(
          {
            manual_dial_add: true,
            phone1: pnum,
            phone_num: pnum,
            lead_id: leadId,
          },
          function () {
            //fetch lead info and fill the form
            this.fill_lead_info_incase_of_new_lead();
            ///////////////////////////////////////////
            this.listPopCustomFields(leadId);
          }
        );
      else this.listPopCustomFields(leadId);
    } catch (e) {
      this.setState({ manual_dial_add: false }, function () {
        this.listPopCustomFields();
      });
      console.error(e);
    }
  }
  listPopCustomFields = (lead_id) => {
    let legit_lead_id = lead_id !== undefined ? 0 : lead_id;
    //we have to bring in custom fields list and show the fields over here ;)
    fetch(
      this.props.baseUrl + "/index.php/Ajax/GetCustomFields/" + legit_lead_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((data) => data.json())
      .then((resp) => {
        console.log(resp.sw_custom_fields);
        if (
          resp.sw_custom_fields !== undefined &&
          resp.sw_custom_fields !== null
        )
          if (resp.sw_custom_fields.length !== 0)
            this.setState({ custom_fields: resp.sw_custom_fields });
      })
      .catch((e) => {
        console.log(e);
      });
    //
  };
  fill_lead_info_incase_of_new_lead = () => {
    const lead_id = this.state.lead_id;
    //fetch lead info so info
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Leads/View/" + lead_id, {
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        console.error(data);
        if (data.status) {
          let lead = data.msg.lead.info;
          let list_camp = data.msg.lead.list_and_campaign;
          this.setState({
            lead_id: lead.lead_id,
            list_id: list_camp.list_id,
            campaign_id: list_camp.campaign_id,
            called_count: lead.called_count,
            fname: lead.first_name,
            lname: lead.last_name,
            email: lead.email,
            from_address: lead.from_address,
            phone1: lead.phone,
            phone2: lead.phone2,
            phone3: lead.phone3,
            address1: lead.address1,
            address2: lead.address2,
            city: lead.city,
            state: lead.state,
            zip_code: lead.postal_code,
            company: lead.company,
            source: lead.source,
            lead_status: lead.lead_status,
            owner: lead.assigned_to,
            last_call_dispo: lead.last_call_dispo,
            notes: lead.comments,
            custom_fields: [], //lead.custom_fields,
            script: lead.script,
            email_templates: data.msg.templates,
            isContact: lead.isContact == "Y" ? true : false,
            img: lead.img,
            isDNC: lead.isDNC,
          });
          //we load custom fields in span here ;)
          //$("#disp_custom_sw_fields").html(lead.custom_fields);
          ///////////////////////////////////////////
          console.error("asdsad");
          console.error(data.msg.lead.info);
          window.$(".bs-select").trigger("change");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    ///////////////////////////////////
  };
  componentWillUnmount() {
    //do the discard lead in case its not already submitted ;)
    if (
      !this.state.is_submitted &&
      this.state.phone_num != "" &&
      this.state.phone_num != undefined
    ) {
      this.handleDiscardLead(null);
    }
  }
  updateCustomFields = (i, val) => {
    let cfields = this.state.custom_fields;
    cfields[i].value = val;
    this.setState({
      custom_fields: cfields,
    });
  };
  submitAddLead = (form) => {
    form.preventDefault();
    /*this.props.doPost(
			"/index.php/Leads/post_process",
			new URLSearchParams($(".leadAddform").serialize())
		  );
*/
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Leads/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".leadAddform").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          this.setState({ is_submitted: true });
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  handleDiscardLead = (e) => {
    this.props.blockUI(true);
    console.error(e);
    fetch(
      this.props.baseUrl +
        "/index.php/Leads/DiscardLead/" +
        this.state.phone_num,
      {
        credentials: "include",
        method: "get",
      }
    )
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          //window.displayAlert("#alert_container", data.msg, "success");
          window.global_alert_msg = data.msg;
          window.location.href = "#/fromleadadd/true";
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  handleManualDialKeep = (e) => {
    $("#form_submit").click();
  };
  fillLists = () => {
    fetch(this.props.baseUrl + "/index.php/Lists/Ajax", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=get_lists"),
    })
      .then((data) => {
        //console.log(data);
        return data.json();
      })
      .then((data) => {
        //console.log(data);
        var lists = [{ id: "", name: "" }];
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].ListId,
            name: data[i].ListName,
          };
          lists.push(list_ob);
        }
        //console.log(lists);
        this.setState({ lists });
      })
      .catch((err) => {});
  };
  updateListsData = () => {
    //list_time_zone_setting
    //fetch and fill with info
    fetch(
      this.props.baseUrl + "/index.php/Lists/Modify/" + this.state.list_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          list_time_zone_setting: resp.msg.time_zone_setting,
        });
      });
    ///////////////////////////////
  };
  render() {
    return (
      <div className="page-container">
        <div className="page-content-wrapper">
          <HeadTitle title="Add a new Lead" />

          <div className="page-content">
            <div className="container">
              <div className="page-content-inner">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <span id="alert_container"></span>
                    {/** keep manually dialed number or not thingy */}
                    <span
                      className={this.state.manual_dial_add ? "" : "hidden"}
                    >
                      <div className={"alert alert-warning "}>
                        <div className="row">
                          <div className="col-md-6">
                            <strong>Add New Lead?</strong> Do you wish to save
                            this as a new lead?
                          </div>
                          <div
                            clasName="col-md-6"
                            style={{ paddingRight: "10px" }}
                          >
                            {/*onclick="window.location.href='<?=base_url('index.php/Leads/DiscardLead/'.$pnum)?>'"*/}
                            <button
                              type="button"
                              onClick={this.handleDiscardLead}
                              className="btn red pull-right"
                            >
                              Discard
                            </button>
                            {/*onclick="document.getElementById('form_lead_sub').submit();"*/}
                            <button
                              type="button"
                              onClick={this.handleManualDialKeep}
                              className="btn green pull-right"
                              style={{ marginRight: "5px" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </span>
                    {/** */}
                    <div className="portlet light">
                      <div className="portlet-body">
                        <div className="row">
                          <div className=" col-md-12">
                            <form
                              className="leadAddform"
                              onSubmit={this.submitAddLead}
                            >
                              <input
                                type="hidden"
                                name="action"
                                value={
                                  this.state.manual_dial_add
                                    ? "update_lead_info"
                                    : "add_new_lead"
                                }
                              />
                              <input
                                type="hidden"
                                name="lead_id"
                                value={this.state.lead_id}
                              />
                              <input
                                id="ignore_duplicate"
                                type="hidden"
                                name="ignore_duplicate"
                                value={this.state.ignore_duplicate}
                              />
                              <div className=" col-md-6">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>First Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="first_name"
                                          value={this.state.fname}
                                          onChange={(e) => {
                                            this.setState({
                                              fname: e.target.value,
                                            });
                                          }}
                                          required
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Last Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="last_name"
                                          value={this.state.lname}
                                          onChange={(e) => {
                                            this.setState({
                                              lname: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email"
                                          value={this.state.email}
                                          onChange={(e) => {
                                            this.setState({
                                              email: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Phone (*)</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="phone"
                                          minLength="10"
                                          maxLength="10"
                                          required
                                          value={this.state.phone_num}
                                          onChange={(e) => {
                                            this.setState({
                                              phone_num: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Phone2</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="phone2"
                                          minLength="10"
                                          maxLength="10"
                                          value={this.state.phone2}
                                          onChange={(e) => {
                                            this.setState({
                                              phone2: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Phone3</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="phone3"
                                          minLength="10"
                                          maxLength="10"
                                          value={this.state.phone3}
                                          onChange={(e) => {
                                            this.setState({
                                              phone3: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Address</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address1"
                                          value={this.state.address1}
                                          onChange={(e) => {
                                            this.setState({
                                              address1: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>City</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="city"
                                          value={this.state.city}
                                          onChange={(e) => {
                                            this.setState({
                                              city: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>State</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="state"
                                          value={this.state.state}
                                          onChange={(e) => {
                                            this.setState({
                                              state: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className=" col-md-6">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Zip Code</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="zip_code"
                                          value={this.state.zip_code}
                                          onChange={(e) => {
                                            this.setState({
                                              zip_code: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Company</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company"
                                          value={this.state.company}
                                          onChange={(e) => {
                                            this.setState({
                                              company: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Source</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="source"
                                          value={this.state.source}
                                          onChange={(e) => {
                                            this.setState({
                                              source: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>List ID</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          name="list_id"
                                          value={this.state.list_id}
                                        />
                                        <Select
                                          style={{ width: "100%" }}
                                          defaultValue="0"
                                          onChange={(val) => {
                                            this.setState(
                                              {
                                                list_id: val,
                                              },
                                              () => {
                                                this.updateListsData();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.lists.map((item, i) => (
                                            <Option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </Option>
                                          ))}
                                        </Select>
                                        {/*<ListDropDown
                                          baseUrl={this.props.baseUrl}
                                          name="list_id"
                                          value={this.state.list_id}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                list_id: e.target.value,
                                              },
                                              () => {
                                                this.updateListsData();
                                              }
                                            );
                                          }}
                                        />*/}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Lead Status</td>
                                      <td>
                                        <LeadStatusDropDown
                                          name="lead_status"
                                          id="lead_status_LA"
                                          baseUrl={this.props.baseUrl}
                                          value={this.state.lead_status}
                                          onChange={(e) => {
                                            this.setState({
                                              lead_status: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Owner</td>
                                      <td>
                                        <UsersDropDown
                                          name="assigned_to"
                                          id="assigne_to_LA"
                                          baseUrl={this.props.baseUrl}
                                          value={this.state.owner}
                                          onChange={(e) => {
                                            this.setState({
                                              owner: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Last Call Dispo</td>
                                      <td>
                                        <DispoStatusesAll
                                          name="last_call_dispo"
                                          baseUrl={this.props.baseUrl}
                                          value={this.state.last_call_dispo}
                                          onChange={(e) => {
                                            this.setState({
                                              last_call_dispo: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Notes</td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          name="comments"
                                          value={this.state.notes}
                                          onChange={(e) => {
                                            this.setState({
                                              notes: e.target.value,
                                            });
                                          }}
                                        ></textarea>
                                      </td>
                                    </tr>
                                    {this.state.list_time_zone_setting ==
                                    "MANUAL" ? (
                                      <tr>
                                        <td>GMT Offset</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="gmt_offset_now"
                                            value={this.state.gmt_offset_now}
                                            onChange={(e) => {
                                              this.setState({
                                                gmt_offset_now: e.target.value,
                                              });
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                    <tr>
                                      <td colspan="2">
                                        <Checkbox
                                          onChange={(e) => {
                                            this.setState({
                                              ignore_duplicate:
                                                e.target.checked === false
                                                  ? "0"
                                                  : "1",
                                            });
                                          }}
                                        >
                                          Ignore Duplicate Check
                                        </Checkbox>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/***Custom fields section */}

                              <div
                                className="col-md-12"
                                style={{ marginBottom: "10px" }}
                              >
                                <Collapse onChange={(e) => {}}>
                                  <Panel header="Custom Fields" key="1">
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                      <tbody>
                                        {this.state.custom_fields.length > 0
                                          ? this.state.custom_fields.map(
                                              (cf, i) => (
                                                <tr>
                                                  <td>{cf.display_name}</td>
                                                  <td>
                                                    {cf.type == "TEXT" ? (
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name={cf.name}
                                                        value={cf.value}
                                                        onChange={(e) => {
                                                          this.updateCustomFields(
                                                            i,
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    ) : cf.type == "SELECT" ? (
                                                      <select
                                                        name={cf.name}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          this.updateCustomFields(
                                                            i,
                                                            e.target.value
                                                          );
                                                        }}
                                                      >
                                                        {cf.options
                                                          .split("\r\n")
                                                          .map((op) => (
                                                            <option
                                                              value={op}
                                                              selected={
                                                                op == cf.value
                                                                  ? "selected"
                                                                  : ""
                                                              }
                                                            >
                                                              {op}
                                                            </option>
                                                          ))}
                                                      </select>
                                                    ) : (
                                                      <textarea
                                                        onChange={(e) => {
                                                          this.updateCustomFields(
                                                            i,
                                                            e.target.value
                                                          );
                                                        }}
                                                        name={cf.name}
                                                        className="form-control"
                                                      >
                                                        {cf.value}
                                                      </textarea>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          : ""}
                                      </tbody>
                                    </table>
                                  </Panel>
                                </Collapse>
                              </div>
                              {/****Custom fields section ends */}

                              <div className="col-md-12">
                                <div className="col-md-2 col-md-offset-5 ">
                                  <button
                                    id="form_submit"
                                    type="submit"
                                    className="btn green-jungle"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadAdd;
