import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import CampaignsDropDown from "../Bricks/CampaignsDropDown";
import ListDropDown from "../Bricks/ListDropDown";
import { Checkbox } from 'antd';
import HeadTitle from "../HeadTitle";
class ReportOutboundCalling extends Component {
  state = {
    totals: { calls: 0, avg_call_dur: 0 },
    HA: { calls: 0, avg_call_dur: 0, call_time: 0 },
    NA: {
      sys_calls: 0,
      agent_calls: 0,
      call_time: 0,
      calls: 0,
      avg_call_dur: 0,
    },
    Hangup_Stats: [],
    Call_status_stats: [],
    List_id_stats: [],
    Agent_stats: [],
    include_unassigned:'no'
  };
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/outboundreport.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
  }
  handleSubmitForm = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Report/process_post", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_outboundCalling").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          console.log(data.msg);
          this.setState({
            totals: data.msg.data.total,
            HA: data.msg.data.HA,
            NA: data.msg.data.NA,
            Hangup_Stats: data.msg.data.Hangup_stats,
            Call_status_stats: data.msg.data.Call_Status_stats,
            List_id_stats: data.msg.data.List_id_stats,
            Agent_stats: data.msg.data.Agent_stats,
          });
        }
        this.props.blockUI(false);
        //collapse form now
        if (window.$("#collapseBtn_ob").hasClass("collapse")) {
          window.$("#collapseBtn_ob").click();
        }
        /////////////////////////
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Outbound Dialing Report
                    <small></small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <form
                        id="form_outboundCalling"
                        onSubmit={this.handleSubmitForm}
                      >
                        <input
                          type="hidden"
                          name="action"
                          value="outbound_calling"
                        />
                        <input type="hidden" id="lobr_ds" name="date_start" />
                        <input type="hidden" id="lobr_de" name="date_end" />
                        <div className="portlet light">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className=" icon-layers font-green"></i>
                              <span className="caption-subject font-green bold uppercase">
                                Search Parameters{" "}
                                <small id="cur_process"></small>
                              </span>
                            </div>
                            <div className="tools">
                              <NavLink
                                to=""
                                id="collapseBtn_ob"
                                className="collapse"
                              >
                                {" "}
                              </NavLink>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td>Date Range</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          id="date_start"
                                          value=""
                                        />
                                        <input
                                          type="hidden"
                                          id="date_end"
                                          value=""
                                        />
                                        <input type="hidden"
                                        name="include_unassigned"
                                        value={this.state.include_unassigned}
                                        />
                                        <div
                                          id="date_range"
                                          className="btn default"
                                        >
                                          <i className="fa fa-calendar"></i>{" "}
                                          &nbsp;
                                          <span> </span>
                                          <b className="fa fa-angle-down"></b>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaigns</td>
                                      <td>
                                        <a id="ms_cmp_sel_all">Select All</a>
                                        &nbsp;
                                        <a
                                          id="ms_cmp_desel_all"
                                          style={{ marginLeft: "143px" }}
                                        >
                                          Deselect All
                                        </a>
                                        {/*<select multiple="multiple" className="multi-select" id="ms_campaigns" name="ms_campaigns[]">
																		
	</select>*/}
                                        <CampaignsDropDown
                                          baseUrl={this.props.baseUrl}
                                          ismulti={true}
                                          id="ms_campaigns"
                                          name="ms_campaigns[]"
                                        ></CampaignsDropDown>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Lists</td>
                                      <td>
                                        <a id="ms_lst_sel_all">Select All</a>
                                        &nbsp;
                                        <a
                                          id="ms_lst_desel_all"
                                          style={{ marginLeft: "143px" }}
                                        >
                                          Deselect All
                                        </a>
                                        {/*<select multiple="multiple" className="multi-select" id="ms_lists" name="ms_lists[]">
																		
</select>*/}
                                        <ListDropDown
                                          baseUrl={this.props.baseUrl}
                                          ismulti={true}
                                          id="ms_lists"
                                          name="ms_lists[]"
                                        ></ListDropDown>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                      <Checkbox onChange={(e)=>{                                        
                                          this.setState({include_unassigned:e.target.checked});                                     
                                      }}>Include Unassigned</Checkbox>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          className="btn default hidden"
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Outbound Calling Stats{" "}
                              <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <a
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </a>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="portlet box green">
                                <div className="portlet-title">
                                  <div className="caption">
                                    <i className="fa fa-gift"></i>Totals{" "}
                                  </div>
                                </div>
                                <div className="portlet-body">
                                  <table className="table table-striped">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Total Calls placed from this Search:
                                        </td>
                                        <th>{this.state.totals.calls}</th>
                                      </tr>
                                      <tr>
                                        <td>
                                          Average Call Length for all Calls in
                                          seconds:
                                        </td>
                                        <th>
                                          {this.state.totals.avg_call_dur}
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="portlet box green">
                                <div className="portlet-title">
                                  <div className="caption">
                                    <i className="fa fa-gift"></i>Connected
                                    Calls{" "}
                                  </div>
                                </div>
                                <div className="portlet-body">
                                  <table className="table table-striped">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Total Connected calls for this Search:
                                        </td>
                                        <th>{this.state.HA.calls}</th>
                                      </tr>
                                      <tr>
                                        <td>
                                          Average Call Length for all Connected
                                          Calls in seconds:
                                        </td>
                                        <th>{this.state.HA.avg_call_dur}</th>
                                      </tr>
                                      <tr>
                                        <td>Total Time for Connected Calls:</td>
                                        <th>{this.state.HA.call_time}</th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="portlet box green">
                                <div className="portlet-title">
                                  <div className="caption">
                                    <i className="fa fa-gift"></i>No Answers
                                  </div>
                                </div>
                                <div className="portlet-body">
                                  <table className="table table-striped">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Total NA calls (Busy, Disconnects,
                                          Ring No Answer):
                                        </td>
                                        <th>{this.state.NA.calls}</th>
                                      </tr>
                                      <tr>
                                        <td>Total auto NA calls :</td>
                                        <th>{this.state.NA.sys_calls}</th>
                                      </tr>
                                      <tr>
                                        <td>Total manual NA calls :</td>
                                        <th>{this.state.NA.agent_calls}</th>
                                      </tr>
                                      <tr>
                                        <td>
                                          Average Call Length for NA Calls in
                                          seconds :
                                        </td>
                                        <th>
                                          {this.state.totals.avg_call_dur}
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Call Hangup Reason Stats{" "}
                              <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <NavLink
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Hangup Reason</th>
                                  <th>Calls</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.Hangup_Stats.map((item) => (
                                  <tr>
                                    <td>
                                      {window.jsUcfirst(item.hangup_reason)}
                                    </td>
                                    <td>{item.calls}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              List ID Stats <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <NavLink
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>List</th>
                                  <th>Calls</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.List_id_stats.map((item) => (
                                  <tr>
                                    <td>
                                      {item.list} <small>{item.list_id}</small>
                                    </td>
                                    <td>{item.calls}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Call Status Stats <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <NavLink
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Description</th>
                                  <th>Calls</th>
                                  <th>Total time (Call time)</th>
                                  <th>Avg Time</th>
                                  <th>Calls/Hour</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.Call_status_stats.map((item) => (
                                  <tr>
                                    <td>{item.status}</td>
                                    <td>{item.status_desc || ""}</td>
                                    <td>{item.calls}</td>
                                    <td>{item.call_duration}</td>
                                    <td>{item.avg_time}</td>
                                    <td>{item.cph}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              List ID Stats <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <NavLink
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <table className="table table-striped">
                              <tr>
                                <thead>
                                  <th>List</th>
                                  <th>Calls</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
*/}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Agent Stats <small id="cur_process"></small>
                            </span>
                          </div>
                          <div className="tools">
                            <NavLink
                              to=""
                              className="collapse"
                              data-original-title=""
                              title=""
                            >
                              {" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Agent</th>
                                  <th>Calls</th>
                                  <th>TIME H:M:S</th>
                                  <th>AVERAGE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.Agent_stats.map((item) => (
                                  <tr>
                                    <td>
                                      {item.full_name}{" "}
                                      <small>{item.user_id}</small>
                                    </td>
                                    <td>{item.calls}</td>
                                    <td>{item.call_duration}</td>
                                    <td>{item.avg_dur}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportOutboundCalling;
