import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class UserGroupAdd extends Component {
  state = { user_group: { name: "", description: "" } };
  componentDidMount() {
    window.resizeHandle();
  }
  handleSubmitAdd = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/UserGroups/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_add_usergroup").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.global_alert_msg = data.msg;
          this.props.history.push(
            "/admin/usergroups/edit/" + this.state.user_group.name
          );
          //window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="User Groups" subtitle="add new" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_add_usergroup"
                                onSubmit={this.handleSubmitAdd}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_user_group"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          name="name"
                                          type="text"
                                          className="form-control"
                                          required
                                          value={this.state.user_group.name}
                                          onChange={(e) => {
                                            this.setState({
                                              user_group: {
                                                ...this.state.user_group,
                                                name: e.target.value.replace(
                                                  /[^a-zA-Z0-9_]/gi,
                                                  ""
                                                ),
                                              },
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Description</td>
                                      <td>
                                        <input
                                          name="description"
                                          className="form-control"
                                          id="tbody"
                                          value={
                                            this.state.user_group.description
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              user_group: {
                                                ...this.state.user_group,
                                                description: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserGroupAdd;
