import React, { Component } from "react";
class PAMLabel extends Component {
  state = {
    inedit: false,
    pam_num: 1,
    label: "PAM ",
    new_label: "",
    global: true,
  };
  componentDidMount() {
    this.setState({
      pam_num: this.props.pam,
      label: this.props.label,
      new_label: "",
      global: this.props.global,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.pam != this.state.pam_num ||
      (nextProps.label != this.state.label && this.state.new_label == "")
    ) {
      this.setState({
        pam_num: nextProps.pam,
        label: nextProps.label,
      });
    }
  }
  editPAM = (e) => {
    this.setState({ inedit: !this.state.inedit }, function () {
      if (!this.state.inedit) this.setState({ new_label: "" });
    });
  };
  update_PAMLabel = (e) => {
    if (this.state.global == false) {
      this.prv_updatePamLabel(
        this.props.baseUrl + "/index.php/User/post_process"
      );
    } else {
      this.prv_updatePamLabel(
        this.props.baseUrl + "/index.php/SystemSettings/post_process"
      );
    }
  };
  prv_updatePamLabel = (url) => {
    window.App.blockUI();
    fetch(url, {
      credentials: "include",
      method: "post",
      body: new URLSearchParams(
        "action=update_pam_label&pam=lbl_pam" +
          this.state.pam_num +
          "&label=" +
          this.state.new_label
      ),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          this.setState({ inedit: false, label: this.state.new_label });
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <span
          id={"pam" + this.state.pam_num + "_text"}
          className={this.state.inedit ? "hidden" : ""}
        >
          {this.state.label}&nbsp;&nbsp;
          <a href="javascript:;" onClick={this.editPAM}>
            <i className="fa fa-pencil"></i>
          </a>
        </span>
        <span
          id={"pam" + this.state.pam_num + "_if"}
          className={this.state.inedit ? "" : "hidden"}
        >
          <div className="input-group  input-medium">
            <input
              id={"pam" + this.state.pam_num + "_label"}
              className="form-control"
              type="text"
              name={"pam" + this.state.pam_num + "_label"}
              placeholder={this.state.label}
              value={this.state.new_label}
              onChange={(e) => {
                this.setState({ new_label: e.target.value });
              }}
            />{" "}
            <span className="input-group-btn">
              <button
                id="pam_save"
                className="btn btn-success"
                type="button"
                onClick={this.update_PAMLabel}
              >
                <i className="fa fa-check"></i>
              </button>
              <button
                id="pam_stop_edit"
                className="btn btn-warning"
                type="button"
                onClick={this.editPAM}
              >
                <i className="fa fa-close"></i>
              </button>
            </span>
          </div>
        </span>
      </React.Fragment>
    );
  }
}

export default PAMLabel;
