import React, { Component } from "react";
import Campaignsdd from "../../Bricks/Campaignsdd";
import $ from "jquery";
import { Select } from "antd";
import HeadTitle from "../../HeadTitle";

const { Option } = Select;
const reports = [
  { key: "live_agent", name: "Live Agent Report" },
  { key: "data_export", name: "Data Export Report" },
  { key: "agent_achievement", name: "Agent Achievement Report" },
  { key: "outbound_dialing", name: "Outbound DIaling Report" },
  { key: "cdr", name: "CDR" },
  { key: "ext_summary", name: "Extension Summary Report" },
  { key: "admin_log", name: "Admin Log" },
];
const all_rep = [
  "live_agent",
  "data_export",
  "agent_achievement",
  "outbound_dialing",
  "cdr",
  "ext_summary",
  "admin_log",
];
class UserGroupEdit extends Component {
  state = {
    user_group: {
      name: "",
      description: "",
      allowed_campaigns: [],
      allowed_reports: [],
    },
  };
  componentDidMount() {
    this.initCom();
  }
  initCom = () => {
    window.resizeHandle();
    //fetch detail of this id template
    fetch(
      this.props.baseUrl +
        "/index.php/UserGroups/View/" +
        this.props.match.params.usergroup,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          user_group: {
            name: resp.msg.name,
            description: resp.msg.description,
            allowed_campaigns:
              resp.msg.allowed_campaigns !== ""
                ? resp.msg.allowed_campaigns.split(",")
                : [],
            allowed_reports:
              resp.msg.allowed_reports !== ""
                ? resp.msg.allowed_reports.split(",")
                : [],
          },
        });
      });
    ////////////////////////////////////
  };
  handleSubmitEdit = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/UserGroups/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_edit_usergroup").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        if (data.status) {
          window.global_alert_msg = data.msg;
          this.props.history.push("/admin/usergroups/view/true");
          //window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="User Groups" subtitle={"edit "+this.state.user_group.name} />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_edit_usergroup"
                                onSubmit={this.handleSubmitEdit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="edit_user_group"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          name="name"
                                          type="text"
                                          className="form-control"
                                          placeholder="Type Title"
                                          required
                                          value={this.state.user_group.name}
                                          readOnly
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Description</td>
                                      <td>
                                        <input
                                          name="description"
                                          className="form-control"
                                          id="tbody"
                                          value={
                                            this.state.user_group.description
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              user_group: {
                                                ...this.state.user_group,
                                                description: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allowed Campaigns</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          name="allowed_campaigns"
                                          value={
                                            this.state.user_group
                                              .allowed_campaigns
                                          }
                                        />
                                        <Campaignsdd
                                          baseUrl={this.props.baseUrl}
                                          value={
                                            this.state.user_group
                                              .allowed_campaigns
                                          }
                                          onChange={(sel) => {
                                            this.setState({
                                              user_group: {
                                                ...this.state.user_group,
                                                allowed_campaigns: sel,
                                              },
                                            });
                                          }}
                                        ></Campaignsdd>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allowed Reports</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          name="allowed_reports"
                                          value={
                                            this.state.user_group
                                              .allowed_reports
                                          }
                                        />
                                        <Select
                                          listHeight={200}
                                          mode="multiple"
                                          allowClear
                                          style={{ width: "100%" }}
                                          placeholder="Select Reports"
                                          value={
                                            this.state.user_group
                                              .allowed_reports
                                          }
                                          onSelect={(selval) => {
                                            if (selval == "all") {
                                              this.setState({
                                                user_group: {
                                                  ...this.state.user_group,
                                                  allowed_reports: all_rep,
                                                },
                                              });
                                            }
                                            return selval;
                                          }}
                                          onChange={(sel) => {
                                            this.setState({
                                              user_group: {
                                                ...this.state.user_group,
                                                allowed_reports: sel,
                                              },
                                            });
                                          }}
                                        >
                                          <Option key="all" value="all">
                                            All
                                          </Option>
                                          {reports.map((report) => (
                                            <Option
                                              key={report.key}
                                              value={report.key}
                                            >
                                              {report.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserGroupEdit;
