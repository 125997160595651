import React, { Component } from "react";
import { Affix, Pagination, Typography, Button, Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import "../../css/floatingpdlist_table.css";

const { Text } = Typography;
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const columns = [
  {
    title: "Sort",
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: "Lead ID",
    dataIndex: "key",
    key: "lead_id",
    render: (text) => {
      return controlElipses(text);
    },
  },
  {
    title: "Full Name",
    dataIndex: "full_name",
    key: "full_name",
    render: (text) => {
      return controlElipses(text);
    },
  },

  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
    render: (text) => {
      return controlElipses(text);
    },
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    key: "campaign",
    render: (text) => {
      return controlElipses(text);
    },
  },
  {
    title: "List",
    dataIndex: "list",
    key: "list",
    render: (text) => {
      return controlElipses(text);
    },
  },
];
const controlElipses = (text) => {
  if (text !== null && text !== undefined)
    text =
      text.length > 10 ? (
        <Text title={text}>{text.substring(0, 10) + "..."}</Text>
      ) : (
        text
      );
  return text;
};
class FloatingPdList extends Component {
  dialingInterval = null;
  state = { bottom: 10, right: 5, data: [], visible: false };
  componentDidMount() {
    this.setState({ data: this.props.float_power_dial_list }, () => {
      console.log("on init", this.state.data);
    });
  }
  componentDidUpdate(prevProps) {
    if (Array.isArray(this.props.float_power_dial_list)) {
      if (
        JSON.stringify(this.props.float_power_dial_list) !==
        JSON.stringify(this.state.data)
      )
        this.setState({ data: this.props.float_power_dial_list }, () => {
          console.log("on up", this.state.data);
        });
    }
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      this.setState({ data: newData }, () => {
        this.props.update_fly_pd_list(this.state.data);
      });
    }
  };
  DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { data } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.key === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };
  startDialing = () => {
    const data1 = this.state.data;
    let lead_to_lead = null;
    if (data1.length > 0) {
      lead_to_lead = data1.splice(0, 1);
      lead_to_lead = lead_to_lead[0];
      console.warn("Dial", lead_to_lead);
      //console.log("Data", data);
      this.setState({ data: data1 }, () => {
        //dial this lead
        setTimeout(() => {
          window.sipCall(
            lead_to_lead.phone,
            lead_to_lead.key,
            lead_to_lead.campaign_id
          );
        }, 2000);
        if (this.dialingInterval === null)
          this.dialingInterval = setInterval(() => {
            if (window.call_detail_obj.call_status === "dispoed") {
              console.warn("dialing");
              window.call_detail_obj.call_status = "";

              this.startDialing();
            }
          }, 2000);
        //
        console.log("Data", this.state.data);
        this.props.update_fly_pd_list(this.state.data);
      });
    } else {
      clearInterval(this.dialingInterval);
      this.dialingInterval = null;
    }
  };
  render() {
    return (
      <Affix
        style={{
          position: "fixed",
          bottom: this.state.bottom,
          right: this.state.right,
          zIndex: 99999,
          Opacity: this.state.visible ? 0.8 : 1,
          padding: this.state.visible ? "10px" : "5px",
          width: this.state.visible ? 400 : "auto",
          backgroundColor: "#f1f7fd",
          borderRadius: "15px",
          borderWidth: "1px",
          borderColor: "#1890ff",
        }}
      >
        <div>
          <Button
            style={{ float: "left" }}
            onClick={(e) => {
              this.setState({ visible: !this.state.visible });
            }}
            title={this.state.visible ? "Close" : "Open"}
          >
            {this.state.visible ? (
              <i className="fa fa-chevron-down"></i>
            ) : (
              <i className="fa fa-chevron-up"></i>
            )}
          </Button>
          {this.state.visible ? (
            <Button style={{ float: "right" }}>
              <i className="fa fa-refresh"></i>
            </Button>
          ) : (
            ""
          )}
          <Button
            title="Dial Selected List"
            style={{ float: "right" }}
            onClick={this.startDialing}
          >
            <i className="fa fa-phone"></i>
          </Button>
        </div>
        {this.state.visible ? (
          <div style={{ width: "100%", overflow: "auto", maxHeight: "400px" }}>
            <Table
              className="floatingLeads"
              columns={columns}
              dataSource={[...this.state.data]}
              pagination={{
                pageSize: 10,
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
              rowKey="key"
              components={{
                body: {
                  wrapper: this.DraggableContainer,
                  row: this.DraggableBodyRow,
                },
              }}
              size="small"
            />
          </div>
        ) : (
          ""
        )}
      </Affix>
    );
  }
}

export default FloatingPdList;
