import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import CampaignsDropDown from "../Bricks/CampaignsDropDown";
import ListDropDown from "../Bricks/ListDropDown";
import DispoStatusesAll from "../Bricks/DispoStatusesAll";
import HeadTitle from "../HeadTitle";
class ReportLeadsExport extends Component {
  state = { start: "", end: "" };
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/leadexportreport.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////

    $("#download_iframe")
      .contents()
      .find("html")
      .html(
        '<html><head></head><body><form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Report/process_post" id="form_leadexport_d" ></form> </body></html>'
      );
    this.loadiframe();
  }
  componentWillUnmount() {
    window.removeJS("jsdolphin/leadexportreport.js");
  }
  loadiframe = () => {
    $("#download_iframe")
      .contents()
      .find("html")
      .html(
        '<html><head></head><body><form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Report/process_post" id="form_leadexport_d" ></form> </body></html>'
      );
  };
  handleLeadExportForm = (e) => {
    e.preventDefault();
    $("#download_iframe")
      .contents()
      .find("body")
      .html(
        '<form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Report/process_post" id="form_leadexport_d" ></form>'
      );
    //alert("I am here");
    $("#download_iframe")
      .contents()
      .find("#form_leadexport_d")
      .html($("#form_leadexport").html());
    //making multi select options to submit to iframe
    let campaign_selected = $("#ms_campaigns").val();
    let camp_options = "";
    for (var camp in campaign_selected) {
      camp_options +=
        "<option selected>" + campaign_selected[camp] + "</options>";
    }

    let list_selected = $("#ms_lists").val();
    let list_options = "";
    for (var list in list_selected) {
      list_options += "<option selected>" + list_selected[list] + "</options>";
    }

    let status_selected = $("#ms_statuses").val();
    let status_options = "";
    for (var status in status_selected) {
      status_options +=
        "<option selected>" + status_selected[status] + "</options>";
    }

    $("#download_iframe")
      .contents()
      .find("#form_leadexport_d")
      .find("#ms_campaigns")
      .html(camp_options);
    $("#download_iframe")
      .contents()
      .find("#form_leadexport_d")
      .find("#ms_lists")
      .html(list_options);
    $("#download_iframe")
      .contents()
      .find("#form_leadexport_d")
      .find("#ms_statuses")
      .html(status_options);
    /////////////////////////////////////
    $("#download_iframe").contents().find("#form_leadexport_d").submit();
  };
  render() {
    return (
      <React.Fragment>
        <iframe id="download_iframe" style={{ display: "none" }}></iframe>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title=" Data Export Report" subtitle="" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2" id="theform">
                      <form
                        id="form_leadexport"
                        onSubmit={this.handleLeadExportForm}
                      >
                        <input
                          type="hidden"
                          name="action"
                          value="lead_export"
                        />
                        <input type="hidden" id="ler_ds" name="date_start" />
                        <input type="hidden" id="ler_de" name="date_end" />
                        <div className="portlet light">
                          <div className="portlet-body">
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td>Date Range</td>
                                      <td>
                                        <input
                                          type="hidden"
                                          id="date_start"
                                          value={this.state.start}
                                          onChange={(e) => {
                                            this.setState({
                                              start: e.target.value,
                                            });
                                          }}
                                        />
                                        <input
                                          type="hidden"
                                          id="date_end"
                                          value={this.state.end}
                                          onChange={(e) => {
                                            this.setState({
                                              end: e.target.value,
                                            });
                                          }}
                                        />
                                        <div
                                          id="date_range"
                                          className="btn default"
                                        >
                                          <i className="fa fa-calendar"></i>{" "}
                                          &nbsp;
                                          <span> </span>
                                          <b className="fa fa-angle-down"></b>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr class="hidden">
                                      <td>Header Row</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="header_row"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr class="hidden">
                                      <td>Custom Fields</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="custom_fields"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaigns</td>
                                      <td>
                                        <a id="ms_cmp_sel_all">Select All</a>
                                        &nbsp;
                                        <a
                                          style={{ marginLeft: "143px" }}
                                          id="ms_cmp_desel_all"
                                        >
                                          Deselect All
                                        </a>
                                        {/*<select
                                          multiple="multiple"
                                          className="multi-select"
                                          id="ms_campaigns"
                                          name="ms_campaigns[]"
                                        ></select>*/}
                                        <CampaignsDropDown
                                          id="ms_campaigns"
                                          name="ms_campaigns[]"
                                          baseUrl={this.props.baseUrl}
                                          ismulti={true}
                                        ></CampaignsDropDown>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Lists</td>
                                      <td>
                                        <a id="ms_lst_sel_all">Select All</a>
                                        &nbsp;
                                        <a
                                          id="ms_lst_desel_all"
                                          style={{ marginLeft: "143px" }}
                                        >
                                          Deselect All
                                        </a>
                                        {/*<select
                                          multiple="multiple"
                                          className="multi-select"
                                          id="ms_lists"
                                          name="ms_lists[]"
                                        ></select>*/}
                                        <ListDropDown
                                          id="ms_lists"
                                          name="ms_lists[]"
                                          baseUrl={this.props.baseUrl}
                                          ismulti={true}
                                        ></ListDropDown>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Statuses</td>
                                      <td>
                                        <a id="ms_statuses_sel_all">
                                          Select All
                                        </a>
                                        &nbsp;
                                        <a
                                          id="ms_statuses_desel_all"
                                          style={{ marginLeft: "143px" }}
                                        >
                                          Deselect All
                                        </a>
                                        {/*<select
                                          multiple="multiple"
                                          className="multi-select"
                                          id="ms_statuses"
                                          name="ms_statuses[]"
                                        ></select>*/}
                                        <DispoStatusesAll
                                          baseUrl={this.props.baseUrl}
                                          ismulti={true}
                                          id="ms_statuses"
                                          name="ms_statuses[]"
                                        ></DispoStatusesAll>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          className="btn default hidden"
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportLeadsExport;
