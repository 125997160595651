import React, { Component } from "react";
import HeadTitle from "../../HeadTitle";
class DIDadd extends React.Component {
  componentDidMount() {
    window.resizeHandle();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    DIDs
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add </small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                className="listEditform"
                                onSubmit={this.handleSubmit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_did"
                                />

                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>DID</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_id"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Description</td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          name="list_name"
                                        ></textarea>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>User Group</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                          id="list_active"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                          id="list_active"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Route</td>
                                      <td>
                                        <select
                                          className=" form-control"
                                          name="list_reset_lead_called_status"
                                        >
                                          <option value="N">No</option>
                                          <option value="Y">Yes</option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Extension</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_last_call_date"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Ring Group</td>
                                      <td>
                                        <select
                                          className=" form-control"
                                          name="list_reset_lead_called_status"
                                        >
                                          <option value="N">RG 1</option>
                                          <option value="Y">RG 2</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colSpan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DIDadd;
