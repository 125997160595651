import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
class HighChart extends Component {
  state = {
    options: {
      chart: {
        type: "areaspline",
      },
      title: {
        text: "Calls Per Day Graph",
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "top",
        x: 150,
        y: 100,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          "#FFFFFF",
      },
      xAxis: {
        categories: this.props.categories,
      },
      yAxis: {
        title: {
          text: "Number Of Calls",
        },
      },
      tooltip: {
        shared: true,
        valueSuffix: " Calls",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
        },
      },
      series: [
        {
          name: "Outbound calls",
          data: this.props.series,
        },
      ],
    },
  };
  componentDidMount() {
    /*console.log("I am mounted" + this.props.categories);*/
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.series !== this.props.series &&
      prevProps.categories !== this.props.categories
    ) {
      this.setState({
        options: {
          chart: {
            type: "areaspline",
          },
          title: {
            text: "Calls Per Day Graph",
          },
          legend: {
            layout: "vertical",
            align: "left",
            verticalAlign: "top",
            x: 150,
            y: 100,
            floating: true,
            borderWidth: 1,
            backgroundColor:
              (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
              "#FFFFFF",
          },
          xAxis: {
            categories: this.props.categories,
          },
          yAxis: {
            title: {
              text: "Number Of Calls",
            },
          },
          tooltip: {
            shared: true,
            valueSuffix: " Calls",
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            areaspline: {
              fillOpacity: 0.5,
            },
          },
          series: [
            {
              name: "Outbound calls",
              data: this.props.series,
            },
          ],
        },
      });
    }
    /*console.log("I am updated" + this.props.categories);*/
  }
  render() {
    return (
      <HighchartsReact
        constructorType={"chart"}
        highcharts={Highcharts}
        options={this.state.options}
      />
    );
  }
}

export default HighChart;
