import React, { Component } from "react";
import $ from "jquery";
class TenantAdd extends Component {
  state = {
    server_id: 0,
    tenant_url: "",
    uuid: "",
    pbx_user: "",
    pbx_pass: "",
    api_key: "",
    ftp_user: "",
    ftp_pass: "",
    ftp_port: "",
  };
  componentDidMount() {
    window.resizeHandle();
    const server_id = this.props.match.params.server_id;
    if (window.selected_server <= 0) {
      alert("No Server Selected");
      this.props.history.push("/pbxservers/view");
    }

    //fetch and fill with info
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Tenants/Add/" + server_id, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          server_id: resp.id,
          tenant_url: resp.tenant_url,
          uuid: resp.uuid,
          pbx_user: resp.pbx_user,
          pbx_pass: resp.pbx_pass,
          api_key: resp.api_key,
          ftp_user: resp.ftp_user,
          ftp_pass: resp.ftp_pass,
          ftp_port: resp.ftp_port,
        });
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    ///////////////////////////////
  }
  handleAddTenant = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Tenants/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_addtenant").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Tenants
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <form
                              id="form_addtenant"
                              onSubmit={this.handleAddTenant}
                            >
                              <input type="hidden" name="action" value="add" />
                              <input
                                type="hidden"
                                name="server_id"
                                value={this.state.server_id || ""}
                              />
                              <div className="col-md-8 col-md-offset-2">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Main Url</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="url"
                                          className="form-control"
                                          value={this.state.tenant_url || ""}
                                          onChange={(e) =>
                                            this.setState({
                                              tenant_url: e.target.value,
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tenant UID</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="uuid"
                                          className="form-control"
                                          value={this.state.uuid || ""}
                                          onChange={(e) => {
                                            this.setState({
                                              uuid: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Fusionpbx Login User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="pbx_user"
                                          className="form-control"
                                          value={this.state.pbx_user || ""}
                                          onChange={(e) => {
                                            this.setState({
                                              pbx_user: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Login Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="pbx_pass"
                                          className="form-control"
                                          value={this.state.pbx_pass || ""}
                                          onChange={(e) => {
                                            this.setState({
                                              pbx_pass: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Api Key</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="api_key"
                                          className="form-control"
                                          value={this.state.api_key}
                                          onChange={(e) => {
                                            this.setState({
                                              api_key: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_user"
                                          className="form-control"
                                          value={this.state.ftp_user}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_user: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="ftp_pass"
                                          className="form-control"
                                          value={this.state.ftp_pass}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_pass: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Port</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_port"
                                          className="form-control"
                                          value={this.state.ftp_port}
                                          onChange={(e) => {
                                            this.setState({
                                              ftp_port: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn green-jungle"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TenantAdd;
