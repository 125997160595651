import React, { Component } from "react";
import $ from "jquery";
class AddAcustomField extends Component {
  state = { show_options: false };
  handleFieldTypeChange = (e) => {
    if (e.target.value === "SELECT") {
      this.setState({ show_options: true });
    } else {
      this.setState({ show_options: false });
    }
  };
  handleCustomFieldAdd = (form) => {
    form.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Manager/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#formCustomFieldAdd").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
          this.props.onNewCfieldAdd();
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <form
          class="form-inline"
          id="formCustomFieldAdd"
          onSubmit={this.handleCustomFieldAdd}
        >
          <input type="hidden" name="action" value="add_custom_fields" />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-md-3 control-label">Name</label>
                <div className="col-md-9">
                  <input className="form-control" type="text" name="name" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">Type</label>
                <div className="col-md-9">
                  <select
                    className="bs-select form-control"
                    name="type"
                    id="type"
                    onChange={this.handleFieldTypeChange}
                  >
                    <option value="TEXT">Text field</option>
                    <option value="AREA">Text Box</option>
                    <option value="SELECT">Drop Down</option>
                  </select>
                </div>
              </div>
              {this.state.show_options === true ? (
                <span className="field_option" id="field_option">
                  <div className="form-group">
                    <label className="col-md-3 control-label">Options</label>
                    <div className="col-md-9">
                      <textarea
                        className="form-control"
                        type="text"
                        name="options"
                      ></textarea>
                    </div>
                  </div>
                </span>
              ) : (
                ""
              )}
              <button type="submit" className="btn green-jungle">
                Add
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default AddAcustomField;
