import React, { Component } from 'react'
import { Input, Button, Table, Typography, Popconfirm } from 'antd'
import 'antd/dist/antd.css'
import { Link } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { isArray } from 'highcharts'
import '../../css/table_antd_copy.css'
const { Text } = Typography
class NewClientList extends Component {
  state = {
    page: 0,
    page_size: 10,
    selectedRowsIds: [],
    loading: false,
    data: [{ key: 0, id: 0, did: '090078601', route: 'ring group' }],
    alert_msg: '',
    did_edit: { key: 0, id: -1, did: '', route: '' },
    editing_key: '',
    dataSource: [],
    global_search: '',
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 20,
      defaultCurrent: 1,
      pageSize: 20,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50, 100, 500],
    },
    sorting: [],
    filters: [],
  }
  componentDidMount() {
    window.resizeHandle()
    this.fetchData({
      pagination: this.state.pagination,
      filter: this.state.filters,
      sorting: this.state.sorting,
      global_search: this.state.global_search,
    })
  }
  handleTableChange = (p, f, s) => {
    this.setState({ pagination: p, sorting: s }, () => {
      this.fetchData({
        pagination: this.state.pagination,
        filter: this.state.filters,
        sorting: isArray(s) ? s : [s],
        global_search: this.state.global_search,
      })
    })
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex] !== null && record[dataIndex] !== undefined
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filters = this.state.filters
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].field == dataIndex) {
        filters.splice(i, 1)
        break
      }
    }
    filters.push({ field: dataIndex, value: selectedKeys[0] })
    this.setState(filters, () => {
      console.log('Filters', this.state.filters)
      confirm()
    })
  }

  handleReset = (clearFilters) => {
    this.setState({ filters: [] }, function () {
      clearFilters()
    })
  }
  fetchData = (params = {}) => {
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/Ajax_ClientList', {
      credentials: 'include',
      method: 'post',
      body: JSON.stringify({
        results: 10, //params.pagination.pageSize,
        page: 0, //params.pagination.current,
        ...params,
      }),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((response) => {
        console.log(response.results)
        this.setState({
          dataSource: response.results,
          pagination: {
            ...response.pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          },
        })
        this.props.blockUI(false)
      })
      .catch((e) => {
        console.log('Crash', e)
        this.props.blockUI(false)
      })
  }
  viewClient = (id) => {
    var childKey = id
    fetch(
      this.props.baseUrl + '/index.php/SuperAdmin/ClientsDetail/' + childKey,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          //show detail bootbox modal with detail as html :)
          window.bootbox.alert({
            message:
              '<h5>' +
              data.msg.name +
              ' - ' +
              data.msg.url +
              "</h5><table style='margin-bottom:-8px;' class='table table-striped'><tr><th>Calls</th><td>" +
              data.msg.calls +
              '</td></tr><tr><th>Call Minutes</th><td>' +
              data.msg.minutes +
              '</td></tr><tr><th>Date Created</th><td>' +
              data.msg.crt_at +
              '</td></tr><tr><th>Tenant</th><td>' +
              data.msg.tenant +
              '</td></tr><tr><th>Sent Texts</th><td>' +
              data.msg.text_count +
              '</td></tr><tr><th>Available Texts</th><td>' +
              data.msg.sms_credits +
              '</td></tr></table>',
            size: 'small',
            backdrop: true,
          })
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  deleteClient = (id) => {
    var childKey = id
    window.App.blockUI()
    fetch(
      this.props.baseUrl + '/index.php/SuperAdmin/ClientDelete/' + childKey,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success', 10)
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger', 15)
        }
        window.App.unblockUI()
        this.fetchData({
          pagination: this.state.pagination,
          filter: this.state.filters,
          sorting: this.state.sorting,
          global_search: this.state.global_search,
        })
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  controlElipses = (text) => {
    if (text !== null && text !== undefined)
      text =
        text.length > 12 ? (
          <Text title={text}>{text.substring(0, 12) + '...'}</Text>
        ) : (
          text
        )
    return text
  }
  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'fullname',
        key: 'fullname',
        ...this.getColumnSearchProps('fullname'),
        sorter: { multiple: 1 },
        width: '12%',
        render: (text, row) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
        sorter: { multiple: 1 },
        width: '12%',
        render: (text, row) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        ...this.getColumnSearchProps('url'),
        sorter: { multiple: 1 },
        width: '8%',
        render: (text, row) => {
          return (
            <a
              title={text.length > 15 ? text : ''}
              target="_blank"
              href={
                'https://' +
                row.url +
                '/backend_spa/index.php/Login/Login/' +
                row.id +
                '/' +
                this.props.user_id
              }
            >
              {text.length > 15 ? text.substring(0, 15) + '...' : text}
            </a>
          )
        },
      },
      {
        title: 'Seats',
        dataIndex: 'num_seats',
        key: 'num_seats',
        ...this.getColumnSearchProps('num_seats'),
        sorter: { multiple: 1 },
        width: '4%',
        render: (text, row) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Type',
        dataIndex: 'account_type',
        key: 'account_type',
        ...this.getColumnSearchProps('account_type'),
        sorter: { multiple: 1 },
        width: '7%',
        render: (text, row) => {
          if (text.length > 1)
            return this.controlElipses(
              text.charAt(0).toUpperCase() + text.slice(1)
            )
          return this.controlElipses(text)
        },
      },
      {
        title: 'Status',
        dataIndex: 'account_status',
        key: 'account_status',
        ...this.getColumnSearchProps('account_status'),
        sorter: { multiple: 1 },
        width: '7%',
        render: (text, row) => {
          switch (text) {
            case 'active':
              return (
                <span className="label label-sm label-success"> Active </span>
              )
              break
            case 'inactive':
              return (
                <span className="label label-sm label-danger"> Inactive </span>
              )
              break
            default:
              return (
                <span className="label label-sm label-warning"> {text} </span>
              )
              break
          }
        },
      },
      {
        title: 'Status',
        dataIndex: 'agreement_status',
        key: 'agreement_status',
        ...this.getColumnSearchProps('agreement_status'),
        sorter: { multiple: 1 },
        width: '7%',
        render: (text, row) => {
          switch (text) {
            case 'signed':
              return (
                <span className="label label-sm label-success"> Signed </span>
              )
              break
            case 'unsigned':
              return (
                <span className="label label-sm label-danger"> Un Signed </span>
              )
              break
            default:
              return (
                <span className="label label-sm label-warning"> {text} </span>
              )
              break
          }
        },
      },
      {
        title: 'Login',
        dataIndex: 'last_login_date',
        key: 'last_login_date',
        ...this.getColumnSearchProps('last_login_date'),
        sorter: { multiple: 1 },
        width: '13%',
        render: (text, row) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Created At',
        dataIndex: 'crt_op',
        key: 'crt_op',
        ...this.getColumnSearchProps('crt_op'),
        sorter: { multiple: 1 },
        width: '13%',
        render: (text, row) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '16%',
        render: (text, record) => {
          return (
            <React.Fragment>
              <button
                className="btn btn-success btn-sm"
                style={{ marginRight: '2px' }}
                onClick={(e) => {
                  this.viewClient(record.id)
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
              <Link
                className="btn btn-info btn-sm"
                to={'/dolphinclients/calleridswhitelist/' + record.id}
                style={{ marginRight: '2px' }}
              >
                <i className="fa fa-phone-square"></i>
              </Link>
              <Link
                className="btn btn-warning btn-sm"
                to={'/dolphinclients/edit/' + record.id}
                style={{ marginRight: '2px' }}
              >
                <i className="fa fa-pencil"></i>
              </Link>

              <Popconfirm
                title=" Are you sure?"
                onConfirm={(e) => {
                  this.deleteClient(record.id)
                }}
                okText="Yes"
                cancelText="No"
              >
                <button className="btn btn-danger btn-sm" onClick={(e) => {}}>
                  <i className="fa fa-trash"></i>
                </button>
              </Popconfirm>
            </React.Fragment>
          )
        },
      },
    ]
    return (
      <React.Fragment>
        <style>{this.my_css}</style>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Client
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;List</small>
                  </h1>
                </div>
                <div class="page-toolbar">
                  <button
                    style={{ height: '62px' }}
                    id="slv_btn_refresh"
                    class="btn"
                    onClick={(e) => {
                      this.cl_dt_clients.api().ajax.reload()
                    }}
                  >
                    <i class="icon-reload"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Clients <small id="cur_process">list</small>
                            </span>
                          </div>
                          <div className="actions">
                            <input
                              type="text"
                              className="form-control input-sm"
                              style={{
                                width: '150px',
                                display: 'inline',
                                marginRight: '5px',
                              }}
                              value={this.state.global_search}
                              onChange={(e) =>
                                this.setState(
                                  { global_search: e.target.value },
                                  () => {
                                    if (
                                      this.state.global_search.length >= 3 ||
                                      this.state.global_search.length == 0
                                    )
                                      this.fetchData({
                                        pagination: this.state.pagination,
                                        filter: this.state.filters,
                                        sorting: this.state.sorting,
                                        global_search: this.state.global_search,
                                      })
                                  }
                                )
                              }
                            />
                            <a
                              href="#/dolphinclients/add"
                              className="btn btn-circle btn-default"
                            >
                              <i className="fa fa-plus"></i> Add{' '}
                            </a>
                          </div>
                        </div>
                        <div
                          className="portlet-body flip-scroll"
                          style={{ paddingTop: 0 }}
                        >
                          <Table
                            size={'small'}
                            tableLayout={'auto'}
                            dataSource={this.state.dataSource}
                            columns={columns}
                            onChange={this.handleTableChange}
                            scroll={{ x: true }}
                            pagination={{
                              ...this.state.pagination,

                              defaultPageSize: 20,
                              defaultCurrent: 1,
                            }}
                          />
                          ;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewClientList
