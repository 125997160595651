import React, { Component } from "react";
import $ from "jquery";
import LeadStatusDropDown from "../Bricks/LeadStatusDropDown";
import UsersDropDown from "../Bricks/UsersDropDown";
import ListDropDown from "../Bricks/ListDropDown";
import HeadTitle from "../HeadTitle";
export default class ViewSingleLead extends Component {
  state = {
    cc: "",
    lead_id: "",
    list_id: "",
    campaign_id: "",
    fname: "",
    lname: "",
    email: "",
    from_address: "",
    phone1: "",
    phone2: "",
    phone3: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
    company: "",
    source: "",
    lead_status: "",
    owner: "",
    notes: "",
    custom_fields: [],
    script: "",
    last_call_dispo: "",
    email_templates: [],
    isContact: true,
  };
  componentDidMount() {
    window.resizeHandle();
    const lead_id = this.props.match.params.lead_id;
    this.setState({ lead_id });
    window.initSummerNoteandEmailOnSLV(this.props.baseUrl, lead_id);
    window.load_select2(".bs-select");
    this.initLead();
  }
  initLead = () => {
    const lead_id = this.props.match.params.lead_id;
    //fetch lead info so info
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Contact/contact_view/" + lead_id, {
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          let lead = data.msg.contact;
          //console.log(lead);
          //console.log("HIIII");
          this.setState({
            lead_id: lead.contact_id,
            fname: lead.first_name,
            lname: lead.last_name,
            email: lead.email,
            from_address: lead.from_address,
            phone1: lead.phone,
            phone2: lead.phone2,
            phone3: lead.phone3,
            address1: lead.address1,
            address2: lead.address2,
            city: lead.city,
            state: lead.state,
            zip_code: lead.postal_code,
            company: lead.company,
            source: lead.source,
            lead_status: lead.lead_status,
            owner: lead.assigned_to,
            last_call_dispo: lead.last_call_dispo,
            notes: lead.comments,
            custom_fields: [], //lead.custom_fields,
            script: lead.script,
            email_templates: data.msg.templates,
          });
          //we load custom fields in span here ;)
          $("#disp_custom_sw_fields").html(lead.custom_fields);
          ///////////////////////////////////////////
          console.error("asdsad");
          console.error(data.msg.lead.info);
          window.$(".bs-select").trigger("change");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    window.refresh_callNotes("#leadNotes", lead_id); ///refresh notes portion to show note
    ///////////////////////////////////
  };

  handleLeadInfoSubmit = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Contact/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_editLeadInfo").serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
        this.initLead();
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };

  handleSubmitNote = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Leads/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_postnote").serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
        this.initLead();
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };

  MakeContact = (e) => {
    {
      /*href="<?=base_url('index.php/Leads/Makecontact/'.$lead['info']['lead_id'])?>"*/
    }
    this.props.blockUI(true);
    fetch(
      this.props.baseUrl + "/index.php/Leads/Makecontact/" + this.state.lead_id,
      { credentials: "include", method: "get" }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.$("#static").modal("hide");
        this.props.blockUI(false);
        this.props.history.push("/crm/contacts");
        //this.initLead();
      })
      .catch((e) => {
        window.$("#static").modal("hide");
        this.props.blockUI(false);
      });
  };
  MakeLead = (e) => {
    {
      /*<a href="<?=base_url('index.php/Leads/Makelead/'.$lead['info']['lead_id'])?>" className="btn green-jungle">Make A Lead</a>	*/
    }
    this.props.blockUI(true);
    fetch(
      this.props.baseUrl + "/index.php/Leads/Makelead/" + this.state.lead_id,
      { credentials: "include", method: "get" }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.$("#static").modal("hide");
        this.props.blockUI(false);

        this.props.history.push("/crm/contacts");
        //this.initLead();
      })
      .catch((e) => {
        window.$("#static").modal("hide");
        this.props.blockUI(false);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>Lead</h1>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div id="bootstrap_alerts_demo"></div>
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="profile-sidebar">
                        <div className="portlet light">
                          <div className="profile-userpic">
                            <img
                              src={
                                this.props.baseUrl +
                                "/index.php/User/get_users_avatar"
                              }
                              className="img-responsive"
                              alt=""
                            />{" "}
                          </div>

                          <div
                            className="profile-usertitle"
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              className="profile-usertitle-name"
                              style={{ fontSize: "27px" }}
                            >
                              {this.state.fname + " " + this.state.lname}
                            </div>
                            <div
                              className="profile-usertitle-job"
                              style={{ fontSize: "16px" }}
                            >
                              {this.state.company}
                            </div>
                            <span>
                              <a data-target="#static" data-toggle="modal"></a>
                            </span>
                          </div>

                          <hr
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          />

                          <div>
                            <h4 className="profile-desc-title">
                              Contact Information
                            </h4>
                            <div className="margin-top-20 profile-desc-link">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="profile-sideabar-info-h">
                                    Type:
                                  </h4>
                                  <span>
                                    {this.state.isContact ? (
                                      <a
                                        data-target="#static"
                                        data-toggle="modal"
                                      >
                                        Contact
                                      </a>
                                    ) : (
                                      <a
                                        data-target="#static"
                                        data-toggle="modal"
                                      >
                                        Lead
                                      </a>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <h4 className="profile-sideabar-info-h">
                                    Owner:
                                  </h4>
                                  <span>
                                    <a
                                      href={"#/leads/view/" + this.state.owner}
                                    >
                                      {this.state.owner}
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="margin-top-10 profile-desc-link">
                              <h4 className="profile-sideabar-info-h">
                                Email Address:
                              </h4>
                              <span>{this.state.email}</span>
                            </div>
                            <div className="margin-top-10 profile-desc-link">
                              <h4 className="profile-sideabar-info-h">
                                Phone Number:
                              </h4>
                              <span>
                                <a
                                  className=""
                                  href={
                                    "javascript:sipCall('" +
                                    this.state.phone1 +
                                    "','" +
                                    this.state.lead_id +
                                    "','" +
                                    this.state.campaign_id +
                                    "');"
                                  }
                                >
                                  {this.state.phone1}
                                </a>
                                &nbsp;
                                <a
                                  className=""
                                  href={
                                    "javascript:sipCall('" +
                                    this.state.phone2 +
                                    "','" +
                                    this.state.lead_id +
                                    "','" +
                                    this.state.campaign_id +
                                    "');"
                                  }
                                >
                                  {this.state.phone2}
                                </a>
                                &nbsp;
                                <a
                                  className=""
                                  href={
                                    "javascript:sipCall('" +
                                    this.state.phone3 +
                                    "','" +
                                    this.state.lead_id +
                                    "','" +
                                    this.state.campaign_id +
                                    "');"
                                  }
                                >
                                  {this.state.phone3}
                                </a>
                              </span>
                            </div>
                            <div className="margin-top-10 profile-desc-link">
                              <h4 className="profile-sideabar-info-h">
                                Address:
                              </h4>
                              <span>{this.state.address1}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile-content">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="portlet light ">
                              <div className="portlet-title tabbable-line">
                                <div className="caption caption-md">
                                  <i className="icon-globe theme-font hide"></i>
                                  <span className="caption-subject font-blue-madison bold uppercase">
                                    Profile Account
                                  </span>
                                </div>
                                <ul className="nav nav-tabs">
                                  <li>
                                    <a
                                      href="#tab_contact_activities"
                                      data-toggle="tab"
                                    >
                                      Activities
                                    </a>
                                  </li>
                                  <li class="active">
                                    <a href="#tab_info" data-toggle="tab">
                                      Lead Info
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="portlet-body">
                                <div className="tab-content">
                                  <div
                                    className="tab-pane active"
                                    id="tab_info"
                                  >
                                    <form
                                      id="form_editLeadInfo"
                                      onSubmit={this.handleLeadInfoSubmit}
                                    >
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_contact"
                                      />
                                      <input
                                        type="hidden"
                                        name="contact_id"
                                        value={this.state.lead_id}
                                        onCHange={(e) => {
                                          this.setState({
                                            lead_id: e.target.value,
                                          });
                                        }}
                                      />
                                      <input
                                        type="hidden"
                                        name="phone_code"
                                        className="form-control"
                                        value={this.state.phone_code}
                                      />

                                      <input
                                        type="hidden"
                                        name="title"
                                        className="form-control"
                                        value={this.state.title}
                                      />
                                      <input
                                        type="hidden"
                                        name="middle_initial"
                                        className="form-control"
                                        value={this.state.middle_initial}
                                      />
                                      <input
                                        type="hidden"
                                        name="address2"
                                        className="form-control"
                                        value={this.state.address2}
                                      />
                                      <input
                                        type="hidden"
                                        name="province"
                                        className="form-control"
                                        value={this.state.province}
                                      />
                                      <input
                                        type="hidden"
                                        name="country_code"
                                        className="form-control"
                                        value={this.state.country_code}
                                      />
                                      <input
                                        type="hidden"
                                        name="date_of_birth"
                                        className="form-control"
                                        value={this.state.date_of_birth}
                                      />
                                      <input
                                        type="hidden"
                                        name="email2"
                                        className="form-control"
                                        value={this.state.email2}
                                      />
                                      <input
                                        type="hidden"
                                        name="gender"
                                        className="form-control"
                                        value={this.state.gender}
                                      />
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              name="first_name"
                                              className="form-control"
                                              value={this.state.fname}
                                              onChange={(e) => {
                                                this.setState({
                                                  fname: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              name="last_name"
                                              className="form-control"
                                              value={this.state.lname}
                                              onChange={(e) => {
                                                this.setState({
                                                  lname: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Email
                                            </label>
                                            <input
                                              type="text"
                                              name="email"
                                              className="form-control"
                                              value={this.state.email}
                                              onChange={(e) => {
                                                this.setState({
                                                  email: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone{" "}
                                            </label>
                                            <input
                                              type="text"
                                              name="phone"
                                              className="form-control"
                                              value={this.state.phone1}
                                              onChange={(e) => {
                                                this.setState({
                                                  phone1: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone 2
                                            </label>
                                            <input
                                              type="text"
                                              name="phone2"
                                              className="form-control"
                                              value={this.state.phone2}
                                              onChange={(e) => {
                                                this.setState({
                                                  phone2: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone 3
                                            </label>
                                            <input
                                              type="text"
                                              name="phone3"
                                              className="form-control"
                                              value={this.state.phone3}
                                              onChange={(e) => {
                                                this.setState({
                                                  phone3: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Address
                                            </label>
                                            <input
                                              type="text"
                                              name="address1"
                                              className="form-control"
                                              value={this.state.address1}
                                              onChange={(e) => {
                                                this.setState({
                                                  address1: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              City
                                            </label>
                                            <input
                                              type="text"
                                              name="city"
                                              className="form-control"
                                              value={this.state.city}
                                              onChange={(e) => {
                                                this.setState({
                                                  city: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              State
                                            </label>
                                            <input
                                              type="text"
                                              name="state"
                                              className="form-control"
                                              value={this.state.state}
                                              onChange={(e) => {
                                                this.setState({
                                                  state: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              Zip Code
                                            </label>
                                            <input
                                              type="text"
                                              name="postal_code"
                                              className="form-control"
                                              value={this.state.zip_code}
                                              onChange={(e) => {
                                                this.setState({
                                                  zip_code: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Company
                                            </label>
                                            <input
                                              type="text"
                                              name="company"
                                              className="form-control"
                                              value={this.state.company}
                                              onChange={(e) => {
                                                this.setState({
                                                  company: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Source
                                            </label>
                                            <input
                                              type="text"
                                              name="source"
                                              className="form-control"
                                              value={this.state.source}
                                              onChange={(e) => {
                                                this.setState({
                                                  source: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/*<div className="form-group">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label className="control-label">
                                              Notes
                                            </label>
                                            <textarea
                                              name="comments"
                                              className="form-control"
                                              value={this.state.notes}
                                              onChange={e => {
                                                this.setState({
                                                  notes: e.target.value
                                                });
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>*/}
                                      <span id="disp_custom_sw_fields"></span>
                                      <div className="row margin-top-10">
                                        <div className="col-md-4">
                                          <button
                                            href="javascript:;"
                                            className="btn green-jungle"
                                            type="submit"
                                          >
                                            {" "}
                                            Save Changes{" "}
                                          </button>
                                          {/*<a href="<?=$this->agent->referrer()?>" className="btn default"> Back </a>*/}
                                        </div>
                                        <div className="col-md-8 text-right"></div>
                                      </div>
                                    </form>
                                  </div>
                                  <div
                                    className="tab-pane"
                                    id="tab_contact_activities"
                                  >
                                    <div className="portlet light portlet-fit ">
                                      <div className="portlet-body">
                                        <div className="timeline">
                                          <div className="timeline-item">
                                            <div className="timeline-badge">
                                              <div className="timeline-icon">
                                                <i className="icon-user font-green-jungle"></i>
                                              </div>
                                            </div>
                                            <div className="timeline-body">
                                              <div className="timeline-body-arrow">
                                                {" "}
                                              </div>
                                              <div className="timeline-body-head">
                                                <form
                                                  id="form_postnote"
                                                  onSubmit={
                                                    this.handleSubmitNote
                                                  }
                                                  method="post"
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="action"
                                                    value="add_note"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="lead_id"
                                                    value={this.state.lead_id}
                                                  />
                                                  <textarea
                                                    name="note"
                                                    rows="4"
                                                    style={{ width: "100%" }}
                                                  ></textarea>
                                                  <button
                                                    type="submit"
                                                    className="btn btn-default pull-right"
                                                  >
                                                    Submit
                                                  </button>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                          <span id="leadNotes"></span>
                                        </div>
                                        <div className="clearfix">
                                          <div
                                            id="record_count"
                                            className="pull-left"
                                          ></div>
                                          <div className="btn-toolbar pull-right">
                                            <div className="btn-group">
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                id="btn_act_prev"
                                              >
                                                Prev
                                              </button>
                                            </div>
                                            <div className="btn-group">
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                id="btn_act_next"
                                              >
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="static"
          className="modal fade"
          tabindex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {!this.state.isContact ? (
                <React.Fragment>
                  <div className="modal-body">
                    <p> Would you like to make it contact? </p>
                  </div>
                  <div className="modal-footer">
                    {/*href="<?=base_url('index.php/Leads/Makecontact/'.$lead['info']['lead_id'])?>"*/}
                    <button
                      onClick={this.MakeContact}
                      className="btn green-jungle"
                    >
                      Make A Contact
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-outline dark"
                    >
                      Cancel
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="modal-body">
                    <p> Would you like to make it Lead? </p>
                  </div>
                  <div className="modal-footer">
                    {/*<a href="<?=base_url('index.php/Leads/Makelead/'.$lead['info']['lead_id'])?>" className="btn green-jungle">Make A Lead</a>	*/}
                    <button
                      onClick={this.MakeLead}
                      className="btn green-jungle"
                    >
                      Make Lead
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-outline dark"
                    >
                      Cancel
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignLeadToList"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Assign This Lead to Another List
                </h4>
              </div>
              <form
                className="form-horizontal"
                id="form_mdl_assignList"
                onSubmit={this.handleAssignList}
              >
                <input type="hidden" name="action" value="assignLeadToList" />
                <input
                  type="hidden"
                  name="lead_id"
                  value={this.state.lead_id}
                />
                <div className="modal-body">
                  <div className="form-group">
                    <div className="col-md-4 col-md-offset-1">
                      <label className="control-label">Assigned To</label>
                    </div>
                    <div className="col-md-6">
                      {/*<select
                        name="assignToList"
                        className="bs-select form-control"
                      ></select>*/}
                      <ListDropDown
                        id="mdl_lists"
                        name="assignToList"
                        inmodal={true}
                        class="bs-select"
                        value={this.state.list_id}
                        baseUrl={this.props.baseUrl}
                        onChange={(ev) => {
                          this.setState({
                            list_id: ev.target.value,
                          });
                        }}
                      ></ListDropDown>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="mdl_view_email_stuff"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Email View</h4>
              </div>
              <div className="modal-body">
                <div className="portlet-body">
                  <table className="table table-striped table-bordered table-advance table-hover">
                    <tbody>
                      <tr>
                        <td>Send To</td>
                        <td id="mdl_email_sent_to"></td>
                      </tr>
                      <tr>
                        <td>Send From</td>
                        <td id="mdl_email_sent_from"></td>
                      </tr>
                      <tr>
                        <td>Email Subject</td>
                        <td id="mdl_email_subject"></td>
                      </tr>
                      <tr>
                        <td>Email body</td>
                        <td id="mdl_email_body"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<!-- Modal for viewing email -->*/}
        <div
          className="modal fade"
          id="mdl_view_email_stuff"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Email View</h4>
              </div>
              <div className="modal-body">
                <div className="portlet-body">
                  <table className="table table-striped table-bordered table-advance table-hover">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>Send To</td>
                        <td id="mdl_email_sent_to"></td>
                      </tr>
                      <tr>
                        <td>Send From</td>
                        <td id="mdl_email_sent_from"></td>
                      </tr>
                      <tr>
                        <td>Email Subject</td>
                        <td id="mdl_email_subject"></td>
                      </tr>
                      <tr>
                        <td>Email body</td>
                        <td id="mdl_email_body"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/**<!-- /.modal -->*/}
      </React.Fragment>
    );
  }
}
