import React, { Component } from "react";
class UsersDropDown extends Component {
  state = {
    users: [],
    container: "body",
    class: "form-control",
    id: "",
    name: "assigned_to",
    inmodal: false,
    ismulti: false,
  };
  componentDidMount() {
    if (this.props.id !== undefined) {
      this.setState({ id: this.props.id });
    }
    if (this.props.container !== undefined) {
      this.setState({ container: this.props.container });
    }
    if (this.props.class !== undefined) {
      this.setState({ class: this.props.class });
    }
    if (this.props.name !== undefined) {
      this.setState({ name: this.props.name });
    }
    if (this.props.inmodal !== undefined) {
      this.setState({ inmodal: this.props.inmodal });
    }
    if (this.props.ismulti !== undefined) {
      this.setState({ ismulti: this.props.ismulti });
    }
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/User/Ajax", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=list_users"),
    })
      .then((data) => {
        //console.log(data);
        return data.json();
      })
      .then((data) => {
        //console.log(data);
        var users = [];
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].key,
            name: data[i].value,
          };
          users.push(list_ob);
        }
        //console.log(users);
        this.setState({ users });

        if (!this.state.ismulti) {
          /*if (this.state.inmodal == true) {
            window.load_select2_in_modal("#" + this.state.id);
          } else {
            window.load_select2("#" + this.state.id);
          }*/
        } else window.$("#" + this.state.id).multiSelect();
      })
      .catch((err) => {});
  }
  render() {
    let dd_user = this.state.users.map(function (item, i) {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <select
        data-container={this.state.container}
        name={this.state.name}
        id={this.state.id}
        className={this.state.class}
        multiple={this.state.ismulti}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        <option></option>
        {dd_user}
      </select>
    );
  }
}

export default UsersDropDown;
