import React, { Component } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'
import { Popconfirm, Collapse, message, Radio } from 'antd'
import 'antd/dist/antd.css'
import '../../css/DynamicCIDCampaignWidget.css'
import {
  addToSelectedDynamicCids,
  clearSelectedDynamicCids,
  getSelectedDynamicCids,
  removeFromSelectedDynamicCids,
} from '../../helpers/pureUtilities'
// import SearchSelect from './SearchSelect'
class UserCIDWidget extends Component {
  state = {
    user_id: '',

    add_cid_dynamic_type: 'one_by_one',
    follow_master: false,
    toggle_checked: 0,
    edit_all_data: [],
    edit_list: [],
    selectedCID: [],

    search_query: '',

    user_cids: [],
    can_add_cid: false,
  }
  dt_user_cids = null
  componentDidMount() {
    this.setState({ user_id: this.props?.user_id }, () => {
      this.doTheDynamicWidget()
      this.fetchUserCids()
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.user_id != this.state.user_id) {
      this.setState({ user_id: this.props?.user_id }, () => {
        this.doTheDynamicWidget()
        this.fetchUserCids()
      })
    }
  }

  fetchUserCids = async () => {
    try {
      await this.setState({ edit_list: [] })
      const res = await fetch(
        `/backend_spa/index.php/Ajax/Users_and_WhitelistedCIDs/list_users_cids/${this.state?.user_id}`,
        {
          credentials: 'include',
          method: 'get',
        }
      )
      const res_j = await res.json()
      if (res_j.status && res_j?.data) {
        this.setState({ user_cids: res_j.data })
      }
    } catch (e) {}
  }
  doTheDynamicWidget = () => {
    if (this.dt_user_cids == null) {
      window.oooUsersCidTable = this.dt_user_cids = window.dttable(
        'dt_user_cids',

        `/backend_spa/index.php/Ajax/Users_and_WhitelistedCIDs/list_users_cids_dt/${this.state.user_id}`
      )
      /****Below is delete control for Dynamic CIDs */
      var Aurl = this.props.baseUrl
      var dtable_dynamic_cids = this.dt_user_cids

      var blockUI = this.props.blockUI

      //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
      // dtable_dynamic_cids.api().column(2).visible(!this.state.follow_master)
    } else {
      //table.ajax.url( 'newData.json' ).load();
      this.dt_user_cids
        .api()
        .ajax.url(
          `/backend_spa/index.php/Ajax/Users_and_WhitelistedCIDs/list_users_cids_dt/${this.state?.user_id}`
        )
        .load()
      // this.dt_user_cids.api().column(2).visible(!this.state.follow_master)
    }
  }
  /*************************Adding dynamic cid code */

  displayEditModal = async () => {
    this.props.blockUI(true)
    try {
      await this.setState({ edit_list: [] })
      const res = await fetch(
        `/backend_spa/index.php/Ajax/WhitelistedCID/list_all_whitelisted_cids`,
        {
          credentials: 'include',
          method: 'get',
        }
      )
      const res_j = await res.json()
      this.fetchUserCids() //fetch user caller ids
      if (res_j.status && res_j?.data) {
        const ndata = res_j?.data.map((dar) =>
          this.state.user_cids?.find((el) => el?.cid == dar?.cid)
            ? { ...dar, is_active: true }
            : { ...dar, is_active: false }
        )
        this.setState({ edit_list: ndata, edit_all_data: ndata }, () => {
          window.$('#modal_edit_dynamic_cid').modal('show')
        })
      } else {
      }
    } catch (e) {
    } finally {
      this.props.blockUI(false)
    }
  }

  /***************************************************** */
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          {/****Dynamic CID portlet */}

          <div className="portlet portlet-sortable light bordered">
            <div className="portlet-title">
              <div className="caption font-green-sharp">
                <i className="icon-speech font-green-sharp"></i>
                <span className="caption-subject bold pull-left">
                  {' '}
                  Caller IDs
                </span>
                <span className="caption-helper font-red">
                  <br /> Optional Leave blank for default caller IDs.
                </span>
              </div>
              <div className="actions">
                {this.state.follow_master ? (
                  ''
                ) : (
                  <>
                    <a
                      onClick={(e) => {
                        this.displayEditModal()
                      }}
                      className="btn btn-circle btn-default btn-sm"
                      style={{ marginRight: '4px' }}
                    >
                      <i className="fa fa-pencil"></i> Edit{' '}
                    </a>
                    {this.state.can_add_cid && (
                      <a
                        id="modal_AddDynamicCID"
                        onClick={(e) => {
                          window.$('#modal_add_dynamic_cid').modal('show')
                        }}
                        className="btn btn-circle btn-default btn-sm"
                      >
                        <i className="fa fa-plus"></i> Add{' '}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="portlet-body">
              <table
                className="table table-striped table-bordered table-advance table-hover"
                id="dt_user_cids"
              >
                <thead>
                  <tr>
                    <th>Caller ID</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>

          {/***** */}
        </div>
        {/*<!-----------Dynamic CID Modal to Add------------------->*/}
        <div
          className="modal fade draggable-modal"
          id="modal_add_dynamic_cid"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">ADD NEW Dynamic CID</h4>
              </div>

              <form
                className="form-horizontal"
                onSubmit={this.handleadddynamiccid}
                id="form_add_dynamic_cid"
                method="post"
                encType="multipart/form-data"
              >
                <input
                  type="hidden"
                  name="action"
                  defaultValue="add_whitelisted_cid"
                />
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <input
                  type="hidden"
                  name="add_cid_type"
                  value={this.state.add_cid_dynamic_type}
                />
                <div className="modal-body">
                  {/* Lets do this, add multiple option and add by csv */}
                  <Radio.Group
                    onChange={this.onAddDCChange}
                    value={this.state.add_cid_dynamic_type}
                    style={{ marginBottom: '10px' }}
                  >
                    <Radio value="one_by_one">One</Radio>
                    <Radio value="multiple">Mutliple</Radio>
                    <Radio value="csv">Upload CSV</Radio>
                  </Radio.Group>
                  {/******************************* */}
                  {this.state.add_cid_dynamic_type == 'one_by_one' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">CID</label>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="sel2_in_modal form-control"
                              name="cid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Description
                          </label>
                          <div className="col-md-4">
                            <textarea
                              type="text"
                              className="sel2_in_modal form-control"
                              name="description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {this.state.add_cid_dynamic_type == 'multiple' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">CIDs</label>
                          <div className="col-md-4">
                            <textarea
                              name="cids"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {this.state.add_cid_dynamic_type == 'csv' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">File</label>
                          <div className="col-md-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="csv_file"
                              name="csv_file"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------------------------------->*/}
        {/*<!-----------Dynamic CID Modal to Edit------------------->*/}
        <div
          className="modal fade draggable-modal"
          id="modal_edit_dynamic_cid"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title inline">Edit Dynamic Caller IDs</h4>
                <input
                  className="form-control inline"
                  style={{
                    marginLeft: '5px',
                    marginRight: '10px',
                    float: 'right',
                    height: '28px',
                    width: '180px',
                  }}
                  onChange={(e) => {
                    try {
                      this.setState(
                        { search_query: e.target.value, edit_list: [] },
                        () => {
                          const temp_dat = []
                          if (this.state.search_query == '') {
                            this.setState({
                              edit_list: this.state.edit_all_data,
                            })
                          } else {
                            this.state.edit_all_data.forEach((el) => {
                              if (
                                el.cid.indexOf(this.state.search_query) !==
                                  -1 ||
                                el.description.indexOf(
                                  this.state.search_query
                                ) !== -1
                              ) {
                                temp_dat.push(el)
                              }
                              this.setState({ edit_list: temp_dat })
                            })
                          }
                        }
                      )
                    } catch (e) {}
                  }}
                />
                {/* <SearchSelect
                  mode="multiple"
                  value={this.state.selectedCID}
                  placeholder="Select users"
                  onChange={(newValue) => {
                    this.setState({ selectedCID: newValue })
                  }}
                  style={{
                    width: '40%',
                  }}
                /> */}
              </div>
              <div className="modal-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Caller ID</th>
                      <th>Description</th>
                      <th>Status</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.edit_list.map((ci, ind) => (
                      <tr>
                        <td style={{ verticalAlign: 'middle' }}>
                          {/* <input
                            className="form-control"
                            type="text"
                            value={ci.cid}
                            onChange={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids[ind].cid = e.target.value
                              this.setState({ edit_list: all_cids })
                            }}
                          /> */}
                          <label className="label label-default">
                            {ci.cid}
                          </label>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <textarea
                            className="form-control"
                            onChange={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids[ind].description = e.target.value
                              this.setState({ edit_list: all_cids })
                            }}
                            rows={1}
                            value={ci.description}
                          >
                            {ci.description}
                          </textarea>{' '}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <label className="switch_dcid">
                            <input
                              onChange={(e) => {
                                const all_cids = this.state.edit_list
                                all_cids[ind].is_active = e.target.checked
                                  ? '1'
                                  : '0'
                                this.setState({ edit_list: all_cids })
                              }}
                              className="cid_toggler"
                              type="checkbox"
                              checked={ci.is_active == '1'}
                            />
                            <div className="slider_dcid round">
                              {/*ADDED HTML */}
                              {ci.is_active == '1' && (
                                <span className="on">ON</span>
                              )}
                              {ci.is_active == '0' && (
                                <span className="off">OFF</span>
                              )}
                              {/*END*/}
                            </div>
                          </label>
                        </td>
                        {/* <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids.splice(ind, 1)
                              this.setState({ edit_list: all_cids })
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={async (e) => {
                    this.props.blockUI(true)
                    try {
                      const resp = await fetch(
                        `/backend_spa/index.php/Ajax/Users_and_WhitelistedCIDs/bulk_edit_user_cids/${this.state.user_id}`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          body: new URLSearchParams({
                            edit_list: JSON.stringify(this.state.edit_list),
                          }),
                        }
                      )
                      const resp_j = await resp.json()
                      if (resp_j.status) {
                        window.displayAlert(
                          '#alert_container',
                          resp_j.msg,
                          'success'
                        )
                      } else {
                        window.displayAlert(
                          '#alert_container',
                          resp_j.msg,
                          'danger'
                        )
                      }
                    } catch (e) {
                      console.log('error raised somehow', e)
                      window.displayAlert(
                        '#alert_container',
                        'Something went wrong',
                        'success'
                      )
                    } finally {
                      this.dt_user_cids.api().ajax.reload()
                      this.props.blockUI(false)
                    }
                  }}
                >
                  Save All
                </button>
              </div>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------------------------------->*/}
      </React.Fragment>
    )
  }
}

export default UserCIDWidget
