import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class ScriptList extends Component {
  state = { scripts: [], isSuccessMsg: false };
  componentDidMount() {
    window.resizeHandle();

    const isSuccess = this.props.match.params.success;
    if (isSuccess !== undefined) {
      window.displayAlert("#alert_container", "Success.", "success");
    }
    //alert(isSuccess);
    fetch(this.props.baseUrl + "/index.php/Script/script_list", {
      method: "GET",
      credentials: "include"
    })
      .then(resp => resp.json())
      .then(resp => {
        console.error(resp);
        this.setState({ scripts: resp.msg });
        window.initConfirmation();
        window.App.initUniform();
      });
  }
  /*
  handleActiveToggle = (ev, script_id, i) => {
    var status = ev.target.checked ? "Y" : "N";
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Script/Ajax", {
      method: "post",
      credentials: "include",
      body: new URLSearchParams(
        $.param({
          action: "toggle_script_active",
          status: status,
          script_id: script_id
        })
      )
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        var scripts = this.state.scripts;
        scripts[i].active = status;
        this.setState(scripts);
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.blockUI();
      });
  };
  */
  DeleteScript = (ev, i) => {
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Script/post_process", {
      method: "POST",
      credentials: "include",
      body: new URLSearchParams(
        $.param({
          action: "delete_script",
          script_id: this.state.scripts[i].script_id
        })
      )
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");

          var scripts = [...this.state.scripts];
          scripts.splice(i, 1);
          this.setState({ scripts });
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Script" subtitle="list" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Scripts
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                              <tr>
                                <th>Script ID</th>
                                <th>Script Name</th>
                                {/*<th>Script Color</th>*/}
                                {/*<th>Active</th>*/}
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.scripts.map((script, i) => (
                                <tr>
                                  <td>{script.script_id}</td>
                                  <td>{script.script_name}</td>
                                  {/*<td>
                                    <div class="script_color">
                                      <div
                                        class="color-view bold uppercase"
                                        style={{
                                          backgroundColor: script.script_color
                                        }}
                                      ></div>
                                    </div>
                                  </td>*/}
                                  {/*<td>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onChange={ev => {
                                          this.handleActiveToggle(
                                            ev,
                                            script.script_id,
                                            i
                                          );
                                        }}
                                        name={script.script_id}
                                        checked={script.active == "Y"}
                                      />{" "}
                                    </label>
                                  </td>*/}
                                  <td>
                                    <a
                                      href={
                                        "#/admin/scriptedit/" + script.script_id
                                      }
                                      className="btn dark btn-sm btn-outline sbold uppercase"
                                    >
                                      <i className="fa fa-share"></i> Modify
                                    </a>

                                    <button
                                      className="btn red btn-sm btn-outline sbold uppercase"
                                      data-toggle="confirmation"
                                      data-original-title="Are you sure ?"
                                      title=""
                                      onClick={ev => {
                                        this.DeleteScript(ev, i);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScriptList;
