import React, { Component } from "react";
import { Input, Button, Table, Typography, Popconfirm } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { isArray } from "highcharts";
import "../../css/table_antd_copy.css";
const { Text } = Typography;
class ReportCDR_n extends Component {
  state = {
    page: 0,
    page_size: 10,
    selectedRowsIds: [],
    loading: false,
    data: [],
    alert_msg: "",
    editing_key: "",
    dataSource: [],
    global_search: "",
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 20,
      defaultCurrent: 1,
      pageSize: 20,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50, 100, 500],
    },
    sorting: [],
    filters: [],
  };
  componentDidMount() {
    window.resizeHandle();
    this.fetchData({
      pagination: this.state.pagination,
      filter: this.state.filters,
      sorting: this.state.sorting,
      global_search: this.state.global_search,
    });
  }
  handleTableChange = (p, f, s) => {
    this.setState({ pagination: p, sorting: s }, () => {
      this.fetchData({
        pagination: this.state.pagination,
        filter: this.state.filters,
        sorting: isArray(s) ? s : [s],
        global_search: this.state.global_search,
      });
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex] !== null && record[dataIndex] !== undefined
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filters = this.state.filters;
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].field == dataIndex) {
        filters.splice(i, 1);
        break;
      }
    }
    filters.push({ field: dataIndex, value: selectedKeys[0] });
    this.setState(filters, () => {
      console.log("Filters", this.state.filters);
      confirm();
    });
  };

  handleReset = (clearFilters) => {
    this.setState({ filters: [] }, function () {
      clearFilters();
    });
  };
  fetchData = (params = {}) => {
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Report/Ajax_CDR_n", {
      credentials: "include",
      method: "post",
      body: JSON.stringify({
        results: 10, //params.pagination.pageSize,
        page: 0, //params.pagination.current,
        ...params,
      }),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((response) => {
        console.log(response.results);
        this.setState({
          dataSource: response.results,
          pagination: {
            ...response.pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          },
        });
        this.props.blockUI(false);
      })
      .catch((e) => {
        console.log("Crash", e);
        this.props.blockUI(false);
      });
  };
  viewClient = (id) => {
    var childKey = id;
    fetch(this.props.baseUrl + "/index.php/Report/CDR/", {
      method: "GET",
      credentials: "include",
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          //show detail bootbox modal with detail as html :)
          window.bootbox.alert({
            message:
              "<h5>" +
              data.msg.name +
              " - " +
              data.msg.url +
              "</h5><table style='margin-bottom:-8px;' class='table table-striped'><tr><th>Calls</th><td>" +
              data.msg.calls +
              "</td></tr><tr><th>Call Minutes</th><td>" +
              data.msg.minutes +
              "</td></tr><tr><th>Date Created</th><td>" +
              data.msg.crt_at +
              "</td></tr><tr><th>Tenant</th><td>" +
              data.msg.tenant +
              "</td></tr><tr><th>Sent Texts</th><td>" +
              data.msg.text_count +
              "</td></tr><tr><th>Available Texts</th><td>" +
              data.msg.sms_credits +
              "</td></tr></table>",
            size: "small",
            backdrop: true,
          });
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  deleteClient = (id) => {
    var childKey = id;
    window.App.blockUI();
    fetch(
      this.props.baseUrl + "/index.php/SuperAdmin/ClientDelete/" + childKey,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success", 10);
        } else {
          window.displayAlert("#alert_container", data.msg, "danger", 15);
        }
        window.App.unblockUI();
        this.fetchData({
          pagination: this.state.pagination,
          filter: this.state.filters,
          sorting: this.state.sorting,
          global_search: this.state.global_search,
        });
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  controlElipses = (text) => {
    if (text !== null && text !== undefined)
      text =
        text.length > 12 ? (
          <Text title={text}>{text.substring(0, 12) + "..."}</Text>
        ) : (
          text
        );
    return text;
  };
  seconds_to_time = (seconds) => {
    if (seconds == 0 || seconds === undefined) {
      return ":00";
    }
    let hours = Math.floor(seconds / 3600);
    let mins = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);
    if (secs < 10) secs = "0" + secs;
    if (hours === 0) {
      if (mins === 0) {
        return ":" + secs;
      }
      return mins + ":" + secs;
    }
    return hours + ":" + mins + ":" + secs;
  };
  render() {
    const columns = [
      {
        title: "Lead ID",
        dataIndex: "lead_id",
        key: "lead_id",
        ...this.getColumnSearchProps("lead_id"),
        sorter: { multiple: 1 },
        width: "10%",
        render: (text, row) => {
          return (
            <Link to={"/leads/view/" + text}>{this.controlElipses(text)}</Link>
          );
        },
      },
      {
        title: "Agent",
        dataIndex: "agent",
        key: "agent",
        ...this.getColumnSearchProps("agent"),
        sorter: { multiple: 1 },
        width: "10%",
        render: (text, row) => {
          if (row.full_name === undefined || row.full_name === null)
            return text;
          return this.controlElipses(
            text +
              " " +
              (row.full_name.length > 0 ? "(" + row.full_name + ")" : "")
          );
        },
      },
      {
        title: "Time",
        dataIndex: "start_time_f",
        key: "start_time_f",
        ...this.getColumnSearchProps("start_time_f"),
        sorter: { multiple: 1 },
        width: "10%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },
      {
        title: "Customer Name",
        dataIndex: "fullname",
        key: "fullname",
        ...this.getColumnSearchProps("fullname"),
        sorter: { multiple: 1 },
        width: "10%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },

      {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
        ...this.getColumnSearchProps("phone"),
        sorter: { multiple: 1 },
        width: "8%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },
      {
        title: "Dispo",
        dataIndex: "dispo_status",
        key: "dispo_status",
        ...this.getColumnSearchProps("dispo_status"),
        sorter: { multiple: 1 },
        width: "4%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },
      {
        title: "Recording",
        dataIndex: "hasRecording",
        key: "hasRecording",
        ...this.getColumnSearchProps("hasRecording"),
        sorter: { multiple: 1 },
        width: "16%",
        render: (text, row) => {
          if (text === "YES") {
            return (
              <audio preload="none" style={{ width: "220px" }} controls="true">
                <source
                  src={
                    "https://" +
                    row.tenant_url +
                    "/app/call_recordings/Dolphin_download.php?date=" +
                    row.crt_on_rec +
                    "&id=" +
                    row.pbx_sip_call_id +
                    ""
                  }
                ></source>
                <source
                  src={
                    "https://" +
                    row.tenant_url +
                    "/app/call_recordings/Dolphin_download.php?date=" +
                    row.crt_on_rec +
                    "&id=" +
                    row.fpbx_uuid +
                    ""
                  }
                ></source>
              </audio>
            );
          }
          return "";
        },
      },
      {
        title: "Talk Time",
        dataIndex: "talk_seconds",
        key: "talk_seconds",
        ...this.getColumnSearchProps("talk_seconds"),
        sorter: { multiple: 1 },
        width: "10%",
        render: (text, row) => {
          return this.seconds_to_time(text);
        },
      },
      {
        title: "Call Direction",
        dataIndex: "call_direction",
        key: "call_direction",
        ...this.getColumnSearchProps("call_direction"),
        sorter: { multiple: 1 },
        width: "7%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },
      {
        title: "Caller ID",
        dataIndex: "caller_id",
        key: "caller_id",
        ...this.getColumnSearchProps("caller_id"),
        sorter: { multiple: 1 },
        width: "13%",
        render: (text, row) => {
          return this.controlElipses(text);
        },
      },
    ];
    return (
      <React.Fragment>
        <style>{this.my_css}</style>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Report
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;CDR</small>
                  </h1>
                </div>
                <div class="page-toolbar">
                  <button
                    style={{ height: "62px" }}
                    id="slv_btn_refresh"
                    class="btn"
                    onClick={(e) => {
                      this.cl_dt_clients.api().ajax.reload();
                    }}
                  >
                    <i class="icon-reload"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Reports <small id="cur_process">CDR</small>
                            </span>
                          </div>
                          <div className="actions">
                            <input
                              type="text"
                              className="form-control input-sm"
                              style={{
                                width: "150px",
                                display: "inline",
                                marginRight: "5px",
                              }}
                              value={this.state.global_search}
                              onChange={(e) =>
                                this.setState(
                                  { global_search: e.target.value },
                                  () => {
                                    if (
                                      this.state.global_search.length >= 3 ||
                                      this.state.global_search.length == 0
                                    )
                                      this.fetchData({
                                        pagination: this.state.pagination,
                                        filter: this.state.filters,
                                        sorting: this.state.sorting,
                                        global_search: this.state.global_search,
                                      });
                                  }
                                )
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="portlet-body flip-scroll"
                          style={{ paddingTop: 0 }}
                        >
                          <Table
                            size={"small"}
                            tableLayout={"auto"}
                            dataSource={this.state.dataSource}
                            columns={columns}
                            onChange={this.handleTableChange}
                            scroll={{ x: true }}
                            pagination={{
                              ...this.state.pagination,

                              defaultPageSize: 20,
                              defaultCurrent: 1,
                            }}
                          />
                          ;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportCDR_n;
