import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class TemplateList extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_email_templates",
      this.props.baseUrl + "/index.php/Email/Ajax",
      ""
    );
    /****Below is delete control */
    var Aurl = this.props.baseUrl;
    var blockUid = this.props.blockUI;
    $("#dt_email_templates").on("click", ".btn-danger", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      blockUid(true);
      fetch(Aurl + "/index.php/Email/Delete_template/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUid(false);
        })
        .catch((e) => {
          blockUid(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Email Template
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;View</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="bootstrap_alerts_demo"></div>

                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Email Template
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_email_templates"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>

                                <th>Name</th>

                                <th>Subject</th>

                                <th>Edit</th>

                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateList;
