import React, { Component } from "react";

const dtmfs = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "star",
  "hash",
];
class AudioElements extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <audio id="audio_remote"></audio>
        <audio id="audio_remote_whisper"></audio>
        <audio
          preload="auto"
          id="auto_sounds"
          src="assets/sounds/ringring.mp3"
        ></audio>
        <audio
          preload="auto"
          id="incoming_ring"
          src="assets/sounds/old_phone_bell.mp3"
        ></audio>
        <audio
          preload="auto"
          id="incoming_ring_oncall"
          src="assets/sounds/mixkit-water-bubble-1317.wav"
        ></audio>
        <audio
          preload="auto"
          id="audio_callee_busy"
          src="assets/sounds/line_busy.mp3"
        ></audio>
        <audio
          preload="auto"
          id="audio_callee_DCorNIS"
          src="assets/sounds/number_disconnected.mp3"
        ></audio>
        {/****dtmf tones */}
        <audio
          preload="auto"
          id="dtmf_tone_0"
          src="assets/sounds/dtmf/dtmf-0.mp3"
        ></audio>
        <audio
          preload="auto"
          id="dtmf_tone_0"
          src="assets/sounds/dtmf/dtmf-0.mp3"
        ></audio>
        <audio
          preload="auto"
          id="dtmf_tone_0"
          src="assets/sounds/dtmf/dtmf-0.mp3"
        ></audio>
        {dtmfs.map((el) => (
          <audio
            preload="auto"
            id={"dtmf_tone_" + el}
            src={"assets/sounds/dtmf/dtmf-" + el + ".mp3"}
          ></audio>
        ))}

        {/************************** */}
      </React.Fragment>
    );
  }
}

export default AudioElements;
