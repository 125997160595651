import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class RecordingAdd extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/recordingadd.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
  }
  form_addNewRec = (e) => {
    e.preventDefault();
    //window.App.blockUI();
    const formData = new FormData();

    //for(const name in data) {
    formData.append("action", $("#fm_action").val());
    formData.append(
      "audio_filename",
      document.getElementById("fm_audio_filename").files[0]
    );
    //}

    //this.props.doPost("/index.php/Recording/post_process", formData);
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: formData, //new URLSearchParams($("#form_addnewrec").serialize())
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        window.$("#removeFile").click();
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.displayAlert("#alert_container", "Failed", "danger");
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Add Audio
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add New</small>
                  </h1>
                </div>

                <div className="tabbable-line pull-right">
                  <ul className="nav nav-tabs ">
                    <li className="active">
                      <NavLink to="/#tab_15_1" data-toggle="tab">
                        Recording New Audio
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/#tab_15_2" data-toggle="tab">
                        Upload a File
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <div className="tab-content">
                                <div className="tab-pane active" id="tab_15_1">
                                  <div className="row">
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="col-md-12 text-center">
                                              <h3>
                                                Click the MIC to start a
                                                recording
                                              </h3>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <div className="col-md-12 text-center">
                                              <button
                                                className="btn btn-lg bg-green-jungle bg-font-green-jungle"
                                                id="btnRecording"
                                                onClick={(e) => {
                                                  window.record();
                                                }}
                                              >
                                                <i className="fa fa-microphone fa-2x in_btn_fa"></i>
                                              </button>
                                              <div id="time">
                                                <label id="minutes">00</label>:
                                                <label id="seconds">00</label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          id="recordingslist_tr"
                                          style={{ display: "none" }}
                                        >
                                          <td>
                                            <div className="col-md-12 text-center">
                                              <ul id="recordingslist"></ul>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr
                                          id="action_tr"
                                          style={{ display: "none" }}
                                        >
                                          <td className="col-md-4">
                                            Name your audio file
                                          </td>
                                          <td className="col-md-8">
                                            <div id="action">
                                              <div className="col-md-6">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="recording_name"
                                                  id="recording_name"
                                                />
                                              </div>
                                              <div className="col-md-6">
                                                <button
                                                  className="btn green-jungle  margin-right-10"
                                                  onClick={(e) => {
                                                    window.submit();
                                                  }}
                                                  id="alert_show"
                                                >
                                                  Submit
                                                </button>
                                                <button
                                                  className="btn btn-default"
                                                  onClick={(e) => {
                                                    window.reset();
                                                  }}
                                                >
                                                  Reset
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="tab-pane" id="tab_15_2">
                                  <form
                                    id="form_addnewrec"
                                    onSubmit={this.form_addNewRec}
                                    enctype="multipart/form-data"
                                  >
                                    <input
                                      type="hidden"
                                      id="fm_action"
                                      name="action"
                                      value="add_new_recording"
                                    />
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                      <tbody>
                                        <tr>
                                          <td>Browse (.wav)</td>
                                          <td>
                                            <div
                                              className="fileinput-new"
                                              data-provides="fileinput"
                                            >
                                              <div className="input-group input-large">
                                                <div
                                                  className="form-control uneditable-input input-fixed input-medium"
                                                  data-trigger="fileinput"
                                                >
                                                  <i className="fa fa-file fileinput-exists"></i>
                                                  &nbsp;
                                                  <span className="fileinput-filename"></span>
                                                </div>
                                                <span className="input-group-addon btn default btn-file">
                                                  <span className="fileinput-new">
                                                    Select file
                                                  </span>
                                                  <span className="fileinput-exists">
                                                    Change
                                                  </span>
                                                  <input
                                                    type="file"
                                                    id="fm_audio_filename"
                                                    name="audio_filename"
                                                  />
                                                </span>
                                                <NavLink
                                                  to="/"
                                                  className="input-group-addon btn red fileinput-exists"
                                                  data-dismiss="fileinput"
                                                  id="removeFile"
                                                >
                                                  Remove
                                                </NavLink>
                                              </div>
                                            </div>
                                            <small>
                                              File must be 16bit 8khz/16khz mono
                                              WAV file PCM raw
                                            </small>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            colspan="2"
                                            className="hidden-xs text-center"
                                          >
                                            <input
                                              type="hidden"
                                              name="audio_length"
                                              value=""
                                            />
                                            <span id="audiolen"></span>
                                            <button
                                              type="submit"
                                              className="btn green-jungle"
                                            >
                                              Submit
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RecordingAdd;
