import React, { Component } from 'react'
import { ReactSortable } from 'react-sortablejs'
import {
  Select,
  Option,
  Input,
  Button,
  Space,
  Checkbox,
  Table,
  Modal,
  Empty,
  Typography,
  Menu,
  Dropdown,
  message,
  Tooltip,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'

import ListDropDown from '../Bricks/ListDropDown'
import UsersDropDown from '../Bricks/UsersDropDown'
import LeadStatusDropDown from '../Bricks/LeadStatusDropDown'
import HeadTitle from '../HeadTitle'
import { isArray } from 'highcharts'

import '../../css/table_antd.css'

const { Text } = Typography

const serialize = function (obj) {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

class LeadList extends Component {
  /***submit button additional menu */
  submit_add_menu = (
    <Menu
      onClick={(e) => {
        this.setState({ sbmit_chevron_up: false })
        this.doTheAction(e)
      }}
    >
      <Menu.Item key="1">Apply to Selected Leads</Menu.Item>
      <Menu.Item key="2">Apply to All Filtered Leads (Max 50,000)</Menu.Item>
    </Menu>
  )
  /***************************************** */
  /**static columns */
  actionCol = {
    title: 'Action',
    dataIndex: 'key',
    key: 'key',
    width: '',
    visible: true,
    permanent: true,
    render: (text, record) => (
      <Button
        type="link"
        onClick={(e) => {
          this.props.history.push('/leads/view/' + text)
        }}
      >
        View
      </Button>
    ),
  }
  /*********************** */
  /**The special filters */
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value ? value.toLowerCase() : '')
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filters = this.state.filters
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].field == dataIndex) {
        filters.splice(i, 1)
        break
      }
    }
    filters.push({ field: dataIndex, value: selectedKeys[0] })
    this.setState(filters, function () {
      localStorage.setItem(
        'Dol_LeadList_filters',
        JSON.stringify(this.state.filters)
      )
      this.updateColumns()
      confirm()
    })
  }

  handleReset = (clearFilters) => {
    this.setState({ filters: [] }, function () {
      localStorage.setItem(
        'Dol_LeadList_filters',
        JSON.stringify(this.state.filters)
      )
      this.updateColumns()
      clearFilters()
    })
  }
  /*************************** */
  controlElipses = (text) => {
    if (text !== null && text !== undefined)
      text =
        text.length > 17 ? (
          <Text title={text}>{text.substring(0, 17) + '...'}</Text>
        ) : (
          text
        )
    return text
  }
  /**************** */
  state = {
    sbmit_chevron_up: false,
    lists: [],
    users: [],
    lead_statuses: [],
    AllColumns: [
      {
        title: 'List',
        dataIndex: 'list',
        key: 'list',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },

      {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text, record, index) => {
          let campaign_id = ''
          let lead_id = ''
          /***limiting length visible in table only */
          //text = this.controlElipses(text);
          /******************* */
          try {
            lead_id = record.key
            campaign_id = record.campaign.split('-')[0]
          } catch (e) {
            console.log('a')
          }
          return record.isDNC == 0 ? (
            record.user_status.toLowerCase() !== 'active' ? (
              <span
                title="Your user account does not have dialing privileges enabled. Please contact a manager."
                style={{
                  cursor: 'not-allowed',
                }}
              >
                {text}
              </span>
            ) : (
              <Button
                type="link"
                onClick={(e) => {
                  window.sipCall(text, lead_id, campaign_id)
                }}
                block
                style={{ textAlign: 'left', paddingLeft: '0' }}
              >
                {text}
              </Button>
            )
          ) : (
            <span
              title="This number has been previously dispositioned as DO NOT CALL."
              style={{ color: 'red' }}
            >
              {text}
            </span>
          )
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Last Call Status',
        dataIndex: 'last_call_dispo',
        key: 'last_call_dispo',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Owner',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Date Modified',
        dataIndex: 'date_modified',
        key: 'date_modified',
        width: '',
        visible: true,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Campaign',
        dataIndex: 'campaign',
        key: 'campaign',
        width: '',
        visible: false,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: '',
        visible: false,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: '',
        visible: false,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Lead Status',
        dataIndex: 'lead_status',
        key: 'lead_status',
        width: '',
        visible: false,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width: '',
        visible: false,
        sorter: { multiple: 1 },
        render: (text) => {
          return this.controlElipses(text)
        },
      },
    ],
    lc_id: '',
    columns: [],
    selectedRowKeys: [],
    selectedRows: [],
    table_loading: false,
    data: [],
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 50,
      defaultCurrent: 1,
      pageSize: 50,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50, 100, 500],
    },
    sorting: [],
    filters: [],
    action: '',
    mdl_assignee_visible: false,
    assignee: '',
    app_type: 'lead_ids', //application type on submit lead actions
    global_search_param: '',
    module_type: 'all', //all or mine or a list id
    search_list_id: '',
  }

  componentDidMount() {
    window.resizeHandle()
    //load pagination from localstorage//
    const pagination = JSON.parse(
      localStorage.getItem('Dol_LeadList_pagination')
    )
    const filters = JSON.parse(localStorage.getItem('Dol_LeadList_filters'))
    //const sorter = JSON.parse(localStorage.getItem("Dol_LeadList_sorter"));

    this.setState(
      {
        pagination: pagination ? pagination : this.state.pagination,
        filters: filters ? filters : [],
      },
      function () {
        this.initCom()
        /* if (sorter) {
          this.setState(sorter, function () {
            this.initCom();
          });
        }*/
      }
    )

    /////////////////////////////////////////
  }
  componentDidUpdate(prevProps) {
    try {
      if (prevProps.leadview !== this.props.leadview) {
        this.setState({ module_type: this.props.leadview }, function () {
          console.warn('****mod type***')
          this.initCom()
          console.warn('**************')
        })
      }
      const gs = this.props.match.params.global_search
      const lid = this.props.match.params.list_id
      if (
        gs !== this.state.global_search_param &&
        (gs !== undefined ||
          (this.state.global_search_param !== undefined &&
            this.state.global_search_param !== ''))
      ) {
        this.setState({ global_search_param: gs }, function () {
          console.warn('******gs********')
          this.initCom()
          console.warn('**************')
        })
      }
      if (
        lid !== this.state.search_list_id &&
        (lid !== undefined ||
          (this.state.search_list_id !== undefined &&
            this.state.search_list_id != ''))
      ) {
        this.setState({ search_list_id: lid }, function () {
          console.warn('*******lid*******')
          this.initCom()
          console.warn('**************')
        })
      }
    } catch (e) {
      console.warn('***********catch***********')
      console.warn(e)
      console.warn('***********catch***********')
    }
  }
  initCom = () => {
    try {
      const gs = this.props.match.params.global_search
      const lid = this.props.match.params.list_id
      //alert(gs);
      if (gs !== undefined || lid !== undefined) {
        if (lid !== undefined && window.global_alert_msg.length > 2) {
          window.displayAlert(
            '#alert_container',
            window.global_alert_msg,
            'success'
          )
          window.global_alert_msg = ''
        }
        //alert("hi1");
        this.setState(
          { global_search_param: gs, search_list_id: lid },
          function () {
            this.fillInTheTable({
              global_search: this.state.global_search_param,
              search_list_id: this.state.search_list_id,
              module_type: this.state.module_type,
              filter: this.state.filters,
            })
          }
        )
      } else {
        //alert("hi");
        this.fillInTheTable({
          pagination: this.state.pagination,
          global_search: this.state.global_search_param,
          search_list_id: this.state.search_list_id,
          module_type: this.state.module_type,
          filter: this.state.filters,
        })
      }
      //alert(gs);
    } catch (e) {
      //alert("j");
    }
    //fetch lists, users and leads statuses
    //fetch lead statuses
    fetch(this.props.baseUrl + '/index.php/Ajax/getLeadStatuses', {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        var lead_statuses = []
        for (var i = 0; i < data.length; i++) {
          lead_statuses.push(data[i])
        }
        this.setState({ lead_statuses })
      })
      .catch((err) => {})
    //
    //fetch lists
    fetch(this.props.baseUrl + '/index.php/Lists/Ajax', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams('action=get_lists'),
    })
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        var lists = [{ id: '', name: '' }]
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].ListId,
            name: data[i].ListName,
          }
          lists.push(list_ob)
        }
        this.setState({ lists })
      })
      .catch((err) => {})
    //
    //fetch users
    fetch(this.props.baseUrl + '/index.php/User/Ajax', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams('action=list_users'),
    })
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        var users = []
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].key,
            name: data[i].value,
          }
          users.push(list_ob)
        }
        this.setState({ users })
      })
      .catch((err) => {})
    /////////////////////////////////////////////
    //fetch columns for the first time form db
    fetch(this.props.baseUrl + '/index.php/Ajax/lc_columns', {
      method: 'POST',
      credentials: 'include',
      body: new URLSearchParams(
        serialize({ action: 'view', type: 'leads_table' })
      ),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status == true) {
          /*this.setState({ AllColumns: data.msg }, function () {
            this.updateColumns();
          });*/
          if (isArray(data.msg)) {
            let acols = this.state.AllColumns
            for (var i = 0; i < data.msg.length; i++) {
              for (var j = 0; j < acols.length; j++) {
                if (acols[j].key == data.msg[i].key) {
                  acols[j].visible = data.msg[i].visible
                }
              }
            }
            this.setState({ AllColumns: acols }, function () {
              this.updateColumns()
            })
          }
        }
      })
      .catch((data) => {
        console.log(data)
      })

    ///////////////////////////////////////////////
  }
  updateColumns = () => {
    let cols = []
    for (var i = 0; i < this.state.AllColumns.length; i++) {
      if (this.state.AllColumns[i].visible == true) {
        let tc = this.state.AllColumns[i]
        //get the active filter abd text
        let fil_array = []
        for (var kk = 0; kk < this.state.filters.length; kk++) {
          if (this.state.filters[kk].field == tc.dataIndex) {
            fil_array.push(this.state.filters[kk].value)
          }
        }
        ///
        let tc_wf = {
          ...tc,
          ...this.getColumnSearchProps(tc.dataIndex),
          render: tc.render,
          filteredValue: fil_array,
        }

        //checking if filter gui should be there or not
        if (
          (this.state.global_search_param !== undefined &&
            this.state.global_search_param !== '' &&
            this.state.global_search_param !== null &&
            ['full_name', 'phone', 'email', 'company'].includes(
              tc.dataIndex
            )) ||
          (this.state.search_list_id !== undefined &&
            this.state.search_list_id !== '' &&
            this.state.search_list_id !== null &&
            ['list', 'campaign'].includes(tc.dataIndex))
        ) {
          cols.push(tc)
        } else {
          //push tc with filter
          cols.push(tc_wf)
        }
      }
    }
    cols.push(this.actionCol) //put in static action column here ;)
    this.setState({
      columns: cols,
    })
  }
  //Our method to bring in leads list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true })
    fetch(this.props.baseUrl + '/index.php/Leads/LeadsListv2', {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        results: 10, //params.pagination.pageSize,
        page: 0, //params.pagination.current,
        ...params,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data)
        this.setState(
          {
            loading: false,
            data: data.results,
            pagination: {
              ...params.pagination,
              total: data.pagination.total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              defaultPageSize: 20,
              defaultCurrent: 1,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100, 500],
            },
            sorting: params.sorting,
          },
          function () {
            //we should save pagination to localstorage and load it when component is remounted
            try {
              localStorage.setItem(
                'Dol_LeadList_pagination',
                JSON.stringify(this.state.pagination)
              )
            } catch (e) {}

            /*localStorage.setItem(
              "Dol_LeadList_sorter",
              JSON.stringify(this.state.sorter)
            );*/
          }
        )
      })
      .catch((er) => {
        console.log(er)
      })
  }
  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    console.log('******')
    console.log(pagination)
    console.log('******')
    this.setState({ sorting: sorter })
    this.fillInTheTable({
      pagination,
      filter: this.state.filters,
      sorting: isArray(sorter) ? sorter : [sorter],
      global_search: this.state.global_search_param,
      search_list_id: this.state.search_list_id,
      module_type: this.state.module_type,
    })
  }
  restoreActionSelect = (a) => {
    this.setState({ action: '', loading: false })
  }
  doTheAction = (e) => {
    let app_type = 'lead_ids'
    if (e.key !== undefined && e.key !== null && e.key !== '1') {
      app_type = 'filter'
      this.setState({ app_type: 'filter' })
    } else {
      if (this.state.selectedRowKeys.length <= 0) {
        Modal.info({
          content: 'No Leads Selected',
        })
        return
      }
    }

    switch (this.state.action) {
      case 'remove_leads':
      case 'make_contact':
        const url = this.props.baseUrl + '/index.php/Leads/Ajaxv2'
        const action = this.state.action
        const leads = this.state.selectedRowKeys
        const fillintable = this.fillInTheTable
        const pagination = this.state.pagination
        const filters = this.state.filters
        const sorting = this.state.sorting
        const gs = this.state.global_search_param
        const lid = this.state.search_list_id
        const mtype = this.state.module_type
        Modal.confirm({
          afterClose: this.restoreActionSelect,
          icon: <ExclamationCircleOutlined />,
          content: <p>Are you sure?</p>,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            //submit to server
            fetch(url, {
              credentials: 'include',
              method: 'POST',
              body: new URLSearchParams({
                action: action,
                leadIds: leads,
                app_type: app_type,
                filter: JSON.stringify(filters),
                sorter: JSON.stringify(sorting),
                global_search: gs,
                search_list_id: lid,
                module_type: mtype,
              }),
            })
              .then((data) => data.json())
              .then((data) => {
                if (data.status) {
                  fillintable({
                    pagination: pagination,
                    filter: filters,
                    sorting: isArray(sorting) ? sorting : [sorting],
                    global_search: gs,
                    search_list_id: lid,
                    module_type: mtype,
                  })
                  window.displayAlert('#alert_container', data.msg, 'success')
                } else
                  window.displayAlert('#alert_container', data.msg, 'danger')
              })
              .catch((data) => {
                console.log(data)
                window.displayAlert('#alert_container', data.msg, 'danger')
              })
          },
          onCancel() {},
        })

        break
      case 'assign_to_list':
      //show modal of list, pick list and submit ;)
      case 'assign_to_user':
      case 'assign_lead_status':
        this.setState({ mdl_assignee_visible: true })
      case 'add_to_float_pd':
        this.props.update_fly_pd_list(this.state.selectedRows)
        break
      default:
        break
    }
  }
  handleAsigneeOk = () => {
    console.log(this.state.filters)
    //submit to server
    fetch(this.props.baseUrl + '/index.php/Leads/Ajaxv2', {
      credentials: 'include',
      method: 'POST',
      body: new URLSearchParams({
        action: this.state.action,
        leadIds: this.state.selectedRowKeys,
        assignee: this.state.assignee,
        app_type: this.state.app_type,
        filter: JSON.stringify(this.state.filters),
        sorter: JSON.stringify(this.state.sorting),
        global_search: this.state.global_search_param,
        search_list_id: this.state.search_list_id,
        module_type: this.state.module_type,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.fillInTheTable({
            pagination: this.state.pagination,
            filter: this.state.filters,
            sorting: isArray(this.state.sorting)
              ? this.state.sorting
              : [this.state.sorting],
            global_search: this.state.global_search_param,
            search_list_id: this.state.search_list_id,
            module_type: this.state.module_type,
          })
          window.displayAlert('#alert_container', data.msg, 'success')
        } else window.displayAlert('#alert_container', data.msg, 'danger')

        this.setState({ mdl_assignee_visible: false })
      })
      .catch((data) => {
        window.displayAlert('#alert_container', data.msg, 'danger')

        this.setState({
          mdl_assignee_visible: false,
          assignee: '',
          action: '',
        })
      })
    console.log(this.state.assignee)
  }
  handleAsigneeCancel = () => {
    console.log(this.state.assignee)
    this.setState({ mdl_assignee_visible: false, assignee: '', action: '' })
  }
  render() {
    return (
      <React.Fragment>
        {/******************Modal for assignment of leads start******************** */}
        <Modal
          title={
            this.state.action == 'assign_to_list'
              ? 'Select A List'
              : this.state.action == 'assign_to_user'
              ? 'Select A User'
              : this.state.action == 'assign_lead_status'
              ? 'Select Lead Status'
              : ''
          }
          visible={this.state.mdl_assignee_visible}
          onOk={this.handleAsigneeOk}
          onCancel={this.handleAsigneeCancel}
        >
          {this.state.action == 'assign_to_list' ? (
            <Select
              value={this.state.assignee}
              onChange={(val) => {
                this.setState({ assignee: val })
              }}
              style={{ width: '100%' }}
            >
              {this.state.lists.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          ) : this.state.action == 'assign_to_user' ? (
            <Select
              value={this.state.assignee}
              onChange={(val) => {
                this.setState({ assignee: val })
              }}
              style={{ width: '100%' }}
            >
              {this.state.users.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          ) : this.state.action == 'assign_lead_status' ? (
            <Select
              value={this.state.assignee}
              onChange={(val) => {
                this.setState({ assignee: val })
              }}
              style={{ width: '100%' }}
            >
              {this.state.lead_statuses.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          ) : (
            ''
          )}
        </Modal>
        {/**********Modal for assignment of leads end */}
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Leads List ">
              <div class="page-toolbar">
                <button
                  style={{ height: '62px' }}
                  id="slv_btn_refresh"
                  class="btn"
                  title="Refresh page"
                  onClick={(e) => {
                    this.fillInTheTable({
                      pagination: this.state.pagination,
                      filter: this.state.filters,
                      sorting: isArray(this.state.sorting)
                        ? this.state.sorting
                        : [this.state.sorting],
                      global_search: this.state.global_search_param,
                      search_list_id: this.state.search_list_id,
                      module_type: this.state.module_type,
                    })
                  }}
                >
                  <i class="icon-reload"></i>
                </button>
              </div>
            </HeadTitle>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Leads{' '}
                              {[undefined, null, ''].includes(
                                this.state.search_list_id
                              )
                                ? ''
                                : ' (List ' + this.state.search_list_id + ')'}
                            </span>
                          </div>

                          <div className="actions">
                            {/*****settings gear icon */}
                            <div
                              className="btn-group btn-theme-panel"
                              style={{ marginTop: '0px' }}
                            >
                              <a
                                href="javascript:;"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={{ padding: '0px 6px', opacity: '0.6' }}
                              >
                                <i
                                  className="icon-settings"
                                  style={{
                                    color: '#acbac6',
                                    fontSize: '24px',
                                    marginTop: '7px',
                                  }}
                                ></i>
                              </a>
                              <div
                                className="dropdown-menu theme-panel pull-right dropdown-custom"
                                style={{
                                  minWidth: '300px',
                                  marginTop: '0px',
                                  padding: '0px 5px',
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <h3>Select Columns</h3>
                                    <div className="row">
                                      <div
                                        className="col-md-3 col-sm-4 col-xs-5"
                                        style={{ paddingRight: '0' }}
                                      >
                                        <ul className="list-group">
                                          {this.state.AllColumns.map(
                                            (item, ind) => (
                                              <li
                                                className="list-group-item"
                                                key={item.dataIndex}
                                              >
                                                <Checkbox
                                                  checked={item.visible}
                                                  disabled={
                                                    item.permanent !== undefined
                                                  }
                                                  onChange={(e) => {
                                                    let acs =
                                                      this.state.AllColumns
                                                    if (
                                                      this.state.columns
                                                        .length >= 8 &&
                                                      e.target.checked == true
                                                    ) {
                                                      Modal.info({
                                                        content: (
                                                          <p>
                                                            Not allowed to
                                                            display more than 7
                                                            columns
                                                          </p>
                                                        ),
                                                      })
                                                      return
                                                    }
                                                    acs[ind].visible =
                                                      e.target.checked
                                                    this.setState(
                                                      {
                                                        AllColumns: acs,
                                                      },
                                                      function () {
                                                        this.updateColumns()
                                                        //update db

                                                        //save updated state to database
                                                        fetch(
                                                          this.props.baseUrl +
                                                            '/index.php/Ajax/lc_columns',
                                                          {
                                                            method: 'POST',
                                                            credentials:
                                                              'include',
                                                            body: new URLSearchParams(
                                                              serialize({
                                                                action:
                                                                  'update',
                                                                columns:
                                                                  JSON.stringify(
                                                                    this.state
                                                                      .AllColumns
                                                                  ),
                                                                type: 'leads_table',
                                                              })
                                                            ),
                                                          }
                                                        )
                                                          .then((data) =>
                                                            data.json()
                                                          )
                                                          .then((data) => {
                                                            if (
                                                              data.status ==
                                                              true
                                                            ) {
                                                              this.setState({
                                                                lc_id: data.id,
                                                              })
                                                            }
                                                          })
                                                          .catch((data) => {
                                                            console.log(data)
                                                          })
                                                        //

                                                        ///////////////
                                                      }
                                                    )
                                                  }}
                                                ></Checkbox>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div
                                        className="col-md-9 col-sm-8 col-xs-7"
                                        style={{ paddingLeft: '0' }}
                                      >
                                        {/*************List Columns */}
                                        <ReactSortable
                                          list={this.state.AllColumns}
                                          setList={(newList) => {
                                            this.setState(
                                              {
                                                AllColumns: newList,
                                              },
                                              function () {
                                                this.updateColumns()
                                              }
                                            )
                                          }}
                                          tag="ul"
                                          className="list-group"
                                          options={{ delay: true }}
                                          onChange={console.log}
                                          style={{ marginBottom: '5px' }}
                                        >
                                          {this.state.AllColumns.map(
                                            (item, ind) => (
                                              <li
                                                className="list-group-item"
                                                key={item.dataIndex}
                                              >
                                                {item.title}
                                              </li>
                                            )
                                          )}
                                        </ReactSortable>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/************************** */}
                            <div className="btn-group">
                              <input
                                type="hidden"
                                id="asignee_value"
                                value=""
                              />
                              {/*<button
                                onClick={this.doTheAction}
                                className="btn btn-sm green table-group-action-submit pull-right"
                              >
                                <i className="fa fa-check"></i> Submit
                              </button>*/}
                              <Dropdown.Button
                                onClick={this.doTheAction}
                                overlay={this.submit_add_menu}
                                className="pull-right leadlist_dd"
                                trigger="click"
                                onVisibleChange={(e) => {
                                  this.setState({ sbmit_chevron_up: e })
                                }}
                                buttonsRender={([leftButton, rightButton]) => [
                                  <button
                                    onClick={this.doTheAction}
                                    className="btn btn-sm green table-group-action-submit pull-right"
                                    style={{ borderRadius: '0' }}
                                  >
                                    Submit
                                  </button>,
                                  <button
                                    className="btn btn-sm green table-group-action-submit pull-right"
                                    style={{
                                      borderRadius: '0',
                                      borderLeft: '1px solid white important',
                                    }}
                                  >
                                    <i
                                      className={
                                        this.state.sbmit_chevron_up
                                          ? 'fa fa-chevron-up'
                                          : 'fa fa-chevron-down'
                                      }
                                    ></i>
                                  </button>,
                                ]}
                              >
                                Submit
                              </Dropdown.Button>
                              <select
                                onChange={(e) => {
                                  this.setState({ action: e.target.value })
                                }}
                                className="table-group-action-input form-control input-inline input-small input-sm"
                                value={this.state.action}
                              >
                                <option value="">Select...</option>

                                {this.props.role !== 'agent' ? (
                                  <React.Fragment>
                                    <option value="remove_leads">Delete</option>
                                    <option value="make_contact">
                                      Convert to Contact
                                    </option>
                                    <option value="assign_to_list">
                                      Assign to List
                                    </option>
                                    <option value="assign_to_user">
                                      Assign to User
                                    </option>
                                    <option value="assign_lead_status">
                                      Assign Lead Status
                                    </option>
                                    <option value="add_to_float_pd">
                                      Add to Dial List
                                    </option>
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                                {this.props.role === 'agent' &&
                                this.props.settings.allow_lead_assign ==
                                  'Yes' ? (
                                  <option value="assign_to_user">
                                    Assign to User
                                  </option>
                                ) : (
                                  ''
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          className="portlet-body flip-scroll"
                          style={{ paddingTop: 0 }}
                        >
                          {/****ANTD TABLE HERE */}
                          <Table
                            size={'small'}
                            tableLayout={'auto'}
                            rowSelection={{
                              onChange: (selectedRowKeys, selectedRows) => {
                                this.setState({
                                  selectedRowKeys: selectedRowKeys,
                                  selectedRows: selectedRows,
                                })
                              },
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={this.state.pagination}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            scroll={{ x: true }}
                            locale={{
                              emptyText: (
                                <Empty description="No Leads Added"></Empty>
                              ),
                            }}
                          ></Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default LeadList
