import React, { Component } from 'react'
import UserGroupsdd from '../../Bricks/UserGroupsdd'
import $ from 'jquery'
import HeadTitle from '../../HeadTitle'
import { generatePassword } from '../../../helpers/minorhelp'
import UserCIDWidget from '../../Bricks/UsersCIDWidget'
class UserAdd extends Component {
  state = {
    user_id: '',
    full_name: '',
    email: '',
    password: '',
    user_group: '',
    caller_id: '',
    role: 'agent',
    status: 'Active',
    sms_cid: '',
  }
  u_id = ''
  componentDidMount() {
    window.resizeHandle()
    this.u_id = this.props.match.params.user_id
    //load its specific js file ;)
    const script = document.createElement('script')
    script.src = 'jsdolphin/user_edit.js'
    script.async = true
    document.body.appendChild(script)
    ////////////////////////////////////
    //window.load_select2(".bs-select");
    //fetch and fill with info
    fetch(this.props.baseUrl + '/index.php/User/Edit/' + this.u_id, {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp)
        this.setState({
          user_id: this.u_id,
          full_name: resp.msg.full_name,
          email: resp.msg.email,
          password: resp.msg.password,
          user_group: resp.msg.user_group,
          caller_id: resp.msg.caller_id,
          role: resp.msg.role,
          status: resp.msg.status,
          sms_cid: resp.msg.sms_cid,
        })
        window.$('.bs-select').trigger('change')
      })
    ///////////////////////////////
  }
  handleSubmit = (form) => {
    form.preventDefault()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#formUserEdit').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          //window.displayAlert("#alert_container", data.msg, "success");

          //take it back to user list page and display the alert too
          window.global_alert_msg = 'User successfully Edited'
          this.props.history.push('/admin/users/view/1')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleGenPasswordClick = (e) => {
    // var generator = require("generate-password");

    // var password = generator.generate({
    //   length: 10,
    //   numbers: true,
    //   uppercase: true,
    //   lowercase: true,
    //   symbols: false,
    // });
    const password = generatePassword(10, false, true, true, true)
    //$("#password").val(password);
    this.setState({ password })
    window.pass_show = false
    $('#genpassword').click()
  }
  spam_caller_id_check = (e, cid) => {
    window.App.blockUI()
    //alert($(this).attr("data-cid"));
    if (cid === undefined) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    if (cid.length !== 10) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    const Aurl = this.props.baseUrl
    fetch(Aurl + '/index.php/Ajax/scrap_for_spam_cid/' + cid, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        window.$('#spam_check_cid').html(cid)
        window.$('#mdl_spmchk_cid').html(cid)
        window
          .$('#mdl_spmchk_score')
          .html(data.spam_score + '/' + data.spam_total)
        if (data.spam_score <= 2) {
          window.$('#mdl_spmchk_score').css('color', 'green')
        } else {
          window.$('#mdl_spmchk_score').css('color', 'red')
        }
        var spam_markers = 'None'
        for (var i = 0; i < data.spam_markers.length; i++) {
          if (i == 0)
            spam_markers =
              '<a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
          else
            spam_markers +=
              '<br /><a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
        }
        window.$('#mdl_spmchk_by').html(spam_markers)
        var spam_sources = ''
        for (var i = 0; i < data.spam_sources.length; i++) {
          if (i == 0)
            spam_sources =
              '<small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
          else
            spam_sources +=
              '<br /><small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
        }
        window.$('#mdl_spmchk_at').html(spam_sources)
        window.$('#mdl_spam_check_cid').modal('show')
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Users" subtitle="edit" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-7 ">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="formUserEdit"
                                onSubmit={this.handleSubmit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_user"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td width="40%">
                                        User Id{' '}
                                        <small>
                                          (4 or 5 digit extension number)
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="user_id"
                                          value={this.state.user_id}
                                          readOnly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="full_name"
                                          value={this.state.full_name}
                                          onChange={(ev) => {
                                            this.setState({
                                              full_name: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Email</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email"
                                          value={this.state.email}
                                          onChange={(ev) => {
                                            this.setState({
                                              email: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Password</td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <input
                                              type="password"
                                              id="password"
                                              className="form-control"
                                              name="password"
                                              style={{ paddingLeft: '10px' }}
                                              value={this.state.password}
                                              onChange={(ev) => {
                                                this.setState({
                                                  password: ev.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="genpassword"
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-eye fa-fw"></i>
                                            </button>
                                            <button
                                              onClick={
                                                this.handleGenPasswordClick
                                              }
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{' '}
                                              Generate
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>User Group </td>
                                      <td>
                                        <input
                                          type="hidden"
                                          name="user_group"
                                          value={this.state.user_group}
                                        />
                                        <UserGroupsdd
                                          baseUrl={this.props.baseUrl}
                                          name="user_group"
                                          value={this.state.user_group}
                                          onChange={(selected) => {
                                            this.setState({
                                              user_group: selected,
                                            })
                                          }}
                                        ></UserGroupsdd>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                      <td>
                                        CID{' '}
                                        <small>(leave blank for default)</small>
                                        &nbsp;
                                        <a
                                          title="Check Caller ID for potential SPAM flag."
                                          class="fa fa-exclamation-triangle"
                                          href="javascript:;"
                                          onClick={(e) =>
                                            this.spam_caller_id_check(
                                              e,
                                              this.state.caller_id
                                            )
                                          }
                                        ></a>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="caller_id"
                                          maxlength="10"
                                          value={this.state.caller_id}
                                          onChange={(ev) => {
                                            this.setState({
                                              caller_id: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr> */}
                                    <tr>
                                      <td>
                                        SMS Caller ID{' '}
                                        <small>(leave blank for default)</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="sms_caller_id"
                                          maxLength="10"
                                          value={this.state.sms_cid}
                                          onChange={(ev) => {
                                            this.setState({
                                              sms_cid: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Role</td>
                                      <td>
                                        <select
                                          name="role"
                                          className="bs-select form-control"
                                          value={this.state.role}
                                          onChange={(ev) => {
                                            this.setState({
                                              role: ev.target.value,
                                            })
                                          }}
                                        >
                                          <option value="agent">Agent</option>

                                          <option value="manager">
                                            Manager
                                          </option>

                                          <option value="owner">Owner</option>

                                          {this.props.role == 'superadmin' ? (
                                            <option value="superadmin">
                                              Super Admin
                                            </option>
                                          ) : (
                                            ''
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dialing</td>
                                      <td>
                                        <select
                                          name="status"
                                          className="bs-select form-control"
                                          value={this.state.status}
                                          onChange={(ev) => {
                                            this.setState({
                                              status: ev.target.value,
                                            })
                                          }}
                                        >
                                          <option value="Active">Yes</option>

                                          <option value="Inactive">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <UserCIDWidget
                        blockUI={this.props?.blockUI}
                        user_id={this.state.user_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UserAdd
