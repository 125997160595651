import React, { Component } from "react";
import $ from "jquery";

import PAMLabel from "../../Bricks/PAMLabel";
import HeadTitle from "../../HeadTitle";
class RecordingList extends Component {
  state = {
    audio_files: [],
    pam1: "",
    pam2: "",
    pam3: "",
    pam4: "",
    pam5: "",
    lbl_pam1: "",
    lbl_pam2: "",
    lbl_pam3: "",
    lbl_pam4: "",
    lbl_pam5: "",
    pam1_inedit: false,
    pam2_inedit: false,
    pam3_inedit: false,
    pam4_inedit: false,
    pam5_inedit: false,
  };
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_recordings",
      this.props.baseUrl + "/index.php/Recording/Ajax",
      ""
    );
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/recordingslist.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    //fetch data from recording list actually ;)

    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/recording_list", {
      method: "POST",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        //console.error(resp);
        if (resp.status) {
          this.setState({
            audio_files: resp.msg.audio_files,
            pam1: resp.msg.pam1,
            pam2: resp.msg.pam2,
            pam3: resp.msg.pam3,
            pam4: resp.msg.pam4,
            pam5: resp.msg.pam5,
          });
          console.error(resp.msg);
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
    ///////////////////////////////////////
    /****Below is delete control */
    var Aurl = this.props.baseUrl;
    var blockUid = this.props.blockUI;
    $("#dt_recordings").on("click", ".btn-danger", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      blockUid(true);
      fetch(Aurl + "/index.php/Recording/Delete_recording/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUid(false);
        })
        .catch((e) => {
          blockUid(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //^^^^^^^^^^^below is view recording thing^^^^^^^^^^^^^//
    $("#dt_recordings").on("click", ".view_rec", function () {
      var id = $(this).attr("rec_id"); //.closest("tr").contents()[0];
      var filename = $(this).closest("tr").contents()[1];
      window.$("#modal_viewRec").modal("show");
      $("#modal_viewrec_title").html("View Recording " + $(filename).html());
      $("#mdl_viewrec_audio_src").attr(
        "src",
        Aurl + "/index.php/Recording/download/" + id // + $(id).html()
      );

      $("#mdl_download_rec").attr(
        "href",
        Aurl + "/index.php/Recording/download/" + id // + $(id).html()
      );

      var audio = document.getElementById("playRec");
      audio.load();
    });
    ////////////////////////////////////////////////////////////////
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    //console.error(this.props.match.params);
    //alert(show_alert);
    if (show_alert !== undefined) {
      window.displayAlert(
        "#alert_container",
        "Recording Successfully Added. ",
        "success"
      );
    }
    //set pam label vals
    try {
      if (this.props.settings) {
        const set = this.props.settings;
        this.setState({
          lbl_pam1: set.lbl_pam1,
          lbl_pam2: set.lbl_pam2,
          lbl_pam3: set.lbl_pam3,
          lbl_pam4: set.lbl_pam4,
          lbl_pam5: set.lbl_pam5,
        });
      }
    } catch (e) {}
    //
  }
  componentWillReceiveProps(nextProps) {
    try {
      if (
        nextProps.settings.lbl_pam1 != this.state.lbl_pam1 ||
        nextProps.settings.lbl_pam2 != this.state.lbl_pam2 ||
        nextProps.settings.lbl_pam3 != this.state.lbl_pam3 ||
        nextProps.settings.lbl_pam4 != this.state.lbl_pam4 ||
        nextProps.settings.lbl_pam5 != this.state.lbl_pam5
      ) {
        const set = nextProps.settings;
        this.setState({
          lbl_pam1: set.lbl_pam1,
          lbl_pam2: set.lbl_pam2,
          lbl_pam3: set.lbl_pam3,
          lbl_pam4: set.lbl_pam4,
          lbl_pam5: set.lbl_pam5,
        });
      }
    } catch (e) {}
  }
  componentWillUnmount() {
    window.removeJS("jsdolphin/recordingslist.js");
  }
  stopAudio = (e) => {
    var audio = document.getElementById("playRec");
    audio.pause();
    audio.currentTime = 0;
    $("#mdl_viewrec_audio_src").removeAttr("src");
    $("#mdl_download_rec").removeAttr("href");
  };
  submitformPam1 = (e) => {
    e.preventDefault();
    this.props.doPost(
      "/index.php/Recording/post_process",
      new URLSearchParams($("#form_update_pam1").serialize())
    );
    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam1").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };
  submitformPam2 = (e) => {
    e.preventDefault();
    this.props.doPost(
      "/index.php/Recording/post_process",
      new URLSearchParams($("#form_update_pam2").serialize())
    );
    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam2").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };
  submitformPam3 = (e) => {
    e.preventDefault();
    this.props.doPost(
      "/index.php/Recording/post_process",
      new URLSearchParams($("#form_update_pam3").serialize())
    );

    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam3").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };
  submitformPam4 = (e) => {
    e.preventDefault();
    this.props.doPost(
      "/index.php/Recording/post_process",
      new URLSearchParams($("#form_update_pam4").serialize())
    );

    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam4").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };
  submitformPam5 = (e) => {
    e.preventDefault();
    this.props.doPost(
      "/index.php/Recording/post_process",
      new URLSearchParams($("#form_update_pam5").serialize())
    );
    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Recording/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam5").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Audio Files" subtitle="add new" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <span id="alert_container"></span>
                    <div className="col-md-6 col-sm-12">
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              {" "}
                              My Audio Files
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_recordings"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>File size</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              PAM - Voicemail Drops
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <tbody>
                              <tr>
                                <td width="33%">
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={1}
                                    label={this.state.lbl_pam1}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam1"
                                    onSubmit={this.submitformPam1}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam1"
                                      />
                                      <select
                                        id="pam1"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam1}
                                        onChange={(e) => {
                                          this.setState({
                                            pam1: e.target.value,
                                          });
                                          window
                                            .$("#pam1")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        <option></option>
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={2}
                                    label={this.state.lbl_pam2}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam2"
                                    onSubmit={this.submitformPam2}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam2"
                                      />
                                      <select
                                        id="pam2"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam2}
                                        onChange={(e) => {
                                          this.setState({
                                            pam2: e.target.value,
                                          });
                                          window
                                            .$("#pam2")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        <option></option>
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={3}
                                    label={this.state.lbl_pam3}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam3"
                                    onSubmit={this.submitformPam3}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam3"
                                      />
                                      <select
                                        id="pam3"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam3}
                                        onChange={(e) => {
                                          this.setState({
                                            pam3: e.target.value,
                                          });
                                          window
                                            .$("#pam3")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        <option></option>
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={4}
                                    label={this.state.lbl_pam4}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam4"
                                    onSubmit={this.submitformPam4}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam4"
                                      />
                                      <select
                                        id="pam4"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam4}
                                        onChange={(e) => {
                                          this.setState({
                                            pam4: e.target.value,
                                          });
                                          window
                                            .$("#pam4")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        <option></option>
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={5}
                                    label={this.state.lbl_pam5}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam5"
                                    onSubmit={this.submitformPam5}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam5"
                                      />
                                      <select
                                        id="pam5"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam5}
                                        onChange={(e) => {
                                          this.setState({
                                            pam5: e.target.value,
                                          });
                                          window
                                            .$("#pam5")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        <option></option>
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*View Recording modal start */}
        <div
          id="modal_viewRec"
          className="modal fade"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title" id="modal_viewrec_title">
                  View Recording{" "}
                </h4>
              </div>
              <form className="form-horizontal">
                <div className="modal-body">
                  <div className="form-body text-center">
                    <audio
                      autoPlay
                      controls
                      id="playRec"
                      controlsList="nodownload"
                    >
                      <source id="mdl_viewrec_audio_src" type="audio/wav" />
                    </audio>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    target="_blank"
                    href=""
                    id="mdl_download_rec"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-download"></i>
                  </a>
                  <button
                    onClick={this.stopAudio}
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/**View Recording Modal end */}
      </React.Fragment>
    );
  }
}

export default RecordingList;
