import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Modal, Button } from 'antd'
import $ from 'jquery'
import HeadTitle from '../HeadTitle'
class SystemSettings extends Component {
  state = {
    mdl_early_media_kb_visible: false,
    us_timezones: [],
    timezones: [],
    client_settings: {
      sel_timezone: 'UTC',
      sel_tz_offset: '+00:00',
      //numseats: 0,
      hopper_threshhold: 0,
      //enable_rec: "No",
      sms_provider: 'skyetel',
    },
    agent_settings: {
      allow_email: 'No',
      allow_text: 'No',
      can_add_lead: 'No',
      can_upload_lead: 'No',
      can_view_reports: 'No',
      can_export_leads: 'No',
      can_asign_leads: 'No',
      limit_to_my_leads: 'Yes',
      allow_agent_to_callback_sch: '0',
    },
    manager_settings: {
      mgr_can_exports_leads_reports: 'no',
    },
    advanced_settings: {
      ip_based_access: 'N',
      auto_answer_incoming: 'NO',
      early_media: 'ringing',
    },
    whitelisted_ips: [],
    api_key: '',
    view_api_key: false,
    user_role: 'agent',
    settings: null,
  }
  dt_whitelisted_ips = null

  componentWillMount() {
    fetch(this.props.baseUrl + '/index.php/Ajax/list_timezones', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp)
        this.setState({
          timezones: resp.msg.timezones,
          us_timezones: resp.msg.us_timezones,
        })
        //window.$("#tz_name").trigger("change");
        //fetch all settings
        fetch(
          this.props.baseUrl +
            '/index.php/SystemSettings/ajax_get_sys_settings',
          {
            method: 'GET',
            credentials: 'include',
          }
        )
          .then((resp) => resp.json())
          .then((resp) => {
            let client_setting = {
              //numseats: resp.num_seats,
              hopper_threshhold: resp.hopper_threshhold,
              //enable_rec: resp.enable_recording,
              sel_timezone: resp.tz_name,
              sel_tz_offset: resp.tz_utc_offset,
              sms_provider: resp.sms_provider,
            }
            let agent_setting = {
              allow_email: resp.allow_emails,
              allow_text: resp.allow_texting,
              can_add_lead: resp.allow_lead_add,
              can_upload_lead: resp.allow_lead_upload,
              can_view_reports: resp.allow_report_view,
              can_export_leads: resp.allow_lead_export,
              can_asign_leads: resp.allow_lead_assign,
              limit_to_my_leads: resp.limit_to_assigned_leads,
            }
            let manager_settings = {
              mgr_can_exports_leads_reports: resp.mgr_can_exports_leads_reports,
            }
            let advanced_setting = {
              ip_based_access: resp.ip_based_access,
              auto_answer_incoming: resp.auto_answer_incoming,
              early_media: resp.early_media,
            }
            console.log(advanced_setting)
            this.setState({
              client_settings: client_setting,
              agent_settings: agent_setting,
              manager_settings: manager_settings,
              advanced_settings: advanced_setting,
              api_key: resp.api_key,
            })
            window.$('#tz_name').val(client_setting.sel_timezone)
            //window.$(".bs-select").trigger("change");
          })
      })
  }
  componentDidMount() {
    window.resizeHandle()
    //window.load_select2(".bs-select");
    this.dt_whitelisted_ips = window.dttable(
      'dt_whitelisted_ips',
      this.props.baseUrl + '/index.php/SystemSettings/list_whitelisted_ips',
      '',
      '#dt_whitelisted_ips'
    )

    /****Below is delete control for Hotkeys */

    var blockUi = this.props.blockUI
    var Aurl = this.props.baseUrl
    var dtable_wips = this.dt_whitelisted_ips
    $('#dt_whitelisted_ips').on('click', '.btn-danger', function () {
      /*$(this)
			.closest("tr")
            .remove();*/
      blockUi(true)
      var childKey = $(this).attr('delete_id')
      fetch(Aurl + '/index.php/SystemSettings/Delete_ip/' + childKey, {
        method: 'GET',
        credentials: 'include',
      })
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            dtable_wips.api().ajax.reload()
            window.displayAlert('#alert_container', data.msg, 'success')
          } else {
            window.displayAlert('#alert_container', data.msg, 'danger')
          }
          blockUi(false)
        })
        .catch((e) => {
          blockUi(true)
        })
    })
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    this.setState({
      settings: this.props.settings,
      user_role: this.props.user_role,
    })
  }
  componentDidUpdate() {
    if (
      this.state.settings == null &&
      this.props.settings !== null &&
      this.props.settings !== undefined
    ) {
      this.setState({ settings: this.props.settings })
    } else {
      if (this.props.settings !== null && this.props.settings !== undefined) {
        let st_change_needed = false
        for (var i in this.state.settings) {
          if (this.state.settings[i] !== this.props.settings[i]) {
            st_change_needed = true
            break
          }
        }
        /*if (st_change_needed) {
          this.setState({ settings: this.props.settings }, function () {
            this.manage_download_ability();
          });
        }*/
      }
    }
    /*if (this.state.user_role !== this.props.user_role) {
      this.setState({ user_role: this.props.user_role }, function () {
        this.manage_download_ability();
      });
    }*/
  }
  /*numSeatsChange = val => {
    let num_seats = val.target.value;
    let state = this.state;
    state.client_settings.numseats = num_seats;
    this.setState(state);
  };*/
  HopperThreshHoldChange = (val) => {
    let ht = val.target.value
    let state = this.state
    state.client_settings.hopper_threshhold = ht
    this.setState(state)
  }
  handleclientSettingsSubmit = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SystemSettings/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_clientSettings').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleagentSettingSubmit = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SystemSettings/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_agentSettings').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleAdvancedSettingSubmit = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SystemSettings/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_advSettings').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleWhitelistIp = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SystemSettings/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#frm_WhitelistIp').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
          this.dt_whitelisted_ips.api().ajax.reload()
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleGenAPIKEY = (e) => {
    const apiuuid = this.generateUUID()
    this.setState({ api_key: apiuuid, view_api_key: true })
  }
  toggleApiKeyView = (e) => {
    this.setState({ view_api_key: !this.state.view_api_key })
  }
  generateUUID = () => {
    var d = new Date().getTime()

    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now()
    }

    var uuid = 'xxxxxxxx-xxxx-yxxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )

    return uuid
  }
  showModal = () => {
    this.setState({
      mdl_early_media_kb_visible: true,
    })
  }
  handleOk = () => {
    this.setState({ mdl_early_media_kb_visible: false })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Settings" subtitle="system" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit <?=getUserType()=='superadmin'?'':'hidden'?>">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="icon-puzzle font-black-gallery"></i>
                            <span className="caption-subject bold font-black-gallery uppercase">
                              Client Settings
                            </span>
                            <span className="caption-helper"></span>
                          </div>
                          <div className="tools">
                            <NavLink to="/" className="collapse">
                              {' '}
                            </NavLink>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                onSubmit={this.handleclientSettingsSubmit}
                                id="form_clientSettings"
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_dolphin_config"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    {this.props.role !== 'superadmin' ? (
                                      <React.Fragment>
                                        <tr>
                                          <td>Time Zone</td>
                                          <td>
                                            <select
                                              className="bs-select form-control"
                                              id="tz_name"
                                              name="tz_name"
                                              value={this.state.sel_timezone}
                                              onChange={(e) => {
                                                this.setState({
                                                  sel_timezone: e.target.value,
                                                })
                                              }}
                                            >
                                              {this.state.us_timezones.map(
                                                (timezone) => (
                                                  <option
                                                    value={timezone.text}
                                                    key={timezone.value}
                                                  >
                                                    {timezone.alias +
                                                      ' (' +
                                                      timezone.value +
                                                      ')'}
                                                  </option>
                                                )
                                              )}
                                              {this.state.timezones.map(
                                                (timezone) => (
                                                  <option
                                                    value={timezone.text}
                                                    key={timezone.value}
                                                  >
                                                    {timezone.alias +
                                                      ' (' +
                                                      timezone.value +
                                                      ')'}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </td>
                                        </tr>
                                        {/*<tr>
                                          <td>Number of Seats</td>
                                          <td >
                                            <input
                                              type="text"
                                              name="num_seats"
                                              className="form-control"
                                              value={
                                                this.state.client_settings
                                                  .numseats || ""
                                              }
                                              onChange={this.numSeatsChange}
                                            />
                                          </td>
                                        </tr>*/}
                                        <tr>
                                          <td>API KEY</td>
                                          <td>
                                            <div className="input-group">
                                              <div className="input-icon">
                                                <input
                                                  type={
                                                    this.state.view_api_key
                                                      ? 'text'
                                                      : 'password'
                                                  }
                                                  id="api_key"
                                                  className="form-control"
                                                  name="api_key"
                                                  style={{
                                                    paddingLeft: '10px',
                                                  }}
                                                  value={this.state.api_key}
                                                  readOnly
                                                />
                                              </div>
                                              <span className="input-group-btn">
                                                <button
                                                  id="genpassword"
                                                  className="btn green-jungle"
                                                  type="button"
                                                  onClick={
                                                    this.toggleApiKeyView
                                                  }
                                                >
                                                  <i className="fa fa-eye fa-fw"></i>
                                                </button>
                                                <button
                                                  onClick={this.handleGenAPIKEY}
                                                  className="btn green-jungle"
                                                  type="button"
                                                >
                                                  <i className="fa fa-arrow-left fa-fw"></i>{' '}
                                                  Generate
                                                </button>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>SMS Provider</td>
                                          <td>
                                            <select
                                              className="form-control"
                                              id="csms_provider"
                                              name="sms_provider"
                                              value={
                                                this.state.client_settings
                                                  .sms_provider
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  client_setting: {
                                                    ...this.state
                                                      .client_settings,
                                                    sms_provider:
                                                      e.target.value,
                                                  },
                                                })
                                              }}
                                            >
                                              {[
                                                {
                                                  key: 'skyetel',
                                                  value: 'Skyetel',
                                                },
                                                {
                                                  key: 'flowroute',
                                                  value: 'Flowroute',
                                                },
                                              ].map((opt) => (
                                                <option value={opt.key}>
                                                  {opt.value}
                                                </option>
                                              ))}
                                            </select>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ) : (
                                      ''
                                    )}

                                    {/*<tr>
                                      <td>Enable Recording</td>
                                      <td >
                                        <select
                                          className="bs-select form-control"
                                          name="enable_recording"
                                          value={
                                            this.state.client_settings
                                              .enable_rec
                                          }
                                          onChange={e => {
                                            let stat = this.state;
                                            stat.client_settings.enable_rec =
                                              e.target.value;
                                            this.setState(stat);
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/***************************** */}
                      <form
                        id="form_agentSettings"
                        onSubmit={this.handleagentSettingSubmit}
                      >
                        {this.state.user_role == 'owner' ||
                        this.state.user_role == 'superadmin' ? (
                          <div className="portlet light portlet-fit ">
                            <div className="portlet-title">
                              <div className="caption">
                                <i className="icon-puzzle font-black-gallery"></i>
                                <span className="caption-subject bold font-black-gallery uppercase">
                                  Manager Settings
                                </span>
                                <span className="caption-helper"></span>
                              </div>
                              <div className="tools">
                                <NavLink to="/" className="collapse">
                                  {' '}
                                </NavLink>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <input
                                    type="hidden"
                                    name="action"
                                    value="update_system_settings"
                                  />
                                  <table className="table table-striped table-bordered table-advance table-hover">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Allow managers to export reports and
                                          leads
                                        </td>
                                        <td>
                                          <select
                                            className="bs-select form-control"
                                            name="mgr_can_exports_leads_reports"
                                            value={
                                              this.state.manager_settings
                                                .mgr_can_exports_leads_reports
                                            }
                                            onChange={(ev) => {
                                              let state = this.state
                                              state.manager_settings.mgr_can_exports_leads_reports =
                                                ev.target.value
                                              this.setState(state)
                                            }}
                                          >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-center" colspan="2">
                                          <button
                                            type="submit"
                                            className="btn green-jungle"
                                          >
                                            Submit
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/***************************** */}
                        <div className="portlet light portlet-fit ">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="icon-puzzle font-black-gallery"></i>
                              <span className="caption-subject bold font-black-gallery uppercase">
                                Agent Settings
                              </span>
                              <span className="caption-helper"></span>
                            </div>
                            <div className="tools">
                              <NavLink to="/" className="collapse">
                                {' '}
                              </NavLink>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_system_settings"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Allow Email</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_emails"
                                          value={
                                            this.state.agent_settings
                                              .allow_email
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.allow_email =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Texting</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_texting"
                                          value={
                                            this.state.agent_settings.allow_text
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.allow_text =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Agents to add a lead</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_lead_add"
                                          value={
                                            this.state.agent_settings
                                              .can_add_lead
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.can_add_lead =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Agents to upload Leads</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_lead_upload"
                                          value={
                                            this.state.agent_settings
                                              .can_upload_lead
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.can_upload_lead =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Agents to view reports</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_report_view"
                                          value={
                                            this.state.agent_settings
                                              .can_view_reports
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.can_view_reports =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Agents to Export Leads</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_lead_export"
                                          value={
                                            this.state.agent_settings
                                              .can_export_leads
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.can_export_leads =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Agents to assign Leads</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_lead_assign"
                                          value={
                                            this.state.agent_settings
                                              .can_asign_leads
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.can_asign_leads =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Agents can only view{' '}
                                        {/*Leads assigned to
                                        them*/}
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="limit_to_assigned_leads"
                                          value={
                                            this.state.agent_settings
                                              .limit_to_my_leads
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.limit_to_my_leads =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option value="No">
                                            All Leads (default)
                                          </option>
                                          <option value="X">
                                            Leads Assigned to Them and
                                            Unassigned
                                          </option>
                                          <option value="Yes">
                                            Only Leads Assigned to Them
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    {/**Can agent schedule callback from lead info page? */}
                                    <tr>
                                      <td>
                                        Allow Agent to Manually Schedule
                                        Callback
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="allow_agent_to_callback_sch"
                                          value={
                                            this.state.agent_settings
                                              .allow_agent_to_callback_sch
                                          }
                                          onChange={(ev) => {
                                            let state = this.state
                                            state.agent_settings.allow_agent_to_callback_sch =
                                              ev.target.value
                                            this.setState(state)
                                          }}
                                        >
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    {/*************************************************** */}
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="icon-puzzle font-black-gallery"></i>
                            <span className="caption-subject bold font-black-gallery uppercase">
                              Advanced Settings
                            </span>
                            <span className="caption-helper"></span>
                          </div>
                          <div className="tools">
                            <NavLink to="/" className="expand">
                              {' '}
                            </NavLink>
                          </div>
                        </div>
                        <div
                          className="portlet-body"
                          style={{ display: 'none' }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                id="form_advSettings"
                                onSubmit={this.handleAdvancedSettingSubmit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_adv_sys_settings"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Auto Answer Incoming</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="auto_answer_incoming"
                                          value={
                                            this.state.advanced_settings
                                              .auto_answer_incoming
                                          }
                                          onChange={(ev) => {
                                            let stat = this.state
                                            stat.advanced_settings.auto_answer_incoming =
                                              ev.target.value
                                            this.setState(stat)
                                          }}
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Early Media{' '}
                                        <a
                                          href="javascript:;"
                                          onClick={this.showModal}
                                        >
                                          <i class="fa fa-question"></i>
                                        </a>
                                        <Modal
                                          visible={
                                            this.state
                                              .mdl_early_media_kb_visible
                                          }
                                          title="What is Early Media"
                                          onOk={this.handleOk}
                                          onCancel={this.handleOk}
                                          footer={[
                                            <Button
                                              key="submit"
                                              type="primary"
                                              onClick={this.handleOk}
                                              style={{ marginRight: '5px' }}
                                            >
                                              Ok
                                            </Button>,
                                            <Button
                                              key="link"
                                              href="https://freeswitch.org/confluence/display/FREESWITCH/Early+Media"
                                              type="link"
                                              target="_blank"
                                              onClick={this.handleOk}
                                            >
                                              Learn More
                                            </Button>,
                                          ]}
                                        >
                                          <p>
                                            Early media is the exchange of
                                            information before establishment of
                                            a connection
                                            <br /> The best way to illustrate
                                            that is by looking at a traditional
                                            telephone call where party A calls
                                            party B:{' '}
                                          </p>
                                          <p>
                                            Party A picks up her phone, hears
                                            dial-tone, and enters a phone number
                                            <br />
                                            After a few moments, she hears
                                            ringing. (This is "early" media
                                            because the call hasn't been
                                            answered yet) Meanwhile party B's
                                            phone starts to ring After a few
                                            rings, party B picks up, and a call
                                            is established. Party A and party B
                                            can now hear each other speak.
                                            Another good example is what happens
                                            on a busy signal. Using the same
                                            parties from the previous example:
                                          </p>
                                          <p>
                                            Party A picks up her phone, hears
                                            dial-tone, and enters a phone number
                                            <br />
                                            After a few moments, she hears a
                                            busy signal. (This is "early" media
                                            - no call has been established)
                                            <br />
                                            Party A hangs up
                                            <br /> The busy signal is an audible
                                            signal - a form of audio media if
                                            you will - that lets the calling
                                            party know that the call has not
                                            gone through. It is an unconnected
                                            call, but it still had sound. In a
                                            case of per-call billing, this call
                                            would not be billed (usually)
                                            because it was never connected. The
                                            same holds true for calls that are
                                            ring/no answer. It even holds true
                                            for calls to disconnected numbers
                                            where you hear the Special
                                            Information Tones (SIT) and a
                                            recorded message.
                                          </p>
                                        </Modal>
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="early_media"
                                          value={
                                            this.state.advanced_settings
                                              .early_media
                                          }
                                          onChange={(ev) => {
                                            let stat = this.state
                                            stat.advanced_settings.early_media =
                                              ev.target.value
                                            this.setState(stat)
                                          }}
                                        >
                                          <option value="early_media">
                                            Early Media
                                          </option>
                                          <option value="ringing">
                                            Ringing
                                          </option>
                                          <option value="disabled">
                                            Disabled
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>IP Based Access</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="ip_based_access"
                                          value={
                                            this.state.advanced_settings
                                              .ip_based_access
                                          }
                                          onChange={(ev) => {
                                            let stat = this.state
                                            stat.advanced_settings.ip_based_access =
                                              ev.target.value
                                            this.setState(stat)
                                          }}
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                              <span id="whitelist_ip">
                                <form
                                  id="frm_WhitelistIp"
                                  onSubmit={this.handleWhitelistIp}
                                >
                                  <input
                                    type="hidden"
                                    name="action"
                                    value="whitelist_ip"
                                  />
                                  <table className="table table-striped table-bordered table-advance table-hover">
                                    <tbody>
                                      <tr>
                                        <td>
                                          IP Address{' '}
                                          <small>
                                            <NavLink
                                              data-target="#mdl_view_whiteips"
                                              data-toggle="modal"
                                              to="/"
                                            >
                                              View Whitelisted IPs
                                            </NavLink>
                                          </small>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            name="ip_address"
                                            className="form-control"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-center" colspan="2">
                                          <button
                                            type="submit"
                                            className="btn green-jungle"
                                          >
                                            Whitelist IP
                                          </button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-center" colspan="2">
                                          <a href="#/admin/dispoworkflows/view">
                                            View Workflows
                                          </a>
                                          {/*<a href="#/admin/dispoworkflows/add">
                                            Add Workflow
                                          </a>*/}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </form>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="mdl_view_whiteips"
          tabIndex="-1"
          arole="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Whitelisted IPs</h4>
              </div>
              <div className="modal-body">
                <div className="portlet-body">
                  <table
                    id="dt_whitelisted_ips"
                    className="table table-striped table-bordered table-advance table-hover"
                  >
                    <thead>
                      <tr>
                        <th>IP Address</th>
                        <th>Date</th>
                        <th>By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SystemSettings
