import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../HeadTitle";
class CompanyAdd extends Component {
  state = {};
  SubmitCompanyForm = (form) => {
    form.preventDefault();
    this.props.doPost(
      "/index.php/Company/post_process",
      new URLSearchParams($(".companyAddForm").serialize())
    );
    /*window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Company/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".companyAddForm").serialize())
    })
      .then(blob => {
        return blob.json();
      })
      .then(data => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        }
        window.App.unblockUI();
      })
      .catch(e => {
        window.App.unblockUI();
      });*/
  };
  componentDidMount() {
    window.load_select2(".bs-select");
    window.resizeHandle();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Add New Company"/>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <span id="alert_container"></span>
                            <div className=" col-md-12">
                              <form
                                className="companyAddForm"
                                onSubmit={this.SubmitCompanyForm}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_company"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Company ID</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_id"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Company Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_name"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Company Comments</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_description"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          name="active"
                                          className="form-control bs-select"
                                        >
                                          <option value="Y">Active</option>
                                          <option value="N">Inactive</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyAdd;
