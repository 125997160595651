import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../HeadTitle";
class MyCallbacks extends Component {
  state = { callbacks: [], lead_id: 0 };
  dt_callbacks = null;
  componentDidMount() {
    window.resizeHandle();
    const lead_id = this.props.match.params.lead_id;
    if (lead_id !== undefined && lead_id !== null) {
      this.setState({ lead_id: lead_id }, function () {
        //load callbacks list
        this.dt_callbacks = window.dttable(
          "db_leadevents",
          this.props.baseUrl +
            "/index.php/LeadEvents/dt_LeadsCallbacks/" +
            this.state.lead_id,
          [
            {
              orderable: false,
              targets: [1, 4, 7, 8],
            },
            {
              visible: false,
              targets: [0],
            },
          ],
          "body",
          [0, "asc"]
        );
        ////////////////////////
        /****remove callback handler */

        var Aurl = this.props.baseUrl;
        $("#db_leadevents").on("click", ".btn-deleter", function () {
          $(this).closest("tr").remove();
          var childKey = $(this).attr("delete_id");
          window.App.blockUI();
          fetch(Aurl + "/index.php/LeadEvents/post_process/" + childKey, {
            method: "POST",
            credentials: "include",
            body: new URLSearchParams("action=remove_callback&id=" + childKey),
          })
            .then((blob) => {
              return blob.json();
            })
            .then((data) => {
              console.log(data);
              if (data.status) {
                window.displayAlert("#alert_container", data.msg, "success");
              } else {
                window.displayAlert("#alert_container", data.msg, "danger");
              }
              window.App.unblockUI();
            })
            .catch((e) => {
              window.App.unblockUI();
            });
        });
        //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
        /******************************** */
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Callbacks" subtitle={"Lead "+this.state.lead_id} />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="db_leadevents"
                          >
                            <thead>
                              <tr>
                                <th>Call Back Date(db format) </th>
                                <th>Call Back Date</th>
                                <th>List ID</th>
                                <th>Full Name</th>
                                <th>Call Now</th>
                                <th>Lead Status</th>
                                <th>Owner</th>
                                <th>Date Modified</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyCallbacks;
