import React, { Component } from "react";
class LeadStatusDropDown extends Component {
  state = {
    lead_statuses: [],
    container: "body",
    class: "form-control",
    id: "",
    name: "lead_status",
    inmodal: false,
    disabled: false,
  };
  componentDidMount() {
    if (this.props.id !== undefined) {
      this.setState({ id: this.props.id });
    }
    if (this.props.container !== undefined) {
      this.setState({ container: this.props.container });
    }
    if (this.props.class !== undefined) {
      this.setState({ class: this.props.class });
    }
    if (this.props.name !== undefined) {
      this.setState({ name: this.props.name });
    }
    if (this.props.inmodal !== undefined) {
      this.setState({ inmodal: this.props.inmodal });
    }
    if (this.props.disabled !== undefined) {
      this.setState({ disabled: this.props.disabled });
    }
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Ajax/getLeadStatuses", {
      credentials: "include",
      method: "get",
    })
      .then((data) => {
        //console.log(data);
        return data.json();
      })
      .then((data) => {
        //console.log(data);
        var lead_statuses = [];
        for (var i = 0; i < data.length; i++) {
          lead_statuses.push(data[i]);
        }
        // console.log(lead_statuses);
        // console.log("iii");
        this.setState({ lead_statuses });
        /*if (this.state.inmodal == true) {
          window.load_select2_in_modal("#" + this.state.id);
        } else {
          window.load_select2("#" + this.state.id);
        }*/
      })
      .catch((err) => {});
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.state.id && nextProps.id !== undefined) {
      this.setState({ id: nextProps.id });
    }
    if (
      nextProps.container !== this.state.container &&
      nextProps.container !== undefined
    ) {
      this.setState({ container: nextProps.container });
    }
    if (nextProps.class !== this.state.class && nextProps.class !== undefined) {
      this.setState({ class: nextProps.class });
    }
    if (nextProps.name !== this.state.name && nextProps.name !== undefined) {
      this.setState({ name: nextProps.name });
    }
    if (
      nextProps.inmodal !== this.state.inmodal &&
      nextProps.inmodal !== undefined
    ) {
      this.setState({ inmodal: nextProps.inmodal });
    }
    if (nextProps.disabled !== undefined && nextProps.disabled !== undefined) {
      this.setState({ disabled: nextProps.disabled });
    }
  }
  render() {
    let dd_lead_status = this.state.lead_statuses.map(function (item, i) {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
    return (
      <select
        data-container={this.state.container}
        name={this.state.name}
        id={this.state.id}
        className={this.state.class}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.state.disabled}
      >
        {dd_lead_status}
      </select>
    );
  }
}

export default LeadStatusDropDown;
