import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../HeadTitle";
class CompanyEdit extends Component {
  state = {
    company_id: "",
    client_id: "",
    company_name: "",
    campaign_id: null,
    active: "N",
    company_description: "",
    company_changedate: null,
    list_lastcalldate: null,
    reset_time: "",
    agent_script_override: "",
    campaign_cid_override: "",
    am_message_exten_override: "",
    drop_inbound_group_override: "",
    xferconf_a_number: "",
    xferconf_b_number: "",
    xferconf_c_number: "",
    xferconf_d_number: "",
    xferconf_e_number: "",
    web_form_address: null,
    web_form_address_two: null,
    time_zone_setting: "",
    inventory_report: "",
    expiration_date: "",
    na_call_url: null,
    local_call_time: "",
    web_form_address_three: null,
    status_group_id: "",
    user_new_lead_limit: "-1",
  };
  componentDidMount() {
    var cid = this.props.match.params.company_id;

    //fetch and fill with info
    fetch(this.props.baseUrl + "/index.php/Company/Edit/" + cid, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);

        this.setState({
          company_id: resp.msg.company_id,
          company_name: resp.msg.company_name,
          active: resp.msg.active,
          company_description: resp.msg.company_description,
        });
        window.$(".bs-select").trigger("change");
      });
    ///////////////////////////////
  }
  SubmitCompanyForm = (form) => {
    form.preventDefault();
    this.props.doPost(
      "/index.php/Company/post_process",
      new URLSearchParams($(".companyEditForm").serialize())
    );
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Company
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Edit</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                className="companyEditForm"
                                onSubmit={this.SubmitCompanyForm}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="edit_company"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Company ID</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_id"
                                          value={this.state.company_id}
                                          readOnly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Company Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_name"
                                          value={this.state.company_name}
                                          onChange={(e) => {
                                            this.setState({
                                              company_name: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Company Comments</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_description"
                                          value={this.state.company_description}
                                          onChange={(e) => {
                                            this.setState({
                                              company_description:
                                                e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          name="active"
                                          className="form-control bs-select"
                                          value={this.state.active}
                                          onChange={(e) => {
                                            this.setState({
                                              active: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value="Y">Active</option>
                                          <option value="N">Inactive</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyEdit;
