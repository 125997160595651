import React, { Component } from 'react'
import { Modal } from 'antd'
import $ from 'jquery'
import HeadTitle from './HeadTitle'
import UserCIDWidget from './Bricks/UsersCIDWidget'
class MyProfile extends Component {
  state = {
    name: '',
    caller_id: '',
    email: '',
    address: '',
    picture: '',
    role: '',
    imageversion: '',
  }
  ge_avatars = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  componentDidMount() {
    window.resizeHandle()
    //fetch and fill with info
    window.App.blockUI()
    this.setState({ imageversion: this.props.imageversion })
    fetch(this.props.baseUrl + '/index.php/User/Profile', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          this.setState({
            name: resp.msg.user_me.full_name,
            caller_id: resp.msg.user_me.caller_id,
            email: resp.msg.user_me.email,
            address: resp.msg.user_me.address,
            picture: resp.msg.user_me.profile_pic,
            role: resp.msg.user_me.role,
          })
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
    ///////////////////////////////
    window.$('[data-toggle=confirmation]').confirmation({
      container: 'body',
      btnOkClass: 'btn btn-sm btn-success',
      btnCancelClass: 'btn btn-sm btn-danger',
    })
  }
  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.setState({
      imageversion: nextProps.imageversion,
    })
  }
  updateUserInfo = (form) => {
    form.preventDefault()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_editUser').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleSubmitUpdateAvi = (e) => {
    e.preventDefault()
    window.App.blockUI()
    const formData = new FormData()
    //avi_action current_avi users_avi
    //for(const name in data) {
    formData.append('action', $('#avi_action').val())
    formData.append('users_avi', document.getElementById('users_avi').files[0])
    formData.append('current_avi', this.state.picture)
    //}
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: formData, //new URLSearchParams($("#form_addnewrec").serialize())
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.props.onupdateAvi()
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleUpdatePassword = (e) => {
    e.preventDefault()
    const mp_new_password = window.$('#mp_new_password').val()
    window.webphone.wp_unregister()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_updatePass').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          //lets update the sip password ^_^ and re-register
          if (window.wss_pass !== mp_new_password) {
            Modal.destroyAll()
            Modal.error({
              title: 'Re-Login required',
              content:
                'Your password has been changed. Please Login again with new Password',
              onOk: () => {
                this.props.handleLogout()
              },
            })
          }
          /*window.wss_pass = mp_new_password;
          //window.webphone.wp_unregister();
          //window.webphone.wp_register();
          //
          window.displayAlert("#alert_container", data.msg, "success");
          setTimeout(() => {
            window.webphone.wp_register();
          }, 2500);*/
        } else {
          /*Modal.error({
            title: "Failed",
            content: "Failed to Update Password",
          });*/
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  changeAvatar = (e, av_num) => {
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/update_av_generic/' + av_num, {
      credentials: 'include',
      method: 'get',
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.props.onupdateAvi()
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="User" subtitle="profile" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="page-content">
                    <div className="container">
                      <div className="page-content-inner">
                        <div className="row">
                          <div className="col-md-12">
                            <span id="alert_container"></span>
                            <div className="profile-sidebar">
                              <div className="portlet light profile-sidebar-portlet ">
                                <div className="profile-userpic">
                                  <img
                                    src={
                                      this.props.baseUrl +
                                      '/index.php/User/get_users_avatar?' +
                                      this.state.imageversion
                                    }
                                    className="img-responsive"
                                    alt=""
                                  />{' '}
                                </div>

                                <div
                                  className="profile-usertitle"
                                  style={{ marginTop: '10px' }}
                                >
                                  <div className="profile-usertitle-name">
                                    {' '}
                                    {this.state.name}{' '}
                                  </div>
                                  <div className="profile-usertitle-job">
                                    {' '}
                                    {this.state.role}{' '}
                                  </div>
                                </div>

                                <div className="profile-usermenu">
                                  <ul className="nav"></ul>
                                </div>
                              </div>
                            </div>

                            <div className="profile-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet light ">
                                    <div className="portlet-title tabbable-line">
                                      <div className="caption caption-md">
                                        <i className="icon-globe theme-font hide"></i>
                                        <span className="caption-subject font-blue-madison bold uppercase">
                                          Profile Account
                                        </span>
                                      </div>
                                      <ul className="nav nav-tabs">
                                        <li className="active">
                                          <a href="#tab_1_1" data-toggle="tab">
                                            Personal Info
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#tab_1_2" data-toggle="tab">
                                            Caller ids
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#tab_1_3" data-toggle="tab">
                                            Change Avatar
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#tab_1_4" data-toggle="tab">
                                            Change Password
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="portlet-body">
                                      <div className="tab-content">
                                        <div
                                          className="tab-pane active"
                                          id="tab_1_1"
                                        >
                                          <form
                                            id="form_editUser"
                                            onSubmit={this.updateUserInfo}
                                          >
                                            <input
                                              type="hidden"
                                              name="action"
                                              value="update_user_profile"
                                            />
                                            <div className="form-group">
                                              <label className="control-label">
                                                Full Name
                                              </label>
                                              <input
                                                type="text"
                                                name="full_name"
                                                value={this.state.name}
                                                onChange={(e) => {
                                                  this.setState({
                                                    name: e.target.value,
                                                  })
                                                }}
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Email Address
                                              </label>
                                              <input
                                                type="text"
                                                name="email"
                                                value={this.state.email}
                                                onChange={(e) => {
                                                  this.setState({
                                                    email: e.target.value,
                                                  })
                                                }}
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Caller ID
                                              </label>
                                              <input
                                                type="text"
                                                name="caller_id"
                                                value={this.state.caller_id}
                                                onChange={(e) => {
                                                  this.setState({
                                                    caller_id: e.target.value,
                                                  })
                                                }}
                                                className="form-control"
                                                minLength="10"
                                                maxLength="10"
                                              />
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Address
                                              </label>
                                              <textarea
                                                className="form-control"
                                                rows="3"
                                                name="address"
                                                value={this.state.address}
                                                onChange={(e) => {
                                                  this.setState({
                                                    address: e.target.value,
                                                  })
                                                }}
                                              ></textarea>
                                            </div>
                                            <div className="margiv-top-10">
                                              <button
                                                type="submit"
                                                className="btn green-jungle"
                                              >
                                                {' '}
                                                Save Changes{' '}
                                              </button>
                                            </div>
                                          </form>
                                        </div>

                                        <div className="tab-pane" id="tab_1_2">
                                          <div class="row">
                                            <div
                                              class="col-md-12"
                                              style={{
                                                textAlign: 'center',
                                                backgroundColor: '#ececec',
                                                paddingTop: '10px',
                                              }}
                                            >
                                              <UserCIDWidget
                                                blockUI={this.props.blockUI}
                                                user_id={''}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="tab-pane" id="tab_1_3">
                                          <div class="row">
                                            <div
                                              class="col-md-12"
                                              style={{
                                                textAlign: 'center',
                                                backgroundColor: '#ececec',
                                                paddingTop: '10px',
                                              }}
                                            >
                                              <form
                                                id="form_updateAvi"
                                                onSubmit={
                                                  this.handleSubmitUpdateAvi
                                                }
                                              >
                                                <input
                                                  type="hidden"
                                                  id="avi_action"
                                                  name="action"
                                                  value="update_user_avatar"
                                                />
                                                <input
                                                  type="hidden"
                                                  id="current_avi"
                                                  name="current_avi"
                                                  value={this.state.picture}
                                                />
                                                <div
                                                  className="form-group"
                                                  style={{
                                                    marginBottom: '5px',
                                                  }}
                                                >
                                                  <div
                                                    className="fileinput fileinput-new"
                                                    data-provides="fileinput"
                                                  >
                                                    <div
                                                      className="fileinput-new thumbnail"
                                                      style={{
                                                        width: '200px',
                                                        height: '150px',
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          this.props.baseUrl +
                                                          '/index.php/User/get_users_avatar'
                                                        }
                                                        alt="Image Not Loaded"
                                                      />{' '}
                                                    </div>
                                                    <div
                                                      className="fileinput-preview fileinput-exists thumbnail"
                                                      style={{
                                                        maxWidth: '200px',
                                                        maxHeight: '150px',
                                                      }}
                                                    >
                                                      {' '}
                                                    </div>
                                                    <div>
                                                      <span className="btn default btn-file">
                                                        <span className="fileinput-new">
                                                          {' '}
                                                          Browse{' '}
                                                        </span>
                                                        <span className="fileinput-exists">
                                                          {' '}
                                                          Change{' '}
                                                        </span>
                                                        <input
                                                          type="file"
                                                          name="users_avi"
                                                          id="users_avi"
                                                        />{' '}
                                                      </span>
                                                      <button
                                                        style={{
                                                          marginLeft: '5px',
                                                        }}
                                                        type="submit"
                                                        className="btn green-jungle"
                                                      >
                                                        {' '}
                                                        Save{' '}
                                                      </button>
                                                      <a
                                                        href="javascript:;"
                                                        className="btn default fileinput-exists"
                                                        data-dismiss="fileinput"
                                                      >
                                                        {' '}
                                                        Remove{' '}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/*<div className="margin-top-10">
                                                  <button
                                                    type="submit"
                                                    className="btn green-jungle"
                                                  >
                                                    {" "}
                                                    Submit{" "}
                                                  </button>
                                                    </div>*/}
                                              </form>
                                            </div>
                                            {/*We list existing avatars here ;) */}
                                            <div class="col-md-12">
                                              <h3
                                                style={{ textAlign: 'center' }}
                                              >
                                                Choose An Existing Avatar
                                              </h3>
                                              {this.ge_avatars.map((av) => (
                                                <div class="col-md-4">
                                                  <img
                                                    data-toggle="confirmation"
                                                    data-placement="right"
                                                    src={
                                                      this.props.baseUrl +
                                                      '/index.php/Ajax/get_generic_avatar/' +
                                                      av
                                                    }
                                                    class="img-ga"
                                                    onClick={(e) => {
                                                      this.changeAvatar(e, av)
                                                    }}
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                            {/** */}
                                          </div>
                                        </div>

                                        <div className="tab-pane" id="tab_1_4">
                                          <form
                                            id="form_updatePass"
                                            onSubmit={this.handleUpdatePassword}
                                          >
                                            <input
                                              type="hidden"
                                              name="action"
                                              value="update_user_pass"
                                            />
                                            <div className="form-group">
                                              <label className="control-label">
                                                Current Password
                                              </label>
                                              <input
                                                type="password"
                                                name="cpass"
                                                className="form-control"
                                              />{' '}
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                New Password
                                              </label>
                                              <input
                                                type="password"
                                                name="npass"
                                                className="form-control"
                                              />{' '}
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Re-type New Password
                                              </label>
                                              <input
                                                id="mp_new_password"
                                                type="password"
                                                name="rnpass"
                                                className="form-control"
                                              />{' '}
                                            </div>
                                            <div className="margin-top-10">
                                              <button
                                                type="submit"
                                                className="btn green-jungle"
                                              >
                                                {' '}
                                                Submit{' '}
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MyProfile
