import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
const agent_fields = [
  { key: "sf_agent_fullname", val: "Agent's Full Name" },
  { key: "sf_agent_email", val: "Agent's Email" },
];
class TemplateEdit extends Component {
  state = {
    template_id: "",
    template_name: "",
    email_subject: "",
    email_body: "",
  };
  componentDidMount() {
    window.resizeHandle();
    window.load_select2(".bs-select");
    fetch(
      this.props.baseUrl +
        "/index.php/Email/Edit/" +
        this.props.match.params.template_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          template_id: resp.msg.template_id,
          template_name: resp.msg.template_name,
          email_subject: resp.msg.email_subject,
          email_body: resp.msg.email_body,
        });
        window.$(".bs-select").trigger("change");
        window
          .$("#summernote_1")
          .summernote("code", $("<div/>").html(resp.msg.email_body).text());
      });
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/emailtemplateadd.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    fetch(this.props.baseUrl + "/index.php/Script/list_lead_fields", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.text())
      .then((resp) => {
        $("#field_name").html(resp);
        agent_fields.forEach((element) => {
          $("#field_name").append(
            '<option value="{{' +
              element.key +
              '}}">' +
              element.val +
              "</option>"
          );
        });
      });
  }
  handleSubmitAdd = (form) => {
    form.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Email/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_add_emailtemplate").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Email Template" subtitle="add new" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_add_emailtemplate"
                                onSubmit={this.handleSubmitAdd}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_email_template"
                                />
                                <input
                                  type="hidden"
                                  name="template_id"
                                  value={this.state.template_id}
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Title</td>
                                      <td>
                                        <input
                                          name="template_name"
                                          type="text"
                                          className="form-control"
                                          placeholder="Type Title"
                                          required
                                          value={this.state.template_name}
                                          onChange={(e) => {
                                            this.setState({
                                              template_name: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Email Subject</td>
                                      <td>
                                        <input
                                          name="email_subject"
                                          type="text"
                                          className="form-control"
                                          placeholder="Type Email Subject"
                                          required
                                          value={this.state.email_subject}
                                          onChange={(e) => {
                                            this.setState({
                                              email_subject: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Insert Variable</td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <select
                                              id="field_name"
                                              className="bs-select form-control"
                                              style={{ paddingLeft: "7px" }}
                                            >
                                              <option value="{{}}"></option>
                                            </select>
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="AppendFieldName"
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{" "}
                                              append
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Email Body</td>
                                      <td>
                                        <textarea
                                          name="email_body"
                                          id="summernote_1"
                                          tabindex="1"
                                        ></textarea>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateEdit;
