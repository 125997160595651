import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../HeadTitle";
class CompanyList extends Component {
  state = {};
  componentDidMount() {
    window.dttable(
      "dt_companies",
      this.props.baseUrl + "/index.php/Company/ajax_company_list"
    );
    window.resizeHandle();

    /****Below is delete control */
    var blockUI = this.props.blockUI;
    var Aurl = this.props.baseUrl;
    $("#dt_companies").on("click", ".btn-danger", function() {
      $(this)
        .closest("tr")
        .remove();
      var childKey = $(this).attr("delete_id");
      blockUI(true);
      fetch(Aurl + "/index.php/Company/remove/" + childKey, {
        method: "GET",
        credentials: "include"
      })
        .then(blob => {
          return blob.json();
        })
        .then(data => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUI(false);
        })
        .catch(e => {
          blockUI(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Company
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;View</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="portlet light portlet-fit ">
                      <div
                        className="portlet-title"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="caption">
                          <i className="fa fa-leaf"></i>
                          <span className="caption-subject font-dark sbold uppercase">
                            Company
                          </span>
                        </div>
                      </div>
                      <div className="portlet-body">
                        <table
                          className="table table-striped table-bordered table-advance table-hover"
                          id="dt_companies"
                        >
                          <thead>
                            <tr>
                              <th>Company ID</th>
                              <th>Company Name</th>
                              <th>Company Description</th>
                              <th>modify</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyList;
