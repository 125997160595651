import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../HeadTitle";
class SystemFields_edit extends Component {
  state = { name: "", type: "", options: "" };
  sf_id = null;

  componentDidMount() {
    window.resizeHandle();
    this.sf_id = this.props.match.params.sf_edit_id;
    //console.warn(this.cf_id);

    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/fieldsmanager_edit.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////

    fetch(this.props.baseUrl + "/index.php/Manager/EditSys/" + this.sf_id, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.warn(resp.msg.options);
        this.setState({
          name: resp.msg.display_name,
          type: resp.msg.type,
          options: resp.msg.options,
        });
        console.warn(this.state.options);
      });
  }
  handle_systemfields_edit = (f) => {
    f.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Manager/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_edit_systemfields").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <div className="page-container">
        {/*<!-- BEGIN CONTENT -->*/}
        <div className="page-content-wrapper">
          {/*<!-- BEGIN CONTENT BODY -->*/}
          {/*<!-- BEGIN PAGE HEAD-->*/}
          <HeadTitle title="Fields Manager" subtitle="edit" />
          {/*<!-- END PAGE HEAD-->*/}
          {/*<!-- BEGIN PAGE CONTENT BODY -->*/}
          <div className="page-content">
            <div className="container">
              {/*<!-- BEGIN PAGE CONTENT INNER -->*/}
              <div className="page-content-inner">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <span id="alert_container"></span>
                    <div className="portlet light portlet-fit ">
                      <div className="portlet-body">
                        <div className="row">
                          <div className=" col-md-12">
                            <form
                              id="form_edit_systemfields"
                              onSubmit={this.handle_systemfields_edit}
                            >
                              <input
                                type="hidden"
                                name="action"
                                value="update_manager_sysfields"
                              />
                              <input
                                type="hidden"
                                name="id"
                                value={this.sf_id}
                              />
                              <table className="table table-striped table-bordered table-advance table-hover">
                                <tbody>
                                  <tr>
                                    <td>Display Name</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="display_name"
                                        value={this.state.name}
                                        onChange={(e) => {
                                          this.setState({
                                            name: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Type</td>
                                    <td>
                                      <select
                                        className="bs-select form-control"
                                        name="type"
                                        id="type"
                                        value={this.state.type}
                                        onChange={(e) => {
                                          this.setState({
                                            type: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="TEXT">Text field</option>
                                        <option value="AREA">Text Box</option>
                                        <option value="SELECT">
                                          Drop Down
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr id="row_options">
                                    <td>options</td>
                                    <td>
                                      <textarea
                                        className="form-control"
                                        type="text"
                                        name="options"
                                        onChange={(e) => {
                                          this.setState({
                                            options: e.target.value,
                                          });
                                        }}
                                        value={this.state.options}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="2"
                                      className="hidden-xs text-center"
                                    >
                                      <button
                                        type="submit"
                                        className="btn green-jungle"
                                      >
                                        Update
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SystemFields_edit;
