import React, { Component } from "react";
import HeadTitle from "../HeadTitle";
class ReportAdminLog extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_adminLog",
      this.props.baseUrl + "/index.php/Log/Ajax_AdminLog",
      "",
      "body",
      [0, "desc"]
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Admin Log
                    <small></small>
                  </h1>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_adminLog"
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>IP</th>
                                <th>Section</th>
                                <th>Event</th>
                                <th>Query</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportAdminLog;
