import React, { Component } from "react";
import { Select } from "antd";

const { Option } = Select;

class Campaignsdd extends Component {
  state = { value: "", options: [], baseUrl: "" };
  componentDidMount() {
    this.setState(
      { value: this.props.value, baseUrl: this.props.baseUrl },
      function () {
        console.log(this.props.value);
        this.fillDD();
      }
    );
  }
  componentDidUpdate() {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
      console.log(this.props.value);
    }
    if (this.props.baseUrl !== this.state.baseUrl) {
      this.setState({ baseUrl: this.props.baseUrl });
    }
  }
  /***********Text Template Events */
  fillDD = (search) => {
    if (search == undefined) search = "";
    fetch(this.state.baseUrl + "/index.php/Campaigns/list_select/" + search, {
      credentials: "include",
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ options: data.msg });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  handleChange = (value) => {
    this.props.onChange(value);
  };
  handleSearch = (search) => {
    this.fillDD(search);
  };
  handleClear = () => {
    this.fillDD();
  };
  handleFocus = () => {
    this.fillDD();
  };
  /************************************ */
  render() {
    return (
      <Select
        listHeight={200}
        showSearch
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Select Campaigns"
        optionFilterProp="children"
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onSearch={this.handleSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear={true}
        onClear={this.handleClear}
        value={this.state.value}
      >
        {this.state.options.map((opt) => (
          <Option key={opt.campaign_id} value={opt.campaign_id}>
            {opt.campaign_name + " (" + opt.campaign_id + ")"}
          </Option>
        ))}
      </Select>
    );
  }
}

export default Campaignsdd;
