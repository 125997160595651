import React, { Component } from "react";
import $ from "jquery";
import { Radio, Checkbox } from "antd";
import HeadTitle from "../../HeadTitle";
class WorkflowAdd extends Component {
  constructor(props) {
    super(props);
    const workflow_id = this.props.match.params.workflow_id;
    this.state = {
      optionif: "ifdispo", //ifdispo in case last call dispo is selected, ifleadstatus if lead status is selected
      int_type: "url", //integration type decider, zoho or url
      zoho_client_id: "",
      zoho_client_secret: "",
      zoho_grant_token: "",
      zoho_is_authenticated: false,
      workflow_id: workflow_id,
      workflow: [],
      system_dispos: [],
      campaigns: [],
      templates: [],
      lead_status: [],
      another_lists: [],
      assigned_users: [],
      disable_campaigns: true,
      disable_emailTemplate: true,
      disable_url: true,
      disable_anotherList: true,
      disable_leadStatus: true,
      disable_assignedUser: true,
      is_assignUser: 0,
      is_convertToContact: 0,
      is_moveToAnotherList: 0,
      is_sendemail: 0,
      is_toUrl: 0,
      is_updateLeadStatus: 0,
      convert_to_contact: false,
    };
  }
  handleOptionIfToggle = (e, val) => {
    if (e.target.checked) this.setState({ optionif: val });
  };
  /****Zoho Int management */
  handleInt_typeToggle = (e) => {
    this.setState({
      int_type: e.target.value,
      url: "https://www.zohoapis.com/crm/v2/Leads",
    });
  };

  /***************** */
  componentDidMount() {
    window.resizeHandle();
    //window.App.initUniform();
    // window.load_select2(".bs-select");

    fetch(this.props.baseUrl + "/index.php/Workflow/getInitialData", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            system_dispos: resp.msg.system_dispos,
            campaigns: resp.msg.campaigns,
            templates: resp.msg.templates,
            lead_status: resp.msg.lead_status,
            another_lists: resp.msg.another_lists,
            assigned_users: resp.msg.assigned_users,
          },
          function () {}
        );
        //window.App.initUniform();
      });

    fetch(
      this.props.baseUrl + "/index.php/Workflow/Edit/" + this.state.workflow_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            workflow: resp.msg.workflow,
            optionif:
              resp.msg.workflow.standard_dispo !== null &&
              resp.msg.workflow.standard_dispo !== undefined &&
              resp.msg.workflow.standard_dispo !== "" &&
              resp.msg.workflow.standard_dispo !== "null"
                ? "ifdispo"
                : "ifleadstatus",
            is_assignUser: resp.msg.workflow.is_assignUser == 1 ? true : false,
            is_convertToContact:
              resp.msg.workflow.is_convertToContact == 1 ? true : false,
            is_moveToAnotherList:
              resp.msg.workflow.is_moveToAnotherList == 1 ? true : false,
            is_sendemail: resp.msg.workflow.is_sendemail == 1 ? true : false,
            is_toUrl: resp.msg.workflow.is_toUrl == 1 ? true : false,
            is_updateLeadStatus:
              resp.msg.workflow.is_updateLeadStatus == 1 ? true : false,
            int_type: resp.msg.workflow.toUrl_type, //integration type decider, zoho or url
            zoho_client_id: resp.msg.workflow.zoho_client_id,
            zoho_client_secret: resp.msg.workflow.zoho_client_secret,
            zoho_grant_token: resp.msg.workflow.zoho_grant_token,
          },
          function () {
            this.setState({
              disable_campaigns: resp.msg.workflow.scope == "global",
              disable_emailTemplate:
                resp.msg.workflow.is_sendemail == 1 ? false : true,
              disable_anotherList:
                resp.msg.workflow.is_moveToAnotherList == 1 ? false : true,
              disable_assignedUser:
                resp.msg.workflow.is_assignUser == 1 ? false : true,
              disable_leadStatus:
                resp.msg.workflow.is_updateLeadStatus == 1 ? false : true,
              disable_url: resp.msg.workflow.is_toUrl == 1 ? false : true,
              disable_urlBody:
                resp.msg.workflow.toUrl_method == "get" ? true : false,
            });
          }
        );
        window.$(".bs-select").trigger("change");
        //window.App.initUniform();
      })
      .catch((e) => {
        console.log(e);
        this.props.blockUI(false);
      });
  }

  handleSubmit = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Workflow/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#formWorkflowEdit").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };

  handleSendEmail = (event) => {
    console.log(event.target.checked);
    this.setState({
      is_sendemail: event.target.checked,
      disable_emailTemplate: !event.target.checked,
    });
  };

  handleAPIString = (event) => {
    // console.log(event.target.checked);
    this.setState({
      disable_url: !event.target.checked,
      is_toUrl: event.target.checked,
    });
  };

  handleAnotherList = (event) => {
    // console.log(event.target.checked);
    this.setState({
      disable_anotherList: !event.target.checked,
      is_moveToAnotherList: event.target.checked,
    });
  };

  handleAssignedUser = (event) => {
    // console.log(event.target.checked);
    this.setState({
      disable_assignedUser: !event.target.checked,
      is_assignUser: event.target.checked,
    });
  };

  handleLeadStatus = (event) => {
    // console.log(event.target.checked);
    this.setState({
      disable_leadStatus: !event.target.checked,
      is_updateLeadStatus: event.target.checked,
    });
  };

  handleCampaignChange = (event) => {
    // console.log(event.target.value);
    this.setState({ disable_campaigns: event.target.value == "global" });
  };

  handleUrlBody = (event) => {
    // console.log(event.target.value);
    this.setState({ disable_urlBody: event.target.value == "get" });
  };

  render() {
    var JsonFormat = 'JSON Format: [{"key1":"value1","key2":"value2"}]';
    return (
      <React.Fragment>
        <form id="formWorkflowEdit" onSubmit={this.handleSubmit}>
          <input type="hidden" name="action" value="edit_workflow" />
          <input type="hidden" name="id" value={this.state.workflow_id} />
          <div className="page-container">
            <div className="page-content-wrapper">
              <HeadTitle title="Disposition Workflow" subtitle="edit" />

              <div className="page-content">
                <div className="container">
                  <div className="page-content-inner">
                    <div className="row">
                      <div className="col-md-8 col-md-offset-2">
                        <span id="alert_container"></span>

                        <div className="portlet light portlet-fit ">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-cogs"></i>
                              <span className="caption-subject font-dark sbold">
                                Settings
                              </span>
                            </div>
                            <div className="tools">
                              <a
                                href="javascript:;"
                                class="expand"
                                data-original-title=""
                                title=""
                              >
                                {" "}
                              </a>
                            </div>
                          </div>
                          <div className="portlet-body collapse">
                            <div className="row">
                              <div className=" col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Scope</td>
                                      <td>
                                        <select
                                          name="scope"
                                          className="bs-select form-control"
                                          value={this.state.workflow.scope}
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                scope: ev.target.value,
                                              },
                                              disable_campaigns:
                                                ev.target.value == "global",
                                            });
                                          }}
                                        >
                                          <option value="global">Global</option>
                                          <option value="campaign">
                                            Campaign
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaign</td>
                                      <td>
                                        <select
                                          name="campaign_id"
                                          className="bs-select form-control"
                                          value={
                                            this.state.workflow.campaign_id ==
                                            null
                                              ? ""
                                              : this.state.workflow.campaign_id
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                campaign_id: ev.target.value,
                                              },
                                            });
                                          }}
                                          disabled={
                                            this.state.disable_campaigns
                                          }
                                        >
                                          {this.state.campaigns.map(
                                            (campaign) => (
                                              <option
                                                key={campaign.campaign_id}
                                                value={campaign.campaign_id}
                                              >
                                                {campaign.campaign_name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Status</td>
                                      <td>
                                        <select
                                          name="status"
                                          className="bs-select form-control"
                                          value={this.state.workflow.status}
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                status: ev.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          <option value="Active">Active</option>
                                          <option value="Inactive">
                                            InActive
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="portlet light portlet-fit ">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-question-circle"></i>
                              <span className="caption-subject font-dark sbold">
                                Workflow Conditions (IF)
                              </span>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div className="row">
                              <div className=" col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <input
                                          type="hidden"
                                          name="optionIF"
                                          value={this.state.optionif}
                                        />
                                        <Radio
                                          checked={
                                            this.state.optionif == "ifdispo"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            this.handleOptionIfToggle(
                                              e,
                                              "ifdispo"
                                            );
                                          }}
                                        >
                                          Last Call Status is Updated to:
                                        </Radio>
                                        {/*<div className="radio-list">
                                          <label>
                                            <input
                                              type="radio"
                                              name="optionIF"
                                              id="optionIF"
                                              value="ifdispo"
                                              onClick={
                                                this.handleOptionIfToggle
                                              }
                                              checked={
                                                this.state.optionif == "ifdispo"
                                                  ? true
                                                  : false
                                              }
                                            />{" "}
                                            Last Call Status is Updated to:
                                          </label>
                                            </div>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="standard_dispo"
                                          className="bs-select form-control"
                                          value={
                                            this.state.workflow.standard_dispo
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                standard_dispo: ev.target.value,
                                              },
                                            });
                                          }}
                                          disabled={
                                            this.state.optionif == "ifdispo"
                                              ? false
                                              : true
                                          }
                                        >
                                          {this.state.system_dispos.map(
                                            (sd) => (
                                              <option
                                                key={sd.status}
                                                value={sd.status}
                                              >
                                                {sd.status_name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Radio
                                          checked={
                                            this.state.optionif ==
                                            "ifleadstatus"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            this.handleOptionIfToggle(
                                              e,
                                              "ifleadstatus"
                                            );
                                          }}
                                        >
                                          Lead Status is Updated to:
                                        </Radio>
                                        {/*<label>
                                          <input
                                            type="radio"
                                            name="optionIF"
                                            id="optionIF"
                                            value="ifleadstatus"
                                            onClick={this.handleOptionIfToggle}
                                            checked={
                                              this.state.optionif ==
                                              "ifleadstatus"
                                                ? true
                                                : false
                                            }
                                          />{" "}
                                          Lead Status is Updated to:
                                          </label>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="lead_status"
                                          className="bs-select form-control"
                                          disabled={
                                            this.state.optionif ==
                                            "ifleadstatus"
                                              ? false
                                              : true
                                          }
                                          value={
                                            this.state.workflow.lead_status
                                              ? this.state.workflow.lead_status
                                              : ""
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                lead_status: e.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {this.state.optionif !==
                                          "ifleadstatus" ? (
                                            <option selected></option>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.lead_status.map(
                                            (ls, i) => (
                                              <option key={i} value={ls}>
                                                {ls}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {/* <LeadStatusDropDown
                                          name="lead_status"
                                          class="bs-select form-control"
                                          baseUrl={this.props.baseUrl}
                                          id="lead_status"
                                          value={
                                            this.state.workflow.lead_status
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                lead_status: e.target.value,
                                              },
                                            });
                                          }}
                                        ></LeadStatusDropDown>*/}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="portlet light portlet-fit">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="fa fa-map-signs"></i>
                              <span className="caption-subject font-dark sbold">
                                Workflow Actions (THEN)
                              </span>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <div className="row">
                              <div className=" col-md-12">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>
                                        {this.state.is_sendemail ? (
                                          <input
                                            type="hidden"
                                            name="is_sendemail"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Checkbox
                                          onChange={this.handleSendEmail}
                                          checked={this.state.is_sendemail}
                                        >
                                          Send Email
                                        </Checkbox>
                                        {/*<div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_sendemail"
                                              name="is_sendemail"
                                              defaultValue="1"
                                              onClick={this.handleSendEmail}
                                              checked={this.state.is_sendemail}
                                            />
                                            Send Email
                                          </label>
                                        </div>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="sendEmail_templateid"
                                          id="sendEmail_templateid"
                                          disabled={
                                            this.state.disable_emailTemplate
                                          }
                                          className="bs-select form-control"
                                        >
                                          {this.state.templates.map(
                                            (template) => (
                                              <option
                                                key={template.key}
                                                value={template.key}
                                              >
                                                {template.value}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        {this.state.is_convertToContact ? (
                                          <input
                                            type="hidden"
                                            name="is_convertToContact"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}

                                        <Checkbox
                                          onChange={(e) =>
                                            this.setState({
                                              is_convertToContact:
                                                e.target.checked,
                                            })
                                          }
                                          checked={
                                            this.state.is_convertToContact
                                          }
                                        >
                                          Convert to Contact
                                        </Checkbox>
                                        {/*<div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_convertToContact"
                                              name="is_convertToContact"
                                              defaultValue="1"
                                              checked={
                                                this.state.is_convertToContact
                                              }
                                              onClick={(e) =>
                                                this.setState({
                                                  is_convertToContact: !this
                                                    .state.is_convertToContact,
                                                })
                                              }
                                            />
                                            Convert to Contact
                                          </label>
                                            </div>*/}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {this.state.is_toUrl ? (
                                          <input
                                            type="hidden"
                                            name="is_toUrl"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Checkbox
                                          onChange={this.handleAPIString}
                                          checked={this.state.is_toUrl}
                                        >
                                          API String
                                        </Checkbox>
                                        {/*<div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_toUrl"
                                              name="is_toUrl"
                                              defaultValue="1"
                                              onClick={this.handleAPIString}
                                              checked={this.state.is_toUrl}
                                            />
                                            API String
                                          </label>
                                        </div>*/}
                                      </td>
                                      <td>
                                        {/* <input
																					type="text"
																					className="form-control"
																					name="toUrl_url"
																					disabled={this.state.disable_url}
																					value={this.state.workflow.toUrl_url}
																					onChange={ev => {
																						this.setState({
																							workflow: {
																								...this.state.workflow,
																								toUrl_url: ev.target.value,
																							}
																						});
																					}}
																				/> */}
                                        {this.state.disable_url == false ? (
                                          <a
                                            data-toggle="modal"
                                            href="#mdl_url"
                                          >
                                            API Settings
                                          </a>
                                        ) : (
                                          <div></div>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {this.state.is_moveToAnotherList ? (
                                          <input
                                            type="hidden"
                                            name="is_moveToAnotherList"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Checkbox
                                          onChange={this.handleAnotherList}
                                          checked={
                                            this.state.is_moveToAnotherList
                                          }
                                        >
                                          Move to Another List
                                        </Checkbox>
                                        {/*} <div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_moveToAnotherList"
                                              name="is_moveToAnotherList"
                                              defaultValue="1"
                                              onClick={this.handleAnotherList}
                                              checked={
                                                this.state.is_moveToAnotherList
                                              }
                                            />
                                            Move to Another List
                                          </label>
                                            </div>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="moveToAnotherList_listId"
                                          id="moveToAnotherList_listId"
                                          disabled={
                                            this.state.disable_anotherList
                                          }
                                          className="bs-select form-control"
                                          value={
                                            this.state.workflow
                                              .moveToAnotherList_listId
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                moveToAnotherList_listId:
                                                  ev.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {this.state.another_lists.map(
                                            (list) => (
                                              <option
                                                key={list.ListId}
                                                value={list.ListId}
                                              >
                                                {list.ListId +
                                                  " - " +
                                                  list.ListName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {this.state.is_updateLeadStatus ? (
                                          <input
                                            type="hidden"
                                            name="is_updateLeadStatus"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Checkbox
                                          onChange={this.handleLeadStatus}
                                          checked={
                                            this.state.is_updateLeadStatus
                                          }
                                        >
                                          Update Lead Status
                                        </Checkbox>
                                        {/*
                                        <div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_updateLeadStatus"
                                              name="is_updateLeadStatus"
                                              defaultValue="1"
                                              onClick={this.handleLeadStatus}
                                              checked={
                                                this.state.is_updateLeadStatus
                                              }
                                            />
                                            Update Lead Status
                                          </label>
                                            </div>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="updateLeadStatus_lead"
                                          id="updateLeadStatus_lead"
                                          disabled={
                                            this.state.disable_leadStatus
                                          }
                                          className="bs-select form-control"
                                          value={
                                            this.state.workflow
                                              .updateLeadStatus_lead
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                updateLeadStatus_lead:
                                                  ev.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {this.state.lead_status.map(
                                            (status, i) => (
                                              <option key={i} value={status}>
                                                {status}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {this.state.is_assignUser ? (
                                          <input
                                            type="hidden"
                                            name="is_assignUser"
                                            defaultValue="1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Checkbox
                                          onChange={this.handleAssignedUser}
                                          checked={this.state.is_assignUser}
                                        >
                                          Assign to Another User
                                        </Checkbox>
                                        {/*<div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="is_assignUser"
                                              name="is_assignUser"
                                              defaultValue="1"
                                              onClick={this.handleAssignedUser}
                                              checked={this.state.is_assignUser}
                                            />
                                            Assign to Another User
                                          </label>
                                            </div>*/}
                                      </td>
                                      <td>
                                        <select
                                          name="assignUser_userId"
                                          id="assignUser_userId"
                                          disabled={
                                            this.state.disable_assignedUser
                                          }
                                          className="bs-select form-control"
                                          value={
                                            this.state.workflow
                                              .assignUser_userId
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              workflow: {
                                                ...this.state.workflow,
                                                assignUser_userId:
                                                  ev.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {this.state.assigned_users.map(
                                            (user) => (
                                              <option
                                                key={user.key}
                                                value={user.key}
                                              >
                                                {user.value}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="mdl_url"
            className="modal fade"
            tabindex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  ></button>
                  <h4 className="modal-title">URL Settings</h4>
                </div>
                <div className="modal-body">
                  <div
                    className="scroller"
                    style={{ height: "300px", overflowY: "auto" }}
                    data-always-visible="1"
                    data-rail-visible1="1"
                  >
                    <table
                      className="table table-striped table-bordered table-advance table-hover"
                      id="tbl_url_setting"
                    >
                      <tbody>
                        <tr>
                          <td>Integration Type</td>
                          <td>
                            {/*!--two radio buttons zoho or api call-->*/}
                            <div className="radio-list">
                              <label>
                                <input
                                  type="radio"
                                  name="Int_type"
                                  id="Int_type"
                                  value="zoho"
                                  onClick={this.handleInt_typeToggle}
                                  checked={
                                    this.state.int_type == "zoho" ? true : false
                                  }
                                />{" "}
                                Zoho Integration
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="Int_type"
                                  id="Int_type"
                                  value="url"
                                  onClick={this.handleInt_typeToggle}
                                  checked={
                                    this.state.int_type == "url" ? true : false
                                  }
                                />{" "}
                                Single Api Call
                              </label>
                            </div>
                          </td>
                        </tr>
                        {/*ask for client id, client secret, and grant type here in case he uses zoho */}
                        <tr
                          className={
                            this.state.int_type == "zoho" ? "" : "hidden"
                          }
                        >
                          <td colspan="2">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>Client ID</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="zoho_client_id"
                                      name="zoho_client_id"
                                      value={this.state.zoho_client_id}
                                      onChange={(e) => {
                                        this.setState({
                                          zoho_client_id: e.target.value,
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Client Secret</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="zoho_client_secret"
                                      name="zoho_client_secret"
                                      value={this.state.zoho_client_secret}
                                      onChange={(e) => {
                                        this.setState({
                                          zoho_client_secret: e.target.value,
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Grant Token</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="zoho_grant_token"
                                      name="zoho_grant_token"
                                      value={this.state.zoho_grant_token}
                                      onChange={(e) => {
                                        this.setState({
                                          zoho_grant_token: e.target.value,
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        {/********************* */}
                        <tr>
                          <td> Method </td>
                          <td>
                            <select
                              name="toUrl_method"
                              id="toUrl_method"
                              className="form-control"
                              value={this.state.workflow.toUrl_method}
                              onChange={(ev) => {
                                this.setState({
                                  disable_urlBody: ev.target.value == "get",
                                  workflow: {
                                    ...this.state.workflow,
                                    toUrl_method: ev.target.value,
                                  },
                                });
                              }}
                              // onChange={this.handleUrlBody}
                            >
                              <option key="get" value="get">
                                GET
                              </option>
                              <option key="post" value="post">
                                POST
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>URL</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="toUrl_url"
                              value={this.state.workflow.toUrl_url}
                              onChange={(ev) => {
                                this.setState({
                                  workflow: {
                                    ...this.state.workflow,
                                    toUrl_url: ev.target.value,
                                  },
                                });
                              }}
                            />
                            {/* <input type="text" className="form-control" id="toUrl_url" name="toUrl_url" /> */}
                          </td>
                        </tr>
                        <tr>
                          <td>Body</td>
                          <td>
                            <textarea
                              type="text"
                              className="form-control"
                              name="toUrl_body"
                              value={this.state.workflow.toUrl_body}
                              disabled={this.state.disable_urlBody}
                              style={{ resize: "vertical" }}
                              onChange={(ev) => {
                                this.setState({
                                  workflow: {
                                    ...this.state.workflow,
                                    toUrl_body: ev.target.value,
                                  },
                                });
                              }}
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Headers
                            <p
                              className="font-grey-mint"
                              style={{ margin: "0px" }}
                            >
                              <small>{JsonFormat}</small>
                            </p>
                          </td>
                          <td>
                            <textarea
                              type="text"
                              className="form-control"
                              name="toUrl_headers"
                              value={this.state.workflow.toUrl_headers}
                              style={{ resize: "vertical" }}
                              onChange={(ev) => {
                                this.setState({
                                  workflow: {
                                    ...this.state.workflow,
                                    toUrl_headers: ev.target.value,
                                  },
                                });
                              }}
                            ></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn green-jungle btn-outline"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default WorkflowAdd;
