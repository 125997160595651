import React, { Component } from "react";
class CampaignsDropDown extends Component {
  state = {
    campaigns: [],
    id: "list_campaign_id_LtA",
    name: "campaign_id",
    class: "",
    ismulti: false,
    inmodal: false,
  };
  componentDidMount() {
    if (this.props.id !== undefined) this.setState({ id: this.props.id });
    if (this.props.name !== undefined) this.setState({ name: this.props.name });
    if (this.props.class !== undefined)
      this.setState({ class: this.props.class });
    if (this.props.ismulti !== undefined)
      this.setState({ ismulti: this.props.ismulti });
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Campaigns/Ajax", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=list_campaigns"),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        var campaigns = [];
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].campaign_id,
            name: data[i].campaign_name,
          };
          campaigns.push(list_ob);
        }
        //console.log(lists);
        this.setState({ campaigns });
        /*if (!this.state.ismulti) window.load_select2("#" + this.state.id);
        else window.$("#" + this.state.id).multiSelect();
*/
        if (this.state.ismulti) window.$("#" + this.state.id).multiSelect();

        if (this.props.MountCall !== undefined) {
          this.props.MountCall();
        }
      })
      .catch((err) => {
        alert(err);
      });
  }
  render() {
    let dd_campaign = this.state.campaigns.map(function (item, i) {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <select
        name={this.state.name}
        id={this.state.id}
        className={"form-control " + this.state.class}
        multiple={this.state.ismulti}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {dd_campaign}
      </select>
    );
  }
}

export default CampaignsDropDown;
