import React, { Component } from "react";
import PanelLeadInfo from "./Standalone/PanelLeadInfo";
import Drawer_inbound from "./Standalone/Drawer_inbound";
class Footer extends Component {
  state = { user_role: "agent", client_id: "", user_id: "" };
  year_copyright = new Date();
  componentDidMount() {
    this.setState({
      user_role: this.props.role,
      client_id: this.props.client_id,
      user_id: this.props.user_id,
    });
    /*window.$("#callbk_appt").datetimepicker({
      isRTL: window.App.isRTL(),
      format: "mm-dd-yyyy HH:ii P",
      showMeridian: true,
      autoclose: true,
      pickerPosition: window.App.isRTL() ? "bottom-right" : "bottom-left",
      todayBtn: true
    });*/
    window.datetimepickerinit();
    //setup callback modal hiding event to call callback dismissed here
    /*window.$("#modal_callbk").on("hide.bs.modal", function() {
      window.callback_dismissed();
    });*/
    ///////////////////////////////////////////////////////////////////////////
    window.$("#close_transfer_actions").click(function () {
      window.ToggleTransferControls(false);
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.role != this.state.user_role) {
      this.setState({ user_role: nextProps.role });
    }
    if (nextProps.client_id != this.state.client_id) {
      this.setState({ client_id: nextProps.client_id });
    }
    if (nextProps.user_id != this.state.user_id) {
      this.setState({ user_id: this.state.user_id });
    }
    //console.error("eee" + nextProps.role);
  }
  render() {
    return (
      <React.Fragment>
        <Drawer_inbound />
        <div className="page-footer">
          <div className="container">
            {" "}
            &copy; {this.year_copyright.getFullYear()}&nbsp;
            <a
              href="https://evs7.com"
              rel="noopener noreferrer"
              title="http://evs7.com"
              target="_blank"
            >
              Electronic Voice Services, Inc. (EVS7)
            </a>{" "}
            | All Rights Reserved
          </div>
        </div>
        <div className="scroll-to-top">
          <i className="icon-arrow-up"></i>
        </div>

        <div
          id="customfields"
          className="modal fade"
          tabIndex="-1"
          data-width="760"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add custom fields</h4>
              </div>
              <form
                arole="form"
                className="form-horizontal"
                action=""
                method="post"
              >
                <input type="hidden" name="action" value="add_custom_fields" />
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Display Name
                        </label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="text"
                            name="display_name"
                          />
                          <span className="help-block">
                            {" "}
                            Display Name of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">Name</label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                          />
                          <span className="help-block">
                            {" "}
                            Name of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Description
                        </label>
                        <div className="col-md-9">
                          <textarea
                            className="form-control"
                            type="text"
                            name="description"
                          ></textarea>
                          <span className="help-block">
                            {" "}
                            Description of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Help Text
                        </label>
                        <div className="col-md-9">
                          <textarea
                            className="form-control"
                            type="text"
                            name="help_text"
                          ></textarea>
                          <span className="help-block">
                            {" "}
                            Help Text of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">Type</label>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            name="type"
                            id="type"
                          >
                            <option value="TEXT">TEXT</option>
                            <option value="AREA">AREA</option>
                            <option value="SELECT">SELECT</option>
                            <option value="MULTI">MULTI</option>
                            <option value="RADIO">RADIO</option>
                            <option value="CHECKBOX">CHECKBOX</option>
                            <option value="DATE">DATE</option>
                            <option value="TIME">TIME</option>
                            <option value="DISPLAY">DISPLAY</option>
                            <option value="HIDDEN">HIDDEN</option>
                            <option value="HIDELOB">HIDELOB</option>
                            <option value="READONLY">READONLY</option>
                          </select>
                          <span className="help-block">
                            {" "}
                            Type of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <span className="Checkbox_consent" id="Checkbox_consent">
                        <div className="form-group">
                          <label className="col-md-3 control-label">
                            Checked/Unchecked
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-control"
                              name="Checkbox_consent"
                            >
                              <option value="1">Checked</option>
                              <option value="0">Unchecked</option>
                            </select>
                            <span className="help-block">
                              {" "}
                              Checkbox of Custom Field{" "}
                            </span>
                          </div>
                        </div>
                      </span>
                      <span className="row_col" id="row_col">
                        <div className="form-group">
                          <label className="col-md-3 control-label">Row</label>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="display_row"
                              name="display_row"
                            />
                            <span className="help-block">
                              {" "}
                              Row of Custom Field{" "}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-3 control-label">Col</label>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="display_col"
                              name="display_col"
                            />
                            <span className="help-block">
                              {" "}
                              Col of Custom Field{" "}
                            </span>
                          </div>
                        </div>
                      </span>
                      <span className="field_option" id="field_option">
                        <div className="form-group">
                          <label className="col-md-3 control-label">
                            Options
                          </label>
                          <div className="col-md-9">
                            <textarea
                              className="form-control"
                              type="text"
                              name="options"
                            ></textarea>
                            <span className="help-block">
                              {" "}
                              Options of Custom Field{" "}
                            </span>
                          </div>
                        </div>
                      </span>
                      <span className="size" id="size">
                        <div className="form-group">
                          <label className="col-md-3 control-label">Size</label>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="text"
                              name="size"
                            />
                            <span className="help-block">
                              {" "}
                              Size of Custom Field{" "}
                            </span>
                          </div>
                        </div>
                      </span>
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Default
                        </label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="text"
                            name="field_default"
                          />
                          <span className="help-block">
                            {" "}
                            Default of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">Scope</label>
                        <div className="col-md-9">
                          <select className="form-control" name="scope">
                            <option value="Global">Global</option>

                            <option value=">">List</option>
                          </select>
                          <span className="help-block">
                            {" "}
                            Scope of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <input
                        className="form-control"
                        type="hidden"
                        name="crt_by"
                        value="<?=$user_id?>"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-outline dark"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/***Modal for agreement sining */}
        <div
          id="static_agreement_modal"
          className="modal fade"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Calling Software and Phone Services Agreement
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  {" "}
                  {this.state.user_role == "owner"
                    ? "Before dialing, you must complete the Calling Software and Phone Services Agreement indicating that you will use Dolphin Cloud legally."
                    : "Before dialing, the account owner must complete the Calling Software and Phone Services Agreement indicating that Dolphin Cloud will be used legally. "}{" "}
                </p>
              </div>
              <div className="modal-footer">
                {this.state.user_role == "owner" ? (
                  <a
                    className="btn green"
                    href={
                      "https://dolphin.evs7.com/backend_spa/index.php/TheAgreement/takemetoAgreement/" +
                      this.state.client_id +
                      "/" +
                      this.state.user_id
                    }
                    target="_blank"
                  >
                    Sign Electronic Agreement
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/******Modal for agreement end */}
        {/*Modal fro viewing spam cid details*/}
        <div id="mdl_spam_check_cid" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Spam check for <span id="spam_check_cid"></span>
                </h4>
              </div>
              <div className="modal-body" style={{ paddingBottom: "0px" }}>
                <table className="table table-responsive table-striped">
                  <tbody>
                    <tr>
                      <th>CID</th>
                      <td id="mdl_spmchk_cid"></td>
                    </tr>
                    <tr>
                      <th>Spam Score</th>
                      <td id="mdl_spmchk_score"></td>
                    </tr>
                    <tr>
                      <th>Marked as Spam by</th>
                      <td id="mdl_spmchk_by"></td>
                    </tr>
                    <tr>
                      <th>Checked On</th>
                      <td id="mdl_spmchk_at"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="modal-footer"
                style={{
                  textAlign: "left",
                  paddingTop: "5px",
                  paddingBottom: "0px",
                  fontSize: "12px",
                }}
              >
                <p>
                  To get a more accurate depiction of potential SPAM flagging,
                  testing a call to a mobile phone on different carriers such as
                  AT &ampersand; T, Verizon, and T-Mobile is advised.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------ */}
        {/*}
        <div
          id="modal_callbk"
          className="modal fade"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={e => {
                    window.callback_dismissed();
                  }}
                ></button>
                <h4 className="modal-title">Add new Call Back</h4>
              </div>
              <div className="modal-body">
                <div
                  className="scroller"
                  style={{ height: "42px" }}
                  data-always-visible="1"
                  data-rail-visible1="1"
                >
                  <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                      <label className="col-md-4">Call Back At</label>
                      <div className="col-md-8">
                        <input type="hidden" id="cb_lead_id" />
                        <input
                          type="text"
                          id="callbk_appt"
                          className="col-md-12 form-control date-time-picker"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn dark btn-outline"
                  onClick={e => {
                    window.callback_dismissed();
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={e => {
                    window.add_callBack();
                  }}
                  className="btn green"
                >6
                  Create Callback
                </button>
              </div>
            </div>
          </div>
        </div>*/}

        <div id="op-station">
          <div id="panelname" className="op-panel transparent-darknight">
            <div className="op-panelctrl">
              <div
                data-close="panelname"
                className="op-panelbt op-bt-close"
                id="qlv_close"
              ></div>

              <div className="clearspace"></div>
            </div>

            <div className="op-panelform">
              <PanelLeadInfo baseUrl={this.props.baseUrl}></PanelLeadInfo>
            </div>
          </div>
        </div>

        <div
          id="transfer_actions"
          className="hidden"
          style={{
            position: "fixed",
            width: "250px",
            height: "110px",
            backgroundColor: "#d3d3d3",
            zIndex: 99999,
          }}
        >
          <button
            id="close_transfer_actions"
            style={{
              position: "absolute",
              left: "0px",
              top: "-19px",
              width: "20px",
              height: "20px",
              fontSize: "10px",
              textAlign: "center",
              backgroundColor: "#CBCBCB",
              border: "0px solid gray",
              padding: "0",
              margin: "0",
              borderRadius: "1px",
              zIndex: "999999",
            }}
          >
            x
          </button>
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="input-group-btn">
                        <button
                          className="btn form-control black dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Presets
                          <i className="fa fa-angle-down"></i>
                        </button>
                        <ul
                          className="dropdown-menu bottom-down"
                          id="trx_presets"
                        ></ul>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group input-medium">
                        <input
                          id="xfer_num"
                          className="form-control"
                          name="xfer_to"
                          placeholder="Xfer to"
                          type="text"
                        />
                        <span className="input-group-btn">
                          <button
                            id="xfer_blind"
                            style={{ marginRight: "1px" }}
                            type="button"
                            className=" btn blue"
                            title="Blind Transfer"
                            onClick={(e) => {
                              window.webphone.wp_transfer("blind");
                            }}
                          >
                            <i
                              className="fa fa-eye-slash transfer_icon"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="xfer_warm"
                            style={{ marginRight: "1px" }}
                            type="button"
                            className=" btn bg-yellow-gold bg-font-yellow-gold"
                            onClick={(e) => {
                              window.webphone.wp_transfer("warm");
                            }}
                          >
                            <i
                              className="fa fa-exclamation-triangle transfer_icon"
                              aria-hidden="true"
                              title="Warm"
                            ></i>
                          </button>
                          <button
                            id="xfer_3_way"
                            type="button"
                            className=" btn green"
                            title="3-Way Transfer"
                            onClick={(e) => {
                              window.webphone.wp_transfer("3way");
                            }}
                          >
                            <i
                              className="fa fa-road transfer_icon"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="leave_3_way"
                            type="button"
                            className=" btn green hidden"
                            title="Hangup 3rd Party"
                            onClick={(e) => {
                              window.webphone.wp_hangup3way();
                            }}
                          >
                            <i
                              className="fa fa-chain-broken"
                              aria-hidden="true"
                            ></i>
                          </button>
                          {/***test to hang myself out of a 3way confere3nce */}
                          <button
                            id="leave_3_way_actually"
                            type="button"
                            className=" btn green hidden"
                            title="Leave Conference"
                            onClick={(e) => {
                              window.webphone.wp_transfer("hangup_3rd_party");
                            }}
                          >
                            <i
                              className="fa fa-sign-out"
                              aria-hidden="true"
                            ></i>
                          </button>
                          {/** */}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
/*
function Footer(props) {
  const year_copyright = new Date();
  window.$("#callbk_appt").datetimepicker({
    isRTL: window.App.isRTL(),
    format: "mm-dd-yyyy HH:ii P",
    showMeridian: true,
    autoclose: true,
    pickerPosition: window.App.isRTL() ? "bottom-right" : "bottom-left",
    todayBtn: true
  });
  return (
    <React.Fragment>
      <div className="page-footer">
        <div className="container">
          {" "}
          &copy; {year_copyright.getFullYear()}&nbsp;
          <a
            href="https://evs7.com"
            rel="noopener noreferrer"
            title="http://evs7.com"
            target="_blank"
          >
            Electronic Voice Services, Inc. (EVS7)
          </a>{" "}
          | All Rights Reserved
        </div>
      </div>
      <div className="scroll-to-top">
        <i className="icon-arrow-up"></i>
      </div>

      <div
        id="customfields"
        className="modal fade"
        tabIndex="-1"
        data-width="760"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              ></button>
              <h4 className="modal-title">Add custom fields</h4>
            </div>
            <form
              arole="form"
              className="form-horizontal"
              action=""
              method="post"
            >
              <input type="hidden" name="action" value="add_custom_fields" />
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Display Name
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="display_name"
                        />
                        <span className="help-block">
                          {" "}
                          Display Name of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">Name</label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                        />
                        <span className="help-block">
                          {" "}
                          Name of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Description
                      </label>
                      <div className="col-md-9">
                        <textarea
                          className="form-control"
                          type="text"
                          name="description"
                        ></textarea>
                        <span className="help-block">
                          {" "}
                          Description of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Help Text
                      </label>
                      <div className="col-md-9">
                        <textarea
                          className="form-control"
                          type="text"
                          name="help_text"
                        ></textarea>
                        <span className="help-block">
                          {" "}
                          Help Text of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">Type</label>
                      <div className="col-md-9">
                        <select className="form-control" name="type" id="type">
                          <option value="TEXT">TEXT</option>
                          <option value="AREA">AREA</option>
                          <option value="SELECT">SELECT</option>
                          <option value="MULTI">MULTI</option>
                          <option value="RADIO">RADIO</option>
                          <option value="CHECKBOX">CHECKBOX</option>
                          <option value="DATE">DATE</option>
                          <option value="TIME">TIME</option>
                          <option value="DISPLAY">DISPLAY</option>
                          <option value="HIDDEN">HIDDEN</option>
                          <option value="HIDELOB">HIDELOB</option>
                          <option value="READONLY">READONLY</option>
                        </select>
                        <span className="help-block">
                          {" "}
                          Type of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <span className="Checkbox_consent" id="Checkbox_consent">
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Checked/Unchecked
                        </label>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            name="Checkbox_consent"
                          >
                            <option value="1">Checked</option>
                            <option value="0">Unchecked</option>
                          </select>
                          <span className="help-block">
                            {" "}
                            Checkbox of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                    </span>
                    <span className="row_col" id="row_col">
                      <div className="form-group">
                        <label className="col-md-3 control-label">Row</label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="display_row"
                            name="display_row"
                          />
                          <span className="help-block">
                            {" "}
                            Row of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-3 control-label">Col</label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="display_col"
                            name="display_col"
                          />
                          <span className="help-block">
                            {" "}
                            Col of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                    </span>
                    <span className="field_option" id="field_option">
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Options
                        </label>
                        <div className="col-md-9">
                          <textarea
                            className="form-control"
                            type="text"
                            name="options"
                          ></textarea>
                          <span className="help-block">
                            {" "}
                            Options of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                    </span>
                    <span className="size" id="size">
                      <div className="form-group">
                        <label className="col-md-3 control-label">Size</label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="text"
                            name="size"
                          />
                          <span className="help-block">
                            {" "}
                            Size of Custom Field{" "}
                          </span>
                        </div>
                      </div>
                    </span>
                    <div className="form-group">
                      <label className="col-md-3 control-label">Default</label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="field_default"
                        />
                        <span className="help-block">
                          {" "}
                          Default of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">Scope</label>
                      <div className="col-md-9">
                        <select className="form-control" name="scope">
                          <option value="Global">Global</option>

                          <option value=">">List</option>
                        </select>
                        <span className="help-block">
                          {" "}
                          Scope of Custom Field{" "}
                        </span>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      type="hidden"
                      name="crt_by"
                      value="<?=$user_id?>"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-outline dark"
                >
                  Close
                </button>
                <button type="submit" className="btn green">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        id="modal_callbk"
        className="modal fade"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              ></button>
              <h4 className="modal-title">Add new Call Back</h4>
            </div>
            <div className="modal-body">
              <div
                className="scroller"
                style={{ height: "42px" }}
                data-always-visible="1"
                data-rail-visible1="1"
              >
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <label className="col-md-4">Call Back At</label>
                    <div className="col-md-8">
                      <input type="hidden" id="cb_lead_id" />
                      <input
                        type="text"
                        id="callbk_appt"
                        className="col-md-12 form-control date-time-picker"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn dark btn-outline"
              >
                Close
              </button>
              <button
                type="button"
                onClick={e => {
                  window.add_callBack();
                }}
                className="btn green"
              >
                Create Callback
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="op-station">
        <div id="panelname" className="op-panel transparent-darknight">
          <div className="op-panelctrl">
            <div
              data-close="panelname"
              className="op-panelbt op-bt-close"
              id="qlv_close"
            ></div>

            <div className="clearspace"></div>
          </div>

          <div className="op-panelform">
            <PanelLeadInfo baseUrl={props.baseUrl}></PanelLeadInfo>
          </div>
        </div>
      </div>

      <div
        id="transfer_actions"
        className="hidden"
        style={{
          position: "fixed",
          width: "250px",
          height: "110px",
          backgroundColor: "#d3d3d3",
          zIndex: 99999
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <table className="table">
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    <span className="input-group-btn">
                      <button
                        className="btn form-control black dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Presets
                        <i className="fa fa-angle-down"></i>
                      </button>
                      <ul
                        className="dropdown-menu bottom-up"
                        id="trx_presets"
                      ></ul>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="input-group input-medium">
                      <input
                        id="xfer_num"
                        className="form-control"
                        name="xfer_to"
                        placeholder="Xfer to"
                        type="text"
                      />
                      <span className="input-group-btn">
                        <button
                          id="xfer_blind"
                          style={{ marginRight: "1px" }}
                          type="button"
                          className=" btn blue"
                          title="Blind Transfer"
                        >
                          <i
                            className="fa fa-eye-slash transfer_icon"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <button
                          id="xfer_warm"
                          style={{ marginRight: "1px" }}
                          type="button"
                          className=" btn bg-yellow-gold bg-font-yellow-gold"
                        >
                          <i
                            className="fa fa-exclamation-triangle transfer_icon"
                            aria-hidden="true"
                            title="Warm"
                          ></i>
                        </button>
                        <button
                          id="xfer_3_way"
                          type="button"
                          className=" btn green"
                          title="3-Way Transfer"
                        >
                          <i
                            className="fa fa-road transfer_icon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
*/
