import React, { Component } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'
import { Popconfirm, Collapse, message, TimePicker } from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import ScriptsDropDown from '../Bricks/ScriptsDropDown'
import HeadTitle from '../HeadTitle'
import DynamicCIDCampaignWidget from '../Bricks/DynamicCIDCampaignWidget'
import AreaCIDCampaignWidget from '../Bricks/AreaCIDCampaignWidget'

const { RangePicker } = TimePicker
class MasterDialSettings extends Component {
  state = {
    timezones: [],
    sel_timezone: '',
    callerid: '',
    campaign_cid_type: '',
    sms_cid: '',
    agent_cid_override: 'Y',
    campaign_dial_order: 'standard',
    manual_preview_dial: 'PREVIEW_ONLY',
    campaign_script: '',
    recording: 'NONE',
    dial_timeout: 0,
    auto_alt_dial: 'N',
    three_way_call_cid: 'CAMPAIGN', //CAMPAIGN = Dolphin, CUSTOMER=Leads CID
    standard_dispo: [],
    dial_dispo: [],
    auto_alt_dial_statuses: [],
    system_dispos: [],
    campaign_dispos: [],
    enable_recording: 'Yes',
    scripts_list: [],
    local_call_times: '',
    campaign_id: 'Master',
  }
  dt_md_presets = null
  /*dt_dynamic_cids = null;*/
  componentDidMount() {
    window.resizeHandle()
    this.dt_md_presets = window.dttable(
      'dt_md_presets',
      this.props.baseUrl + '/index.php/Ajax/list_campaigns_presets/Master',
      ''
    )

    //load its specific js file ;)
    const script = document.createElement('script')
    script.src = 'jsdolphin/masterdialsettings.js'
    script.async = true
    document.body.appendChild(script)
    ////////////////////////////////////
    //window.load_select2(".bs-select");
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Ajax/list_timezones', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({ timezones: resp.msg })
        this.initMasterCampaing()
      })

    ///restore defaults management
    var Aurl = this.props.baseUrl
    var blockUI = (to) => {
      this.props.blockUI(to)
    }

    /****Below is delete control for Hotkeys */

    var dtable_presets = this.dt_md_presets
    $('#dt_md_presets').on('click', '.btn-danger', function () {
      /*$(this)
			.closest("tr")
			.remove();*/
      var childKey = $(this).attr('delete_id')
      var campaign_id = $(this).attr('campaign_id')
      blockUI(true)
      fetch(
        Aurl +
          '/index.php/Campaigns/Preset/delete/' +
          childKey +
          '/' +
          campaign_id,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            dtable_presets.api().ajax.reload()
            window.displayAlert('#alert_container', data.msg, 'success')
          } else {
            window.displayAlert('#alert_container', data.msg, 'danger')
          }
          blockUI(false)
        })
        .catch((e) => {
          blockUI(false)
        })
    })
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
  }
  initMasterCampaing = () => {
    fetch(this.props.baseUrl + '/index.php/Campaigns/Modify/Master', {
      method: 'GET',
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        console.log(data)
        data = data.msg
        var campaign = data.campaign
        this.setState(
          {
            callerid: campaign.campaign_cid,
            campaign_cid_type: campaign.campaign_cid_type,
            sms_cid: campaign.sms_cid,
            agent_cid_override: campaign.agent_cid_override,
            campaign_dial_order: campaign.campaign_dial_order,
            manual_preview_dial: campaign.manual_preview_dial,
            campaign_script: campaign.campaign_script,
            campaign_recording: campaign.campaign_recording,
            dial_timeout: campaign.dial_timeout,
            auto_alt_dial: campaign.auto_alt_dial,
            dial_dispo: data.dial_dispo,
            auto_alt_dial_statuses: data.auto_alt_dial_statuses,
            system_dispos: data.system_dispos,
            campaign_dispos: data.campaign_dispos,
            std_dial_dispo: data.std_dial_dispo,
            sel_timezone: campaign.tz_name,
            enable_recording: data.system_settings.enable_recording,
            scripts_list: data.scripts,
            local_call_times: campaign.local_call_time,
            three_way_call_cid: campaign.three_way_call_cid,
          },
          function () {
            //this.doTheDynamicWidget();
          }
        )
        //window.$(".bs-select").trigger("change");
        //console.error(this.state);
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(true)
      })
  }
  handleSettingsSubmit = (e) => {
    e.preventDefault()

    if (Number.parseInt(this.state.dial_timeout) < 30) {
      window.displayAlert(
        '#alert_container',
        'Dial Timeout should be atleast 30',
        'danger'
      )
      return
    }

    this.props.blockUI(true)

    //make sure cids are validated properly
    const val_ccid = $('#campaign_cid').val()
    const val_smscid = $('#sms_cid').val()
    /*if (
      (val_ccid.length !== 10 && val_ccid.length !== 0) ||
      val_ccid[0] == "1" ||
      val_ccid[0] == "0"
    ) {
      window.displayAlert(
        "#alert_container",
        "Campaign Cid has to be 10 numbers (no letters) and it cannot start with a 1 or 0",
        "danger"
      );
      this.props.blockUI(false);
      return false;
    }*/
    if (
      (val_smscid.length !== 10 && val_smscid.length !== 0) ||
      val_smscid[0] == '1' ||
      val_smscid[0] == '0'
    ) {
      window.displayAlert(
        '#alert_container',
        'SMS Cid has to be 10 numbers (no letters) and it cannot start with a 1 or 0',
        'danger'
      )
      this.props.blockUI(false)
      return false
    }
    /////////////////////////////////////////////

    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_submit_settings').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        console.log('trueIN')
        if (data.status) {
          console.log('true')
          window.displayAlert('#alert_container', data.msg, 'success')
          //alert("I am true");
          // on successful editing, lets update caller id ^_^ and reregister
          // window.wss_cid = this.state.callerid
          //window.webphone.wp_register();
          ////////////////////////////////////////////////////////
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddCampaignDispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/Add_campaignDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_add_campaign_dispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
          window.$('#modal_add_camp_dispo').modal('hide')
        } else {
          window.displayAlert('#mdl_csd_alert_container', data.msg, 'danger')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleAddDialableDispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/Add_dialDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_adddialdispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
          window.$('#modal_add_sys_dispo').modal('hide')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddautoaltdialdispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/add_AltDialDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addautoaltdialdispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
          window.$('#modal_add_alt_dial_dispo').modal('hide')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddtransferpreset = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addtransferpreset').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
          window.$('#modal_trxPreset').modal('hide')
          this.dt_md_presets.api().ajax.reload()
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }

  handleRemoveStandardDispo = (e, status) => {
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/remove/' + status, {
      method: 'post',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handlestddialdisporemove = (e, dispo) => {
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl +
        '/index.php/DispStatuses/remove_Dial_Dispo/' +
        dispo +
        '/Master',
      { method: 'post', credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleAutoAltRemoval = (e, aads) => {
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl +
        '/index.php/DispStatuses/remove_Auto_Alt_Dial_Dispo/' +
        aads +
        '/Master',
      { method: 'post', credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.initMasterCampaing()
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(true)
      })
  }
  RestoreListResetDisposToDefault = () => {
    window.App.blockUI(true)
    //alert('You confirmed action #1');
    fetch(
      this.props.baseUrl + '/index.php/DispStatuses/RestoreMasterDialDispo',
      {
        method: 'post',
        credentials: 'include',
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
          this.initMasterCampaing()
        } else {
          window.displayAlert('#alert_container', resp.msg, 'error')
        }
        window.App.unblockUI(false)
      })
      .catch((e) => {
        window.App.unblockUI(false)
      })
  }
  RestoreStandardDisposToDefault = () => {
    window.App.blockUI(true)
    //alert('You confirmed action #1');
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      method: 'post',
      credentials: 'include',
      body: new URLSearchParams('action=restore_missing_standard_dispos'),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
          this.initMasterCampaing()
        } else {
          window.displayAlert('#alert_container', resp.msg, 'error')
        }
        window.App.unblockUI(false)
      })
      .catch((e) => {
        window.App.unblockUI(false)
      })
  }
  show_add_preset_modal = () => {
    window.show_add_preset_model()
  }
  handle2ndSaveChangesBtn = (e) => {
    $('#saveChanges1').click()
  }
  spam_caller_id_check = (e, cid) => {
    window.App.blockUI()
    //alert($(this).attr("data-cid"));
    if (cid === undefined) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    if (cid.length < 10) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    const Aurl = this.props.baseUrl
    fetch(Aurl + '/index.php/Ajax/scrap_for_spam_cid/' + cid, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        window.$('#spam_check_cid').html(cid)
        window.$('#mdl_spmchk_cid').html(cid)
        window
          .$('#mdl_spmchk_score')
          .html(data.spam_score + '/' + data.spam_total)
        if (data.spam_score <= 2) {
          window.$('#mdl_spmchk_score').css('color', 'green')
        } else {
          window.$('#mdl_spmchk_score').css('color', 'red')
        }
        var spam_markers = 'None'
        for (var i = 0; i < data.spam_markers.length; i++) {
          if (i == 0)
            spam_markers =
              '<a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
          else
            spam_markers +=
              '<br /><a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
        }
        window.$('#mdl_spmchk_by').html(spam_markers)
        var spam_sources = ''
        for (var i = 0; i < data.spam_sources.length; i++) {
          if (i == 0)
            spam_sources =
              '<small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
          else
            spam_sources +=
              '<br /><small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
        }
        window.$('#mdl_spmchk_at').html(spam_sources)
        window.$('#mdl_spam_check_cid').modal('show')
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /*doTheDynamicWidget = () => {
    if (this.state.campaign_cid_type == "dynamic") {
      if (this.dt_dynamic_cids == null) {
        this.dt_dynamic_cids = window.dttable(
          "dt_dynamic_cids",
          this.props.baseUrl +
            "/index.php/Campaigns/Ajax?action=list_dynamic_cids&campaign_id=Master",
          ""
        );
        
        var Aurl = this.props.baseUrl;
        var dtable_dynamic_cids = this.dt_dynamic_cids;

        var blockUI = this.props.blockUI;
        $("#dt_dynamic_cids").on("click", ".btn-danger", function () {
          var childKey = $(this).attr("delete_id");
          blockUI(true);
          fetch(Aurl + "/index.php/Campaigns/DynamicCID/delete/" + childKey, {
            method: "GET",
            credentials: "include",
          })
            .then((blob) => {
              return blob.json();
            })
            .then((data) => {
              console.log(data);
              if (data.status) {
                window.displayAlert("#alert_container", data.msg, "success");
              } else {
                window.displayAlert("#alert_container", data.msg, "danger");
              }
              dtable_dynamic_cids.api().ajax.reload();
              blockUI(false);
            })
            .catch((e) => {
              blockUI(false);
            });
        });
        //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
      } else {
        this.dt_dynamic_cids.api().ajax.reload();
      }
    }
  };*/
  getmomentarr = () => {
    try {
      let vv = this.state.local_call_times
      if (vv.indexOf('-') <= 0) {
        return [null, null]
      }
      let a = vv.split('-')
      let ar = [moment(a[0], 'hh:mm:ss a'), moment(a[1], 'hh:mm:ss a')]
      console.log(ar[0])
      console.log(ar[1])
      return ar
    } catch (e) {
      return [null, null]
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Master Dial Settings
                    <small></small>
                  </h1>
                </div>
                <div className="page-toolbar  tabbable-line">
                  <ul className="nav nav-tabs pull-right">
                    <li className="active">
                      <NavLink to="/#tab_settings" data-toggle="tab">
                        {' '}
                        Settings{' '}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab_settings">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          {' '}
                                          Settings
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                    </div>
                                    <div className="portlet-body">
                                      <form
                                        id="form_submit_settings"
                                        onSubmit={this.handleSettingsSubmit}
                                      >
                                        <input
                                          type="hidden"
                                          id="action"
                                          name="action"
                                          defaultValue="update_campaign_settings"
                                        />
                                        <input
                                          type="hidden"
                                          id="campaign_id"
                                          name="campaign_id"
                                          defaultValue="Master"
                                        />
                                        <input
                                          type="hidden"
                                          id="campaign_name"
                                          name="campaign_name"
                                          defaultValue="Master"
                                        />
                                        <table className="table table-striped table-bordered table-advance table-hover">
                                          <tbody>
                                            {/*<tr >
																	<td>
																		Time Zone
																	</td>
																	<td>
																		<select className="bs-select form-control modifiable" id="tz_name" name="tz_name" 
																			value={this.state.sel_timezone} 
																			onChange={(e)=>{this.setState({sel_timezone:e.target.value})}} 
																		>																																			
																			
																			{this.state.timezones.map(timezone=><option key={timezone}>{timezone}</option>)}
																			
																		</select>
																	</td>
																</tr>*/}
                                            <tr>
                                              <td className="highlight">
                                                Caller ID Type{' '}
                                              </td>
                                              <td>
                                                <select
                                                  className="form-control modifiable"
                                                  id="campaign_cid_type"
                                                  name="campaign_cid_type"
                                                  value={
                                                    this.state.campaign_cid_type
                                                  }
                                                  onChange={(e) => {
                                                    this.setState(
                                                      {
                                                        campaign_cid_type:
                                                          e.target.value,
                                                      },
                                                      () => {
                                                        // switch (
                                                        //   this.state
                                                        //     .campaign_cid_type
                                                        // ) {
                                                        //   case 'dynamic':
                                                        //     this.doTheDynamicWidget()
                                                        //     break
                                                        //   case 'accid':
                                                        //     break
                                                        // }
                                                      }
                                                    )
                                                  }}
                                                >
                                                  {/*<option value="standard">
                                                    Standard
                                                  </option>*/}
                                                  <option value="dynamic">
                                                    Rotation
                                                  </option>
                                                  <option value="accid">
                                                    Area Code Switching
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            {/* <input
                                              type="hidden"
                                              name="campaign_cid_type"
                                              id="campaign_cid_type"
                                              value={
                                                this.state.campaign_cid_type
                                              }
                                            />*/}
                                            <input
                                              type="hidden"
                                              name="campaign_cid"
                                              id="campaign_cid"
                                              value={this.state.callerid}
                                            />
                                            {this.state.campaign_cid_type ===
                                              'accid' && (
                                              <tr>
                                                <td className="highlight">
                                                  Default Caller ID
                                                  {/*<a
                                                  title="Check Caller ID for potential SPAM flag."
                                                  class="fa fa-exclamation-triangle"
                                                  href="javascript:;"
                                                  onClick={(e) =>
                                                    this.spam_caller_id_check(
                                                      e,
                                                      this.state.callerid
                                                    )
                                                  }
                                                ></a>*/}
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control modifiable"
                                                    id="campaign_cid"
                                                    name="campaign_cid"
                                                    value={this.state.callerid}
                                                    onChange={(e) => {
                                                      this.setState({
                                                        callerid:
                                                          e.target.value,
                                                      })
                                                    }}
                                                    readOnly={
                                                      this.state
                                                        .campaign_cid_type ==
                                                      'dynamic'
                                                    }
                                                    required={
                                                      this.state
                                                        .campaign_cid_type ===
                                                      'accid'
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            )}
                                            <tr className="<?=$campaign_id=='Master'?'':'hidden' ?>">
                                              <td className="highlight">
                                                SMS Caller ID &nbsp;
                                                {/*<a
                                                  title="Check Caller ID for potential SPAM flag."
                                                  class="fa fa-exclamation-triangle"
                                                  href="javascript:;"
                                                  onClick={(e) =>
                                                    this.spam_caller_id_check(
                                                      e,
                                                      this.state.sms_cid
                                                    )
                                                  }
                                                ></a>*/}
                                                <br />
                                                <small>
                                                  SMS Caller ID (not to be used
                                                  as rotating caller IDs) You
                                                  must register for 10DLC to
                                                  obtain an SMS enabled caller
                                                  ID number which may take 7+
                                                  days. Please contact support
                                                  for help.
                                                </small>
                                              </td>
                                              <td>
                                                {' '}
                                                <input
                                                  type="text"
                                                  className="form-control modifiable"
                                                  id="sms_cid"
                                                  name="sms_cid"
                                                  value={this.state.sms_cid}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      sms_cid: e.target.value,
                                                    })
                                                  }}
                                                />{' '}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td className="highlight">
                                                Mode
                                              </td>
                                              <td>
                                                <select
                                                  className="bs-select form-control modifiable"
                                                  id="manual_preview_dial"
                                                  name="campaign_preview_mode"
                                                  value={
                                                    this.state
                                                      .manual_preview_dial
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      manual_preview_dial:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option value="PREVIEW_ONLY">
                                                    Preview Only
                                                  </option>
                                                  <option value="PREVIEW_AND_SKIP">
                                                    Preview And Skip
                                                  </option>
                                                  <option value="DISABLED">
                                                    Power Dialing
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Script
                                              </td>
                                              <td>
                                                <select
                                                  name="campaign_script"
                                                  id="campaign_script"
                                                  className="bs-select form-control modifiable"
                                                  value={
                                                    this.state.campaign_script
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      campaign_script:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option></option>
                                                  {this.state.scripts_list.map(
                                                    (script) => (
                                                      <option
                                                        value={script.key}
                                                      >
                                                        {script.value +
                                                          '(' +
                                                          script.key +
                                                          ')'}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                {/*<ScriptsDropDown
                                              baseUrl={this.props.baseUrl}
                                              script={
                                                this.state.campaign_script
                                              }
                                              className="bs-select form-control modifiable"
                                              id="campaign_script"
                                              name="campaign_script"
                                            ></ScriptsDropDown>*/}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Call Time</td>
                                              <td>
                                                <input
                                                  type="hidden"
                                                  name="local_call_time"
                                                  value={
                                                    this.state.local_call_times
                                                  }
                                                />
                                                <RangePicker
                                                  use12Hours={true}
                                                  onChange={(val, time) => {
                                                    this.setState({
                                                      local_call_times:
                                                        time.join('-'),
                                                    })
                                                  }}
                                                  className="form-control"
                                                  format={'hh:mm:ss a'}
                                                  value={this.getmomentarr()}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Recording
                                              </td>
                                              <td>
                                                <select
                                                  className="form-control modifiable"
                                                  id="campaign_recording"
                                                  name="campaign_recording"
                                                  value={
                                                    this.state
                                                      .campaign_recording
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      campaign_recording:
                                                        e.target.value,
                                                    })
                                                  }}
                                                  disabled={
                                                    this.state
                                                      .enable_recording == 'Yes'
                                                      ? ''
                                                      : 'disabled'
                                                  }
                                                >
                                                  <option value="NONE">
                                                    None
                                                  </option>
                                                  <option value="AGENTRECORD">
                                                    Agent Record
                                                  </option>
                                                  <option value="RECORDALL">
                                                    Record All
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Dial Timeout
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control modifiable"
                                                  id="dial_timeout"
                                                  name="dial_timeout"
                                                  value={
                                                    this.state.dial_timeout
                                                  }
                                                  onChange={(e) => {
                                                    const parsedN =
                                                      Number.parseInt(
                                                        e.target.value
                                                      )
                                                    if (Number.isNaN(parsedN)) {
                                                      window.displayAlert(
                                                        '#alert_container',
                                                        'Dial Time out must be a number',
                                                        'danger'
                                                      )
                                                    } else if (
                                                      Number.parseInt(parsedN) <
                                                      30
                                                    ) {
                                                      window.displayAlert(
                                                        '#alert_container',
                                                        'Dial Time out cannot be less than 30',
                                                        'danger'
                                                      )
                                                    } else {
                                                      window
                                                        .$('#alert_container')
                                                        .html('')
                                                    }
                                                    this.setState({
                                                      dial_timeout:
                                                        e.target.value,
                                                    })
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Auto Alt Dial
                                              </td>
                                              <td>
                                                <select
                                                  className="bs-select form-control modifiable"
                                                  id="campaign_recording"
                                                  name="auto_alt_dial"
                                                  value={
                                                    this.state.auto_alt_dial
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      auto_alt_dial:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option value="YES">
                                                    Yes
                                                  </option>
                                                  <option value="NO">No</option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="2">
                                                <Collapse>
                                                  <Collapse.Panel
                                                    header=""
                                                    className="pad"
                                                  >
                                                    <table className="table table-striped table-bordered table-advance table-hover">
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            Override Agent CID
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="agent_cid_override"
                                                              name="agent_cid_override"
                                                              value={
                                                                this.state
                                                                  .agent_cid_override
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  agent_cid_override:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="Y">
                                                                Yes
                                                              </option>
                                                              <option value="N">
                                                                No
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/*Adding dialing list order*/}
                                                        <tr>
                                                          <td>Dial Order</td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="campaign_dial_order"
                                                              name="campaign_dial_order"
                                                              value={
                                                                this.state
                                                                  .campaign_dial_order
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  campaign_dial_order:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="standard">
                                                                Newest Uncalled
                                                                Leads"
                                                                (standard)
                                                              </option>
                                                              <option value="list_priority">
                                                                List Priority
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/****** */}
                                                        {/********Adding option to select which cid to use when calling 3rd party in warm and 3way conference */}
                                                        <tr>
                                                          <td>
                                                            Conference CID
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="three_way_call_cid"
                                                              name="three_way_call_cid"
                                                              value={
                                                                this.state
                                                                  .three_way_call_cid
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  three_way_call_cid:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="CAMPAIGN">
                                                                Dolphin
                                                              </option>
                                                              <option value="CUSTOMER">
                                                                Customer
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/*********** */}
                                                      </tbody>
                                                    </table>
                                                  </Collapse.Panel>
                                                </Collapse>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="text-center"
                                                colSpan="2"
                                              >
                                                <button
                                                  type="submit"
                                                  className="btn green-jungle"
                                                  id="saveChanges1"
                                                >
                                                  Save Changes
                                                </button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          {' '}
                                          Dispositions
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                    </div>
                                    <div className="portlet-body">
                                      <table className="table table-striped table-bordered table-advance table-hover">
                                        <tbody>
                                          <tr>
                                            <td class="highlight">
                                              Standard Dispos
                                              <a
                                                data-toggle="modal"
                                                href="#modal_add_camp_dispo"
                                                id="add_camp_dispo"
                                              >
                                                <i class="fa fa-plus"></i>
                                              </a>
                                              {this.props.user_role !=
                                              'agent' ? (
                                                <span>
                                                  <br />{' '}
                                                  <small>
                                                    <Popconfirm
                                                      title=" Are you sure you want to reset List Reset Dispos?"
                                                      onConfirm={(e) => {
                                                        this.RestoreStandardDisposToDefault()
                                                      }}
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <a
                                                        style={{
                                                          marginLeft: '0px',
                                                        }}
                                                        id="a_restoreDefaults"
                                                      >
                                                        Restore Defaults
                                                      </a>
                                                    </Popconfirm>
                                                  </small>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </td>
                                            <td class="hidden-xs">
                                              <div class="accordion">
                                                <div class="checkbox-list">
                                                  {this.state.system_dispos.map(
                                                    (sd) => (
                                                      <label>
                                                        {sd.status +
                                                          ' - ' +
                                                          sd.status_name}
                                                        {sd.sale == 'Y' ? (
                                                          <b>&nbsp;(s)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {sd.DNC == 'Y' ? (
                                                          <b>&nbsp;(dnc)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {sd.scheduled_callback ==
                                                        'Y' ? (
                                                          <b>&nbsp;(cb)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {sd.is_system_dispo ==
                                                          '0' &&
                                                        sd.status !== 'DNC' ? (
                                                          <Popconfirm
                                                            title=" Are you sure you want to delete this disposition?"
                                                            onConfirm={(e) => {
                                                              this.handleRemoveStandardDispo(
                                                                e,
                                                                sd.status
                                                              )
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            className="pull-right"
                                                          >
                                                            <a id="remove_dial_dispo_id">
                                                              <i class="fa fa-minus"></i>
                                                            </a>
                                                          </Popconfirm>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </label>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="highlight">
                                              {/*Standard Dial Dispos{" "*/}
                                              List Reset Dispos
                                              <a
                                                data-toggle="modal"
                                                href="#modal_add_sys_dispo"
                                                id="add_dial_dispo"
                                              >
                                                <i class="fa fa-plus"></i>
                                              </a>
                                              {this.props.user_role !=
                                              'agent' ? (
                                                <span>
                                                  <br />{' '}
                                                  <small>
                                                    <Popconfirm
                                                      title=" Are you sure you want to reset List Reset Dispos?"
                                                      onConfirm={(e) => {
                                                        this.RestoreListResetDisposToDefault()
                                                      }}
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <a
                                                        style={{
                                                          marginLeft: '0px',
                                                        }}
                                                        id="a_restoreDefaults"
                                                      >
                                                        Restore Defaults
                                                      </a>
                                                    </Popconfirm>
                                                  </small>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </td>
                                            <td
                                              className="hidden-xs accordion"
                                              id="acc_dial_dispo"
                                            >
                                              <Collapse>
                                                <Collapse.Panel
                                                  header=""
                                                  className="pad"
                                                >
                                                  <div className="checkbox-list">
                                                    {this.state.dial_dispo.map(
                                                      (dd) => (
                                                        <label>
                                                          {dd}
                                                          <Popconfirm
                                                            title=" Are you sure you want to delete this disposition?"
                                                            onConfirm={(e) => {
                                                              this.handlestddialdisporemove(
                                                                e,
                                                                dd
                                                              )
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            className="pull-right"
                                                          >
                                                            <a id="remove_dial_dispo_id">
                                                              <i className="fa fa-minus"></i>
                                                            </a>
                                                          </Popconfirm>
                                                        </label>
                                                      )
                                                    )}
                                                  </div>
                                                </Collapse.Panel>
                                              </Collapse>
                                              {/*<div className="panel panel-default">
                                            <div className="panel-heading">
                                              <h4 className="panel-title">
                                                <NavLink
                                                  className="accordion-toggle accordion-toggle-styled collapsed"
                                                  data-toggle="collapse"
                                                  aria-expanded="false"
                                                  data-parent="#acc_dial_dispo"
                                                  to="/#acc_dial_dispo_cc"
                                                ></NavLink>
                                              </h4>
                                            </div>
                                            <div
                                              id="acc_dial_dispo_cc"
                                              className="panel-collapse collapse"
                                              aria-expanded="false"
                                            >
                                              <div className="panel-body">
                                                <div className="checkbox-list">
                                                  {this.state.dial_dispo.map(
                                                    (dd) => (
                                                      <label>
                                                        {dd}
                                                        <Popconfirm
                                                          title=" Are you sure you want to delete this disposition?"
                                                          onConfirm={(e) => {
                                                            this.handlestddialdisporemove(
                                                              e,
                                                              dd
                                                            );
                                                          }}
                                                          okText="Yes"
                                                          cancelText="No"
                                                          className="pull-right"
                                                        >
                                                          <a id="remove_dial_dispo_id">
                                                            <i className="fa fa-minus"></i>
                                                          </a>
                                                        </Popconfirm>
                                                      </label>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>*/}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="highlight">
                                              {/*Alt Dial Statuses{" "}*/}
                                              Alt Dial Dispos
                                              <NavLink
                                                data-toggle="modal"
                                                to="/#modal_add_alt_dial_dispo"
                                                id="add_alt_dial_dispo"
                                              >
                                                <i className="fa fa-plus"></i>
                                              </NavLink>
                                            </td>
                                            <td
                                              className="hidden-xs accordion"
                                              id="acc_alt_dial_dispo"
                                            >
                                              <Collapse>
                                                <Collapse.Panel
                                                  header=""
                                                  className="pad"
                                                >
                                                  <div className="checkbox-list">
                                                    {this.state.auto_alt_dial_statuses.map(
                                                      (aads) => (
                                                        <label>
                                                          {aads}
                                                          <Popconfirm
                                                            title=" Are you sure ?"
                                                            onConfirm={(e) => {
                                                              this.handleAutoAltRemoval(
                                                                e,
                                                                aads
                                                              )
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            className="pull-right"
                                                          >
                                                            <a>
                                                              <i className="fa fa-minus"></i>
                                                            </a>
                                                          </Popconfirm>
                                                        </label>
                                                      )
                                                    )}
                                                  </div>
                                                </Collapse.Panel>
                                              </Collapse>
                                              {/*<div className="panel panel-default">
                                            <div className="panel-heading">
                                              <h4 className="panel-title">
                                                <NavLink
                                                  className="accordion-toggle accordion-toggle-styled collapsed"
                                                  data-toggle="collapse"
                                                  aria-expanded="false"
                                                  data-parent="#acc_alt_dial_dispo"
                                                  to="/#acc_alt_dial_dispo_cc"
                                                ></NavLink>
                                              </h4>
                                            </div>
                                            <div
                                              id="acc_alt_dial_dispo_cc"
                                              className="panel-collapse collapse"
                                              aria-expanded="false"
                                            >
                                              <div className="panel-body">
                                                <div className="checkbox-list">
                                                  {this.state.auto_alt_dial_statuses.map(
                                                    (aads) => (
                                                      <label>
                                                        {aads}
                                                        <Popconfirm
                                                          title=" Are you sure ?"
                                                          onConfirm={(e) => {
                                                            this.handleAutoAltRemoval(
                                                              e,
                                                              aads
                                                            );
                                                          }}
                                                          okText="Yes"
                                                          cancelText="No"
                                                          className="pull-right"
                                                        >
                                                          <a>
                                                            <i className="fa fa-minus"></i>
                                                          </a>
                                                        </Popconfirm>
                                                      </label>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>*/}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="text-center"
                                              colSpan="2"
                                            >
                                              <button
                                                type="button"
                                                className="btn green-jungle"
                                                onClick={
                                                  this.handle2ndSaveChangesBtn
                                                }
                                              >
                                                Save Changes
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                {this.state.campaign_cid_type == 'dynamic' && (
                                  <DynamicCIDCampaignWidget
                                    baseUrl={this.props.baseUrl}
                                    campaign_id={this.state.campaign_id}
                                    blockUI={this.props.blockUI}
                                  />
                                )}
                                {this.state.campaign_cid_type === 'accid' && (
                                  <AreaCIDCampaignWidget
                                    baseUrl={this.props.baseUrl}
                                    campaign_id={this.state.campaign_id}
                                    blockUI={this.props.blockUI}
                                  />
                                )}
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          Transfer Presets
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                      <div className="actions">
                                        <a
                                          onClick={this.show_add_preset_modal}
                                          id="modal_AddPreset"
                                          className="btn btn-circle btn-default btn-sm"
                                        >
                                          <i className="fa fa-plus"></i> Add{' '}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="portlet-body">
                                      <table
                                        className="table table-striped table-bordered table-advance table-hover"
                                        id="dt_md_presets"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Preset Label</th>
                                            <th>Number</th>
                                            <th>DTMF</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody></tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade draggable-modal"
          id="modal_add_sys_dispo"
          tabindex="-1"
          arole="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Add a new List Reset dispo {/*Dialable Disposition*/}
                </h4>
              </div>
              <form
                onSubmit={this.handleAddDialableDispo}
                className="form-horizontal"
                id="form_adddialdispo"
              >
                <input type="hidden" name="campaign_id" defaultValue="Master" />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="control-label col-md-3">Status</label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="dispo_id"
                        >
                          {this.state.system_dispos.map((sd) => (
                            <option value={sd.status + ' - ' + sd.status_name}>
                              {sd.status_name}
                            </option>
                          ))}
                          {this.state.campaign_dispos.map((cd) => (
                            <option value={cd.status + ' - ' + cd.status_name}>
                              {cd.status_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade draggable-modal"
          id="modal_add_camp_dispo"
          tabindex="-1"
          arole="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Add new Standard Dial Disposition
                </h4>
              </div>
              <form
                id="form_add_campaign_dispo"
                className="form-horizontal"
                onSubmit={this.handleaddCampaignDispo}
                method="post"
              >
                <input type="hidden" name="campaign_id" defaultValue="Master" />
                <div className="modal-body">
                  <span id="mdl_csd_alert_container"></span>
                  <div className="form-body">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Dispo Code
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="5"
                          name="disp_abb"
                          placeholder="Enter text"
                        />
                        <span className="help-block">
                          {' '}
                          This is the abbreviation version of the status used
                          for APIs and reporting. Ex: &quot;NA&quot;{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Dispo Name
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="disp_name"
                          placeholder="Enter text"
                        />
                        <span className="help-block">
                          {' '}
                          This is the name of the disposition the agents will
                          see when making a selection. Ex: &quot;No Answer&quot;{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Status Type
                      </label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="disp_type"
                        >
                          <option value="selectable">Normal</option>
                          <option value="sale">Sale</option>
                          <option value="callbk">Call back</option>
                          <option value="DNC">Do Not Call</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade draggable-modal"
          id="modal_add_alt_dial_dispo"
          tabindex="-1"
          arole="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  ADD NEW AUTO ALT NUMBER DIALING STATUS
                </h4>
              </div>
              <form
                className="form-horizontal"
                onSubmit={this.handleaddautoaltdialdispo}
                id="form_addautoaltdialdispo"
              >
                <input type="hidden" name="campaign_id" defaultValue="Master" />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="control-label col-md-3">Status</label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="dispo_id"
                        >
                          {this.state.system_dispos.map((sd) => (
                            <option value={sd.status}>{sd.status_name}</option>
                          ))}
                          {this.state.campaign_dispos.map((cd) => (
                            <option value={cd.status}>{cd.status_name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*Transfer preset modal start */}
        <div
          id="modal_trxPreset"
          className="modal fade"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add New Transfer Preset</h4>
              </div>
              <form
                className="form-horizontal"
                id="form_addtransferpreset"
                onSubmit={this.handleaddtransferpreset}
                action="<?=base_url('index.php/Campaigns/Modify/'.(isset($campaign_id)?$campaign_id:''))?>"
                method="post"
              >
                <input
                  type="hidden"
                  id="mdl_add_preset_act"
                  name="action"
                  value="mdl_add_preset"
                />
                <input type="hidden" name="campaign_id" value="Master" />
                <input
                  type="hidden"
                  id="mdl_preset_id"
                  name="mdl_preset_id"
                  value=""
                />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Preset Name
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_name"
                          name="mdl_preset_name"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the label of preset.{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Preset Number
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_num"
                          name="mdl_preset_num"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the phone number associated to preset.{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">DTMF</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_dtmf"
                          name="mdl_preset_dtmf"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the DTMF number associated to preset.{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/**Transfer preset modal end */}
      </React.Fragment>
    )
  }
}

export default MasterDialSettings
