import React, { Component } from "react";
import $ from "jquery";
import { Checkbox } from "antd";
import ScriptsDropDown from "../Bricks/ScriptsDropDown";
import HeadTitle from "../HeadTitle";
class CampaignAdd extends Component {
  state = {
    useMasterDialDefaults: false,
    campaign_id: "",
    campaign_name: "",
    active: "",
    campaign_cid: "",
    sms_cid: "",
    powerdial_mode: "",
    script: "",
    campaign_rec: "",
    dial_timeout: "",
    script_ready: false,
  };
  users_campaign_values = null;
  masterdialdefault = null;
  constructor(props) {
    super(props);
    this.campaign_caller_id = React.createRef();
    this.dial_timeout = React.createRef();
    this.campaign_sms_caller_id = React.createRef();
  }
  componentDidMount() {
    window.load_select2(".bs-select");
    window.resizeHandle();
    //window.App.initUniform(); //checkboxes and radios
  }
  submitAddCampaign = (form) => {
    form.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Campaigns/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".campaignAddform").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
          window.App.unblockUI();
          //goto list edit page
          window.location.href =
            "#/admin/campaignedit/" + this.state.campaign_id + "/true";
          ////////////////////////////////////
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
          window.App.unblockUI();
        }
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  handleMasterDialDefaults = () => {
    let useMasterDialDefaults = !this.state.useMasterDialDefaults;
    console.log(useMasterDialDefaults);
    this.setState({ useMasterDialDefaults });
    if (useMasterDialDefaults) {
      /* this.users_campaign_values = JSON.stringify(
        $(".campaignAddform").serializeArray()
      );
      console.log(this.users_campaign_values);*/
      //fetch defaults from master and set here ;)
      fetch(this.props.baseUrl + "/index.php/Campaigns/Ajax", {
        credentials: "include",
        method: "post",
        body: new URLSearchParams({ action: "get_master_dial_settings" }),
      })
        .then((blob) => blob.json())
        .then((data) => {
          console.log(data);
          for (var x in data) {
            window.master_dial_defaults[x] = data[x];
          }
          /*//update all controls values
          this.campaign_caller_id.current.value = data.campaign_cid;
          this.dial_timeout.current.value = data.dial_timeout;
          //this.campaign_caller_id.current. = "readonly";
          ////////////////////////////////////*/
          window.use_master_dial_settings(!useMasterDialDefaults);
          this.setState({ campaign_cid: data.campaign_cid });
        });
      /////////////////////////////////////////////////////////
    } else {
      window.use_master_dial_settings(!useMasterDialDefaults);
      this.setState({
        campaign_cid: window.users_settings[0].val,
      });
    }
  };
  onScriptLoad = (value) => {
    window.$("#use_master_dial").click(); //by default master dial defaults be checked
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Add New Campaign" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row" id="sortable_portlets">
                        <div className="col-md-8 col-md-offset-2 column sortable">
                          <span id="alert_container"></span>
                          <div className="portlet light portlet-fit">
                            <div className="portlet-body">
                              <form
                                className="campaignAddform"
                                onSubmit={this.submitAddCampaign}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_campaign"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td className="highlight">
                                        Campaign ID
                                        <br />
                                        <small>Must be 1-5 digits</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="campaign_id"
                                          id="campaign_id"
                                          required
                                          minLength="1"
                                          maxLength="5"
                                          value={this.state.campaign_id}
                                          onChange={(e) => {
                                            this.setState({
                                              campaign_id: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        Campaign Name
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="campaign_name"
                                          id="campaign_name"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">Active</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="campaign_active"
                                          id="campaign_active"
                                        >
                                          <option value="Y">Y</option>
                                          <option value="N">N</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <input
                                          type="hidden"
                                          name="use_master_dial_defaults"
                                          value={
                                            this.state.useMasterDialDefaults
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <Checkbox
                                          onChange={
                                            this.handleMasterDialDefaults
                                          }
                                          checked={
                                            this.state.useMasterDialDefaults
                                          }
                                        >
                                          Use Master Dial Defaults
                                        </Checkbox>
                                        {/*<div className="checkbox-list">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="use_master_dial"
                                              name="use_master_dial_defaults"
                                              defaultValue="1"
                                              checked={
                                                this.state.useMasterDialDefaults
                                              }
                                              onClick={
                                                this.handleMasterDialDefaults
                                              }
                                            />
                                            Use Master Dial Defaults
                                          </label>
                                        </div>*/}
                                      </td>
                                    </tr>
                                    <input
                                      type="hidden"
                                      name="campaign_cid_type"
                                      value="dynamic"
                                    />
                                    <input
                                      type="hidden"
                                      name="campaign_cid"
                                      value=""
                                    />
                                    {/*<tr>
                                      <td className="highlight">
                                        Campaign Caller ID
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control modifiable"
                                          name="campaign_cid"
                                          id="campaign_cid"
                                          ref={this.campaign_caller_id}
                                          readOnly={
                                            this.state.useMasterDialDefaults
                                          }
                                        />
                                      </td>
                                        </tr>*/}

                                    <tr>
                                      <td className="highlight">
                                        SMS Caller ID
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control modifiable"
                                          name="sms_cid"
                                          id="sms_cid"
                                          ref={this.campaign_sms_caller_id}
                                          readOnly={
                                            this.state.useMasterDialDefaults
                                          }
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="highlight">Mode</td>
                                      <td>
                                        <select
                                          className="bs-select form-control modifiable"
                                          name="campaign_preview_mode"
                                          id="manual_preview_dial"
                                        >
                                          <option value="DISABLED">
                                            Power Dialing
                                          </option>
                                          <option value="PREVIEW_ONLY">
                                            Preview Only
                                          </option>
                                          <option value="PREVIEW_AND_SKIP">
                                            Preview and Skip
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">Script</td>
                                      <td>
                                        <ScriptsDropDown
                                          onLoad={this.onScriptLoad}
                                          class="modifiable selectcA"
                                          name="campaign_script"
                                          id="campaign_script"
                                          baseUrl={this.props.baseUrl}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        Campaign Recording
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control modifiable"
                                          name="campaign_recording"
                                          id="campaign_recording"
                                        >
                                          <option value="NONE"></option>
                                          <option value="AGENTRECORD">
                                            Agent Record
                                          </option>
                                          <option value="RECORDALL">
                                            Record All
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        Dial Timeout
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control modifiable"
                                          name="dial_timeout"
                                          id="dial_timeout"
                                          defaultValue="30"
                                          ref={this.dial_timeout}
                                          readOnly={
                                            this.state.useMasterDialDefaults
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colSpan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        {/*<button
                                          type="button"
                                          className="btn default"
                                        >
                                          Cancel
                                        </button>*/}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CampaignAdd;
