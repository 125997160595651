import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import CustomFields from "./CustomFields";
import SystemSettings from "./SystemSettings";
import WorkflowList from "./WorkflowList";
import RecordingList from "./RecordingList";
import TextTemplates from "./TextTemplates";
import HeadTitle from "../../HeadTitle";
class Configurations extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Configurations
                    <small></small>
                  </h1>
                </div>
                <div className="page-toolbar  tabbable-line">
                  <ul className="nav nav-tabs pull-right">
                    <li className="active">
                      <NavLink to="/#tab_settings" data-toggle="tab">
                        {" "}
                        System Settings{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/#tab_cf" data-toggle="tab">
                        {" "}
                        Custom Fields{" "}
                      </NavLink>
                    </li>
                    {/*<li>
                      <NavLink to="/#tab_af" data-toggle="tab">
                        {" "}
                        Audio Files{" "}
                      </NavLink>
                    </li>*/}
                    <li>
                      <NavLink to="/#tab_wf" data-toggle="tab">
                        {" "}
                        WorkFlows{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/#tab_txt" data-toggle="tab">
                        {" "}
                        Texting{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab_settings">
                          <SystemSettings
                            {...this.props}
                            baseUrl={this.props.baseUrl}
                            doPost={this.props.doPost}
                            blockUI={this.props.blockUI}
                            user_role={this.props.user_role}
                            settings={this.props.settings}
                          ></SystemSettings>
                        </div>
                        <div className="tab-pane " id="tab_cf">
                          <CustomFields
                            {...this.props}
                            baseUrl={this.props.baseUrl}
                            doPost={this.props.doPost}
                            blockUI={this.props.blockUI}
                            user_role={this.props.user_role}
                            settings={this.props.settings}
                          ></CustomFields>
                        </div>
                        <div className="tab-pane " id="tab_af">
                          <RecordingList
                            {...this.props}
                            baseUrl={this.props.baseUrl}
                            doPost={this.props.doPost}
                            blockUI={this.props.blockUI}
                            user_role={this.props.user_role}
                            settings={this.props.settings}
                          ></RecordingList>
                        </div>
                        <div className="tab-pane " id="tab_wf">
                          <WorkflowList
                            {...this.props}
                            baseUrl={this.props.baseUrl}
                            doPost={this.props.doPost}
                            blockUI={this.props.blockUI}
                            user_role={this.props.user_role}
                            settings={this.props.settings}
                          ></WorkflowList>
                        </div>
                        <div className="tab-pane " id="tab_txt">
                          <TextTemplates
                            {...this.props}
                            baseUrl={this.props.baseUrl}
                            doPost={this.props.doPost}
                            blockUI={this.props.blockUI}
                            user_role={this.props.user_role}
                            settings={this.props.settings}
                          ></TextTemplates>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Configurations;
