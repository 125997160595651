import React, { Component } from "react";
import $ from "jquery";
import ListDropDown from "../Bricks/ListDropDown";
import DolDropzone from "../Bricks/DolDropzone";
import UsersDropDown from "../Bricks/UsersDropDown";

import AddAcustomField from "../Bricks/AddAcustomField";
import { Steps, Radio } from "antd";
import HeadTitle from "../HeadTitle";
const { Step } = Steps;

class LeadImport extends Component {
  state = {
    customfields: [],
    lists: [],
    lead_statuses: [],
    last_call_dispos: [],
    current_step: 0,
    duplicate_check: "Y",
    file_added: false,
    list_time_zone_setting: "MANUAL",
  };

  componentDidMount() {
    window.resizeHandle();
    /*/load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/leadimport.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////*/
    //hiding step wizard here ;)
    $(
      ".ant-steps.ant-steps-horizontal.site-navigation-steps.ant-steps-small.ant-steps-label-horizontal.ant-steps-navigation"
    ).css("display", "none");
    ///////////////////////////////
    //get custom fields and map there ;)
    this.props.blockUI(true);

    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Ajax/getLeadStatuses", {
      credentials: "include",
      method: "get",
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        var lead_statuses = [];
        for (var i = 0; i < data.length; i++) {
          lead_statuses.push(data[i]);
        }
        this.setState({ lead_statuses });
      })
      .catch((err) => {});
    /////////////////////////////////////////////////////

    fetch(this.props.baseUrl + "/index.php/Leads/Import/", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        var cwfields = resp.sw_custom_fields;
        this.setState({
          customfields: cwfields,
          lists: resp.lists,
          last_call_dispos: resp.last_call_dispo,
        });

        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    ///////////////////////////////////
  }
  componentWillUnmount() {
    window.removeJS("jsdolphin/leadimport.js");
  }
  fileAdded = (added) => {
    this.setState({ file_added: added });
  };
  handleMapnSubmission = (form) => {
    form.preventDefault();

    //this.post_for_mapping(this.props.baseUrl + "/index.php/Leads/post_process");
    window.App.blockUI({
      boxed: true,
      message:
        "Loading.. Lists with over 10k leads may take up to 5 minutes to load.",
      overlayColor: "#91FF33",
    });
    //make sure list id and duplicate check is set to go
    if (
      document.getElementById("sel_list_id").value !== undefined &&
      document.getElementById("sel_list_id").value !== null
    )
      document.getElementById("list_id").value =
        document.getElementById("sel_list_id").value;
    /*document.getElementById("check_dup").value = document.getElementById(
      "sel_check_dup"
    ).value;*/
    ////////////////////////////////////
    fetch(this.props.baseUrl + "/index.php/Leads/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#frm_mapUp").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.App.unblockUI();
          //window.displayAlert("#alert_container", data.msg, "success");
          //set global msg thingy and display over redirect page
          window.global_alert_msg = data.msg;
          //goto list edit page
          window.location.href =
            "#/crm/leadsbylist/" + document.getElementById("sel_list_id").value;
          /*this.props.history.push(
            "/crm/leadsbylist/" + window.$("#list_id").val()
          );*/
          ////////////////////////////////////
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
        //reset the gui
        while (window.step > 1) {
          window.$("#back_btn").click();
        }
        //////////////////////////////////////////////////////////////
      })
      .catch((err) => {
        /*console.error("*********************************");
        err.text().then((errorMessage) => {
          console.error(errorMessage);
        });
        console.error("*********************************");*/
        window.displayAlert(
          "#alert_container",
          "Please make sure file is uploaded and fields mapped.",
          "danger"
        );
        window.App.unblockUI();
      });

    /*this.props.doPost(
      "/index.php/Leads/post_process",
      new URLSearchParams($("#frm_mapUp").serialize())
    );*/
  };
  post_for_mapping = (url) => {
    window.App.blockUI();
    window.$.ajax({
      url: url,
      method: "POST",
      data: $("#frm_mapUp").serialize(),
      timeout: 0,
      xhrFields: {
        withCredentials: true,
      },
      dataType: "json",
      success: function (data, ts, jqxhr) {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        //reset the gui
        /*while (window.step > 1) {
          window.$("#back_btn").click();
        }*/
        //redirect to dashboard with alert message

        //////////////////////////////////////////////////////////////
        window.App.unblockUI();
      },
      error: function (jq, rr, pp) {
        window.App.unblockUI();
        console.error("*********************************");
        console.error(jq);
        console.error(rr);
        console.error(pp);
        console.error("*********************************");
      },
    });
  };
  showAddCfieldMdl = (e) => {
    window.$("#mdl_add_cf").modal("show");
  };
  refreshCfields = () => {
    fetch(this.props.baseUrl + "/index.php/Leads/Import/", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        var cwfields = resp.sw_custom_fields;
        this.setState(
          {
            customfields: cwfields,
          },
          function () {
            const field_html = $($(".bs-map_field").get(0)).html();
            $(".cf_new_field").html(field_html);
            window.$("#mdl_add_cf").modal("hide");
          }
        );

        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  validateOnStep2 = () => {
    //check if they selected a list then stop it here
    if (document.getElementById("sel_list_id").value == "") {
      window.displayAlert(
        "#alert_container",
        "You must select a list to proceed.",
        "warning"
      );

      return false;
    } else if (this.state.file_added == false) {
      window.displayAlert(
        "#alert_container",
        "You must select a file to proceed.",
        "warning"
      );

      return false;
    } else {
      window.$("#alert_container").html("");
      return true;
    }
    ///////////////////////////////////////////////////
  };
  validateBeforeSubmit = (button) => {
    /****Check for errors */

    if (
      document.getElementById("sel_list_id").value !== undefined &&
      document.getElementById("sel_list_id").value !== null
    )
      document.getElementById("list_id").value =
        document.getElementById("sel_list_id").value;
    /*document.getElementById("check_dup").value = document.getElementById(
      "sel_check_dup"
    ).value;*/

    var err = "";
    if (document.getElementById("first_name").value == "") {
      err += "No first name mapped, are you sure you wish to proceed?<br />";
    }
    if (document.getElementById("last_name").value == "") {
      err += "No last name mapped, are you sure you wish to proceed?<br />";
    }
    if (document.getElementById("phone").value == "") {
      err += "No Phone mapped, are you sure you wish to proceed?<br />";
    }
    if (err != "") {
      window.bootbox.dialog({
        message: '<div class="alert alert-warning">' + err + "</div>",
        title: "Missing Fields",
        buttons: {
          add: {
            label: "Yes",
            className: "green",
            callback: function () {
              //$("#frm_mapUp").submit();
              document.getElementById("hacksubmitbtnn").click();
              //document.getElementById("frm_mapUp").submit();
              return;
            },
          },
          danger: {
            label: "No",
            className: "red",
            callback: function () {
              return;
            },
          },
        },
      });
    } else {
      document.getElementById("hacksubmitbtnn").click();
      //document.getElementById("frm_mapUp").submit();
    }
    /***************************** */
  };
  updateListsData = (list_id) => {
    //list_time_zone_setting
    //fetch and fill with info
    fetch(this.props.baseUrl + "/index.php/Lists/Modify/" + list_id, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          list_time_zone_setting: resp.msg.time_zone_setting,
        },()=>{
          if(this.list_time_zone_setting!=="MANUAL"){
            $("#gmt_offset_now").val($("#gmt_offset_now option:first").val());            
          }
        });
      });
    ///////////////////////////////
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Leads" subtitle="Import" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Import Leads{" "}
                              <small id="cur_process">
                                {/*this.state.current_step == 0
                                  ? "Step 1"
                                : "Map and Upload"*/}
                              </small>
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Steps
                                type="navigation"
                                size="small"
                                current={this.state.current_step}
                                onChange={(step) => {
                                  let valid = true;
                                  if (step == 1) {
                                    valid = this.validateOnStep2();
                                  }
                                  if (valid)
                                    this.setState({ current_step: step });
                                }}
                                className="site-navigation-steps"
                              >
                                <Step title="Upload File" />
                                <Step title="Verify and Submit" />
                              </Steps>
                            </div>

                            <div
                              className={
                                this.state.current_step == 0
                                  ? "col-md-8 col-md-offset-2 margin-top-10"
                                  : "hidden"
                              }
                              id="step1"
                            >
                              <table className="table table-striped table-bordered table-advance table-hover">
                                <tbody>
                                  <tr>
                                    <td>Select a List to Upload your Leads</td>
                                    <td>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <select
                                            size="5"
                                            id="sel_list_id"
                                            name="sel_list_name"
                                            className="form-control"
                                            data-show-subtext="true"
                                            onChange={(e) => {
                                              this.updateListsData(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option></option>
                                            {this.state.lists.map((e) => (
                                              <option value={e.ListId}>
                                                {e.ListName +
                                                  " (" +
                                                  e.ListId +
                                                  ")"}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ marginTop: "5px" }}
                                        >
                                          <a href="#/addnew/list">
                                            or Create a New List
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Check For Duplicates</td>
                                    <td>
                                      <Radio.Group
                                        onChange={(e) => {
                                          this.setState({
                                            duplicate_check: e.target.value,
                                          });
                                        }}
                                        value={this.state.duplicate_check}
                                      >
                                        <Radio value={"Y"}>Yes</Radio>
                                        <Radio value={"N"}>No</Radio>
                                      </Radio.Group>
                                      {/*<select
                                        id="sel_check_dup"
                                        className="bs-select form-control"
                                        data-show-subtext="true"
                                      >
                                        <option value="Y" data-subtext="">
                                          Yes
                                        </option>
                                        <option value="N" data-subtext="">
                                          No
                                        </option>
                                      </select>*/}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                      <div className="col-md-10 col-md-offset-1 margin-top-10">
                                        <div className="form-group form-md-line-input">
                                          <DolDropzone
                                            baseUrl={this.props.baseUrl}
                                            fileAdded={this.fileAdded}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div
                              className={
                                this.state.current_step == 1
                                  ? "col-md-8 col-md-offset-2 margin-top-10"
                                  : "hidden"
                              }
                              id="step2"
                            >
                              <form
                                onSubmit={this.handleMapnSubmission}
                                className="form-horizontal for-row-separated"
                                id="frm_mapUp"
                              >
                                <input
                                  type="hidden"
                                  name="list_id"
                                  id="list_id"
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="check_dup"
                                  id="check_dup"
                                  value={this.state.duplicate_check}
                                />
                                <input
                                  type="hidden"
                                  name="action"
                                  value="map_and_upload"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <thead>
                                    <tr>
                                      <th>
                                        <i className="fa fa-briefcase"></i>{" "}
                                        Dolphin Field{" "}
                                      </th>
                                      <th>
                                        <i className="fa fa-shopping-cart"></i>{" "}
                                        List Header{" "}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone"
                                          id="phone"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          First Name
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="first_name"
                                          id="first_name"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Last Name
                                        </label>
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="last_name"
                                          id="last_name"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Company
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="company"
                                          id="company"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Email
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="email"
                                          id="email"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Address
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="address1"
                                          id="address"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          City
                                        </label>
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="city"
                                          id="city"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          State
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="state"
                                          id="state"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Zip Code
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="zip_code"
                                          id="zip_code"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone2
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone2"
                                          id="phone2"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone3
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone3"
                                          id="phone3"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr
                                      className={
                                        this.state.list_time_zone_setting ==
                                        "MANUAL"
                                          ? ""
                                          : "hidden"
                                      }
                                    >
                                      <td className="highlight">
                                        <label className="control-label">
                                          GMT Offset
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="gmt_offset_now"
                                          id="gmt_offset_now"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Notes
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="comments"
                                          id="comments"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    {this.state.customfields.map((cf, icf) => (
                                      <tr key={cf.name}>
                                        <td className="highlight">
                                          <label className="control-label">
                                            {cf.display_name}
                                          </label>
                                        </td>
                                        <td>
                                          {" "}
                                          <select
                                            className={
                                              "bs-select form-control bs-map_field " +
                                              (this.state.customfields.length ==
                                              icf + 1
                                                ? "cf_new_field"
                                                : "")
                                            }
                                            name={cf.name}
                                            id={cf.name}
                                          >
                                            <option></option>
                                          </select>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td colspan="2">
                                        <a
                                          onClick={this.showAddCfieldMdl}
                                          href="javascript:;"
                                        >
                                          Add a new Custom Field
                                        </a>
                                      </td>
                                    </tr>
                                    <button
                                      className="btn green-jungle input-lg hidden"
                                      id="hacksubmitbtnn"
                                      type="submit"
                                    >
                                      submit{" "}
                                    </button>
                                  </tbody>
                                </table>
                                <div
                                  class="panel-group accordion"
                                  id="acc_map_imp"
                                >
                                  {/*}
                                <div className="panel panel-default">
                                  <div className="panel-heading">
                                    <h4 className="panel-title">
                                      <a
                                        className="accordion-toggle accordion-toggle-styled"
                                        data-toggle="collapse"
                                        data-parent="#acc_map_imp"
                                        href="#acc_map_imp_1"
                                      >
                                        {" "}
                                        Basic Fields{" "}
                                      </a>
                                    </h4>
                                  </div>
                                  <div
                                    id="acc_map_imp_1"
                                    className="panel-collapse in"
                                  >
                                    <div className="panel-body"></div>
                                  </div>
                                </div>
                                */}
                                  <div className="panel panel-default">
                                    <div className="panel-heading">
                                      <h4 className="panel-title">
                                        <a
                                          className="accordion-toggle accordion-toggle-styled collapsed"
                                          data-toggle="collapse"
                                          data-parent="#acc_map_imp_adv"
                                          href="#acc_map_imp_adv_2"
                                        >
                                          {" "}
                                          Advanced Fields{" "}
                                        </a>
                                      </h4>
                                    </div>
                                    <div
                                      id="acc_map_imp_adv_2"
                                      className="panel-collapse collapse"
                                    >
                                      <div className="panel-body">
                                        <table className="table table-striped table-bordered table-advance table-hover">
                                          <thead>
                                            <tr>
                                              <th>
                                                <i className="fa fa-briefcase"></i>{" "}
                                                Dolphin Field{" "}
                                              </th>
                                              <th>
                                                <i className="fa fa-shopping-cart"></i>{" "}
                                                List Header{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Source
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control bs-map_field"
                                                  name="source"
                                                  id="source"
                                                >
                                                  <option></option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Lead Status
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control"
                                                  name="lead_status"
                                                  id="lead_status"
                                                >
                                                  {this.state.lead_statuses.map(
                                                    (ls) => (
                                                      <option value={ls}>
                                                        {ls}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Last Call Dispo
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control"
                                                  name="last_call_dispo"
                                                  id="last_call_dispo"
                                                >
                                                  <option></option>
                                                  {this.state.last_call_dispos.map(
                                                    (lcd) => (
                                                      <option
                                                        value={lcd.status}
                                                        selected={
                                                          lcd.status == "N"
                                                        }
                                                      >
                                                        {lcd.status_name +
                                                          " (" +
                                                          lcd.status +
                                                          ")"}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Assigned To
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                {/*<select className="bs-select form-control" name="assigned_to" id="assigned_to">
																	
</select>*/}{" "}
                                                <UsersDropDown
                                                  name="assigned_to"
                                                  id="assigned_to"
                                                  baseUrl={this.props.baseUrl}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <div className="col-md-8 col-md-offset-2 margin-top-10  margin-bottom-10">
                              <div className="col-md-12 text-center">
                                <button
                                  className={
                                    this.state.current_step == 1
                                      ? "btn red-intense input-lg "
                                      : "hidden"
                                  }
                                  id="back_btn"
                                  type="button"
                                  onClick={(e) => {
                                    this.setState({
                                      current_step: this.state.current_step - 1,
                                    });
                                  }}
                                  style={{ marginRight: "4px" }}
                                >
                                  Previous
                                </button>
                                {this.state.current_step == 0 ? (
                                  <button
                                    className="btn green-jungle input-lg"
                                    id="next_btn"
                                    type="button"
                                    onClick={(e) => {
                                      let valid = true;
                                      if (this.state.current_step == 0) {
                                        valid = this.validateOnStep2();
                                      }
                                      if (valid)
                                        this.setState({
                                          current_step:
                                            this.state.current_step + 1,
                                        });
                                    }}
                                  >
                                    Next{" "}
                                  </button>
                                ) : (
                                  <button
                                    className="btn green-jungle input-lg"
                                    id="back_btn"
                                    type="submit"
                                    onClick={this.validateBeforeSubmit}
                                  >
                                    Upload
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*<div className="row">
                            <div className="mt-element-step">
                              <div className="row step-default">
                                <div
                                  className="col-md-4 bg-grey mt-step-col active"
                                  id="bar_step1"
                                >
                                  <div className="mt-step-number bg-white font-grey">
                                    1
                                  </div>
                                  <div className="mt-step-title uppercase font-grey-cascade">
                                    List
                                  </div>
                                  <div className="mt-step-content font-grey-cascade">
                                    Choose A List
                                  </div>
                                </div>
                                <div
                                  className="col-md-4 bg-grey mt-step-col"
                                  id="bar_step2"
                                >
                                  <div className="mt-step-number bg-white font-grey">
                                    2
                                  </div>
                                  <div className="mt-step-title uppercase font-grey-cascade">
                                    Redundancy Check
                                  </div>
                                  <div className="mt-step-content font-grey-cascade">
                                    Check for Duplicates
                                  </div>
                                </div>
                                <div
                                  className="col-md-4 bg-grey mt-step-col "
                                  id="bar_step3"
                                >
                                  <div className="mt-step-number bg-white font-grey">
                                    3
                                  </div>
                                  <div className="mt-step-title uppercase font-grey-cascade">
                                    Submit File
                                  </div>
                                  <div className="mt-step-content font-grey-cascade">
                                    Drag and Drop or Browse
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row margin-top-30 form-horizontal"
                            id="data_step1"
                          >
                            <div className="row">
                              <div className="form-group">
                                <label className="control-label col-md-3 col-md-offset-2">
                                  Select a List to add leads to:
                                </label>
                                <div className="col-md-4">
                                  <div className="row">
                                    <select
                                      id="sel_list_id"
                                      name="sel_list_name"
                                      className="form-control"
                                      data-show-subtext="true"
                                    >
                                      <option></option>
                                      {this.state.lists.map((e) => (
                                        <option value={e.ListId}>
                                          {e.ListName + " (" + e.ListId + ")"}
                                        </option>
                                      ))}
                                    </select>
                                    {/*<ListDropDown
                                    id="sel_list_id"
                                    name="sel_list_name"
                                    baseUrl={this.props.baseUrl}
                                  />*/
                          /*}
                                  </div>
                                  <div
                                    style={{
                                      paddingLeft: "1px",
                                      paddingTop: "5px",
                                    }}
                                    className="row"
                                  >
                                    <a href="#/addnew/list">
                                      or Create a New List
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="row margin-top-30 hidden"
                            id="data_step2"
                          >
                            <div className="form-group">
                              <label className="control-label col-md-3 col-md-offset-2">
                                Check for duplicates:
                              </label>
                              <div className="col-md-4">
                                <select
                                  id="sel_check_dup"
                                  className="bs-select form-control"
                                  data-show-subtext="true"
                                >
                                  <option value="Y" data-subtext="">
                                    Yes
                                  </option>
                                  <option value="N" data-subtext="">
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-10 col-md-offset-1 margin-top-10 hidden"
                            id="data_step3"
                          >
                            <div className="form-group form-md-line-input">
                              {/*<form action={this.props.baseUrl+"/index.php/Leads/Ajax"} className="margin-top-10 dropzone dropzone-file-area" id="my-dropzone" style={{width: '500px', marginTop: '50px'}}>
												<div className="dz-message needsclick">
													<h3 className="sbold">Drop file or click to browse.</h3>
													<p> File must be in .CSV format to be uploaded properly. </p>												
												</div>
												<input type="hidden" name="action" value="add_file" />
	</form>*/
                          /*}
                              <DolDropzone baseUrl={this.props.baseUrl} />
                            </div>
                          </div>

                          <div
                            className="row margin-top-30 hidden"
                            id="data_step3_1"
                          >
                            <div className="col-md-8 col-md-offset-2">
                              <form
                                onSubmit={this.handleMapnSubmission}
                                className="form-horizontal for-row-separated"
                                id="frm_mapUp"
                              >
                                <input
                                  type="hidden"
                                  name="list_id"
                                  id="list_id"
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="check_dup"
                                  id="check_dup"
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="action"
                                  value="map_and_upload"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <thead>
                                    <tr>
                                      <th>
                                        <i className="fa fa-briefcase"></i>{" "}
                                        Dolphin Field{" "}
                                      </th>
                                      <th>
                                        <i className="fa fa-shopping-cart"></i>{" "}
                                        List Header{" "}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone"
                                          id="phone"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          First Name
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="first_name"
                                          id="first_name"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Last Name
                                        </label>
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="last_name"
                                          id="last_name"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Company
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="company"
                                          id="company"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Email
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="email"
                                          id="email"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Address
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="address1"
                                          id="address"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          City
                                        </label>
                                      </td>
                                      <td>
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="city"
                                          id="city"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          State
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="state"
                                          id="state"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Zip Code
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="zip_code"
                                          id="zip_code"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone2
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone2"
                                          id="phone2"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Phone3
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="phone3"
                                          id="phone3"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="highlight">
                                        <label className="control-label">
                                          Notes
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="bs-select form-control bs-map_field"
                                          name="comments"
                                          id="comments"
                                        >
                                          <option></option>
                                        </select>
                                      </td>
                                    </tr>

                                    {this.state.customfields.map((cf, icf) => (
                                      <tr key={cf.name}>
                                        <td className="highlight">
                                          <label className="control-label">
                                            {cf.display_name}
                                          </label>
                                        </td>
                                        <td>
                                          {" "}
                                          <select
                                            className={
                                              "bs-select form-control bs-map_field " +
                                              (this.state.customfields.length ==
                                              icf + 1
                                                ? "cf_new_field"
                                                : "")
                                            }
                                            name={cf.name}
                                            id={cf.name}
                                          >
                                            <option></option>
                                          </select>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td colspan="2">
                                        <a
                                          onClick={this.showAddCfieldMdl}
                                          href="javascript:;"
                                        >
                                          Add a new Custom Field
                                        </a>
                                      </td>
                                    </tr>
                                    <button
                                      className="btn green-jungle input-lg hidden"
                                      id="hacksubmitbtnn"
                                      type="submit"
                                    >
                                      submit{" "}
                                    </button>
                                  </tbody>
                                </table>
                                <div
                                  class="panel-group accordion"
                                  id="acc_map_imp"
                                >
                                  {/*}
                                <div className="panel panel-default">
                                  <div className="panel-heading">
                                    <h4 className="panel-title">
                                      <a
                                        className="accordion-toggle accordion-toggle-styled"
                                        data-toggle="collapse"
                                        data-parent="#acc_map_imp"
                                        href="#acc_map_imp_1"
                                      >
                                        {" "}
                                        Basic Fields{" "}
                                      </a>
                                    </h4>
                                  </div>
                                  <div
                                    id="acc_map_imp_1"
                                    className="panel-collapse in"
                                  >
                                    <div className="panel-body"></div>
                                  </div>
                                </div>
                                */
                          /*}
                                  <div className="panel panel-default">
                                    <div className="panel-heading">
                                      <h4 className="panel-title">
                                        <a
                                          className="accordion-toggle accordion-toggle-styled collapsed"
                                          data-toggle="collapse"
                                          data-parent="#acc_map_imp_adv"
                                          href="#acc_map_imp_adv_2"
                                        >
                                          {" "}
                                          Advanced Fields{" "}
                                        </a>
                                      </h4>
                                    </div>
                                    <div
                                      id="acc_map_imp_adv_2"
                                      className="panel-collapse collapse"
                                    >
                                      <div className="panel-body">
                                        <table className="table table-striped table-bordered table-advance table-hover">
                                          <thead>
                                            <tr>
                                              <th>
                                                <i className="fa fa-briefcase"></i>{" "}
                                                Dolphin Field{" "}
                                              </th>
                                              <th>
                                                <i className="fa fa-shopping-cart"></i>{" "}
                                                List Header{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Source
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control bs-map_field"
                                                  name="source"
                                                  id="source"
                                                >
                                                  <option></option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Lead Status
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control"
                                                  name="lead_status"
                                                  id="lead_status"
                                                >
                                                  {this.state.lead_statuses.map(
                                                    (ls) => (
                                                      <option value={ls}>
                                                        {ls}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Last Call Dispo
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                <select
                                                  className="bs-select form-control"
                                                  name="last_call_dispo"
                                                  id="last_call_dispo"
                                                >
                                                  <option></option>
                                                  {this.state.last_call_dispos.map(
                                                    (lcd) => (
                                                      <option
                                                        value={lcd.status}
                                                        selected={
                                                          lcd.status == "N"
                                                        }
                                                      >
                                                        {lcd.status_name +
                                                          " (" +
                                                          lcd.status +
                                                          ")"}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                <label className="control-label">
                                                  Assigned To
                                                </label>
                                              </td>
                                              <td>
                                                {" "}
                                                {/*<select className="bs-select form-control" name="assigned_to" id="assigned_to">
																	
</select>*/
                          /*}{" "}
                                                <UsersDropDown
                                                  name="assigned_to"
                                                  id="assigned_to"
                                                  baseUrl={this.props.baseUrl}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div className="row margin-top-30">
                            <div className="col-md-12 text-center">
                              <button
                                className="btn green-jungle input-lg"
                                id="next_btn"
                                type="button"
                                style={{ marginRight: "4px" }}
                              >
                                Next{" "}
                              </button>
                              <button
                                className="btn red-intense input-lg "
                                id="back_btn"
                                type="button"
                              >
                                Back
                              </button>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*****************Modal to add custom field */}
        <div
          id="mdl_add_cf"
          className="modal fade bs-modal-lg"
          tabIndex="-1"
          data-width="760"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add custom field</h4>
              </div>
              <div className="modal-body">
                <AddAcustomField
                  baseUrl={this.props.baseUrl}
                  onNewCfieldAdd={this.refreshCfields}
                ></AddAcustomField>
              </div>
            </div>
          </div>
        </div>
        {/******************************** */}
      </React.Fragment>
    );
  }
}

export default LeadImport;
