import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class TextTemplateEdit extends Component {
  state = {
    lead_fields: [],
    appender: "",
    template: { id: 0, title: "", body: "" },
  };
  componentDidMount() {
    this.initCom();
  }
  initCom = () => {
    window.resizeHandle();
    fetch(this.props.baseUrl + "/index.php/Script/list_lead_fields_as_array", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          this.setState({ lead_fields: resp.msg }, function () {});
        }
        // $("#field_name").html(resp);
      });
    //fetch detail of this id template
    fetch(
      this.props.baseUrl +
        "/index.php/Texting/View/" +
        this.props.match.params.template_id,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          template: {
            id: resp.msg.id,
            title: resp.msg.name,
            body: resp.msg.body,
          },
        });
      });
    ////////////////////////////////////
  };
  appendField = (e) => {
    let el = document.getElementById("tbody");
    const start = el.selectionStart;
    const end = el.selectionEnd;
    let temp = this.state.template.body;
    this.setState({
      template: {
        ...this.state.template,
        body: temp.slice(0, start) + this.state.appender + temp.slice(end),
      },
    });
  };
  handleSubmitEdit = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Texting/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_edit_texttemplate").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.global_alert_msg = data.msg;
          this.props.history.push("/admin/texttemplates/view/true");
          //window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Text Template" subtitle="edit" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_edit_texttemplate"
                                onSubmit={this.handleSubmitEdit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="edit_text_template"
                                />
                                <input
                                  type="hidden"
                                  name="id"
                                  value={this.state.template.id}
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Title</td>
                                      <td>
                                        <input
                                          name="name"
                                          type="text"
                                          className="form-control"
                                          placeholder="Type Title"
                                          required
                                          value={this.state.template.title}
                                          onChange={(e) => {
                                            this.setState({
                                              template: {
                                                ...this.state.template,
                                                title: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Insert Variable</td>
                                      <td>
                                        <div className="input-group">
                                          <select
                                            id="field_name"
                                            className="bs-select form-control"
                                            value={this.state.appender}
                                            onChange={(e) => {
                                              this.setState({
                                                appender: e.target.value,
                                              });
                                            }}
                                          >
                                            <option></option>
                                            {this.state.lead_fields.map(
                                              (field) => (
                                                <option
                                                  key={field.key}
                                                  value={
                                                    "{{" + field.key + "}}"
                                                  }
                                                >
                                                  {field.val}
                                                </option>
                                              )
                                            )}
                                          </select>

                                          <span className="input-group-btn">
                                            <button
                                              id="AppendFieldName"
                                              className="btn green-jungle"
                                              type="button"
                                              onClick={this.appendField}
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{" "}
                                              append
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Text Body</td>
                                      <td>
                                        <textarea
                                          name="body"
                                          tabindex="1"
                                          className="form-control"
                                          id="tbody"
                                          value={this.state.template.body}
                                          onChange={(e) => {
                                            this.setState({
                                              template: {
                                                ...this.state.template,
                                                body: e.target.value,
                                              },
                                            });
                                          }}
                                        >
                                          {this.state.template.body}
                                        </textarea>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TextTemplateEdit;
