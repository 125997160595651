import React, { Component } from "react";
import AudioElements from "./Bricks/AudioElements";
class WebphoneBar extends Component {
  state = { s_pam: "" };
  MySIP = null;
  MyUA = null;
  MyActiveInSession = null;
  componentDidMount() {
    this.MySIP = window.SIP;
    /*alert(
      this.MySIP.UserAgent.makeURI(
        "sip:1523698745@dcdev6002.zoomcalls.com:7443"
      )
    );*/
    //this is where we will have the dialing initialized
    window.openPanel(); //init openpanel we initialize it here because it resides here ;)
    window.resizeHandle();

    //applying pam change event 'cause actual change isn't working
    window.$("#sendPAMdd").on("change", function () {
      window.SendPam(this);
    });
    //applying keypad here
    window.$("#keypad_sel1").keypad({
      showOn: "button",
      keypadOnly: false,
      useThemeRoller: true,
      layout: [
        "123" + window.$.keypad.CLOSE,
        "456" + window.$.keypad.CLEAR,
        "789",
        "*0#",
      ],
      onKeypress: function (key) {
        var match = key.match(/[0-9*#]/g);
        key = match[0]; //key.replace(/\s+/g, "");
        if ((key >= 0 && key <= 9) || key == "#" || key == "*")
          window.$("#keypad_sel").val(window.$("#keypad_sel").val() + key);
        if (window.webphone.wp_state()) {
          window.webphone.wp_dtmf(key);
        } else {
          window.$("#keypad_sel").val("");
        }
      },
    });
  }

  wp_transfer_hangup_3rd_party = () => {
    window.webphone.wp_transfer("hangup_3rd_party");
  };
  newCallClick = () => {
    //window.$("#wp_newCall").click();
    try {
      window.webphone.wp_hangup();
    } catch (e) {}
  };
  ToggleTransferControls = () => {
    window.ToggleTransferControls(true);
  };
  pv_dialedLead = () => {
    window.pv_dialLead();
  };
  pv_skipLead = () => {
    window.pv_skipLead();
  };
  dispoCall = () => {
    window.select_dispo();
  };
  /*sendPAM = sel => {
    this.setState({ s_pam: sel.target.value });
    window.SendPam(sel.target.value);
  };*/
  render() {
    return (
      <div id="webphones_bar" className="webphone_bar_bottom hidden">
        <AudioElements />
        <a
          id="qlv_panel"
          data-panelid="panelname"
          data-pos="left"
          className="op-tab op-block1 floatleft hidden"
        >
          Lead View
        </a>
        <div className="container ">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="info pull-left hidden" id="phone_incall_anim">
                <div className="live_call_anim">
                  <div className="spinner-eff spinner-eff-3">
                    <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div>
                    <div className="circle circle-3"></div>
                  </div>
                </div>
              </div>
              <div className="info pull-left hidden" id="phone_connecting_anim">
                <div className="loading_anim">
                  <div className="loader loader-7">
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    <div className="line line3"></div>
                  </div>
                </div>
              </div>
              <div
                className="info pull-left font-grey-gallery"
                id="phone_status_msg"
              ></div>
              <div
                className="call_timer pull-left"
                id="call_timer"
                style={{ paddingTop: "5px" }}
              ></div>
            </div>
            <div className="col-lg-7 col-md-8">
              <div className="row">
                <div className="pull-left hidden" id="call_control_btns">
                  {/*<audio id="audio_remote"></audio>
                  <audio
                    id="auto_sounds"
                    src="<?=base_url('assets/sounds/ringring.mp3')?>"
                  ></audio>
                  <audio
                    id="audio_callee_busy"
                    src="<?=base_url('assets/sounds/line_busy.mp3')?>"
                  ></audio>
                  <audio
                    id="audio_callee_DCorNIS"
                    src="<?=base_url('assets/sounds/number_disconnected.mp3')?>"
    ></audio><a
                    id="qlv_panel"
                    data-panelid="panelname"
                    data-pos="left"
                    className="op-tab op-block1 floatleft hidden"
                  >
                    Lead View
                  </a>*/}

                  <input
                    type="text"
                    className="form-control hidden"
                    name="keypad"
                    title="KeyPad"
                    id="keypad_sel1"
                  />
                  <button
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                    className="btn grey-cascade call_2ndry_btns"
                    id="wp_Mute"
                    title="Mute"
                  >
                    <i className="fa fa-2x fa-microphone-slash font-grey-gallery"></i>
                  </button>
                  <button
                    style={{ marginRight: "2px" }}
                    className="btn grey-cascade call_2ndry_btns"
                    id="wp_Hold"
                    title="Hold/UnHold"
                  >
                    <i className="fa fa-2x fa-pause font-grey-gallery"></i>
                  </button>
                  <button
                    style={{ marginRight: "2px" }}
                    className="btn grey-cascade call_2ndry_btns"
                    onClick={this.ToggleTransferControls}
                    id="wp_Trx"
                    title="ToggleTransfer Controls"
                  >
                    <i className="fa fa-2x icon-call-out font-grey-gallery"></i>
                  </button>
                  <button
                    className="btn grey-cascade call_2ndry_btns hidden"
                    id="wp_Record"
                    title="Record"
                  >
                    <i className="fa fa-2x fa-circle font-red-thunderbird"></i>
                  </button>

                  <span style={{ display: "none" }} id="trx_ctrl_warm">
                    <div
                      style={{
                        display: "inline",
                        width: "90px",
                        height: "60px",
                        marginTop: "0px",
                        marginLeft: "2px",
                      }}
                      className="pull-right"
                    >
                      <button
                        className="btn btn-circle yellow call_ingrp_button"
                        onClick={this.wp_transfer_hangup_3rd_party}
                        title="Transfer customer to 3rd party"
                      >
                        <i
                          className="fa fa-eye-slash transfer_icon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </span>
                </div>

                <div
                  className="pull-right text-center hidden dialable_leads_count"
                  id="wp_disp_dialableLeads"
                >
                  <b id="num_dialableLeads">0</b>
                </div>

                <div className="pull-right">
                  <button
                    className="btn hidden"
                    title="End Dialing Session"
                    id="wp_s_powDialer"
                    style={{
                      backgroundImage: 'url("assets/img/stopsign50.png")',
                      backgroundColor: "transparent",
                      marginLeft: "8px",
                    }}
                  ></button>
                </div>
                <div className="input-group pull-right">
                  <input
                    type="text"
                    className="form-control call_ingrp_input hidden"
                  />

                  <button
                    className="btn red-pink  call_ingrp_button"
                    title="Hang Up"
                    id="wp_hangupCall"
                    onClick={this.newCallClick}
                    type="button"
                    style={{
                      backgroundColor: "#B22222",
                      borderColor: "#B22222",
                    }}
                  >
                    <i className="fa fa-phone"></i>
                  </button>
                </div>
                <div
                  className="pull-right hidden"
                  id="btn_PAM"
                  style={{
                    paddingTop: "12px",
                    marginLeft: "5px",
                    marginRight: "9px",
                  }}
                >
                  <select
                    className="form-control input-small"
                    data-style="white"
                    id="sendPAMdd"
                  >
                    <option value="">Drop Voicemail</option>
                    <option>Pam - 1</option>
                    <option>Pam - 2</option>
                    <option>Pam - 3</option>
                    <option>Pam - 4</option>
                    <option>Pam - 5</option>
                  </select>
                </div>
                <div
                  className="input-group pull-left hidden"
                  id="wp_PreviewCallDiv"
                >
                  <button
                    id="wp_dialLead"
                    className="btn green-jungle hidden"
                    onClick={this.pv_dialedLead}
                  >
                    Dial Lead
                  </button>
                  <button
                    id="wp_skipLead"
                    className="btn red hidden"
                    onClick={this.pv_skipLead}
                  >
                    Skip Lead
                  </button>
                </div>
                <span id="wp_DispoDIv" className="hidden">
                  <div
                    className="webphone_bar_btn"
                    style={{
                      marginLeft: "2px",
                      marginRight: "4px",
                      marginTop: "5px",
                      maxWidth: "200px",
                    }}
                  >
                    <select
                      className="form-control"
                      onChange={this.dispoCall}
                      style={{ height: "50px" }}
                      id="wp_DispoSelect"
                    ></select>
                    <span className="input-group-btn hidden">
                      <button
                        className="btn green"
                        onClick={this.dispoCall}
                        style={{ width: "50px", height: "50px" }}
                        title="Submit"
                        type="button"
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebphoneBar;
