import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import $ from 'jquery'
import { Radio, InputNumber, Tooltip, Button } from 'antd'
import { MinusOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons'

const booleanopts = ['Yes', 'No']
export default class ClientEdit extends Component {
  state = {
    client_id: 0,
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    url_prefix: '',
    fullname: '',
    tenant_id: '',
    tenant_url: '',
    tenant_uuid: '',
    tenant_apikey: '',
    tenant_ftpuser: '',
    tenant_ftppass: '',
    tenant_ftpurl: '',
    tenant_ftpport: '',
    url: '',
    num_seats: '',
    enable_rec: 'No',
    international_dialing: 0,
    sms_provider: 'skyetel',
    admin_email: '',
    sms_ability: 'inactive',
    sms_credits: 0,
    sms_credits_db: 0,
    istrial: '',
    account_type: '',
    account_status: '',
    agreement_status: '',
    can_add_cid: 0,
    crt_at: '',
    crt_by: '',
    updated_by: '',
    updated_at: '',
    tenants: [],
    credits_sms_mod: 0,
  }

  componentDidMount() {
    //load its specific js file ;)
    const script = document.createElement('script')
    script.src = 'jsdolphin/clientedit.js'
    script.async = true
    document.body.appendChild(script)
    ////////////////////////////////////
    this.initClient()
  }
  initClient = () => {
    var cid = this.props.match.params.client_id
    this.setState({ client_id: cid })
    ////////////////////////////////////
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/ClientEdit/' + cid, {
      method: 'GET',
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        console.log(data)
        data = data.msg
        console.error(data)
        this.setState({
          id: data.id,
          email: data.email,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          url_prefix: data.country,
          fullname: data.fullname,
          tenant_id: data.tenant_id,
          tenant_url: data.tenant_url,
          tenant_uuid: data.tenant_uuid,
          tenant_apikey: data.tenant_apikey,
          tenant_ftpuser: data.ftp_user,
          tenant_ftppass: data.tenant_ftppass,
          tenant_ftpurl: data.ftpurl,
          tenant_ftpport: data.ftpport,
          url: data.url,
          num_seats: data.num_seats,
          enable_rec: data.enable_recording,
          international_dialing: data.international_dialing,
          sms_provider: data.sms_provider,
          admin_email: data.admin_email,
          sms_ability: data.sms_ability,
          sms_credits: data.sms_credits,
          sms_credits_db: data.sms_credits,
          istrial: data.istrial,
          account_type: data.account_type,
          account_status: data.account_status,
          agreement_status: data.agreement_status,
          can_add_cid: data?.can_add_cid,
          crt_at: data.crt_at,
          crt_by: data.crt_by,
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          tenants: data.tenants,
        })
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleClientEditSubmit = (e) => {
    e.preventDefault()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_editClient').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  render() {
    return (
      <React.Fragment>
        {/*<!-- BEGIN CONTAINER -->*/}
        <div class="page-container">
          {/*<!-- BEGIN CONTENT -->*/}
          <div class="page-content-wrapper">
            {/*<!-- BEGIN CONTENT BODY -->*/}
            {/*<!-- BEGIN PAGE HEAD-->*/}
            <div class="page-head">
              <div class="container">
                {/*<!-- BEGIN PAGE TITLE -->*/}
                <div class="page-title">
                  <h1>
                    Client
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Modify</small>
                  </h1>
                </div>
                {/*<!-- END PAGE TITLE -->*/}
              </div>
            </div>
            {/*<!-- END PAGE HEAD-->*/}
            {/*<!-- BEGIN PAGE CONTENT BODY -->*/}
            <div class="page-content">
              <div class="container">
                {/*<!-- BEGIN PAGE CONTENT INNER -->*/}
                <div class="page-content-inner">
                  <div class="row">
                    <div class="col-md-12">
                      <span id="alert_container"></span>
                      {/*<?=$this->session->flashdata('msg')?>*/}
                      <div class="portlet light portlet-fit ">
                        {/*<!--<div class="portlet-title">
									<div class="caption">
										<i class=" icon-layers font-green"></i>
										<span class="caption-subject font-green bold uppercase">Lists <small id="cur_process">view</small></span>
									</div>
									
								</div>-->*/}
                        <div class="portlet-body">
                          <form
                            id="form_editClient"
                            onSubmit={this.handleClientEditSubmit}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                {/*<?php echo form_open(base_url('index.php/SuperAdmin/ClientEdit/'.$id),'class="form-horizontal"','role="form"')?>*/}

                                <input
                                  type="hidden"
                                  name="action"
                                  value="modify_clientlist"
                                />
                                <input
                                  type="hidden"
                                  name="client_id"
                                  value={this.state.client_id}
                                  readOnly
                                />
                                <table class="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="fullname"
                                          value={this.state.fullname}
                                          onChange={(e) => {
                                            this.setState({
                                              fullname: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="email"
                                          value={this.state.email}
                                          onChange={(e) => {
                                            this.setState({
                                              email: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Address</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="address"
                                          value={this.state.address}
                                          onChange={(e) => {
                                            this.setState({
                                              address: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>City</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="city"
                                          value={this.state.city}
                                          onChange={(e) => {
                                            this.setState({
                                              city: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>State</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="state"
                                          value={this.state.state}
                                          onChange={(e) => {
                                            this.setState({
                                              state: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Country</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="country"
                                          value={this.state.country}
                                          onChange={(e) => {
                                            this.setState({
                                              country: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Url</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="url_prefix"
                                          value={this.state.url}
                                          onChange={(e) => {
                                            this.setState({
                                              url: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tenant</td>
                                      <td class="hidden-xs">
                                        <select
                                          className="form-control sel2_tenantlist"
                                          id="cctenant_id"
                                          name="tenant_id"
                                          value={this.state.tenant_id}
                                          onChange={(e) => {
                                            this.setState({
                                              tenant_id: e.target.value,
                                            })
                                          }}
                                        >
                                          {this.state.tenants.map((tenant) => (
                                            <option value={tenant.id}>
                                              {tenant.url}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Seats</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="num_seats"
                                          value={this.state.num_seats}
                                          onChange={(e) => {
                                            this.setState({
                                              num_seats: e.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    {/**SMS Part */}
                                    <tr>
                                      <td>SMS Ability</td>
                                      <td class="hidden-xs">
                                        <input
                                          type="hidden"
                                          name="sms_ability"
                                          value={this.state.sms_ability}
                                        />
                                        <Radio.Group
                                          onChange={(e) => {
                                            this.setState({
                                              sms_ability: e.target.value,
                                            })
                                          }}
                                          value={this.state.sms_ability}
                                        >
                                          <Radio value="active">Active</Radio>
                                          <Radio value="inactive">
                                            Inactive
                                          </Radio>
                                        </Radio.Group>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        SMS Credits{' '}
                                        <Tooltip title="Reset Credits">
                                          <Button
                                            type="link"
                                            icon={<RedoOutlined />}
                                            onClick={(e) => {
                                              this.setState({
                                                sms_credits:
                                                  this.state.sms_credits_db,
                                              })
                                            }}
                                          />
                                        </Tooltip>
                                      </td>
                                      <td>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <input
                                              type="text"
                                              name="sms_credits"
                                              value={this.state.sms_credits}
                                              className="form-control"
                                              readOnly
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <div className="pull-right">
                                              <Tooltip title="Add Credits">
                                                <Button
                                                  type="primary"
                                                  icon={<PlusOutlined />}
                                                  onClick={(e) => {
                                                    try {
                                                      let nv =
                                                        parseInt(
                                                          this.state.sms_credits
                                                        ) +
                                                        parseInt(
                                                          this.state
                                                            .credits_sms_mod
                                                        )
                                                      this.setState({
                                                        sms_credits: nv,
                                                      })
                                                    } catch (e) {}
                                                  }}
                                                />
                                              </Tooltip>
                                              <InputNumber
                                                min={0}
                                                max={100000}
                                                defaultValue={0}
                                                onChange={(value) => {
                                                  this.setState({
                                                    credits_sms_mod: value,
                                                  })
                                                }}
                                              />
                                              <Tooltip title="Remove Credits">
                                                <Button
                                                  type="danger"
                                                  icon={<MinusOutlined />}
                                                  onClick={(e) => {
                                                    try {
                                                      let nv =
                                                        parseInt(
                                                          this.state.sms_credits
                                                        ) -
                                                        parseInt(
                                                          this.state
                                                            .credits_sms_mod
                                                        )

                                                      this.setState({
                                                        sms_credits:
                                                          nv >= 0 ? nv : 0,
                                                      })
                                                    } catch (e) {}
                                                  }}
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {/***** */}
                                    {/** */}
                                    <tr>
                                      <td>Account Type</td>
                                      <td class="">
                                        <select
                                          name="account_type"
                                          id="account_type"
                                          class="bs-select form-control"
                                          value={this.state.account_type}
                                          onChange={(e) => {
                                            this.setState({
                                              account_type: e.target.value,
                                            })
                                          }}
                                        >
                                          <option value="trial">Trial</option>
                                          <option value="regular">
                                            Regular
                                          </option>
                                          {/*<?=Dolphin_make_dropdown(array(array('key'=>'trial', 'value'=>'Trial'),array('key'=>'regular','value'=>'Regular')), $account_type,'',true)?>*/}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Account Status</td>
                                      <td class="hidden-xs">
                                        <select
                                          name="account_status"
                                          id="account_status"
                                          class="bs-select form-control"
                                          value={this.state.account_status}
                                          onChange={(e) => {
                                            this.setState({
                                              account_status: e.target.value,
                                            })
                                          }}
                                        >
                                          <option value="active">Active</option>
                                          <option value="inactive">
                                            Inactive
                                          </option>
                                          <option value="trial_expired">
                                            Trial Expired
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Agreement</td>
                                      <td class="hidden-xs">
                                        <select
                                          name="agreement_status"
                                          id="agreement_status"
                                          class="bs-select form-control"
                                          value={this.state.agreement_status}
                                          onChange={(e) => {
                                            this.setState({
                                              agreement_status: e.target.value,
                                            })
                                          }}
                                        >
                                          <option value="signed">Signed</option>
                                          <option value="unsigned">
                                            Unsigned
                                          </option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-center" colspan="2">
                                        <button
                                          type="submit"
                                          class="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                {/*<?php echo form_close()?>*/}
                              </div>
                              <div class="col-md-6">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    {/*Enable recording adding here */}
                                    <tr>
                                      <td>Enable Recording</td>
                                      <td class="hidden-xs">
                                        <select
                                          className="form-control"
                                          id="cenable_rec"
                                          name="enable_recording"
                                          value={this.state.enable_rec}
                                          onChange={(e) => {
                                            this.setState({
                                              enable_rec: e.target.value,
                                            })
                                          }}
                                        >
                                          {booleanopts.map((opt) => (
                                            <option value={opt}>{opt}</option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>International Dialing</td>
                                      <td class="hidden-xs">
                                        <select
                                          className="form-control"
                                          id="cinternational_dialing"
                                          name="international_dialing"
                                          value={
                                            this.state.international_dialing
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              international_dialing:
                                                e.target.value,
                                            })
                                          }}
                                        >
                                          {[
                                            { key: 1, value: 'Enabled' },
                                            { key: 0, value: 'Disabled' },
                                          ].map((opt) => (
                                            <option value={opt.key}>
                                              {opt.value}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>SMS Provider</td>
                                      <td class="hidden-xs">
                                        <select
                                          className="form-control"
                                          id="csms_provider"
                                          name="sms_provider"
                                          value={this.state.sms_provider}
                                          onChange={(e) => {
                                            this.setState({
                                              sms_provider: e.target.value,
                                            })
                                          }}
                                        >
                                          {[
                                            {
                                              key: 'skyetel',
                                              value: 'Skyetel',
                                            },
                                            {
                                              key: 'flowroute',
                                              value: 'Flowroute',
                                            },
                                            {
                                              key: 'questblue',
                                              value: 'QuestBlue',
                                            },
                                          ].map((opt) => (
                                            <option value={opt.key}>
                                              {opt.value}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Allow Adding Caller ID?</td>
                                      <td class="hidden-xs">
                                        <select
                                          name="can_add_cid"
                                          id="can_add_cid"
                                          class="bs-select form-control"
                                          value={this.state.can_add_cid}
                                          onChange={(e) => {
                                            this.setState({
                                              can_add_cid: e.target.value,
                                            })
                                          }}
                                        >
                                          <option value="0">No</option>
                                          <option value="1">Yes</option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
