import React, { Component } from "react";
import Dashboard from "./Dashboard";
import Dashboard_agent from "./Dashboard_agent";
class DashboardSwitcher extends Component {
  state = { show_alert: false };
  componentDidMount() {
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    //console.error(this.props.match.params);
    //alert(show_alert);
    if (show_alert !== undefined) {
      this.setState({ show_alert: show_alert });
    }
  }
  render() {
    if (this.props.role !== "agent")
      return (
        <Dashboard
          role={this.props.role}
          baseUrl={this.props.baseUrl}
          doPost={this.props.doPost}
          blockUI={this.props.blockPageUI}
          show_alert={this.state.show_alert}
          user_id={this.props.user_id}
        ></Dashboard>
      );
    else
      return (
        <Dashboard_agent
          role={this.props.role}
          baseUrl={this.props.baseUrl}
          doPost={this.props.doPost}
          blockUI={this.props.blockPageUI}
          show_alert={this.state.show_alert}
        ></Dashboard_agent>
      );
  }
}

export default DashboardSwitcher;
