import React, { Component } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../../css/drawer_inbound.css";
import "../../css/ringing_anim.css";

class Drawer_inbound extends Component {
  state = { inbound_queue_visible: false };
  render() {
    return (
      <React.Fragment>
        <NavLink
          to="#"
          onClick={(e) => {
            if (document.getElementById("mySidenav").style.width !== "250px")
              window.openInboundDrawer();
            else window.closeInboundDrawer();
          }}
          style={{ paddingBottom: "8px" }}
          title="Inbound"
        >
          <span class="material-icons" style={{ color: "white" }}>
            phone_callback
          </span>
          <span className="badge badge-default"></span>
        </NavLink>
        {/* ringing animation */}

        <div
          className="phonering-alo-phone phonering-alo-green phonering-alo-show"
          id="incoming_animation"
          style={{ display: "none" }}
        >
          <div className="phonering-alo-ph-circle"></div>
          <div className="phonering-alo-ph-circle-fill"></div>

          <a
            href="javascript:;"
            onClick={(e) => {
              if (document.getElementById("mySidenav").style.width !== "250px")
                window.openInboundDrawer();
              else window.closeInboundDrawer();
            }}
            className="pps-btn-img"
          >
            <span class="dumClass">New Incoming Call</span>
            <div className="phonering-alo-ph-img-circle"></div>
          </a>
        </div>
        {/* */}
        <div id="mySidenav" className="sidenav">
          <NavLink
            to="#"
            id="inb_close_btn"
            class="closebtn"
            onClick={(e) => {
              if (document.getElementById("mySidenav").style.width !== "250px")
                window.openInboundDrawer();
              else window.closeInboundDrawer();
            }}
            title="Inbound List"
          >
            <i class="icon-login"></i>
          </NavLink>
          {/*<a
            href="javascript:;"
            className="closebtn"
            onClick={(e) => {
              console.log("click detected");
              document.getElementById("mySidenav").style.width = "0";
            }}
          >
            &times;
          </a>*/}

          <div
            className="portlet light "
            style={{
              paddingTop: "3px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="portlet-title tabbable-line">
              <ul className="nav nav-tabs" style={{ width: "100%" }}>
                <li
                  className="active"
                  id="inb_li_ringing"
                  style={{ width: "33%", textAlign: "center" }}
                >
                  <a
                    id="a_ringing"
                    href="#tab_ringing"
                    data-toggle="tab"
                    title="Incoming Calls"
                  >
                    {" "}
                    <img src="assets/img/phone-ringing.png" width="24px" />{" "}
                  </a>
                </li>
                <li
                  style={{ width: "33%", textAlign: "center" }}
                  id="inb_li_incall"
                >
                  <a
                    href="#tab_incall"
                    id="a_incall"
                    data-toggle="tab"
                    title="Live Calls"
                  >
                    {" "}
                    <img src="assets/img/phone-incall.png" width="24px" />{" "}
                  </a>
                </li>
                <li
                  style={{ width: "33%", textAlign: "center" }}
                  id="inb_li_missed"
                >
                  <a
                    href="#tab_callsmissed"
                    data-toggle="tab"
                    title="Missed Calls"
                  >
                    {" "}
                    <img src="assets/img/phone-missed.png" width="24px" />{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="portlet-body" style={{ paddingBottom: "80px" }}>
              <div className="tab-content">
                <div className="tab-pane active" id="tab_ringing">
                  <div className="row">
                    <div
                      className=" col-md-12 "
                      style={{ height: "100%" }}
                      data-always-visible="1"
                      data-rail-visible1="0"
                      data-handle-color="#D7DCE2"
                    >
                      <ul className="feeds" id="call_in_queue"></ul>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab_incall">
                  <div className="row">
                    <div
                      className=" col-md-12 "
                      style={{ height: "100%" }}
                      data-always-visible="1"
                      data-rail-visible1="0"
                      data-handle-color="#D7DCE2"
                    >
                      <ul className="feeds" id="call_incall"></ul>
                    </div>
                  </div>
                </div>
                <div className="tab-pane " id="tab_callsmissed">
                  <div className="row">
                    <div
                      className=" col-md-12 "
                      style={{ height: "100%" }}
                      data-always-visible="1"
                      data-rail-visible1="0"
                      data-handle-color="#D7DCE2"
                    >
                      <ul className="feeds" id="call_missed"></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Drawer_inbound;
