import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class CampaignList extends Component {
  state = { campaigns: [] };
  componentDidMount() {
    window.resizeHandle();

    fetch(this.props.baseUrl + "/index.php/Campaigns/campaign_list", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({ campaigns: resp.msg });
        window.initConfirmation();
        window.App.initUniform();
      })
      .catch((e) => {});
  }
  toggleCampaignActive = (event, campaign_id) => {
    let body = {
      action: "toggle_campaign_status",
      campaign_id: campaign_id,
      status: event.target.checked ? "Y" : "N",
    };
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Campaigns/Ajax", {
      method: "POST",
      credentials: "include",
      body: new URLSearchParams($.param(body)),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.warn(resp);
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  handleDeleteCampaign = (ev, i) => {
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Campaigns/post_process", {
      method: "POST",
      credentials: "include",
      body: new URLSearchParams(
        $.param({
          action: "delete_campaign",
          campaign_id: this.state.campaigns[i].campaign_id,
        })
      ),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");

          var campaigns = [...this.state.campaigns];
          campaigns.splice(i, 1);
          this.setState({ campaigns });
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Campaigns" subtitle="list" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="portlet light portlet-fit ">
                    <div className="portlet-body">
                      <div className="row">
                        <div className="col-md-12">
                          <span id="alert_container"></span>
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                              <tr>
                                <th>Campaign ID</th>
                                <th>Campaign Name</th>
                                <th>Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.campaigns.map((camp, i) => (
                                <tr key={camp.campaign_id}>
                                  <td>{camp.campaign_id}</td>
                                  <td>{camp.campaign_name}</td>
                                  <td>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onClick={(ev) => {
                                          this.toggleCampaignActive(
                                            ev,
                                            camp.campaign_id
                                          );
                                          let tstate = this.state;
                                          if (ev.target.checked)
                                            tstate.campaigns[i].active = "Y";
                                          else tstate.campaigns[i].active = "N";
                                          this.setState(tstate);
                                        }}
                                        checked={camp.active == "Y"}
                                      />{" "}
                                    </label>
                                  </td>
                                  <td>
                                    <a
                                      href={
                                        "#/admin/campaignedit/" +
                                        camp.campaign_id
                                      }
                                      className="btn dark btn-sm btn-outline sbold uppercase"
                                    >
                                      <i className="fa fa-pencil"></i> Modify
                                    </a>

                                    <button
                                      type="submit"
                                      data-toggle="confirmation"
                                      data-placement="top"
                                      data-singleton="true"
                                      data-popout="true"
                                      className="btn red btn-sm btn-outline sbold uppercase"
                                      onClick={(ev) => {
                                        this.handleDeleteCampaign(ev, i);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CampaignList;
