import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class ListDNC extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    window.App.initUniform();
    /***load script */
    const script = document.createElement("script");
    script.src = "jsdolphin/dnclist.js";
    script.async = true;
    document.body.appendChild(script);
    /************************************ */
  }
  componentWillUnmount() {
    window.removeJS("jsdolphin/dnclist.js");
  }
  handleDNC = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Lists/post_process", {
      method: "POST",
      body: data,
    })
      .then((a) => a.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((er) => {
        window.displayAlert("#alert_container", data.msg, "danger");
        window.App.unblockUI();
      });
  };
  handleDNCSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Lists/post_process", {
      method: "POST",
      body: data,
    })
      .then((a) => a.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((er) => {
        window.displayAlert("#alert_container", data.msg, "danger");
        window.App.unblockUI();
      });
  };
  handleDNCDownload = (ev) => {
    ev.preventDefault();
    //submit form inside iframe
    $("#DNCdown_iframe")
      .contents()
      .find("html")
      .html(
        '<html><head></head><body><form method="post" action="' +
          this.props.baseUrl +
          '/index.php/Lists/post_process" id="frm_iframe_dncdown" ><input type="hidden" name="action" value="download_DNC" /><button type="submit" className="btn green-jungle-madison" ></button></form> </body></html>'
      );

    $("#DNCdown_iframe").contents().find("#frm_iframe_dncdown").submit();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    DNC
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Lists </small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet portlet-sortable light bordered">
                        <div className="portlet-title">
                          <div className="caption font-green-jungle">
                            <i className="icon-speech font-green-jungle"></i>
                            <span className="caption-subject bold uppercase">
                              {" "}
                              Add/Remove Numbers from DNC LIST
                            </span>
                            <span className="caption-helper"></span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <form onSubmit={this.handleDNC}>
                            <input
                              type="hidden"
                              name="action"
                              value="add_dnc"
                            />
                            <table className="table table-striped table-bordered table-advance table-hover">
                              <tbody>
                                <tr>
                                  <td>Action</td>
                                  <td>
                                    <input
                                      id="action"
                                      name="dnc_action"
                                      type="checkbox"
                                      className="make-switch"
                                      data-on-text="&nbsp;Add&nbsp;"
                                      data-off-text="&nbsp;Remove&nbsp;"
                                      data-on-color="primary"
                                      data-off-color="danger"
                                      checked
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="40%">Method</td>
                                  <td>
                                    <input
                                      id="method"
                                      name="dnc_method"
                                      type="checkbox"
                                      className="make-switch"
                                      data-on-text="&nbsp;One by One&nbsp;"
                                      data-off-text="&nbsp;Upload File&nbsp;"
                                      data-on-color="primary"
                                      data-off-color="info"
                                      checked
                                    />
                                  </td>
                                </tr>
                                <tr id="phoneNumbers">
                                  <td>Phone Number</td>
                                  <td>
                                    <textarea
                                      name="dnc_phones"
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </td>
                                </tr>
                                <tr id="uploadFile" style={{ display: "none" }}>
                                  <td>File</td>
                                  <td>
                                    <div
                                      className="fileinput fileinput-new"
                                      data-provides="fileinput"
                                    >
                                      <span className="btn green btn-file">
                                        <span className="fileinput-new">
                                          {" "}
                                          Select file{" "}
                                        </span>
                                        <span className="fileinput-exists">
                                          {" "}
                                          Change{" "}
                                        </span>
                                        <input
                                          type="file"
                                          name="dnc_file"
                                        />{" "}
                                      </span>
                                      <span className="fileinput-filename">
                                        {" "}
                                      </span>{" "}
                                      &nbsp;
                                      <NavLink
                                        to="/"
                                        className="close fileinput-exists"
                                        data-dismiss="fileinput"
                                      ></NavLink>
                                    </div>
                                    <div className="alert alert-info">
                                      Upload should be in csv format with just
                                      one column of phone numbers and no header
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colspan="2">
                                    <button
                                      type="submit"
                                      className="btn green-jungle"
                                    >
                                      Submit
                                    </button>
                                    <button
                                      type="button"
                                      className="btn default"
                                    >
                                      Cancel
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <div className="portlet portlet-sortable light bordered">
                        <div className="portlet-title">
                          <div className="caption font-green-jungle">
                            <i className="icon-speech font-green-jungle"></i>
                            <span className="caption-subject bold uppercase">
                              {" "}
                              DNC List
                            </span>
                            <span className="caption-helper"></span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <tbody>
                              <tr>
                                <td>
                                  <form onSubmit={this.handleDNCSearch}>
                                    <input
                                      type="hidden"
                                      name="action"
                                      value="search_DNC"
                                    />
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        name="phoneNumber"
                                        className="form-control"
                                      />
                                      <span className="input-group-btn">
                                        <button
                                          className="btn red"
                                          type="submit"
                                        >
                                          Search
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                                <td>
                                  <form onSubmit={this.handleDNCDownload}>
                                    <input
                                      type="hidden"
                                      name="action"
                                      value="download_DNC"
                                    />
                                    <button
                                      type="submit"
                                      className="btn green-jungle-madison"
                                    >
                                      Download DNC LIST
                                    </button>
                                  </form>
                                  <iframe
                                    class="hidden"
                                    id="DNCdown_iframe"
                                  ></iframe>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListDNC;
