import React, { Component } from "react";
import $ from "jquery";
import CampaignsDropDown from "../Bricks/CampaignsDropDown";
import ScriptsDropDown from "../Bricks/ScriptsDropDown";
import TimeKeeper from "react-timekeeper";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import HeadTitle from "../HeadTitle";
class ListAdd extends Component {
  state = {
    list_id: "",
    time: "12:00pm",
    /*window.hourwithAMPM(new Date())*/ timetags: [],
  };
  componentDidMount() {
    //const time = window.hourwithAMPM(new Date());
    //if (time) this.setState({ time: window.hourwithAMPM(new Date()) });
    //this.setState({ time: new Date().toString("hh:mm tt") });
    window.load_select2(".bs-select");
    //window.apply_timepicker_no_seconds();
    //window.load_list_add_js();
    window.resizeHandle();
  }
  submitAddList = (form) => {
    form.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Lists/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($(".listAddform").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success", 0);
          //goto list edit page
          window.location.href =
            "#/admin/listedit/" + this.state.list_id + "/true";
          ////////////////////////////////////
        } else {
          window.displayAlert("#alert_container", data.msg, "danger", 0);
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
    /*this.props.doPost(
      "/index.php/Lists/post_process",
      new URLSearchParams($(".listAddForm").serialize())
    );*/
  };
  showTimeModal = (e) => {
    if (this.state.timetags.length >= 2) {
      window.bootbox.alert("You can add two reset times at maximum.");
    } else {
      window.$("#modal_cf_tp").modal("show");
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Lists" subtitle="Add" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                className="listAddform"
                                onSubmit={this.submitAddList}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_list"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>
                                        List ID <br />
                                        <small>Must be 1-5 digits</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_id"
                                          required
                                          minLength="1"
                                          maxLength="5"
                                          value={this.state.list_id}
                                          onChange={(e) => {
                                            this.setState({
                                              list_id: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>List Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_name"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaign</td>
                                      <td>
                                        <CampaignsDropDown
                                          baseUrl={this.props.baseUrl}
                                          name="list_campaign_id"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Reset Times</td>
                                      <td>
                                        <div className="input-group">
                                          <span className="input-group-btn">
                                            <button
                                              className="btn red"
                                              type="button"
                                              id="clear_reset_times"
                                              onClick={(e) => {
                                                this.setState({ timetags: [] });
                                              }}
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </span>
                                          {/*<input
                                            className="form-control"
                                            readOnly
                                            id="list_reset_times"
                                            name="list_reset_times"
                                            type="text"
                                          />*/}
                                          <input
                                            type="hidden"
                                            name="list_reset_times"
                                            value={this.state.timetags.join(
                                              ","
                                            )}
                                          />
                                          <TagsInput
                                            disabled
                                            maxTags={2}
                                            value={this.state.timetags}
                                            onChange={(e) => {
                                              this.setState({ timetags: e });
                                            }}
                                            inputProps={{ placeholder: "" }}
                                          />
                                          <span className="input-group-btn">
                                            <button
                                              className="btn green-jungle"
                                              id="btn_add_reset_time"
                                              onClick={this.showTimeModal}
                                              type="button"
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Script Override</td>
                                      <td>
                                        <ScriptsDropDown
                                          name="list_script_override"
                                          id="list_script_LtA"
                                          baseUrl={this.props.baseUrl}
                                          onLoad={(e) => {
                                            console.log("hi");
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="text-center" colSpan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        {/*<button
                                          type="button"
                                          href=""
                                          className="btn default"
                                        >
                                          Cancel
                                        </button>*/}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modal_cf_tp" className="modal fade" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add Reset Time</h4>
              </div>
              <div className="modal-body">
                <form action="#" className="form-horizontal">
                  <div className="form-group">
                    <div className="col-md-6 col-md-offset-3">
                      <TimeKeeper
                        time={this.state.time}
                        onChange={(e) => this.setState({ time: e.formatted12 })}
                        switchToMinuteOnHourSelect
                      />
                      {/*<div className="input-group">
                        <input
                          type="text"
                          id="curTime"
                          className="form-control timepicker timepicker-no-seconds"
                        />
                        
                        <span className="input-group-btn">
                          <button className="btn default" type="button">
                            <i className="fa fa-clock-o"></i>
                          </button>
                        </span>
                      </div>*/}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn default"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  Close
                </button>
                <button
                  className="btn green-jungle"
                  id="add_to_reset_times"
                  data-dismiss="modal"
                  onClick={(e) => {
                    let tt = this.state.timetags;
                    if (tt.includes(this.state.time) === false)
                      tt.push(this.state.time);
                    this.setState({ timetags: tt });
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListAdd;
