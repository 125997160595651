import React, { Component } from "react";
import $ from "jquery";
import LeadStatusDropDown from "../Bricks/LeadStatusDropDown";
import UsersDropDown from "../Bricks/UsersDropDown";
import HeadTitle from "../HeadTitle";
class ContactAdd extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
  }
  SubmitContactForm = (form) => {
    form.preventDefault();

    this.props.doPost(
      "/index.php/Contact/post_process",
      new URLSearchParams($(".contactAddForm").serialize())
    );

    /*window.App.blockUI();
		fetch(this.props.baseUrl+'/index.php/Contact/post_process',{
			credentials: "include",
			method: 'post',
			body: new URLSearchParams($('.contactAddForm').serialize())
		}).then(blob=>{					
			return blob.json();
		}).then(data=>{
			console.log(data);
			if(data.status){
				window.displayAlert('#alert_container',data.msg,'success');
			}
			window.App.unblockUI();
		}).catch(e=>{
			window.App.unblockUI();
		});*/
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="Add new Contact" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                className="contactAddForm"
                                onSubmit={this.SubmitContactForm}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_contact"
                                />
                                <div className=" col-md-6">
                                  <table className="table table-striped table-bordered table-advance table-hover">
                                    <tbody>
                                      <tr>
                                        <td>First Name</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Last Name</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Phone</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Phone2</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="phone2"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Phone3</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="phone3"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Email</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Address</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="address1"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>City</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-md-6">
                                  <table className="table table-striped table-bordered table-advance table-hover">
                                    <tbody>
                                      <tr>
                                        <td>State</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Zip Code</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="zip_code"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Company</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="company"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Source</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="source"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Lead Status</td>
                                        <td>
                                          <LeadStatusDropDown
                                            name="lead_status"
                                            baseUrl={this.props.baseUrl}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Last Call Dispo</td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="last_call_dispo"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Assigned To</td>
                                        <td>
                                          <UsersDropDown
                                            name="assigned_to"
                                            id="assigned_to"
                                            baseUrl={this.props.baseUrl}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Notes</td>
                                        <td>
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            name="comments"
                                          ></textarea>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-md-12">
                                  <div className="col-md-2 col-md-offset-5">
                                    <button
                                      type="submit"
                                      className="btn green-jungle"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactAdd;
