import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
const title1 = "Getting Started Checklist";
let step1 = {
  master_cid: {
    text: (
      <React.Fragment>
        Set your caller ID in{" "}
        <NavLink to="/admin/masterdialsettings">Master Dial Settings</NavLink>
      </React.Fragment>
    ),
    check: false,
  },
  leads_up: {
    text: (
      <React.Fragment>
        <NavLink to="/addnew/importleads">Upload leads</NavLink> to your first
        list
      </React.Fragment>
    ),
    check: false,
  },
  audio_rec: {
    text: (
      <React.Fragment>
        <NavLink to="">Record an audio file</NavLink> &amp; assign it as
        pre-recorded voicemail drop (PAM)
      </React.Fragment>
    ),
    check: false,
  },
  call_made: { text: "Make your first phone call", check: false },
};
const title2 = "More Getting Started Items ";
let step2 = {
  newlist_camp: {
    text: (
      <React.Fragment>
        Create a <NavLink to="/addnew/list">new list</NavLink> and{" "}
        <NavLink to="/addnew/campaign">new campaign</NavLink>
      </React.Fragment>
    ),
    check: false,
  },
  add_sw_field: {
    text: (
      <React.Fragment>
        Add <NavLink to="/admin/customfields">custom fields</NavLink>
      </React.Fragment>
    ),
    check: false,
  },
  email_set: {
    text: (
      <React.Fragment>
        Update your{" "}
        <NavLink to="/admin/emailtemplates/settings">email settings</NavLink>
      </React.Fragment>
    ),
    check: false,
  },
  crt_email_temp: {
    text: (
      <React.Fragment>
        Create your first{" "}
        <NavLink to="/admin/emailtemplates/add">email template</NavLink>
      </React.Fragment>
    ),
    check: false,
  },
  crt_script_4_camp: {
    text: (
      <React.Fragment>
        <NavLink to="/admin/scripts/add">Create a script</NavLink> and assign it
        to a campaign
      </React.Fragment>
    ),
    check: false,
  },
  lead_to_contact: {
    text: <React.Fragment>Convert a lead to a contact</React.Fragment>,
    check: false,
  },
  down_agent_p_rep: {
    text: (
      <React.Fragment>Download the Agent Performance Report</React.Fragment>
    ),
    check: false,
  },
};
class GettingStartedCHeckList extends Component {
  state = {
    xd_out: false,
    title: "",
    steps: [],
    master_cid: false,
    leads_up: false,
    audio_rec: false,
    call_made: false,
    newlist_camp: false,
    add_sw_field: false,
    email_set: false,
    crt_email_temp: false,
    crt_script_4_camp: false,
    lead_to_contact: false,
    down_agent_p_rep: false,
  };
  componentDidMount() {
    window.App.initUniform();
    window.initConfirmation();
  }
  componentWillMount() {
    //fetch info from backend and arrange here
    fetch(this.props.baseUrl + "/index.php/Ajax/GetCheckList", {
      credentials: "include",
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({
            master_cid: data.master_cid,
            leads_up: data.leads_up,
            audio_rec: data.audio_rec,
            call_made: data.call_made,
            newlist_camp: data.add_newlist && data.add_newcamp ? 1 : 0,
            add_sw_field: data.add_sw_field,
            email_set: data.email_set,
            crt_email_temp: data.crt_email_temp,
            crt_script_4_camp: data.crt_script_4_camp,
            lead_to_contact: data.lead_to_contact,
            down_agent_p_rep: data.down_agent_p_rep,
            xd_out: data.xd_out,
          });
          this.stepsCompleted(data);
          window.App.initUniform();
        }
        console.erro(data);
      })
      .catch((e) => {});
    //add event to catch close click and ask thru bootbox to get rid of it permanently
    window
      .$("body")
      .on(
        "click",
        ".portlet > .portlet-title > .tools > a.remove#cout",
        function (e) {
          e.preventDefault();
          window.bootbox.dialog({
            message: "Do you want to close this check list permanently?",
            title: "Closing Checklist",
            buttons: {
              success: {
                label: "Yes",
                className: "green",
                callback: function () {
                  //ajax call to set xd out to true
                  $.ajax({
                    url:
                      window.AJAX_PROCESS_URLRJS +
                      "/index.php/Ajax/x_out_checklist",
                    method: "GET",
                  });
                },
              },
              danger: {
                label: "Nope, just remove it for now",
                className: "red",
                callback: function () {},
              },
            },
          });
        }
      );
  }
  stepsCompleted = (data) => {
    if (
      data.master_cid == 1 &&
      data.leads_up == 1 &&
      data.audio_rec == 1 &&
      data.call_made == 1
    ) {
      //step 1 is complete time to check step2
      if (
        data.add_newlist == 1 &&
        data.add_newcamp == 1 &&
        data.add_sw_field == 1 &&
        data.email_set == 1 &&
        data.crt_email_temp == 1 &&
        data.crt_script_4_camp == 1 &&
        data.lead_to_contact == 1 &&
        data.down_agent_p_rep == 1
      ) {
        return true;
      } else {
        //alert(data.add_newlist);
        //alert(data.add_newcamp);
        step2.newlist_camp.check =
          data.add_newlist === "1" && data.add_newcamp == "1" ? 1 : 0;
        step2.add_sw_field.check = data.add_sw_field;
        step2.email_set.check = data.email_set;
        step2.crt_email_temp.check = data.crt_email_temp;
        step2.crt_script_4_camp.check = data.crt_script_4_camp;
        step2.down_agent_p_rep.check = data.down_agent_p_rep;

        let sttp = [
          step2.newlist_camp,
          step2.add_sw_field,
          step2.email_set,
          step2.crt_email_temp,
          step2.crt_script_4_camp,
          step2.down_agent_p_rep,
        ];
        this.setState({ steps: sttp, title: title2 });
      }
    } else {
      //check each step1 state individuall and mark
      step1.master_cid.check = data.master_cid;
      step1.leads_up.check = data.leads_up;
      step1.audio_rec.check = data.audio_rec;
      step1.call_made.check = data.call_made;

      let sttp = [
        step1.master_cid,
        step1.leads_up,
        step1.audio_rec,
        step1.call_made,
      ];
      this.setState({ title: title1, steps: sttp });
    }
    return false;
  };
  render() {
    if (this.state.title == "" || this.state.xd_out == true)
      return <React.Fragment></React.Fragment>;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div class="portlet light">
              <div class="portlet-title" style={{ marginBottom: "0px" }}>
                <div class="caption">
                  <i class="fa fa-gift"></i>
                  {this.state.title}{" "}
                </div>
                <div class="tools">
                  <a
                    href="javascript:;"
                    class="collapse"
                    data-original-title=""
                    title=""
                  >
                    {" "}
                  </a>
                  <a
                    href="javascript:;"
                    class="remove"
                    id="cout"
                    data-original-title=""
                    title=""
                  >
                    {" "}
                  </a>
                </div>
              </div>
              <div class="portlet-body" style={{ paddingTop: "0px" }}>
                <div class="mt-element-list">
                  <div
                    class="mt-list-container list-simple"
                    style={{ paddingBottom: "0px", paddingTop: "0px" }}
                  >
                    <ul>
                      {this.state.steps.map((step) => (
                        <li
                          class="mt-list-item"
                          style={{ paddingBottom: "8px", paddingTop: "8px" }}
                        >
                          <div
                            class={
                              "list-icon-container " +
                              (step.check == "1" ? "done" : "")
                            }
                          >
                            <i
                              class={
                                step.check == "1" ? "icon-check" : "icon-close"
                              }
                            ></i>
                          </div>
                          <div
                            class="list-item-content"
                            style={{ paddingLeft: "27px", paddingRight: "4px" }}
                          >
                            {step.text}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*
            <div className="portlet light ">
              <div class="portlet-title">
                <div class="caption">
                  <i class="fa fa-gift"></i>
                  {this.state.title}{" "}
                </div>
                <div class="tools">
                  <a
                    href="javascript:;"
                    class="expand"
                    data-original-title=""
                    title=""
                  >
                    {" "}
                  </a>
                  <a
                    href="javascript:;"
                    class="remove"
                    data-original-title=""
                    title=""
                  >
                    {" "}
                  </a>
                </div>
              </div>
              <div className="portlet-body">
                <div className="form-group">
                  <div class="input-group">
                    <div class="icheck-list">
                      {this.state.steps.map(step => (
                        <label>
                          <input
                            type="checkbox"
                            class="icheck"
                            data-checkbox={
                              step.check == "1"
                                ? "icheckbox_line-green"
                                : "icheckbox_line-red"
                            }
                            data-label={step.text}
                            checked={step.check == "1" ? true : false}
                            disabled
                          />{" "}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          */}
          </div>
        </div>
        <div className="row">
          {/*
          When you don't have bugs to work on, let's add a Getting Started widget to the homepage for Owners.  
          Italics used when we should have a direct link in the checklist to the specific item.
          Getting Started Checklist                                                       X
          Set your caller ID in Master Dial Settings
          Upload leads to your first list
          Record an audio file & assign it as pre-recorded voicemail drop (PAM)
          Make your first phone call 
          Add a checkmark next to each item when completed

          once these are all complete, congratulate the user, and then provide more
          More Getting Started Items                                                   X
          Create a new list and new campaign
          Add custom fields
          Update your email settings
          Create your first email template
          Create a script and assign it to a campaign
          Convert a lead to a contact
          Download the Agent Performance Report
          This dashboard will only show up for the owner level account, and can be deleted with the X button (Confirm you'd like to remove this widget).  It will also delete automatically when it's fully completed. (edited) 
          */}
        </div>
      </React.Fragment>
    );
  }
}

export default GettingStartedCHeckList;
