import React, { Component } from "react";
import $ from "jquery";
class ServerAdd extends Component {
  state = {};
  status_opts = [
    { key: 0, text: "Inactive" },
    { key: 1, text: "Active" },
  ];
  componentDidMount() {
    window.resizeHandle();
  }
  handleAddServer = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Servers/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_addserver").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Servers
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <form
                              id="form_addserver"
                              onSubmit={this.handleAddServer}
                            >
                              <input
                                type="hidden"
                                value="add"
                                name="action"
                                className="form-control"
                              />
                              <div className="col-md-8 col-md-offset-2">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="name"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>IP</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ip"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Main Url</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="url"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tenant Url Convention</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="tenant_url_convention"
                                          className="form-control"
                                        />
                                        <div className="alert alert-info">
                                          <small>
                                            * (asterisk) - random numerics
                                            <br />
                                            ! (excalamation mark) for random
                                            alphabets
                                            <br />
                                            Example
                                            pbx9*&ampersand;!.zoomcalls.com will
                                            produce something like
                                            pbx90ga.zoomcalls.com or
                                            pbx901a.zoomcalls.com
                                          </small>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Fusionpbx Login User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="pbx_user"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Login Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="pbx_pass"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FusionPbx Api Key</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="api_key"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP User</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_user"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Pass</td>
                                      <td>
                                        <input
                                          type="password"
                                          name="ftp_pass"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>FTP Port</td>
                                      <td>
                                        <input
                                          type="text"
                                          name="ftp_port"
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Status</td>
                                      <td>
                                        <select
                                          className="form-control"
                                          name="active"
                                          onChange={(e) =>
                                            this.setState({
                                              active: e.target.value,
                                            })
                                          }
                                        >
                                          {this.status_opts.map((opt) => (
                                            <option
                                              key={opt.key}
                                              value={opt.key}
                                            >
                                              {opt.text}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn green-jungle"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServerAdd;
