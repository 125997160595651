import React, { Component } from "react";
import $ from "jquery";
import PAMLabel from "./Bricks/PAMLabel";
import HeadTitle from "./HeadTitle";
class MyPAM extends Component {
  state = {
    audio_files: [],
    pam1: "",
    pam2: "",
    pam3: "",
    pam4: "",
    pam5: "",
    lbl_pam1: "",
    lbl_pam2: "",
    lbl_pam3: "",
    lbl_pam4: "",
    lbl_pam5: "",
  };
  componentDidMount() {
    window.resizeHandle();
    window.dt_pamlist = window.dttable(
      "dt_myrecordings",
      this.props.baseUrl + "/index.php/Recording/Ajax/1",
      ""
    );
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/mypam.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    //fetch data from pam actually ;)
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/PAM", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        if (resp.status) {
          this.setState({
            audio_files: resp.msg.audio_files,
            pam1: resp.msg.users_pams.pam1,
            pam2: resp.msg.users_pams.pam2,
            pam3: resp.msg.users_pams.pam3,
            pam4: resp.msg.users_pams.pam4,
            pam5: resp.msg.users_pams.pam5,
          });
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
    ///////////////////////////////////////
    /****Below is delete control */
    var blockUI = this.props.blockUI;
    var Aurl = this.props.baseUrl;
    $("#dt_myrecordings").on("click", ".btn-danger", function () {
      /*$(this)
			.closest("tr")
			.remove();*/
      var childKey = $(this).attr("delete_id");
      blockUI(true);
      fetch(Aurl + "/index.php/Recording/Delete_recording/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.dt_pamlist.api().ajax.reaload();
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUI(false);
        })
        .catch((e) => {
          blockUI(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //^^^^^^^^^^^below is view recording thing^^^^^^^^^^^^^//
    $("#dt_myrecordings").on("click", ".view_rec", function () {
      var id = $(this).attr("rec_id"); //$(this).closest("tr").contents()[0];
      var filename = $(this).attr("rec_filename"); //$(this).closest("tr").contents()[1];
      window.$("#modal_viewRec").modal("show");
      $("#modal_viewrec_title").html("View Recording " + filename);
      $("#mdl_viewrec_audio_src").attr(
        "src",
        Aurl + "/index.php/Recording/download/" + id
      );

      $("#mdl_download_rec").attr(
        "href",
        Aurl + "/index.php/Recording/download/" + id
      );

      var audio = document.getElementById("playRec");
      audio.load();
    });
    ////////////////////////////////////////////////////////////////
    //fetching my pam labels
    fetch(this.props.baseUrl + "/index.php/User/get_users_pam_labels", {
      method: "get",
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({
            lbl_pam1: data.msg.lbl_pam1,
            lbl_pam2: data.msg.lbl_pam2,
            lbl_pam3: data.msg.lbl_pam3,
            lbl_pam4: data.msg.lbl_pam4,
            lbl_pam5: data.msg.lbl_pam5,
          });
        }
      });
    /////////////////////////////////
  }
  componentWillUnmount() {
    window.removeJS("jsdolphin/mypam.js");
  }
  stopAudio = (e) => {
    var audio = document.getElementById("playRec");
    audio.pause();
    audio.currentTime = 0;
    $("#mdl_viewrec_audio_src").removeAttr("src");
    $("#mdl_download_rec").removeAttr("href");
  };
  submitformPam1 = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/post_process/0", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam1").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  submitformPam2 = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/post_process/0", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam2").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  submitformPam3 = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/post_process/0", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam3").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  submitformPam4 = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/post_process/0", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam4").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  submitformPam5 = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Recording/post_process/0", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_update_pam5").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
          <HeadTitle title="My Audio" subtitle="view" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <span id="alert_container"></span>
                    <div className="portlet light portlet-fit ">
                      <div
                        className="portlet-title"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="caption">
                          <i className="fa fa-leaf"></i>
                          <span className="caption-subject font-dark sbold uppercase">
                            Audio Files
                          </span>
                        </div>
                      </div>

                      <div className="portlet-body">
                        <table className="table table-striped table-bordered table-advance table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <div className="col-md-12 text-center">
                                  <h3>Click the MIC to start a recording</h3>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="col-md-12 text-center">
                                  <button
                                    className="btn btn-lg bg-green-jungle bg-font-green-jungle"
                                    id="btnRecording"
                                    onClick={(e) => {
                                      window.record();
                                    }}
                                  >
                                    <i className="fa fa-microphone fa-2x in_btn_fa"></i>
                                  </button>
                                  <div id="time">
                                    <label id="minutes">00</label>:
                                    <label id="seconds">00</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              id="recordingslist_tr"
                              style={{ display: "none" }}
                            >
                              <td>
                                <div className="col-md-12 text-center">
                                  <ul id="recordingslist"></ul>
                                </div>
                              </td>
                            </tr>
                            <tr id="action_tr" style={{ display: "none" }}>
                              <td className="col-md-4">Name your audio file</td>
                              <td className="col-md-8">
                                <div id="action">
                                  <div className="col-md-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="recording_name"
                                      id="recording_name"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      className="btn green-jungle  margin-right-10"
                                      onClick={(e) => {
                                        window.submit();
                                      }}
                                      id="alert_show"
                                    >
                                      Submit
                                    </button>
                                    <button
                                      className="btn btn-default"
                                      onClick={(e) => {
                                        window.reset();
                                      }}
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              My Audio Files
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_myrecordings"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>File size</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              PAM - Voicemail Drops
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <tbody>
                              <tr>
                                <td width="33%">
                                  {" "}
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={1}
                                    label={this.state.lbl_pam1}
                                    global={false}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam1"
                                    onSubmit={this.submitformPam1}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam1"
                                      />
                                      <select
                                        id="pam1"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam1}
                                        onChange={(e) => {
                                          this.setState({
                                            pam1: e.target.value,
                                          });
                                          window
                                            .$("#pam1")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              key={audio_file.id}
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={2}
                                    label={this.state.lbl_pam2}
                                    global={false}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam2"
                                    onSubmit={this.submitformPam2}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam2"
                                      />
                                      <select
                                        id="pam2"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam2}
                                        onChange={(e) => {
                                          this.setState({
                                            pam2: e.target.value,
                                          });
                                          window
                                            .$("#pam2")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              key={audio_file.id}
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={3}
                                    label={this.state.lbl_pam3}
                                    global={false}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam3"
                                    onSubmit={this.submitformPam3}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam3"
                                      />
                                      <select
                                        id="pam3"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam3}
                                        onChange={(e) => {
                                          this.setState({
                                            pam3: e.target.value,
                                          });
                                          window
                                            .$("#pam3")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              key={audio_file.id}
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={4}
                                    label={this.state.lbl_pam4}
                                    global={false}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam4"
                                    onSubmit={this.submitformPam4}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam4"
                                      />
                                      <select
                                        id="pam4"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam4}
                                        onChange={(e) => {
                                          this.setState({
                                            pam4: e.target.value,
                                          });
                                          window
                                            .$("#pam4")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              key={audio_file.id}
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <PAMLabel
                                    baseUrl={this.props.baseUrl}
                                    pam={5}
                                    label={this.state.lbl_pam5}
                                    global={false}
                                  ></PAMLabel>
                                </td>
                                <td>
                                  <form
                                    id="form_update_pam5"
                                    onSubmit={this.submitformPam5}
                                  >
                                    <div className="input-group select2-bootstrap-append">
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_pam5"
                                      />
                                      <select
                                        id="pam5"
                                        name="pam"
                                        className="select2 form-control select2-allow-clear"
                                        value={this.state.pam5}
                                        onChange={(e) => {
                                          this.setState({
                                            pam5: e.target.value,
                                          });
                                          window
                                            .$("#pam5")
                                            .trigger("change.select2");
                                        }}
                                      >
                                        {this.state.audio_files.map(
                                          (audio_file) => (
                                            <option
                                              key={audio_file.id}
                                              value={
                                                audio_file.id +
                                                "-" +
                                                audio_file.audio
                                              }
                                            >
                                              {audio_file.audio}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="input-group-btn">
                                        <button
                                          className="btn green-jungle"
                                          type="submit"
                                          data-select2-open="single-append-text"
                                        >
                                          <span className="fa fa-check"></span>
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*View Recording modal start */}
        <div
          id="modal_viewRec"
          className="modal fade"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title" id="modal_viewrec_title">
                  View Recording{" "}
                </h4>
              </div>
              <form className="form-horizontal">
                <div className="modal-body">
                  <div className="form-body text-center">
                    <audio
                      autoPlay
                      controls
                      id="playRec"
                      controlsList="nodownload"
                    >
                      <source id="mdl_viewrec_audio_src" type="audio/wav" />
                    </audio>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    target="_blank"
                    href="javascript:;"
                    id="mdl_download_rec"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-download"></i>
                  </a>
                  <button
                    onClick={this.stopAudio}
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/**View Recording Modal end */}
      </React.Fragment>
    );
  }
}

export default MyPAM;
