import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class CustomFields extends Component {
  state = {};
  systemfields = [
    {
      id: "lead_status",
      name: "Lead Status",
      code_name: "lead_status",
      type: "system",
    },
  ];
  dt_cfields = null;
  componentDidMount() {
    window.resizeHandle();
    this.dt_cfields = window.dttable(
      "dt_customfields",
      this.props.baseUrl + "/index.php/Manager/Ajax",
      ""
    );
    //load its specific js file ;)
    const script = document.createElement("script");

    script.src = "jsdolphin/fieldsmanager.js";
    script.async = true;

    document.body.appendChild(script);
    ////////////////////////////////////
    /****Below is delete control */
    var Aurl = this.props.baseUrl;
    var dt_customfs = this.dt_cfields;
    $("#dt_customfields").on("click", ".btn-danger", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      window.App.blockUI();
      fetch(Aurl + "/index.php/Manager/Delete_customfield/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            dt_customfs.api().ajax.reload();
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          window.App.unblockUI();
        })
        .catch((e) => {
          window.App.unblockUI();
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //see if we are supposed to show alert
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    if (show_alert !== undefined) {
      window.displayAlert(
        "#alert_container",
        window.global_alert_msg,
        "success"
      );
      window.global_alert_msg = "";
    }
    //////////////////////////////////////////
  }
  handleCustomFieldAdd = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Manager/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#formCustomFieldAdd").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
          this.dt_cfields.api().ajax.reload();
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Add Custom Fields
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <form
                            class="form-inline"
                            id="formCustomFieldAdd"
                            onSubmit={this.handleCustomFieldAdd}
                          >
                            <input
                              type="hidden"
                              name="action"
                              value="add_custom_fields"
                            />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="col-md-3 control-label">
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="name"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="col-md-3 control-label">
                                    Type
                                  </label>
                                  <div className="col-md-9">
                                    <select
                                      className="bs-select form-control"
                                      name="type"
                                      id="type"
                                    >
                                      <option value="TEXT">Text field</option>
                                      <option value="AREA">Text Box</option>
                                      <option value="SELECT">Drop Down</option>
                                    </select>
                                  </div>
                                </div>
                                <span
                                  className="field_option"
                                  id="field_option"
                                >
                                  <div className="form-group">
                                    <label className="col-md-3 control-label">
                                      Options
                                    </label>
                                    <div className="col-md-9">
                                      <textarea
                                        className="form-control"
                                        type="text"
                                        name="options"
                                      ></textarea>
                                    </div>
                                  </div>
                                </span>
                                <button
                                  type="submit"
                                  className="btn green-jungle"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Existing Custom Fields
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_customfields"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>

                                <th>Name</th>
                                <th>Code Name</th>

                                <th>Type</th>

                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                      {this.props.user_role == "superadmin" ? (
                        <div className="portlet light portlet-fit ">
                          <div
                            className="portlet-title"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="caption">
                              <i className="fa fa-leaf"></i>
                              <span className="caption-subject font-dark sbold uppercase">
                                Existing Editable System Fields
                              </span>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <table className="table table-striped table-bordered table-advance table-hover">
                              <thead>
                                <tr>
                                  <th>ID</th>

                                  <th>Name</th>
                                  <th>Code Name</th>
                                  <th>Type</th>
                                  <th>Edit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.systemfields.map((sysfield) => (
                                  <tr>
                                    <td>{sysfield.id}</td>
                                    <td>{sysfield.name}</td>
                                    <td>{sysfield.code_name}</td>
                                    <td>{sysfield.type}</td>
                                    <td>
                                      <a
                                        href={
                                          "#/admin/sysfieldedit/" + sysfield.id
                                        }
                                      >
                                        Edit
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomFields;
