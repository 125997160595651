import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { Popconfirm, Button, Modal } from "antd";
import App from "../App";
const allowed_failed_login_attempts = 3;
class Loginn extends Component {
  state = {
    isLoggedIn: false,
    email: "",
    password: "",
    force_login: false,
    loading: false,
    pc_fl_visible: false,
    pc_fl_confirmloading: false,
    force_login_id: "",
    failed_login_attempts: 0,
  };
  constructor() {
    super();
  }
  componentDidMount() {
    var login_scripts = [
      "assets/global/plugins/jquery-validation/js/jquery.validate.min.js",
      "assets/global/plugins/jquery-validation/js/additional-methods.min.js",
      "assets/global/scripts/login.js",
    ];
    $("body").addClass("login");

    for (var i = 0; i < login_scripts.length; i++) {
      var script = document.createElement("script");
      script.src = login_scripts[i];
      script.async = true;
      document.body.appendChild(script);
    }
    console.log(this.props.baseUrl);
  }
  componentWillUnmount() {
    $("body").removeClass("login");
  }
  inputHandler = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({ [name]: val });
  };
  formLoginSubmitHandler = (event) => {
    event.preventDefault();
    window.App.blockUI(); //blocking UI
    this.setState({ loading: true });
    //console.log(event);
    console.log(
      this.state.email + " " + this.state.password + " " + this.props.baseUrl
    );
    fetch(this.props.baseUrl + "/index.php/Login/checkDuplicateLogin", {
      method: "post",
      credentials: "include",
      body: new URLSearchParams($(".login-form").serialize()),
    })
      .then((blob) => blob.json())
      .then((data) => {
        this.setState({ loading: false });
        console.log(data);
        var force_logout = window.$("#force_login").val();
        if (
          !data.login ||
          data.status == "inactive" ||
          this.state.force_login == true
        ) {
          console.log("ready to login");
          fetch(this.props.baseUrl + "/index.php/Login/Login", {
            credentials: "include",
            method: "post",
            body: new URLSearchParams($(".login-form").serialize()),
          })
            .then((blob) => {
              //console.log(blob.headers.get('Dolphin_ci_sessions'));

              return blob.json();
            })
            .then((data) => {
              console.error(data);
              if (data.status) {
                this.setState({ isLoggedIn: true });
                this.props.handleLoginStateChange(data);
              } else {
                this.setState({
                  failed_login_attempts: this.state.failed_login_attempts + 1,
                });
                if (
                  this.state.failed_login_attempts ===
                  allowed_failed_login_attempts
                ) {
                  window.displayAlert(
                    "#alert_container",
                    "Invalid Username or Password. Please contact Support if you keep on experiencing this issue",
                    "danger"
                  );
                } else {
                  window.displayAlert("#alert_container", data.msg, "danger");
                }
              }
            })
            .catch((e) => {
              window.displayAlert(
                "#alert_container",
                "An error occurred",
                "danger"
              );
            });
          window.App.unblockUI(); //unblockUI
        } else {
          this.setState(
            { pc_fl_visible: true, force_login_id: data.login_id },
            function () {
              window.App.unblockUI(); //unblockUI
            }
          );

          //alert(':O');
          /*if (data.status == "inactive") {
            //alert('you need submitt');
            //$("#btn_loginsubmit").click(); // form validation success, call ajax form submit
          } else if (data.status == "active") {*/
          //display message to force logout ;)

          /*        window.bootbox.dialog({
            message: "Another User is already Logged In.",
            title: "Account In Use",
            buttons: {
              success: {
                label: "Force Logout",
                className: "red",
                callback: function () {
                  //set force logout to 1 and submit form
                  window.setNativeValue(
                    document.getElementById("force_login"),
                    "1"
                  );
                  //window.$("#force_login").val("1");
                  window.$("#btn_loginsubmit").click();
                },
              },
              danger: {
                label: "Cancel",
                className: "orange",
                callback: function () {
                  //alert("uh oh, look out!");
                },
              },
            },
          });
*/
          //alert('you need forced logout');
          /*} else {
            //display msg to tell another user is logged in and in call
            window.bootbox.alert(
              "This Account is already logged in. User is in a call, try again later."
            );
          }*/
        }
      })
      .catch((e) => {
        window.displayAlert("#alert_container", "An error occurred", "danger");
      });
    return false;
  };
  render() {
    return (
      <React.Fragment>
        <div className="logo">
          <a href="index.html">
            <img src="assets/img/dolphin.png" alt="" width="200px" />{" "}
          </a>
        </div>

        <div className="content">
          <form className="login-form" onSubmit={this.formLoginSubmitHandler}>
            <h3 className="form-title font-green">Sign In</h3>
            <div className="form-group">
              <input
                type="hidden"
                name="force_login"
                id="force_login"
                value={this.state.force_login == true ? "1" : "0"}
              />
              <input
                type="hidden"
                name="force_login_id"
                id="force_login_id"
                value={this.state.force_login_id}
              />
              <label className="control-label visible-ie8 visible-ie9">
                Username
              </label>
              <input
                onChange={this.inputHandler}
                className="form-control form-control-solid placeholder-no-fix"
                type="text"
                autoComplete="asdadawqe"
                placeholder="User ID / Email"
                id="email"
                name="email"
              />{" "}
            </div>
            <div className="form-group">
              <label className="control-label visible-ie8 visible-ie9">
                Password
              </label>
              <input
                onChange={this.inputHandler}
                className="form-control form-control-solid placeholder-no-fix"
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                id="password"
                name="password"
              />{" "}
            </div>
            <span id="alert_container"></span>
            <div className="form-actions">
              <button
                type="submit"
                id="btn_loginsubmit"
                className="btn green uppercase"
                disabled={this.state.loading}
              >
                Login
              </button>
              <label className="rememberme check">
                <input type="checkbox" name="remember" value="1" />
                Remember{" "}
              </label>
            </div>
          </form>
          <form className="forget-form" action="index.html" method="post">
            <h3 className="font-green">Forget Password ?</h3>
            <p> Enter your e-mail address below to reset your password. </p>
            <div className="form-group">
              <input
                className="form-control placeholder-no-fix"
                type="text"
                autoComplete="off"
                placeholder="Email"
                name="email"
              />{" "}
            </div>
            <div className="form-actions">
              <button type="button" id="back-btn" className="btn btn-default">
                Back
              </button>
              <button
                type="submit"
                className="btn green-jungle uppercase pull-right"
              >
                Submit
              </button>
            </div>
          </form>

          <form className="register-form">
            <h3 className="font-green">Sign Up</h3>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Full Name
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="Full Name"
                    name="fullname"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Email
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="Email"
                    name="email"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Preferred url prefix
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="Preferred url prefix"
                    name="url_prefix"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Address
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="Address"
                    name="address"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    City/Town
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="City/Town"
                    name="city"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    State/Province
                  </label>
                  <input
                    className="form-control placeholder-no-fix"
                    type="text"
                    placeholder="State/Province"
                    name="state"
                  />{" "}
                </div>
                <div className="form-group">
                  <label className="control-label visible-ie8 visible-ie9">
                    Country
                  </label>
                  <select name="country" className="form-control">
                    <option value="">Country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia and Herzegowina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote d'Ivoire</option>
                    <option value="HR">Croatia (Hrvatska)</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard and Mc Donald Islands</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran (Islamic Republic of)</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macau</option>
                    <option value="MK">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint LUCIA</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia (Slovak Republic)</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SH">St. Helena</option>
                    <option value="PM">St. Pierre and Miquelon</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen Islands</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands (British)</option>
                    <option value="VI">Virgin Islands (U.S.)</option>
                    <option value="WF">Wallis and Futuna Islands</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group margin-top-20 margin-bottom-20">
              <label className="check">
                <input type="checkbox" name="tnc" /> I agree to the
                <a href="#"> Terms of Service </a> &ampersand;
                <a hrf="#"> Privacy Policy </a>
              </label>
              <div id="register_tnc_error"> </div>
            </div>
            <div className="form-actions">
              <button
                type="button"
                id="register-back-btn"
                className="btn btn-default"
              >
                Back
              </button>
              <button
                type="submit"
                id="register-submit-btn"
                className="btn green-jungle uppercase pull-right"
              >
                Submit
              </button>
            </div>
          </form>
          <div
            className="copyright"
            style={{
              color: "black",
              padding: "0",
              fontSize: "11px",
              marginBottom: "-15px",
            }}
          >
            {" "}
            &copy; {window.moment().format("YYYY")}{" "}
            <a
              target="_blank"
              style={{ color: "#15157a" }}
              href="https://evs7.com"
            >
              Electronic Voice Services, Inc. (EVS7)
            </a>{" "}
            |{" "}
            <a style={{ color: "#15157a" }} href="mailto:support@evs7.com">
              support@evs7.com
            </a>{" "}
          </div>
        </div>
        {/*<div className="copyright" style={{ color: "black" }}>
          {" "}
          &copy; {window.moment().format("YYYY")}{" "}
          <a style={{ color: "#15157a" }} href="evs7.com">
            Electronic Voice Services, Inc. (EVS7)
          </a>{" "}
          |{" "}
          <a style={{ color: "#15157a" }} href="mailto:support@evs7.com">
            support@evs7.com
          </a>{" "}
    </div>*/}
        <Modal
          closable={false}
          title="Account in use."
          visible={this.state.pc_fl_visible}
          okButtonProps={this.state.pc_fl_confirmloading}
          cancelButtonProps={{ danger: true }}
          onOk={() => {
            this.setState({
              pc_fl_visible: false,
              pc_fl_confirmloading: false,
            });
          }}
          onCancel={() => {
            this.setState(
              {
                pc_fl_visible: false,
                force_login: true,
                pc_fl_confirmloading: true,
              },
              function () {
                window.$("#btn_loginsubmit").click();
                this.setState({
                  pc_fl_confirmloading: false,
                  pc_fl_visible: false,
                });
              }
            );
          }}
          okText="Cancel"
          cancelText="Force Logout"
        >
          <p>Another User is already Logged In.</p>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Loginn;
