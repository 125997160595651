import React, { Component } from "react";
import HeadTitle from "../../HeadTitle";
class RingGroupAdd extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Ring Groups" subtitle="add" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                className="listEditform"
                                onSubmit={this.handleSubmit}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_did"
                                />

                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_id"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Description</td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          name="list_name"
                                        ></textarea>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Greeting Voice</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                          id="list_active"
                                        >
                                          <option value="Y">Audio 1</option>
                                          <option value="N">Audio 2</option>
                                        </select>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                          id="list_active"
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td>Destinations</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_last_call_date"
                                          placeholder="Extension 1"
                                        />
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_last_call_date"
                                          placeholder="Extension 2"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Timeout Destination</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_last_call_date"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Timeout</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_last_call_date"
                                          placeholder="Seconds"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colSpan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RingGroupAdd;
