import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "./HeadTitle";
class MyCallbacks extends Component {
  state = { callbacks: [] };
  dt_callbacks = null;
  componentDidMount() {
    window.resizeHandle();
    //this.initCallbacks();

    //load callbacks list
    this.dt_callbacks = window.dttable(
      "db_leadevents",
      this.props.baseUrl + "/index.php/LeadEvents/dt_MyCallbacks",
      [
        {
          orderable: false,
          targets: [1, 4, 7, 8],
        },
        {
          visible: false,
          targets: [0],
        },
      ],
      "body",
      [0, "asc"]
    );
    ////////////////////////
    /****remove callback handler */

    var Aurl = this.props.baseUrl;
    $("#db_leadevents").on("click", ".btn-deleter", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      window.App.blockUI();
      fetch(Aurl + "/index.php/LeadEvents/post_process/" + childKey, {
        method: "POST",
        credentials: "include",
        body: new URLSearchParams("action=remove_callback&id=" + childKey),
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          window.App.unblockUI();
        })
        .catch((e) => {
          window.App.unblockUI();
        });
    });

    $("#db_leadevents").on("click", ".btn-viewhistory", function () {
      var childKey = $(this).attr("event_id");
      // alert(childKey);
      window.App.blockUI();
      fetch(
        Aurl + "/index.php/LeadEvents/displayEventEditHistory/" + childKey,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          //callbackedithistory_tbody
          if (data.status) {
            var data_html = "";
            for (var i = 0; i < data.msg.length; i++) {
              data_html += `<tr><td>${data.msg[i].crt_on_tz}</td><td>${data.msg[i].sch_time}</td></tr>`;
            }
            window.$("#callbackedithistory_tbody").html(data_html);
            // alert("1");
            window.$("#callbackedithiostory_modal").modal("show");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          window.App.unblockUI();
        })
        .catch((e) => {
          window.App.unblockUI();
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    /******************************** */
  }
  /*removeCallback = (e, i, action) => {
    e.preventDefault();
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/LeadEvents/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams(
        "action=" + action + "&id=" + this.state.callbacks[i].id
      ),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
          this.initCallbacks();
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
  };*/
  initCallbacks = () => {
    //fetch data from pam actually ;)
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/LeadEvents/index/callbacks/me", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        if (resp.status) {
          this.setState({
            callbacks: resp.msg,
          });
        }
        window.initConfirmation("#db_leadevents");
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
    ///////////////////////////////////////
  };
  viewLeadEditHistroy = ($id) => {
    //displayEventEditHistory
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="My Callbacks" subtitle="" />

            <div className="page-content">
              <div classNam="alert_container"></div>
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="db_leadevents"
                          >
                            <thead>
                              <tr>
                                <th>Call Back Date(db format) </th>
                                <th>Call Back Date</th>
                                <th>List ID</th>
                                <th>Full Name</th>
                                <th>Call Now</th>
                                <th>Lead Status</th>
                                <th>Owner</th>
                                <th>Date Modified</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***Modal for agreement sining */}
        <div
          id="callbackedithiostory_modal"
          className="modal fade"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Edit History for selected </h4>
              </div>
              <div className="modal-body">
                <table className="table table-striped table-bordered table-advance table-hover">
                  <thead>
                    <tr>
                      <th>Edited ON</th>
                      <th>Scheduled ON</th>
                    </tr>
                  </thead>
                  <tbody id="callbackedithistory_tbody"></tbody>
                </table>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/******Modal for agreement end */}
      </React.Fragment>
    );
  }
}

export default MyCallbacks;
