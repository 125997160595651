import React, { Component, useState } from 'react'
import {
  Table,
  Form,
  Popconfirm,
  Button,
  Modal,
  Input,
  Select,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
  Tooltip,
} from 'antd'
import '../../../css/table_antd.css'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import HeadTitle from '../../HeadTitle'
import UsersDropDown from '../../Bricks/UsersDropDown'
//const [form] = Form.useForm();
const { Option } = Select
const originData = []
/*
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}*/

class DIDlist extends Component {
  form = React.createRef()
  state = {
    data: [],
    modified_data: [],
    editingKey: '',
    destination_app: 'transfer',
    destination_data: null,
    selected_user: null,
    selected_users: [],
    all_users: [],
    isModalVisible: false,
    selection_type: '',
    selected_values: [],
    destination_to_number: '',
    changes_to_commit: false,

    selectedRowKeys: [],
  }
  //const [data, setData] = useState(originData);
  //const [editingKey, setEditingKey] = useState("");
  componentDidMount() {
    window.resizeHandle()
    this.fetchData()
    this.fillUsers()
  }
  isEditing = (record) => record.key === this.state.editingKey

  edit = (record) => {
    this.form.current.setFieldsValue({
      destination_number: '',
      destination_app: 'transfer',
      ...record,
    })
    this.setState({ editingKey: record.key })
  }

  cancel = () => {
    this.setState({
      editingKey: '',
      selection_type: '',
      destination_to_number: '',
      selected_values: [],
    })
  }

  save = async (key) => {
    this.setState({ changes_to_commit: true })
    try {
      console.log('Great tabboo', this.form.current)
      const row = await this.form.current.validateFields()

      const newData = [...this.state.data]
      const index = newData.findIndex((item) => key === item.key)

      newData[index].selection_type = this.state.selection_type
      newData[index].selected_values = this.state.selected_values
      newData[index].destination_to_number = this.state.destination_to_number
      newData[index].modified = true

      console.log('The retreived data', row, 'The actual data', newData[index])
      this.setState({
        editingKey: '',
        selection_type: '',
        selected_values: [],
        destination_to_number: '',
        data: newData,
      })
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  /***fetch users to redirect inbound */
  fillUsers = () => {
    fetch(this.props.baseUrl + '/index.php/User/Ajax', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams('action=list_users'),
    })
      .then((data) => {
        //console.log(data);
        return data.json()
      })
      .then((data) => {
        console.log(data)
        var users = []
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].key,
            name: data[i].value,
          }
          users.push(list_ob)
        }
        //console.log(users);
        this.setState({ all_users: users })
      })
      .catch((err) => {})
  }
  /************************************ */

  columns = [
    {
      title: 'Phone Number',
      dataIndex: 'destination_number',
      width: '35%',
      editable: false,
    },
    {
      title: 'Route To',
      dataIndex: 'destination_app',
      width: '35%',
      editable: true,
      render: (text, record) => {
        let ret_text = ''
        const dest_data = record.destination_data
          ? record.destination_data.split(' ')
          : ['', '']
        if (record.destination_app === 'transfer') {
          if (dest_data[0].indexOf('*99') !== -1) {
            ret_text = 'Voice mail'
          } else if (
            dest_data[0].indexOf('700') !== -1 ||
            dest_data[0].length > 4
          ) {
            ret_text = 'Ring Group '
          } else ret_text = 'Agent'
        }
        let text_row_edited = null
        if (record.modified === true) {
          text_row_edited = (
            <React.Fragment>
              {`Change to ${record.selection_type
                .replace('_', ' ')
                .toUpperCase()} `}
              <Button
                danger
                type="link"
                class="btn btn-sm btn-danger"
                onClick={(e) => {
                  //clear this row from edits
                  let tdata = this.state.data
                  let change = false

                  for (var i = 0; i < tdata.length; i++) {
                    if (tdata[i].destination_uuid == record.destination_uuid) {
                      delete tdata[i].selection_type
                      delete tdata[i].selected_values
                      delete tdata[i].destination_to_number
                      delete tdata[i].modified
                      change = true
                      break
                    }
                  }
                  this.setState({ data: tdata })
                }}
              >
                <i class="fa fa-trash"></i>
              </Button>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            {' '}
            {`${ret_text} `}
            {ret_text === 'Ring Group ' ? (
              <Button
                type="link"
                onClick={(e) => {
                  Modal.info({
                    title: 'Included Users',
                    content: (
                      <div>
                        {record.destination_ring_groups.map((user) => (
                          <p>{user}</p>
                        ))}
                      </div>
                    ),
                  })
                }}
              >
                {dest_data[0]}
              </Button>
            ) : (
              dest_data[0]
            )}
            {text_row_edited == null ? '' : text_row_edited}
          </React.Fragment>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = this.isEditing(record)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => this.save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={this.cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={
              this.state.editingKey !== '' ||
              this.state.selectedRowKeys.length > 0
            }
            onClick={() => this.edit(record)}
          >
            Edit
          </Typography.Link>
        )
      },
    },
  ]
  mergedColumns = this.columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'destination_app' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: this.isEditing(record),
      }),
    }
  })

  /***Our editable cell */
  EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === 'select' ? (
        <Select
          value={this.state.selection_type}
          title="Route"
          onSelect={(sel_val) => {
            console.log('The stuff', sel_val)
            //specify_agents -> based on selected value do a modal to select users ;)
            //ext_number -> popup a modal to fill in an external number
            //to_voicemail -> popup a modal to select voicemail of a user
            if (sel_val === 'all_agents') {
              this.setState({ selection_type: 'all_agents' })
            } else
              this.setState({ isModalVisible: true, selection_type: sel_val })
          }}
        >
          <Option value="all_agents">Route to all agents</Option>
          <Option value="specific_agents">Route to specific agent(s)</Option>
          <Option value="external_number">Forward to an external number</Option>
          <Option value="voicemail">Directly to voicemail</Option>
        </Select>
      ) : (
        <Input />
      )
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={'Route'}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input a value!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }
  /**************************** */
  handleRoute = () => {
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Tenants/Ajax/do_inbound_route', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams({
        data: JSON.stringify(this.state.data),
      }),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((response) => {
        window.displayAlert(
          '#alert_container',
          response.msg,
          response.status ? 'success' : 'danger'
        )

        this.fetchData()

        /*console.log("yeh aya", response);
        let tdata = this.state.data;
        for (var i = 0; i < tdata.length; i++) {
          tdata.modified = false;
          delete tdata.modified;
          delete tdata.selection_type;
          delete tdata.selected_values;
        }
        this.setState({ data: tdata, selected_values: [], selection_type: "" });
        this.props.blockUI(false);*/
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  fetchData = () => {
    this.props.blockUI(true)
    this.setState(
      {
        changes_to_commit: false,
        data: [],
        modified_data: [],
        editingKey: '',
        destination_app: 'transfer',
        destination_data: null,
        selected_user: null,
        selected_users: [],
        all_users: [],
        isModalVisible: false,
        selection_type: '',
        selected_values: [],
        destination_to_number: '',
        changes_to_commit: false,

        selectedRowKeys: [],
      },
      () => {
        fetch(
          this.props.baseUrl +
            '/index.php/Tenants/Ajax/list_inbound_route?tenant_id=64',
          {
            credentials: 'include',
            method: 'get',
          }
        )
          .then((blob) => {
            return blob.json()
          })
          .then((response) => {
            console.log('We got this', response)
            try {
              if (response.data.length > 0) {
                let temp_data = []
                for (var i = 0; i < response.data.length; i++) {
                  temp_data.push({
                    key: i,
                    //name: response.data[i].destination_number,
                    //age: response.data[i].destination_prefix,
                    //address: response.data[i].destination_app,
                    ...response.data[i],
                  })
                }
                this.setState({
                  data: temp_data,
                })
              }
            } catch (e) {}

            this.props.blockUI(false)
          })
          .catch((e) => {
            this.props.blockUI(false)
          })
      }
    )
  }
  /***checkbox selection */
  onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', this.state.selectedRowKeys)
    this.setState({ selectedRowKeys: newSelectedRowKeys })
  }

  rowSelection = {
    selectedRowKeys: this.selectedRowKeys,
    onChange: this.onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: this.isEditing(record), // Column configuration not to be checked
    }),
  }
  hasSelected = this.state.selectedRowKeys.length > 0

  //this part is to do dropdown menu button stuff mangement
  handleButtonClick = (e) => {
    message.info('Click on left button.')
    console.log('click left button', e)
  }

  handleMenuClick = (e) => {
    // message.info("Click on menu item.");
    // console.log("click", e);

    /**let mod_data = [];
        for (var i = 0; i < this.state.selectedRowKeys.length; i++) {
          mod_data[i] = this.state.data[this.state.selectedRowKeys[i]];
          mod_data[i].selection_type = "all_agents";
          mod_data[i].selected_values = "";
          mod_data[i].destination_to_number = "";
          mod_data[i].modified = true;
        }
        console.log("this is the data we will submit back", mod_data); */
    if (e.key === 'all_agents') {
      this.setState({ selection_type: e.key }, () => {
        Modal.confirm({
          content: 'Are you sure?',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            this.applyRowSelectionAndSubmit()
          },
          onCancel: () => {
            console.log('Cancel')
          },
        })
      })
    } else this.setState({ selection_type: e.key, isModalVisible: true })
  }
  menuDD = (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="all_agents">Route to all agents</Menu.Item>
      <Menu.Item key="specific_agents">Route to specific agent(s)</Menu.Item>
      <Menu.Item key="external_number">Forward to an external number</Menu.Item>
      <Menu.Item key="voicemail">Directly to voicemail</Menu.Item>
    </Menu>
  )
  applyRowSelectionAndSubmit = () => {
    this.props.blockUI(true)
    let mod_data = []
    for (var i = 0; i < this.state.selectedRowKeys.length; i++) {
      mod_data[i] = this.state.data[this.state.selectedRowKeys[i]]
      mod_data[i].selection_type = this.state.selection_type
      mod_data[i].selected_values = this.state.selected_values
      mod_data[i].destination_to_number = this.state.destination_to_number
      mod_data[i].modified = true
    }
    fetch(this.props.baseUrl + '/index.php/Tenants/Ajax/do_inbound_route', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams({
        data: JSON.stringify(mod_data),
      }),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((response) => {
        window.displayAlert(
          '#alert_container',
          response.msg,
          response.status ? 'success' : 'danger'
        )

        this.fetchData()

        /*console.log("yeh aya", response);
        let tdata = this.state.data;
        for (var i = 0; i < tdata.length; i++) {
          tdata.modified = false;
          delete tdata.modified;
          delete tdata.selection_type;
          delete tdata.selected_values;
        }
        this.setState({ data: tdata, selected_values: [], selection_type: "" });
        this.props.blockUI(false);*/
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
    console.log('this is the data we will submit back', mod_data)
  }
  //
  /**************************** */
  render() {
    return (
      <React.Fragment>
        {/*cooribut*/}
        <Modal
          title={
            this.state.selection_type == 'specific_agents'
              ? 'Specify Agents'
              : this.state.selection_type == 'external_number'
              ? 'External Number'
              : this.state.selection_type == 'voicemail'
              ? 'Select Voicemail'
              : ''
          }
          visible={this.state.isModalVisible}
          onOk={() => {
            this.setState({ isModalVisible: false }, () => {
              //this.handleRoute();
              //alert(this.state.selectedRowKeys.length);
              if (this.state.selectedRowKeys.length > 0) {
                this.applyRowSelectionAndSubmit()
              }
            })
          }}
          onCancel={() => {
            this.setState({ isModalVisible: false })
          }}
        >
          {this.state.selection_type == 'specific_agents' ? (
            <Select
              mode="multiple"
              className="form-control"
              allowClear
              value={this.state.selected_values}
              onChange={(vals) => {
                console.log('ON Select', vals)
                this.setState({ selected_values: vals })
              }}
            >
              {this.state.all_users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          ) : this.state.selection_type == 'external_number' ? (
            <React.Fragment>
              <table className="table table-responsive table-bordered">
                <tbody>
                  <tr>
                    <th>External Number</th>
                    <td>
                      <Input
                        className="form-control"
                        placeholder="Destination number "
                        onChange={(e) => {
                          this.setState({
                            destination_to_number: e.target.value,
                          })
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Extension</th>
                    <td>
                      <Select
                        className="form-control"
                        onChange={(val) => {
                          this.setState({ selected_values: val })
                        }}
                      >
                        {this.state.all_users.map((user) => (
                          <Option value={user.id}>{user.name}</Option>
                        ))}
                      </Select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          ) : this.state.selection_type == 'voicemail' ? (
            <Select
              className="form-control"
              onChange={(val) => {
                this.setState({ selected_values: val })
              }}
            >
              {this.state.all_users.map((user) => (
                <Option value={user.id}>{user.name}</Option>
              ))}
            </Select>
          ) : (
            ''
          )}
        </Modal>
        {/*cooribut*/}
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="DIDs" subtitle="add" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        {/****Title to Apply cancel ;) */}
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Adjust Inbound Routing
                            </span>
                          </div>

                          <div className="actions">
                            <Dropdown
                              overlay={this.menuDD}
                              overlayStyle={{
                                borderWidth: '1px',
                                borderColor: '#2b95bf',
                                borderRadius: '2px',
                                color: '#2b95bf',
                              }}
                              disabled={this.state.selectedRowKeys.length <= 0}
                            >
                              <Button
                                className="btn btn-primary"
                                style={{
                                  marginRight: '3px',
                                  marginBottom: '4px',
                                }}
                              >
                                Route &nbsp;<i class="fa fa-chevron-down"></i>
                              </Button>
                            </Dropdown>
                            <Button
                              onClick={(e) => {
                                this.handleRoute()
                              }}
                              disabled={
                                !this.state.changes_to_commit ||
                                this.state.selectedRowKeys > 0
                              }
                            >
                              Apply Changes
                            </Button>
                          </div>
                        </div>
                        {/************************************ */}

                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Form ref={this.form} component={false}>
                                <Table
                                  size={'small'}
                                  components={{
                                    body: {
                                      cell: this.EditableCell,
                                    },
                                  }}
                                  bordered
                                  dataSource={this.state.data}
                                  columns={this.mergedColumns}
                                  rowClassName="editable-row"
                                  pagination={{
                                    onChange: this.cancel,
                                  }}
                                  rowSelection={this.rowSelection}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

/*
const { Option } = Select;

let formRef = React.createRef();
const EditableCell = (props) => {
  const inputNode =
    props.inputType === "number" ? (
      <InputNumber />
    ) : (
      <Select onChange={(val) => {}}>
        <Option value="all_agents">Route to all agents</Option>
        <Option value="specify_agents">Route to specific agent(s)</Option>
        <Option value="ext_number">Forward to an external number</Option>
        <Option value="to_voicemail">Directly to voicemail</Option>
      </Select>
    );

  return (
    <td>
      {props.editing ? (
        <Form.Item
          name={props.dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${props.title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        props.children
      )}
    </td>
  );
};

class DIDlist extends React.Component {
  state = {
    page: 0,
    page_size: 10,
    sorter: null,
    filter: null,
    selectedRowsIds: [],
    pagination: { pageSize: 10 },
    loading: false,
    data: [],
    alert_msg: "",
    did_edit: {
      destination_accountcode: null,
      destination_alternate_app: null,
      destination_alternate_data: null,
      destination_app: null,
      destination_area_code: null,
      destination_caller_id_name: null,
      destination_caller_id_number: null,
      destination_cid_name_prefix: null,
      destination_condition_field: null,
      destination_context: null,
      destination_data: null,
      destination_description: null,
      destination_enabled: null,
      destination_hold_music: null,
      destination_number: null,
      destination_number_regex: null,
      destination_order: null,
      destination_prefix: null,
      destination_record: null,
      destination_trunk_prefix: null,
      destination_type: null,
      destination_type_emergency: null,
      destination_type_fax: null,
      destination_type_text: null,
      destination_type_voice: null,
      destination_uuid: null,
      dialplan_uuid: null,
      domain_uuid: null,
      fax_uuid: null,
      user_uuid: null,
    },
    editing_key: "",
  };
  componentDidMount() {
    window.resizeHandle();
    this.fetchData();
  }
  edit = (record) => {
    formRef.current.setFieldsValue({
      ...record,
    });
    this.setState({ editing_key: record.destination_uuid });
  };

  cancel = () => {
    this.setState({ editing_key: "" });
  };

  save = async (key) => {
    try {
      const row = await formRef.current.validateFields();
      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.destination_uuid);
      console.log(newData[index]);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.setState({ data: newData });
        this.setState({ editing_key: "" });
        console.log(
          "The Data",
          key,
          " = ",
          row,
          this.state.data[index],
          "************************"
        );
      } else {
        newData.push(row);
        this.setState({ data: newData });
        this.setState({ editing_key: "" });
        console.log(
          "The Data NOW",
          key,
          " = ",
          row,
          this.state.data[index],
          "************************"
        );
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  handleTableChange = (p, f, s) => {
    this.setState({ pagination: p, filters: f, sorter: s }, () => {
      this.fetchData();
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  fetchData = () => {
    this.props.blockUI(true);
    fetch(
      this.props.baseUrl +
        "/index.php/Tenants/Ajax/list_inbound_route?tenant_id=64",
      {
        credentials: "include",
        method: "get",
      }
    )
      .then((blob) => {
        return blob.json();
      })
      .then((response) => {
        console.log(response);
        this.setState({
          data: response.data,
          pagination: {
            ...this.state.pagination,
            total: 10,
            pageSize: 10,
            current: 1,
          },
        });
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  onSelectChange = (selectedRowKeys, selectedRows) => {
    let row_ids = [];
    for (var row in selectedRows) {
      row_ids.push(selectedRows[row].id);
    }
    this.setState({ selectedRowsIds: row_ids }, () => {
      console.log(this.state.selectedRowsIds);
    });
  };
  isEditing = (record) => record.destination_uuid === this.state.editing_key;
  mergedColumns = () => {
    this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        ...this.getColumnSearchProps(),
      };
    });
  };
  render() {
    const rowSelection = { onChange: this.onSelectChange };
    const columns = [
      {
        title: "Phone Number",
        dataIndex: "destination_number",
        key: "destination_number",
        sorter: true,
        ...this.getColumnSearchProps("destination_number"),
        width: "40%",
        render: (text) => {
          return <small>{text}</small>;
        },
        editable: false,
      },
      {
        title: "Routing",
        dataIndex: "destination_app",
        key: "destination_app",
        sorter: true,
        ...this.getColumnSearchProps("destination_app"),
        width: "40%",
        render: (text) => {
          return <small>{text}</small>;
        },
        editable: true,
        onCell: (record, rowIndex) => ({
          record,
          inputType: "text",
          dataIndex: record.dataIndex,
          title: record.title,
          editing: this.isEditing(record),
        }),
      },
      {
        title: "Action",
        dataIndex: "operation",
        render: (_, record) => {
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <a
                href="javascript:;"
                onClick={() => {
                  this.save(record.destination_uuid);
                  console.log(record);
                }}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </a>
              <Popconfirm title="Sure to cancel?" onConfirm={this.cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={this.state.editing_key !== ""}
              onClick={() => this.edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="DIDs" subtitle="add" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Form ref={formRef} component={false}>
                                <Table
                                  rowSelection={rowSelection}
                                  columns={columns}
                                  dataSource={this.state.data}
                                  pagination={this.state.pagination}
                                  onChange={this.handleTableChange}
                                  size={"small"}
                                  components={{
                                    body: {
                                      cell: EditableCell,
                                    },
                                  }}
                                  rowClassName="editable-row"
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
*/
const MyCustomButton = (props) => {
  return <Button {...props}>where is waldo</Button>
}
export default DIDlist
