import React, { Component } from "react";
import { saveAs } from "file-saver";
import Moment from "react-moment";
import {
  Typography,
  Table,
  Empty,
  Popconfirm,
  Space,
  Button,
  Input,
  DatePicker,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import "../../../css/table_antd.css";
import HeadTitle from "../../HeadTitle";
const { Text } = Typography;
const { RangePicker } = DatePicker;
class TextDetails extends Component {
  state = {
    data: [],
    loading: false,
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSize: 10,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    sorting: {},
    filters: {},
    global_search: "",
    available_credits: 0,
    sent_sms: 0,
    settings: null,
    user_role: "agent",
  };
  componentDidMount() {
    window.resizeHandle();
    this.fillInTheTable({});
    //bring in count etc.,
    fetch(this.props.baseUrl + "/index.php/Ajax/getMyTextStats", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);
        if (resp.status) {
          this.setState({
            available_credits: resp.msg.available_credits,
            sent_sms: resp.msg.sent_sms,
          });
        }
      })
      .catch((resp) => {});
    //
    this.setState({
      settings: this.props.settings,
      user_role: this.props.user_role,
    });
  }
  componentDidUpdate() {
    if (
      this.state.settings == null &&
      this.props.settings !== null &&
      this.props.settings !== undefined
    ) {
      this.setState({ settings: this.props.settings });
    } else {
      if (this.props.settings !== null && this.props.settings !== undefined) {
        let st_change_needed = false;
        for (var i in this.state.settings) {
          if (this.state.settings[i] !== this.props.settings[i]) {
            st_change_needed = true;
            break;
          }
        }
        if (st_change_needed) {
          this.setState({ settings: this.props.settings });
        }
      }
    }
    if (this.state.user_role !== this.props.user_role) {
      this.setState({ user_role: this.props.user_role });
    }
  }
  //Our method to bring in reports list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true });
    fetch(this.props.baseUrl + "/index.php/Report/smsDR", {
      method: "post",
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        this.setState({
          loading: false,
          data: data.results,
          pagination: {
            ...params.pagination,
            total: data.pagination.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          },
          sortField: data.sortField,
          sortOrder: data.sortOrder,
        });
      })
      .catch((er) => {
        this.setState({ loading: false });
        console.log(er);
      });
  };

  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      { pagination: pagination, sorting: sorter, filters },
      function () {
        console.log("filters");
        console.log(filters);
      }
    );
    this.fillInTheTable({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };
  /*********************************** */
  controlElipses = (text) => {
    if (text !== null && text !== undefined)
      text =
        text.length > 17 ? (
          <Text title={text}>{text.substring(0, 17) + "..."}</Text>
        ) : (
          text
        );
    console.warn(text);
    return text;
  };
  /**The special filters */
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          allowEmpty={[false, false]}
          style={{ marginBottom: "8px" }}
          showTime={{ format: "HH:mm" }}
          format="MM-DD-YYYY HH:mm"
          onChange={(val, dateString) => {
            let dur = [];
            try {
              for (var d in val) {
                if (val[d] !== null)
                  dur.push(val[d].format("YYYY-MM-DD HH:mm"));
              }
            } catch (e) {}
            setSelectedKeys(val ? [dur.toString()] : []);
            console.log(dur.toString());
          }}
          onOk={(val) => {
            let dur = [];
            try {
              for (var d in val) {
                if (val[d] !== null)
                  dur.push(val[d].format("YYYY-MM-DD HH:mm"));
              }
            } catch (e) {}
            setSelectedKeys(val ? [dur.toString()] : []);
            console.log(dur.toString());
          }}
        />
        <br />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //console.log(this.searchInput);
        //setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  /**************** */
  /**handle search download */
  handleSearchDownload = () => {
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/Report/smsDR", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        pagination: this.state.pagination,
        sorting: this.state.sorting,
        filters: this.state.filters,
        global_search: this.state.global_search,
        download: true,
      }),
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        let ddd = new Date();
        const filename =
          "dolsms_rep_" +
          "D" +
          ddd.getMonth() +
          "-" +
          ddd.getDate() +
          "-" +
          ddd.getFullYear() +
          "T" +
          ddd.getHours() +
          "_" +
          ddd.getMinutes() +
          "_" +
          ddd.getSeconds() +
          ".csv";
        saveAs(blob, filename);
        window.App.unblockUI();
      })
      .catch((error) => {
        window.App.unblockUI();
        console.log(error);
      });
  };
  display_download_button = (user_role, settings, list) => {
    let show_btn = true;
    let button = (
      <button
        className="btn btn-circle btn-icon-only btn-default"
        onClick={(e) => this.handleSearchDownload()}
      >
        <i className="fa fa-download"></i>{" "}
      </button>
    );
    if (this.state.user_role == "agent") return "";
    if (this.state.user_role == "manager") {
      show_btn = false;
      if (settings !== null) {
        if (settings.mgr_can_exports_leads_reports == "yes") {
          show_btn = true;
        }
      }
    }
    if (show_btn) {
      return button;
    }
    return "";
  };
  /******************************* */
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="SMS Detail report" subtitle="" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row widget-row">
                    <div className="col-md-6">
                      <div class="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                        <h4 class="widget-thumb-heading">
                          Available Credits&nbsp;
                          <small>
                            <a
                              class="pull-right"
                              href="https://www.evs7.com/product/text-messages/"
                              target="_blank"
                            >
                              Buy More
                            </a>
                          </small>
                        </h4>
                        <div class="widget-thumb-wrap">
                          <i class="widget-thumb-icon bg-green icon-envelope"></i>
                          <div class="widget-thumb-body">
                            <span class="widget-thumb-subtitle"></span>
                            <span
                              class="widget-thumb-body-stat"
                              data-counter="counterup"
                              data-value=""
                            >
                              {this.state.available_credits}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                        <h4 class="widget-thumb-heading">Sent Texts&nbsp;</h4>
                        <div class="widget-thumb-wrap">
                          <i class="widget-thumb-icon bg-yellow-gold icon-envelope-letter"></i>
                          <div class="widget-thumb-body">
                            <span class="widget-thumb-subtitle"></span>
                            <span
                              class="widget-thumb-body-stat"
                              data-counter="counterup"
                              data-value="7,644"
                            >
                              {this.state.sent_sms}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div id="bootstrap_alerts_demo"></div>

                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              SMS List
                            </span>
                          </div>
                          <div className="actions">
                            <input
                              placeholder="Search"
                              value={this.state.global_search}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    global_search: e.target.value,
                                  },
                                  function () {
                                    this.fillInTheTable({
                                      global_search: this.state.global_search,
                                    });
                                  }
                                );
                              }}
                              className="form-control input-sm"
                              style={{ width: "160px", display: "inline" }}
                            />
                            &nbsp;
                            {this.display_download_button(
                              this.state.user_role,
                              this.state.settings
                            )}
                          </div>
                        </div>
                        <div className="portlet-body">
                          <Table
                            className="table-striped-rows"
                            size={"small"}
                            tableLayout={"auto"}
                            columns={[
                              {
                                title: "Lead ID",
                                dataIndex: "lead_id",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Agent",
                                dataIndex: "full_name",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Full Name",
                                dataIndex: "fullname",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Sent To",
                                dataIndex: "phone",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Content",
                                dataIndex: "content",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                              },
                              {
                                title: "Date Time",
                                dataIndex: "crt_on",
                                sorter: true,
                                render: (text) => {
                                  return this.controlElipses(text);
                                },
                                ...this.getColumnSearchProps("crt_on"),
                              },
                            ]}
                            dataSource={this.state.data}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                            scroll={{ x: true }}
                            locale={{
                              emptyText: (
                                <Empty description="No SMS Sent"></Empty>
                              ),
                            }}
                          ></Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TextDetails;
