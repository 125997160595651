import React, { Component } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
class LeadEventAdd extends Component {
  render() {
    return (
      <div className="page-container">
        <div className="page-content-wrapper">
          <div className="page-head">
            <div className="container">
              <div className="page-title">
                <h1>
                  Calendar
                  <small></small>
                </h1>
              </div>
            </div>
          </div>

          <div className="page-content">
            <div className="container">
              <div className="page-content-inner">
                <div className="row">
                  <div className="col-md-12">
                    <span id="alert_container"></span>
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">
                          <i className="fa fa-gift"></i>Add an Event
                        </div>
                        <div className="tools">
                          <NavLink to="/" className="collapse"></NavLink>
                        </div>
                      </div>
                      <div className="portlet-body" id="port_adEv">
                        <div className="row">
                          <div className="col-md-12">
                            <form className="AddEventForm form-inline">
                              <input
                                type="hidden"
                                className="form-control"
                                name="action"
                                value="addEvent"
                              />
                              <div className="form-group">
                                <label className="sr-only">Title</label>
                                <input
                                  type="text"
                                  name="ev_title"
                                  className="form-control"
                                  id="ev_title"
                                  placeholder="Title"
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Description</label>
                                <input
                                  type="text"
                                  name="ev_Description"
                                  className="form-control"
                                  id="ev_Description"
                                  placeholder="Description"
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Scheduled At</label>
                                <input
                                  type="text"
                                  name="ev_schAt"
                                  className="date-time-picker form-control"
                                  id="ev_schAt"
                                  placeholder="Schedule"
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label className="sr-only">Type</label>
                                <select
                                  name="ev_type"
                                  className="form-control bs-select"
                                  id="ev_type"
                                >
                                  <option value="general">General</option>

                                  <option value="appointment">
                                    Appointment
                                  </option>
                                </select>
                              </div>
                              <button type="submit" className="btn btn-default">
                                Add
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadEventAdd;
