import React, { Component } from "react";
import $ from "jquery";
class ServerList extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_ajax_servers",
      this.props.baseUrl + "/index.php/Servers/Ajax/list_servers",
      ""
    );
    window.oTable_tenants = window.dttable(
      "dt_ajax_tenants",
      this.props.baseUrl + "/index.php/Tenants/Ajax/list_tenants/'",
      ""
    );
    const script1 = document.createElement("script");
    script1.src = "jsdolphin/serverstenantslist.js";
    //script1.async=true;
    document.body.appendChild(script1);

    var Aurl = this.props.baseUrl;
    var blockUI = this.props.blockUI;
    /****Below is delete control for lead recycle */

    $("#dt_ajax_tenants").on("click", ".btn-danger", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      blockUI(true);
      fetch(Aurl + "/index.php/Tenants/Delete/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUI(false);
        })
        .catch((e) => {
          window.App.unblockUI();
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Client
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;List</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Servers <small id="cur_process">list</small>
                            </span>
                          </div>
                          <div className="actions">
                            <a
                              href="#/pbxservers/addserver"
                              className="btn btn-circle btn-default"
                            >
                              <i className="fa fa-plus"></i> Add{" "}
                            </a>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_ajax_servers"
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>IP</th>
                                <th>URL</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className=" icon-layers font-green"></i>
                            <span className="caption-subject font-green bold uppercase">
                              Tenants <small id="cur_process">list</small>
                            </span>
                          </div>
                          <div className="actions">
                            <a
                              href="#/pbxservers/addtenant"
                              className="btn btn-circle btn-default"
                              id="btn_add_tenant"
                              disabled
                            >
                              <i className="fa fa-plus"></i> Add{" "}
                            </a>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_ajax_tenants"
                          >
                            <thead>
                              <tr>
                                <th>url</th>
                                <th>uuid</th>

                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServerList;
