import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class UserList extends Component {
  state = { available_seats: 0 };
  componentDidMount() {
    window.resizeHandle();
    window.dttable(
      "dt_users",
      this.props.baseUrl + "/index.php/User/dtAjax",
      ""
    );
    //fetch and fill with info
    window.App.blockUI();
    fetch(this.props.baseUrl + "/index.php/User/user_list", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          available_seats: resp.msg.available_seats,
        });
        window.$(".bs-select").trigger("change");
        window.App.unblockUI();
      })
      .catch((e) => {
        window.App.unblockUI();
      });
    ///////////////////////////////
    /****Below is delete control */
    var blockUI = this.props.blockUI;
    var Aurl = this.props.baseUrl;
    $("#dt_users").on("click", ".btn-danger", function () {
      $(this).closest("tr").remove();
      var childKey = $(this).attr("delete_id");
      blockUI(true);
      fetch(Aurl + "/index.php/User/Delete_user/" + childKey, {
        method: "GET",
        credentials: "include",
      })
        .then((blob) => {
          return blob.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            window.displayAlert("#alert_container", data.msg, "success");
          } else {
            window.displayAlert("#alert_container", data.msg, "danger");
          }
          blockUI(false);
        })
        .catch((e) => {
          blockUI(false);
        });
    });
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp;
    if (show_alert !== undefined) {
      window.displayAlert(
        "#alert_container",
        window.global_alert_msg,
        "success"
      );
      window.global_alert_msg = "";
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Users" subtitle="view" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>

                      <div className="portlet light portlet-fit ">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Users
                            </span>
                          </div>
                          <div className="actions">
                            <b>Available Dialing Seats: </b>
                            <label className="label label-sm label-success">
                              {this.state.available_seats}
                            </label>
                            &nbsp;
                            <a
                              href="#/admin/users/add"
                              className="btn btn-default btn-circle btn-sm"
                            >
                              Add New User
                            </a>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_users"
                          >
                            <thead>
                              <tr>
                                <th>User ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>

                                <th>Dialing</th>

                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserList;
