import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class ScriptEdit extends Component {
  state = {
    script_id: "",
    script_name: "",
    //script_comments: "",
    script_text: "",
    //active: "N"
    //script_color: ""
  };
  componentDidMount() {
    window.resizeHandle();
    window.load_select2(".bs-select");
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/scriptadd.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////
    fetch(
      this.props.baseUrl +
        "/index.php/Script/Edit/" +
        this.props.match.params.script_id,
      { method: "GET", credentials: "include" }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp);
        this.setState({
          script_id: resp.msg.script_id,
          script_name: resp.msg.script_name,
          //script_comments: resp.msg.script_comments,
          script_text: resp.msg.script_text,
          //active: resp.msg.active
          //script_color: resp.msg.script_color
        });
        console.log(resp.msg.script_text);
        window
          .$("#summernote_1")
          .summernote("code", $("<div/>").html(resp.msg.script_text).text());
        window.$(".bs-select").trigger("change");
      });

    fetch(this.props.baseUrl + "/index.php/Script/list_lead_fields", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.text())
      .then((resp) => {
        $("#field_name").html(resp);
      });
  }
  handleAddScript = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Script/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_add_script").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Script" subtitle="add" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_add_script"
                                onSubmit={this.handleAddScript}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_script"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Script ID</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_id"
                                          value={this.state.script_id}
                                        />
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td>Script Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_name"
                                          value={this.state.script_name}
                                          onChange={(ev) => {
                                            this.setState({
                                              script_name: ev.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Script Comments</td>
                                      <td >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_comments"
                                          value={this.state.script_comments}
                                          onChange={ev => {
                                            this.setState({
                                              script_comments: ev.target.value
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td>Insert Variable</td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <select
                                              style={{ paddingLeft: "2px" }}
                                              id="field_name"
                                              className="bs-select form-control"
                                            >
                                              <option value=""></option>
                                            </select>
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="AppendFieldName"
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{" "}
                                              append
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Script Text</td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          name="script_text"
                                          id="summernote_1"
                                        ></textarea>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Active</td>
                                      <td >
                                        <select
                                          name="active"
                                          className="form-control bs-select"
                                          value={this.state.active}
                                          onChange={ev => {
                                            this.setState({
                                              active: ev.target.value
                                            });
                                          }}
                                        >
                                          <option value="Y">Active</option>
                                          <option value="N">Inactive</option>
                                        </select>
                                      </td>
                                        </tr>*/}
                                    {/*<tr>
                                      <td>Script Color</td>
                                      <td >
                                        <input
                                          type="text"
                                          className="demo form-control"
                                          name="script_color"
                                          data-control="saturation"
                                          value={this.state.script_color}
                                          onChange={ev => {
                                            this.setState({
                                              script_color: ev.target.value
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScriptEdit;
