import React, { Component } from "react";
class DispoStatusesAll extends Component {
  state = {
    lists: [],
    id: "last_call_dispo_LA",
    name: "last_call_dispo",
    inmodal: false,
    ismulti: false
  };
  componentDidMount() {
    if (this.props.id !== undefined) this.setState({ id: this.props.id });
    if (this.props.name !== undefined) this.setState({ name: this.props.name });
    if (this.props.inmodal !== undefined) {
      this.setState({ inmodal: this.props.inmodal });
    }
    if (this.props.ismulti !== undefined) {
      this.setState({ ismulti: this.props.ismulti });
    }
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Ajax/listAllStatuses", {
      credentials: "include",
      method: "get"
    })
      .then(data => {
        //console.log(data);
        return data.json();
      })
      .then(data => {
        console.log(data);
        var lists = [];
        for (var i = 0; i < data.length; i++) {
          var list_ob = {
            id: data[i].status,
            name: data[i].status_name
          };
          lists.push(list_ob);
        }
        //console.log(lists);
        this.setState({ lists });
        // window.load_select2("#last_call_dispo_LA");

        if (!this.state.ismulti)
          if (this.state.inmodal == true) {
            window.load_select2_in_modal("#" + this.state.id);
          } else {
            window.load_select2("#" + this.state.id);
          }
        else window.$("#" + this.state.id).multiSelect();
      })
      .catch(err => {});
  }
  render() {
    let dd_list = this.state.lists.map(function(item, i) {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <select
        name={this.state.name}
        id={this.state.id}
        className={"form-control " + this.props.class}
        multiple={this.state.ismulti ? "multiple" : this.state.ismulti}
      >
        <option></option>
        {dd_list}
      </select>
    );
  }
}

export default DispoStatusesAll;
