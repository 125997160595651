import React, { Component } from "react";
import $ from "jquery";
import HeadTitle from "../../HeadTitle";
class ScriptAdd extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
    //load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/scriptadd.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////

    fetch(this.props.baseUrl + "/index.php/Script/list_lead_fields", {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.text())
      .then((resp) => {
        $("#field_name").html(resp);
      });
  }
  handleAddScript = (form) => {
    form.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Script/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_add_script").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
          /*//after 3 seconds take back to list page
          setInterval(function() {
            window.location.href = "#/admin/scripts/view";
          }, 1500);*/
          this.props.history.push("/admin/scriptsview/true");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.props.blockUI(false);
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Script
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;Add New</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className=" col-md-12">
                              <form
                                id="form_add_script"
                                onSubmit={this.handleAddScript}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_new_script"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td width="150px">
                                        Script ID <br />
                                        <small>3 or 4 digits only</small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_id"
                                          maxLength="4"
                                          minLength="3"
                                          pattern="^[0-9]+$"
                                          title="Script ID should consist of numeric characters only"
                                          required
                                        />
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td>Script Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_name"
                                          required
                                        />
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Script Comments</td>
                                      <td >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="script_comments"
                                        />
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td>Insert Variable</td>
                                      <td>
                                        <div className="input-group">
                                          <div className="input-icon">
                                            <select
                                              style={{ paddingLeft: "2px" }}
                                              id="field_name"
                                              className="bs-select form-control"
                                            >
                                              <option value=""></option>
                                            </select>
                                          </div>
                                          <span className="input-group-btn">
                                            <button
                                              id="AppendFieldName"
                                              className="btn green-jungle"
                                              type="button"
                                            >
                                              <i className="fa fa-arrow-left fa-fw"></i>{" "}
                                              append
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Script Text</td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          name="script_text"
                                          id="summernote_1"
                                        ></textarea>
                                      </td>
                                    </tr>
                                    {/*<tr>
                                      <td>Active</td>
                                      <td >
                                        <select
                                          name="active"
                                          className="form-control"
                                        >
                                          <option value="Y">Active</option>
                                          <option value="N">Inactive</option>
                                        </select>
                                      </td>
                                    </tr>*/}
                                    {/*<tr>
                                      <td>Script Color</td>
                                      <td >
                                        <input
                                          type="text"
                                          className="demo form-control"
                                          name="script_color"
                                          data-control="saturation"
                                          value="#0088cc"
                                        />
                                      </td>
                                    </tr>*/}
                                    <tr>
                                      <td
                                        colspan="2"
                                        className="hidden-xs text-center"
                                      >
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScriptAdd;
