import React, { Component } from "react";
class HeadTitle extends Component {
  state = { title: "", customer_number: "" };
  componentDidMount() {
    this.setState(
      { title: this.props.title, subtitle: this.props.subtitle },
      () => {
        this.setState({ customer_number: window.location.host.split(".")[0] });
      }
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.title !== this.state.title) {
      this.setState({ title: this.props.title });
    }
    if (this.props.subtitle !== this.state.subtitle) {
      this.setState({ subtitle: this.props.subtitle });
    }
  }
  render() {
    return (
      <div className="page-head">
        <div className="container">
          <div className="page-title">
            <h1>
              {this.state.title ? this.state.title : "Dashboard"}
              <small>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.subtitle ? this.state.subtitle : ""}
              </small>
            </h1>
          </div>
          {this.props.children}
          <div
            style={{ height: "50px", paddingTop: "17px", paddingRight: "5px" }}
            className="page-toolbar"
          >
            <small style={{fontSize:"100%"}}>Customer # <span style={{ textTransform: "uppercase"}}>{this.state.customer_number}</span></small>
          </div>
        </div>
      </div>
    );
  }
}

export default HeadTitle;
