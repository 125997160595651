import React, { Component } from "react";
import HeadTitle from "../HeadTitle";
class MyContactList extends Component {
  state = {};
  componentDidMount() {
    window.resizeHandle();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            
            <HeadTitle title="Lists" subtitle="view" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit  portlet-datatable">
                        <div
                          className="portlet-title"
                          style={{ marginBottom: 0 }}
                        >
                          <div className="caption">
                            <i className="fa fa-leaf"></i>
                            <span className="caption-subject font-dark sbold uppercase">
                              Contacts
                            </span>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <div className="table-container">
                            <table
                              className="table table-striped table-bordered table-hover table-checkable"
                              id="datatable_ajax"
                            >
                              <thead>
                                <tr role="row" className="heading">
                                  <th width="2%">
                                    <input
                                      type="checkbox"
                                      className="group-checkable"
                                    />
                                  </th>
                                  <th width="10%"> List ID </th>
                                  <th width="16%"> Full Name </th>
                                  <th width="10%"> Phone </th>
                                  <th width="10%"> Email </th>
                                  <th width="14%"> Lead Status </th>
                                  <th width="10%"> Owner </th>
                                  <th width="16%"> Date Modified </th>
                                  <th width="12%"> Actions </th>
                                </tr>
                                <tr role="row" className="filter">
                                  <td> </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="list_id"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="name"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="phone"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="email"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="lead_status"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="owner"
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control form-filter input-sm"
                                      name="modify_date"
                                    />{" "}
                                  </td>
                                  <td>
                                    <button className="btn btn-sm green btn-outline filter-submit margin-bottom">
                                      <i className="fa fa-search"></i>
                                    </button>
                                    <button className="btn btn-sm red btn-outline filter-cancel">
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </td>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyContactList;
