import React, { Component } from "react";
class ScriptsDropDown extends Component {
  state = { scripts: [], script: "" };
  componentDidMount() {
    //fetch lists
    fetch(this.props.baseUrl + "/index.php/Script/Ajax", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams("action=list_scripts"),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        var scripts = [{ id: "", name: "" }];
        for (var i = 0; i < data.length; i++) {
          if (data[i].key == "") continue;
          var list_ob = {
            id: data[i].key,
            name: data[i].value,
          };
          scripts.push(list_ob);
        }
        //console.log(lists);
        this.setState({ scripts });

        //window.load_select2("#" + this.props.id);
        this.setState({ script: this.props.script });
        // alert(this.props.script);
        window.$("#" + this.props.id).trigger("change");

        this.props.onLoad(true);
      })
      .catch((err) => {
        try {
          this.props.onLoad(false);
        } catch (e) {}
      });
  }
  render() {
    let dd_script = this.state.scripts.map(function (item, i) {
      return (
        <option key={item.id === "" ? "15500" : item.id} value={item.id}>
          {item.id == "" ? "NO" : item.name + " (" + item.id + ") "}
        </option>
      );
    });
    return (
      <select
        name={this.props.name}
        id={this.props.id}
        className={this.props.class + " form-control"}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {dd_script}
      </select>
    );
  }
}

export default ScriptsDropDown;
